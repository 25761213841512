import { Filter } from "../../components/filters";
import { taskStatuses } from "../../entities/ITask";
import { buildDateQueryOperator } from "../../components/filters/DateRangeFilter/utility/buildDateQuery";

export type TaskFilters = {
  status: Filter;
  "user=>id": Filter;
  basketId: Filter;
  createdAt: Filter;
  "createdBy=>id": Filter;
};
export const tasksTableFilters = (
  removeFilter: (keyof TaskFilters)[],
  t: any
): Partial<TaskFilters> => {
  const filters: TaskFilters = {
    status: {
      type: "multiple-choice",
      name: "FILTERS.STATUSES",
      defaultValues: ["onhold", "assigned", "inprogress"],
      options: [
        ...Object.keys(taskStatuses).map((item) => ({
          label: t(`TASK_STATUS.${item}`),
          value: item,
        })),
      ],
      buildQuery: (filter, values) => {
        return {
          name: "status",
          type: "stringArray",
          operator: "in",
          value: JSON.stringify(values),
        };
      },
    },
    "user=>id": {
      type: "multiple-choice-autocomplete",
      name: "FILTERS.ASSIGNEES",
      options: [{ label: "users", value: "users" }],
      buildQuery: (filter, values) => {
        return {
          name: "user=>id",
          type: "numberArray",
          operator: "in",
          value: JSON.stringify(values),
        };
      },
    },
    "createdBy=>id": {
      type: "multiple-choice-autocomplete",
      name: "FILTERS.CREATED_BY",
      options: [{ label: "users", value: "users" }],
      buildQuery: (filter, values) => {
        return {
          name: "createdBy=>id",
          type: "numberArray",
          operator: "in",
          value: JSON.stringify(values),
        };
      },
    },
    basketId: {
      type: "multiple-choice-autocomplete",
      name: "FILTERS.BASKETS",
      options: [{ label: "baskets", value: "baskets" }],
      buildQuery: (filter, values) => {
        return {
          name: "basketId",
          type: "numberArray",
          operator: "in",
          value: JSON.stringify(values),
        };
      },
    },
    createdAt: {
      type: "date-range",
      name: "FILTERS.DATE_CREATED",
      buildQuery: (filter, values) => {
        const { value, operator, type } = buildDateQueryOperator(values);
        return {
          name: "createdAt",
          type,
          operator,
          value: JSON.stringify(value),
        };
      },
    },
  };

  removeFilter.forEach((filter) => {
    delete filters[filter];
  });

  return filters;
};

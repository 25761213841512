import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GenericForm } from "../../components/generic-form";
import AutocompleteOpt from "../../components/autocomplete-opt/AutocompleteOpt";
import { LinkedDocumentTypesModalProps } from "./DocumentTypes.types";

export const LinkDocumentTypesModal = ({
  isVisible,
  onDismiss,
  onSubmit,
  linking,
  linkedDocumentTypeForm,
  onSetLinkedDocumentType,
  selectedItemId,
  linkedDocumentType,
}: LinkedDocumentTypesModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal show={isVisible} animation onHide={onDismiss}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {t("DOCUMENT_TYPE.CREATE_LINKED_DOCUMENT_TYPE")}
        </h5>
        <div onClick={onDismiss}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <AutocompleteOpt
          excluded={[selectedItemId]}
          defaultValue={undefined}
          label={t("DOCUMENT_TYPE.LINKED_DOCUMENT_TYPE")}
          placeholder={t("DOCUMENT_TYPE.SELECT_DOCUMENT_TYPE")}
          onSelectOption={onSetLinkedDocumentType as any}
          endpoint={"/document-types/search-autocomplete"}
        />
        {!!linkedDocumentType ? (
          <GenericForm form={linkedDocumentTypeForm as any} onSubmit={onSubmit} />
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

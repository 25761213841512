import { useTranslation } from "react-i18next";
import { FiltersWithQuery } from "../../components/filters/FiltersWithQuery";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchWithQueryInput } from "../../components/search-input";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { useArchive } from "./useArchive";

export function ArchiveScreen() {
  const { t } = useTranslation();
  const {
    docTableData,
    count,
    archiveTableHeaders,
    loading,
    onRowPress,
    title,
    archiveFilters,
    backBtn,
  } = useArchive();
  return (
    <PageLayout
      search={<SearchWithQueryInput placeHolder={t("ARCHIVE.SEARCH")} className="ml-auto" />}
      filter={<FiltersWithQuery filters={archiveFilters} />}
      backBtn={backBtn}
      title={title}
    >
      <Row>
        <Column>
          <TableWithQueryPaginationAndSort
            headers={archiveTableHeaders}
            data={docTableData}
            totalItems={count}
            onRowPress={onRowPress}
            selection={false}
            loading={loading}
          />
        </Column>
      </Row>
    </PageLayout>
  );
}

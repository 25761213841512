import { useTranslation } from "react-i18next";
import { Button } from "../../components/button";
import { Column, Container, Row } from "../../components/grid";
import { ReverseAuthGuard } from "../../components/guard/ReverseAuthGuard";
import assests from "../../config/assets";
import { useLogin } from "./useLogin";

export function LoginScreen() {
  const {t} = useTranslation()
  const { authorizeWithOidc } = useLogin();
  return (
    <ReverseAuthGuard>
      <Container fluid>
        <Row>
          <Column>
            <div className="d-flex justify-content-center mt-5">
              <div className="p-5 d-flex flex-column justify-content-center mt-5">
                <img src={assests.images.logoBigOnLight} alt="A1 Logo" className="mb-5 mt-5 mx-auto" />
                <Button loader={{ size: "s" }} className="btn btn-primary mt-5 w-100" onClick={authorizeWithOidc}>
                  {t("LOG_IN")}
                </Button>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </ReverseAuthGuard>
  );
}

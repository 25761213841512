import { ChangeEvent } from "react";
import assests from "../../config/assets";
import { Icon } from "../../modules/icon";
import { FilePreviewProps } from "./FilePreview.types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../header/Header";
import { useFilePreview } from "./useFilePreview";
import { Loader } from "../../modules/loader";

export function FilePreview({
  type,
  height = 200,
  width = 200,
  url: serverUrl,
  name,
  enableEditing = false,
  enableCanceling = false,
  onFileChosen,
  onCancel,
  classNameContainer,
  protectedUrl = false,
}: FilePreviewProps) {
  const { t } = useTranslation();
  const { url, loading } = useFilePreview(serverUrl);
  const renderFile = () => {
    switch (type) {
      case "application/pdf":
        return (
          <iframe
            title="File preview"
            width={width}
            height={height}
            className="border rounded"
            src={url}
          />
        );

      case "image/png":
      case "image/jpeg":
        return <img alt="File preview" src={url} height={height} width={width} />;
      default:
        return (
          <>
            {url && (
              <Link to={url} target="_blank" rel="noreferrer">
                <Icon
                  type="fa-solid fa-file"
                  size={50}
                  color={"black"}
                  title={t("DOCUMENTS.SEE_FILE")}
                />
              </Link>
            )}
          </>
        );
    }
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) return;
    if (onFileChosen) {
      onFileChosen(file);
    }
  };
  return (
    <div className={classNameContainer}>
      <div className="d-flex flex-row align-items-center">
        <Header
          size="h6"
          className="w-100"
          title={name}
          actions={
            <>
              {enableCanceling ? (
                <div className="ml-auto cursor-pointer" onClick={onCancel}>
                  <Icon type="fa-solid fa-xmark" size={24} />
                </div>
              ) : null}
              {!enableEditing && !enableCanceling ? (
                <a href={url} target="_blank" rel="noreferrer" className="ml-auto cursor-pointer">
                  <Icon type="fa-solid fa-up-right-from-square" color="black" size={16} />
                </a>
              ) : null}
              {enableEditing ? (
                <div className="position-relative">
                  <input
                    onChange={onFileChange}
                    type="file"
                    style={{
                      height: 24,
                      width: 33,
                      top: 2,
                      zIndex: 2,
                      opacity: 0,
                      position: "absolute",
                      left: 0,
                      cursor: "pointer",
                      fontSize: 0,
                    }}
                  />
                  <img src={assests.images.reupload} height={24} width={33} alt="Reupload" />
                </div>
              ) : null}
            </>
          }
        />
      </div>
      {<div className="mt-3 text-center">{loading ? <Loader color="black" /> : renderFile()}</div>}
    </div>
  );
}

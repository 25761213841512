import { useEffect, useState } from "react";
import { TaskStatuses, taskStatusesColors } from "../../entities/ITask";
import useBaseRequest from "../../api/BaseRequest";
import TaskGateway from "../../api/gateways/TaskGateway";
import { useToast } from "../../components/toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TableRow } from "../../components/table";
import moment from "moment";
import { Icon } from "../../modules/icon";
import { screens } from "../Navigation.types";
import { Badge } from "../../components/badge";
import { BadgeTypes } from "../../components/badge/Badge.types";
import { useCounterService } from "../../services/useCounterService";
import { useTranslation } from "react-i18next";

const useTasks = () => {
  const { danger, success } = useToast();
  const navigate = useNavigate();
  const onRowPress = (id: number) => {
    navigate(screens.task.root.replace(":id", id.toString()));
  };
  const [tasks, setTasks] = useState<TableRow[]>([]);
  const [total, setTotal] = useState<number>(0);
  const location = useLocation();
  const { refreshTaskCounters } = useCounterService();
  const { t } = useTranslation();
  useEffect(() => {
    getData(location.search);
  }, [location.search]);

  const { execute: getData, loading } = useBaseRequest(TaskGateway.getTasks, {
    onCompleted: (data) => {
      if (data) {
        const taskTable: TableRow[] = [];

        data.tasks.forEach((item) => {
          taskTable.push([
            item.id,
            item.title,
            <Badge type={taskStatusesColors[item.status] as BadgeTypes} className="fw-bold">
              {t(`TASK_STATUS.${item.status}`)}
            </Badge>,
            <Link to={screens.basketTasks.replace(":id", item.basketId.toString())}>
              {data.baskets
                ? data.baskets.find((it) => it.id === item.basketId)?.name || ""
                : "Basket"}
            </Link>,

            item.createdBy.name,
            moment(item.createdAt).format("DD.MM.YYYY"),
            <>
              <Link to={screens.task.root.replace(":id", item.id.toString())}>
                <Icon
                  title={t("BASKET_TASKS.SEE_TASK_INFO")}
                  type="fa-solid fa-circle-info"
                  color="black"
                  size={16}
                />
              </Link>
              {!!item.comments.length && (
                <Link
                  to={screens.task.root.replace(":id", item.id.toString()) + `/comments`}
                  title={
                    `${t("BASKET_TASKS.LAST_COMMENT")}: ` +
                    item.comments[item.comments.length - 1].text
                  }
                >
                  <Icon type="fa-solid fa-message" color="black" size={16} />
                </Link>
              )}
            </>,
          ]);
        });
        setTasks(taskTable);
        setTotal(data.count);
      }
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  //Bulk actions
  const [selectedTasks, setSelectedTasks] = useState<number[]>([]);
  const [selectedAction, setSelectedAction] = useState<"" | "assign" | "move">("");
  const { execute: reassignBulkTasks, loading: reassigningTasks } = useBaseRequest(
    TaskGateway.reassignBulkTasks,
    {
      onCompleted: () => {
        setSelectedTasks([]);
        setSelectedAction("");
        getData(location.search);
        refreshTaskCounters();
        success(t("TASKS.REASSIGNED_MESSAGE"));
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );
  const { execute: moveBulkTasks, loading: movingTasks } = useBaseRequest(
    TaskGateway.moveToAnotherBasketBulkTasks,
    {
      onCompleted: () => {
        setSelectedTasks([]);
        setSelectedAction("");
        getData(location.search);
        success(t("TASKS.MOVED_MESSAGE"));
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  return {
    tasks,
    total,
    getData,
    onRowPress,
    loading,
    selectedTasks,
    setSelectedTasks,
    selectedAction,
    setSelectedAction,
    reassignBulkTasks,
    reassigningTasks,
    moveBulkTasks,
    movingTasks,
  };
};

export default useTasks;

import { Filter } from "../../components/filters";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
import { IOption } from "../../components/select";
import { TableHeaders } from "../../components/table";
import {
  CreateDocumentTypeDTO,
  IDocumentType,
  IRelatedDocumentType,
  IRelatedDocumentTypeDTO,
} from "../../entities/IDocumentType";
import { PermissionAccessType } from "../../entities/IRolePermission";

export const documentTypeTableHeaders: TableHeaders[] = [
  { name: "TITLE", key: "name" },
  { name: "DOCUMENT_CATEGORY", key: "docCategory" },
  { name: "PROVIDER", key: "provider" },
  { name: "OCR_DOCUMENT_TYPE_KEY", key: "ocrKeyMapping" },
  { name: "ACTIONS", key: "action" },
];

export type DocumentTypeFilterKeys = {
  docCategoryId: Filter;
};

export const createDocumentTypeForm: GenericForm<CreateDocumentTypeDTO> = {
  name: {
    type: "input",
    name: "name",
    label: "TABLE.NAME",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
  docCategoryId: {
    type: "select",
    name: "documentCategoryId",
    label: "TABLE.DOCUMENT_CATEGORY",
    defaultValue: 0,
    options: [],
    validators: [GenericFormValidator.required()],
    props: { skipOptionTranslation: true },
  },
  customFields: {
    type: "autocompleteMultipleChoice",
    name: "customFields",
    label: "DOCUMENT_TYPE.CUSTOM_FIELDS",
    options: [{ value: "metadata", label: "Custom fields" }],
    defaultValue: [],
    filters: `${JSON.stringify({
      name: "default",
      type: "boolean",
      operator: "ne",
      value: "true",
    })}`,
  },
  providerId: {
    type: "autocompleteSingleChoice",
    name: "providerId",
    label: "DOCUMENT_TYPE.PROVIDER",
    options: [{ value: "providers", label: "Providers" }],
    defaultValue: undefined,
  },
};
export const linkDocumentTypesForm: GenericForm<IRelatedDocumentTypeDTO> = {
  sourceDocumentTypeId: {
    type: "hidden",
    name: "documentTypeId",
    defaultValue: undefined,
    validators: [GenericFormValidator.required()],
  },
  triggerLabelId: {
    type: "autocompleteSingleChoice",
    name: "triggerLabelId",
    label: "DOCUMENT_TYPE.TRIGGER_LABEL",
    options: [{ value: "labels", label: "Labels" }],
    defaultValue: -1,
    validators: [GenericFormValidator.required()],
  },
  targetDocumentTypeId: {
    type: "hidden",
    name: "targetDocumentTypeId",
    defaultValue: -1,
    options: [{ value: "document-types", label: "Document type" }],
    validators: [GenericFormValidator.required()],
  },
  initiatorItemId: {
    type: "select",
    name: "initiatorItemId",
    label: "DOCUMENT_TYPE.SOURCE_DOCUMENT_TYPE_IDENTIFIER_FIELD",
    defaultValue: "" as any,
    options: [],
    validators: [GenericFormValidator.required()],
  },
  responderItemId: {
    type: "select",
    name: "responderItemId",
    label: "DOCUMENT_TYPE.LINKED_DOCUMENT_TYPE_IDENTIFIER_FIELD_LABEL",
    defaultValue: "" as any,
    options: [],
    validators: [GenericFormValidator.required()],
  },
};

export interface CreateDocumentTypeModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  onSubmit: (data: CreateDocumentTypeDTO & { linkedDocumentTypes: IRelatedDocumentType[] }) => void;
  loading: boolean;
  form: GenericForm<CreateDocumentTypeDTO>;
}

export interface LinkedDocumentTypeModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  selectedItem: IDocumentType | null;
}

export interface DocumentTypeAccessConfigForm {
  [key: string]: string;
}

export const linkedDocumentHeader: TableHeaders[] = [
  { key: "documentType", name: "DOCUMENT_TYPE" },
  {
    key: "initiatorItem",
    name: "SOURCE_DOCUMENT_TYPE_IDENTIFIER_FIELD",
  },
  {
    key: "responderItem",
    name: "LINKED_DOCUMENT_TYPE_IDENTIFIER_FIELD_LABEL",
  },
  { name: "TRIGGER_LABEL", key: "triggerLabel" },
  { name: "ACTIONS", key: "action" },
];

export interface LinkedDocumentTypesModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  onSubmit: (data: IRelatedDocumentTypeDTO) => void;
  linking: boolean;
  linkedDocumentTypeForm: GenericForm<IRelatedDocumentTypeDTO>;
  onSetLinkedDocumentType: (data: IOption<number>) => void;
  selectedItemId?: number;
  linkedDocumentType: IOption<number> | undefined;
}

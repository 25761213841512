import { useTranslation } from "react-i18next";
import { Button } from "../../components/button";
import { FiltersWithQuery } from "../../components/filters";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchWithQueryInput } from "../../components/search-input";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { Icon } from "../../modules/icon";
import { useDocuments } from "./useDocuments";
import { CreateArchiveBookItemModal } from "./CreateArchiveBookItemModal";
import { ConfigurationGuard } from "../../components/guard";
import environment from "../../config/environment";

export function DocumentsScreen() {
  const { t } = useTranslation();

  const {
    docTableData,
    count,
    setSelectedDocuments,
    selectedDocuments,
    showArchiveBookModal,
    onArchiveBookItemCreate,
    closeArchiveBookModal,
    openArchiveBookModal,
    documentTableHeaders,
    loading,
    onRowPress,
    documentFilters,
    documentCategories,
  } = useDocuments();

  return (
    <PageLayout
      title={t("DOCUMENTS.TITLE")}
      search={<SearchWithQueryInput placeHolder={t("DOCUMENTS.SEARCH")} className="ml-auto" />}
      filter={<FiltersWithQuery filters={documentFilters} />}
    >
      {selectedDocuments && selectedDocuments.length ? (
        <ConfigurationGuard
          fallback={{ kind: "hide" }}
          isEnabled={environment.modulesEnabled.archive}
        >
          <Row>
            <Column className="mb-2">
              <Button className="btn btn-secondary" onClick={openArchiveBookModal}>
                <Icon type="fa-solid fa-box" />
                &nbsp; {t("ARCHIVE_BOOK_ITEM.CREATE_LABEL")} ({selectedDocuments.length})
              </Button>
              <CreateArchiveBookItemModal
                documentCategories={documentCategories}
                isVisible={showArchiveBookModal}
                onDismiss={closeArchiveBookModal}
                selectedDocuments={selectedDocuments}
                onCreate={onArchiveBookItemCreate}
              />
            </Column>
          </Row>
        </ConfigurationGuard>
      ) : null}
      <Row>
        <Column>
          <TableWithQueryPaginationAndSort
            headers={documentTableHeaders}
            data={docTableData}
            totalItems={count}
            onRowPress={onRowPress}
            selection={environment.modulesEnabled.archive && {
              onSelectionChanged: setSelectedDocuments,
              selected: selectedDocuments,
            }}
            loading={loading}
          />
        </Column>
      </Row>
    </PageLayout>
  );
}

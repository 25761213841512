import { Comments } from "../../components/comments";
import { Loader } from "../../modules/loader";
import { PinCommentModal } from "./PinCommentModal";
import useTaskComments from "./useTaskComments";

const TaskComments = () => {
  const {
    comments,
    showMoreComments,
    showCommentForm,
    hideCommentFormHandler,
    addCommentToTask,
    addingComment,
    taskAddCommentForm,
    showCommentFormHandler,
    setShowMoreComments,
    loadingComments,
    togglePin,
    showAttachmentForm,
    dismissAttachmentForm,
    addAttachment,
    addingAttachment,
    addAttachmentForm,
  } = useTaskComments();
  if (loadingComments) {
    return (
      <div className="w-100 flex-row justify-content-center">
        <Loader size="l" color="black" />
      </div>
    );
  }
  if (!comments) {
    return <div>Comments not found</div>;
  }
  return (
    <>
      <PinCommentModal
        show={showAttachmentForm}
        onDismiss={dismissAttachmentForm}
        onSubmit={addAttachment}
        adding={addingAttachment}
        addAttachmentForm={addAttachmentForm as any}
      />
      <Comments
        comments={comments}
        showMoreComments={showMoreComments}
        addModalData={{
          show: showCommentForm,
          onCancel: hideCommentFormHandler,
          onSubmit: addCommentToTask,
          addingComment: addingComment,
          taskAddCommentForm: taskAddCommentForm,
        }}
        togglePin={togglePin}
        showAddCommentModal={showCommentFormHandler}
        showMoreCommentsToggle={() => {
          setShowMoreComments((prev) => !prev);
        }}
      />
    </>
  );
};

export default TaskComments;

import { useTranslation } from "react-i18next";
import { useToast } from "../../toast";
import { useUser } from "../../../store/auth/AuthSelectors";
import { useAppDispatch } from "../../../store";
import { useRpLogout } from "../../../modules/oidc";
import environment from "../../../config/environment";
import { authActions } from "../../../store/auth/AuthReducer";
import { useJwt } from "../../../modules/jwt";
import useBaseRequest from "../../../api/BaseRequest";
import { useNavigate } from "react-router-dom";
import { screens } from "../../../screens/Navigation.types";

export const useLogout = () => {
  const { t } = useTranslation();
  const { danger } = useToast();
  const user = useUser();
  const dispatch = useAppDispatch();
  const { getLogoutUrl } = useRpLogout();
  const navigate = useNavigate();
  
  const logOut = () => {
    if (user) {
      // If id_token expired, clear the state and navigate to login
      if (user.exp && new Date(+user.exp * 1000) < new Date()) {
        dispatch(authActions.setUser(null));
        navigate(screens.login);
      } else {
        // If id_token is valid, send logout request to oidc provider
        const logoutUrl = getLogoutUrl({
          postlogoutUrl: `${window.location.origin}/login`,
          id_token: user.id_token,
          logoutEndpoint: environment.oidc.endSessionEndpoint,
        });
        dispatch(authActions.setUser(null));
        window.location.href = logoutUrl;
      }
    } else {
      danger(t("ERRORS.UNKNOWN"));
    }
  };

  return { logOut };
};

import axios, { AxiosResponse } from "axios";
import { keyList } from "../../modules/jwt";
import BaseInstance from "../BaseInstance";

export interface tokenResponse {
  id_token: string;
  access_token: string;
}

interface keyListResponse {
  keys: keyList;
}

const AuthGateway = {
  getUserToken: async (tokenUrl: string): Promise<AxiosResponse<tokenResponse>> => {
    return axios.post(tokenUrl);
  },

  getKeyList: async (keyListUrl: string): Promise<AxiosResponse<keyListResponse>> => {
    return axios.post(keyListUrl);
  },

  getUserData: async (dataUrl: string, token: string) => {
    return axios.get(dataUrl, { headers: { Authorization: `Bearer ${token}` } });
  },
  syncUser: async ({
    token,
  }: {
    token: string;
    user: any;
    id_token: string;
  }): Promise<AxiosResponse<number>> => {
    return axios.post(
      BaseInstance.defaults.baseURL + "users/sync",
      {},
      { headers: { Authorization: token } }
    );
  },
};

export default AuthGateway;

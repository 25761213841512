import { FilterProps } from "./Filters.types";
import { Button } from "../button";
import { Icon } from "../../modules/icon";
import { DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import React, { ChangeEvent, useEffect } from "react";
import "./Filters.style.css";
import { useTranslation } from "react-i18next";

export function SingleChoiceFilter({ filter, onRemove, onFilterSet }: FilterProps) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<any>(null);
  const [appliedValue, setAppliedValue] = React.useState<any>(null);
  const [dataType, setDataType] = React.useState<"number" | "string" | null>();

  const setValueBasedOnType = (val: any) => {
    if (dataType === "number") {
      return +val;
    }
    return val;
  };
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(setValueBasedOnType(event.target.value));
  };

  useEffect(() => {
    if (filter.options) {
      setDataType(typeof filter.options[0].value === "number" ? "number" : "string");
    }
    if (
      filter.defaultValues === "" ||
      filter.defaultValues === undefined ||
      filter.defaultValues === null ||
      !filter
    )
      return;

    setValue(filter.defaultValues);
    setAppliedValue(filter.defaultValues);
  }, []);

  const applyFilter = () => {
    if (value === null) return;
    setAppliedValue(value);
    onFilterSet(filter.buildQuery(filter, value), filter);
  };

  return (
    <div className="mr-3 my-1">
      <div className="btn-group">
        <DropdownButton
          as={ButtonGroup}
          variant="outline-dark"
          onToggle={(show) => {
            if (show) {
              setValue(appliedValue);
            }
          }}
          onSelect={() => true}
          title={
            <>
              {t(filter.name)}
              {appliedValue !== null && appliedValue !== undefined && appliedValue !== ""
                ? `: ${filter.options?.find((o) => o.value === appliedValue)?.label}`
                : null}
              &nbsp;
            </>
          }
        >
          <div>
            <div className="px-2 pt-2">
              {filter.options?.map((option, key) => (
                <div className="form-check" key={key}>
                  <input
                    className="form-check-input"
                    type="radio"
                    id={option.value.toString()}
                    name={filter.name}
                    value={option.value}
                    onChange={onChange}
                    checked={value === setValueBasedOnType(option.value)}
                  />
                  <label className="form-check-label" htmlFor={option.value.toString()}>
                    {option.label}
                  </label>
                </div>
              ))}
              <div className="apply" style={{ position: "sticky", bottom: 0 }}>
                <Dropdown.Item className="btn btn-primary mt-3" onClick={applyFilter}>
                  {t("APPLY")}
                </Dropdown.Item>
              </div>
            </div>
          </div>
        </DropdownButton>
        <Button className="btn btn-outline-dark" onClick={() => onRemove(filter)}>
          <Icon title="Remove" type="fa-solid fa-xmark" size={16} />
        </Button>
      </div>
    </div>
  );
}

import { SideNavHeaderProps } from "./SideNav.types";
import "./SideNav.style.css";
import { Icon } from "../../modules/icon";
import { useNavigate } from "react-router";
import { screens } from "../../screens/Navigation.types";
import "./SideNav.style.css";
import { Button } from "../button";
import { useTranslation } from "react-i18next";
import { Logo } from "../logo";

export function SideNavHeader({ toggle, visibleToggleButton }: SideNavHeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToUpload = () => {
    navigate(screens.uploadDocument);
  };
  return (
    <div className="side-nav-header">
      <div className="d-flex gap-2 align-items-center">
        {visibleToggleButton ? (
          <Icon color="white" className="icon ml-3" type="fa fa-bars" size={28} onClick={toggle} />
        ) : null}
        <Logo isBig={true} />
      </div>
      <div>
        <Button className="btn btn-secondary" onClick={goToUpload}>
          <Icon type="fa fa-upload" />
          &nbsp; {t("DOCUMENTS.UPLOAD")}
        </Button>
      </div>
    </div>
  );
}

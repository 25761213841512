import { useEffect, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";

export const useFilePreview = (url: string) => {
  const { loading, data: file } = useBaseRequest(DocumentGateway.getDocumentFile, {
    autoFetch: !!url,
    initialPayload: url,
  });
  const [localUrl, setLocalUrl] = useState<string | undefined>();

  useEffect(() => {
    if (!file) return;
    const url = URL.createObjectURL(file);
    setLocalUrl(url);
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [file]);

  return { loading, url: localUrl };
};

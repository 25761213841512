import React, { useEffect } from "react";
import { TableData, TableHeaders, TableRow } from "../../components/table";
import {
  ReportsGroupByEnum,
  reportTableFilters,
  reportsTableHeadersAssignedUser,
  reportsTableHeadersBasket,
  reportsTableHeadersDocumentType,
  CSVRow,
} from "./Report.types";
import useBaseRequest from "../../api/BaseRequest";
import ReportGateway from "../../api/gateways/ReportGateway";
import { useLocation, useNavigate } from "react-router-dom";
import { useDocumentService } from "../../services/useDocumentService";
import { useDocumentTypesSelector } from "../../store/documents/DocumentSelector";
import { QueryFilter } from "../../components/filters";
import { screens } from "../Navigation.types";
import { useTranslation } from "react-i18next";
import { useMapToReportTable } from "./utils/useMapToReportTable";

const useReports = () => {
  useDocumentService().useGetDocumentTypes();
  const docTypes: any[] = useDocumentTypesSelector();
  const { removeQueryParams } = useDocumentService();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultTableHeader = () => {
    switch (queryParams.get("groupedBy")) {
      case ReportsGroupByEnum.BASKET:
        return reportsTableHeadersBasket;
      case ReportsGroupByEnum.ASSIGNED_USER:
        return reportsTableHeadersAssignedUser;
      case ReportsGroupByEnum.DOCUMENT_TYPE:
        return reportsTableHeadersDocumentType;
      default:
        return reportsTableHeadersBasket;
    }
  };

  // table
  const [tableHeaders, setTableHeaders] = React.useState<TableHeaders[]>(defaultTableHeader());
  const { t } = useTranslation();
  // fetch reports
  const {
    data,
    execute: getReports,
    loading,
  } = useBaseRequest(ReportGateway.getReports, {
    autoFetch: false,
  });

  const { tableData } = useMapToReportTable(data?.items);

  const {
    data: exportData,
    execute: exportReports,
    loading: exportLoading,
  } = useBaseRequest(ReportGateway.exportReports, {
    autoFetch: false,
    onCompleted: (exportData) => {
      const initialData = exportData
        .split("\n")
        .map((item) => item.trim())
        .filter((item) => item !== "");

      const processData = (data: string[]): CSVRow[] => {
        const processedData: CSVRow[] = [];

        if (data.length < 2) {
          // Handle cases where there are no data lines or just a header line
          return processedData;
        }

        const headers = data[0].split(","); // Get the header line

        for (let i = 1; i < data.length; i++) {
          const line = data[i].split(",");
          const rowData: CSVRow = {};

          for (let j = 0; j < headers.length; j++) {
            const header = headers[j];
            const value = line[j];
            rowData[header] = isNaN(Number(value)) ? value : parseFloat(value); // Convert numeric values to numbers
          }

          processedData.push(rowData);
        }

        return processedData;
      };

      const generateCsv = () => {
        const processedData = processData(initialData);
        const csvContent =
          "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCsv(processedData));
        const link = document.createElement("a");
        link.setAttribute("href", csvContent);
        link.setAttribute("download", "data.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      const convertToCsv = (data: any[]) => {
        const headers = Object.keys(data[0])
          .map((item) => t(`REPORT_HEADERS.${item}`))
          .join(",");

        const rows = data.map((item) => Object.values(item).join(",")).join("\n");
        return headers + "\n" + rows;
      };
      generateCsv();
    },
  });

  useEffect(() => {
    if (docTypes)
      if (!queryParams.get("groupedBy")) {
        queryParams.set("groupedBy", ReportsGroupByEnum.BASKET);
        navigate({ search: queryParams.toString() }, { replace: true });
        return;
      }
    getReports(location.search);
  }, [location.search, docTypes]);

  const onChangeGroupBy = (value: string) => {
    queryParams.delete("groupedBy");

    queryParams.set("groupedBy", value);
    switch (value) {
      case ReportsGroupByEnum.BASKET:
        setTableHeaders(reportsTableHeadersBasket);
        break;
      case ReportsGroupByEnum.ASSIGNED_USER:
        setTableHeaders(reportsTableHeadersAssignedUser);
        break;
      case ReportsGroupByEnum.DOCUMENT_TYPE:
        setTableHeaders(reportsTableHeadersDocumentType);
        break;
      default:
        setTableHeaders(reportsTableHeadersBasket);
        break;
    }

    navigate({ search: queryParams.toString() }, { replace: true });
  };

  const exportReport = () => {
    exportReports(removeQueryParams(location.search, ["skip", "limit"]));
  };

  const setDefaultValues = (name: string, value: any) => {
    if (name === "createdAt") {
      if (Array.isArray(value) && value.length === 2 && (value[0] || value[1])) {
        return [value[0] ? new Date(value[0]) : value[0], value[1] ? new Date(value[1]) : value[1]];
      }
    }
    return undefined;
  };

  const getFilters = () => {
    return [
      {
        ...reportTableFilters[0],
        defaultValues:
          queryParams.getAll("assignedUserIds") && queryParams.get("assignedUserIds")?.length
            ? ([...queryParams.getAll("assignedUserIds")] as string[])
            : undefined,
      },
      {
        ...reportTableFilters[1],
        defaultValues:
          queryParams.getAll("basketIds") && queryParams.get("basketIds")?.length
            ? ([...queryParams.getAll("basketIds")] as string[])
            : undefined,
      },
      {
        ...reportTableFilters[2],
        defaultValues:
          queryParams.getAll("documentTypeIds") && queryParams.get("documentTypeIds")?.length
            ? ([...queryParams.getAll("documentTypeIds")] as string[])
            : undefined,
      },
      {
        ...reportTableFilters[3],
        defaultValues: setDefaultValues("createdAt", [
          queryParams.get("createdAtFrom"),
          queryParams.get("createdAtTo"),
        ]),
      },
    ];
  };

  const onFilterChange = (queryFilters: QueryFilter[]) => {
    ["createdAtFrom", "createdAtTo", "assignedUserIds", "basketIds", "documentTypeIds"].forEach(
      (item) => {
        queryParams.delete(item);
      }
    );
    queryFilters.forEach((item) => {
      if (item.name === "createdAt") {
        const dateRange = item.value;
        if (dateRange[0]) {
          queryParams.append("createdAtFrom", dateRange[0]);
        }
        if (dateRange[1]) {
          queryParams.append("createdAtTo", dateRange[1]);
        }
      } else if (Array.isArray(item.value) && item.value.length > 0) {
        item.value.forEach((value) => {
          queryParams.append(item.name, value.toString());
        });
        if (item.value.length === 1) queryParams.append(item.name, item.value[0].toString());
      } else queryParams.set(item.name, item.value);
    });
    navigate({ search: queryParams.toString() }, { replace: true });
  };

  return {
    loading,
    tableData,
    count: data?.count || 0,
    defaultGroupBy: queryParams.get("groupedBy") || ReportsGroupByEnum.BASKET,
    onChangeGroupBy,
    tableHeaders,
    exportReport,
    onFilterChange,
    reportTableFilters: getFilters(),
    onRowPress: (idBasket: number) => {
      if (queryParams.get("groupedBy") === ReportsGroupByEnum.BASKET)
        navigate(screens.basketTasks.replace(":id", idBasket.toString()));
    },
  };
};

export default useReports;

import "./BasketCard.style.css";
import { BasketCardInfoProps } from "../Basket.types";
import { useTranslation } from "react-i18next";

const BasketCardInfo = ({ name, numberOfTasks }: BasketCardInfoProps) => {
  const { t } = useTranslation();
  return (
    <>
      <span className="basketcard-info-item-counter">
        {numberOfTasks ? (
          <>
            <span className="fw-bold" style={{ fontSize: "14px" }}>
              {numberOfTasks}
            </span>
            {numberOfTasks === 1 ? t("BASKETS.SINGLE_OPEN_TASK") : t("BASKETS.OPEN_TASKS")}
          </>
        ) : (
          t("BASKETS.NO_OPEN_TASKS")
        )}
      </span>
      <span
        className="basketcard-info-name fw-bold"
        data-bs-toggle={name.length > 34 ? "tooltip" : undefined}
        data-bs-placement={name.length > 34 ? "bottom" : undefined}
        title={name.length > 34 ? name : undefined}
      >
        {name.length > 34 ? name.slice(0, 34) + "..." : name}
      </span>
    </>
  );
};

export default BasketCardInfo;

import { TableRow } from "../../../components/table";
import { ILabel } from "../../../entities/ILabel";
import { Icon } from "../../../modules/icon";

export const mapLabelToTableRow = (
  label: ILabel,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void
): TableRow => {
  const onDeleteHandler = (event: any) => {
    event.stopPropagation();
    onDelete(label.id);
  };
  const onEditHandler = (event: any) => {
    event.stopPropagation();
    onEdit(label.id);
  };
  return [
    label.id,
    label.name || "",
    <div className="d-flex flex-row gap-2 align-items-center">
      <div
        style={{
          backgroundColor: label.color || "GrayText",
          width: "16px",
          height: "16px",
          borderRadius: "50%",
        }}
      />
      <label>{label.color}</label>
    </div>,
    <div className="flex-row gap-3 align-items-center">
      <Icon
        title={"DELETE_BUTTON"}
        type="fa-solid fa-trash"
        color="black"
        onClick={onDeleteHandler}
      />
      <Icon onClick={onEditHandler} type="fa-solid fa-pen" color="black" size={16} />
    </div>,
  ];
};

import { Filter } from "../../components/filters";
import { buildDateQueryOperator } from "../../components/filters/DateRangeFilter/utility/buildDateQuery";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { FormArrayValidator } from "../../components/generic-form/utility/FormArrayValidator";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
import { TableHeaders } from "../../components/table";
import { MetaDataType, createCustomFieldDTO } from "../../entities/IMetaData";

export const customFieldsTableHeaders: TableHeaders[] = [
  { name: "NAME_EN", key: "nameEn" },
  { name: "NAME_SRB", key: "nameSrb" },
  { name: "DATA_TYPE", key: "dataType" },
  { name: "DATE_CREATED", key: "cratedAt" },
];

export type CustomFieldFilterKeys = {
  dataType: Filter;
  createdAt: Filter;
};

export const customFieldFilters: CustomFieldFilterKeys = {
  dataType: {
    type: "multiple-choice",
    name: "META_DATA.DATA_TYPE",
    options: [],
    buildQuery: (filter, values) => {
      return {
        name: "dataType",
        type: "stringArray",
        operator: "in",
        value: JSON.stringify(values),
      };
    },
  },
  createdAt: {
    type: "date-range",
    name: "FILTERS.DATE_CREATED",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildDateQueryOperator(values);
      return {
        name: "createdAt",
        type,
        operator,
        value: JSON.stringify(value),
      };
    },
  },
};

const customFieldDataTypeOptions = Object.values(MetaDataType).map((type) => ({
  value: type,
  label: type,
}));

export const createCustomFieldForm: GenericForm<createCustomFieldDTO> = {
  enLabel: {
    type: "input",
    name: "enLabel",
    label: "META_DATA.EN_LABEL",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
  srbLabel: {
    type: "input",
    name: "srbLabel",
    label: "META_DATA.SRB_LABEL",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
  dataType: {
    type: "select",
    name: "dataType",
    label: "META_DATA.DATA_TYPE",
    defaultValue: customFieldDataTypeOptions[0].value,
    options: customFieldDataTypeOptions,
    validators: [GenericFormValidator.required()],
    props: { skipOptionTranslation: true },
  },
  options: {
    type: "formArray",
    name: "options",
    label: "META_DATA.OPTIONS",
    defaultValue: [],
    validators: [FormArrayValidator.minControls({ limit: 2 })],
    visibleIf: (state) => state.dataType.value === MetaDataType.SELECT,
  },
};

import { StatNumberCardProps } from "./StatNumberCard.types";
import "./StatNumberCard.styles.css";
import { useNavigate } from "react-router-dom";

const StatNumberCard = ({ title, total, link, color = "#003366" }: StatNumberCardProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={`flex-column p-3 stat-number-card ${
        link ? "cursor-pointer stat-number-card-hover" : ""
      }`}
      onClick={() => {
        link && navigate(link);
      }}
    >
      <h1 style={{ fontWeight: "bolder", color: color }}>{total || 0}</h1>
      <label className="w-100 text-white text-center py-1" style={{ backgroundColor: color }}>
        {title}
      </label>
    </div>
  );
};
export default StatNumberCard;

import { CreateArchiveBookDTO, IArchiveBook } from "../../entities/IArchiveBook";
import {
  CreateArchiveBookItemBulkDTO,
  CreateArchiveBookItemDTO,
  IArchiveBookItem,
} from "../../entities/IArchiveBookItem";
import BaseInstance from "../BaseInstance";

const ArchiveBookGateway = {
  createArchiveBook: async (payload: CreateArchiveBookDTO) => {
    return BaseInstance.post("/archive-books", payload);
  },

  getArchiveBooks: async (
    query: string
  ): Promise<{ count: number; archiveBooks: IArchiveBook[] }> => {
    return BaseInstance.get(`/archive-books/search${query}`);
  },

  getAllArchiveBooks: async (): Promise<{ count: number; archiveBooks: IArchiveBook[] }> => {
    const queryParams = new URLSearchParams();
    queryParams.set("limit", "1000");
    return BaseInstance.get(`/archive-books/search`, { params: queryParams });
  },

  getArchiveBookById: async (
    bookId: number
  ): Promise<{ count: number; archiveBooks: IArchiveBook[] }> => {
    const query =
      `?filters=` +
      JSON.stringify({
        name: "organizationId",
        operator: "eq",
        value: bookId.toString(),
        type: "number",
      });
    return BaseInstance.get(`/archive-books/search${query}`);
  },

  deleteArchiveBook: async (id: number) => {
    return BaseInstance.delete(`/archive-books/${id}`);
  },

  createArchiveBookItem: async (
    payload: CreateArchiveBookItemDTO,
    archiveBookId: number
  ): Promise<IArchiveBookItem> => {
    return BaseInstance.post("/archive-books-items", { ...payload, archiveBookId });
  },

  createArchiveBookItemBulk: async (
    payload: CreateArchiveBookItemBulkDTO,
    documentIds: number[]
  ): Promise<IArchiveBookItem> => {
    return BaseInstance.post("/archive-books-items/document-bulk", { ...payload, documentIds });
  },

  getArchiveBookItems: async (
    query: string
  ): Promise<{ count: number; archiveBookItems: IArchiveBookItem[] }> => {
    return BaseInstance.get(`/archive-books-items/search${query}`);
  },

  getArchiveBookItemById: async (
    itemId: number
  ): Promise<{ count: number; archiveBookItems: IArchiveBookItem[] }> => {
    const query =
      `?filters=` +
      JSON.stringify({
        name: "id",
        operator: "eq",
        value: itemId.toString(),
        type: "number",
      });
    return BaseInstance.get(`/archive-books-items/search${query}`);
  },

  deleteArchiveBookItems: async (id: number) => {
    return BaseInstance.delete(`/archive-books-items/${id}`);
  },

  exportArchiveBookItems: async ({
    archiveBookId,
    query,
  }: {
    archiveBookId: number;
    query: string;
  }) => {
    const queryParams = new URLSearchParams(query);
    const archiveBookFilter = `${JSON.stringify({
      name: "archiveBookId",
      operator: "eq",
      value: archiveBookId.toString(),
      type: "number",
    })}`;
    queryParams.append("filters", archiveBookFilter);

    return BaseInstance.get("/archive-books-items/export", {
      params: queryParams,
      responseType: "blob",
    });
  },
};

export default ArchiveBookGateway;

import "./DigitalSignatureCard.style.css";
import { DigitalSignatureCardProps } from "../";
import { DigitalSignatureCardInfo } from "./DigitalSignatureCardInfo";
import { DigitalSignatureCardActions } from "./DigitalSignaturetCardActions";

export const DigitalSignatureCard = (signature: DigitalSignatureCardProps) => (
  <div className="signature-card cursor-pointer" style={{ padding: 8 }} onClick={signature.onClick}>
    <DigitalSignatureCardActions {...signature} />
    <DigitalSignatureCardInfo {...signature} />
  </div>
);

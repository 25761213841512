import { useEffect } from "react";
import useBaseRequest from "../../api/BaseRequest";
import { useLocation, useNavigate } from "react-router-dom";
import { DateRangeTypeValues } from "../../components/filters";
import moment from "moment";
import { useDocumentService } from "../../services/useDocumentService";
import TaskGateway from "../../api/gateways/TaskGateway";
import { useSetTaskStatsElements } from "./utils/useSetTaskStatsElements";

const useTaskStats = () => {
  // Initialize various hooks and states
  const { removeQueryParams } = useDocumentService();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const {
    data,
    execute: getArchiveStats,
    loading,
  } = useBaseRequest(TaskGateway.getTaskStats, {
    autoFetch: false,

    onError: (error) => {},
  });

  const { basketsStat, statUrgentTaskNumbers, statStatusTaskNumbers, statAHT, statART } =
    useSetTaskStatsElements(data);

  // Fetch data when query parameters change
  useEffect(() => {
    getArchiveStats(removeQueryParams(location.search, ["dateRange"]));
  }, [location.search]);

  // Function to handle changes in basket selection
  const onChangeBasket = (value: number[] | undefined) => {
    queryParams.delete("baskets");
    if (value)
      value.forEach((item) => {
        queryParams.append("baskets", item.toString());
        if (value.length === 1) queryParams.append("baskets", item.toString());
      });
    navigate({ search: queryParams.toString() });
  };

  // Function to handle changes in date range selection
  const onChangeDateRange = (
    value: {
      fromDate?: Date;
      toDate?: Date;
      value: DateRangeTypeValues;
    } | null
  ) => {
    queryParams.delete("dateRange");
    queryParams.delete("dateFrom");
    queryParams.delete("dateTo");
    if (!value) {
      navigate({ search: queryParams.toString() });
      return;
    }
    if (value.fromDate) queryParams.set("dateFrom", value.fromDate.toISOString());
    if (value.toDate) queryParams.set("dateTo", value.toDate.toISOString());
    queryParams.set("dateRange", value.value);

    navigate({ search: queryParams.toString() });
  };

  // Extract and format date range query parameters
  const dateRangeQueryParam = queryParams.get("dateRange");
  const validDateRangeValues = Object.values(DateRangeTypeValues);
  const selectedDateRangeValue =
    dateRangeQueryParam && validDateRangeValues.includes(dateRangeQueryParam as DateRangeTypeValues)
      ? dateRangeQueryParam
      : DateRangeTypeValues.TODAY;
  const fromDate =
    selectedDateRangeValue === DateRangeTypeValues.BETWEEN
      ? queryParams.get("dateFrom") !== null
        ? new Date(queryParams.get("dateFrom") as string)
        : undefined
      : new Date(queryParams.get("dateFrom") || Date.now());
  const toDate =
    selectedDateRangeValue === DateRangeTypeValues.BETWEEN
      ? queryParams.get("dateTo") !== null
        ? new Date(queryParams.get("dateTo") as string)
        : undefined
      : new Date(queryParams.get("dateTo") || moment().toDate());

  // Return relevant values and functions for the component
  return {
    statAHT,
    statART,
    statStatusTaskNumbers,
    statUrgentTaskNumbers,
    basketsStat,
    loading,
    onChangeBasket,
    onChangeDateRange,
    defaultBasket: queryParams.getAll("baskets").map((item) => +item),
    defaultDateRange: {
      value: selectedDateRangeValue as DateRangeTypeValues,
      fromDate,
      toDate,
    },
  };
};

export default useTaskStats;

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetFiltersByPage } from "../../store/filters/FilterSelectors";

export default function QueryAppender({
  children,
  queryString,
  queryKey,
}: {
  children: React.ReactNode;
  queryString?: string;
  queryKey?: string;
}) {
  const navigate = useNavigate();
  const search = useLocation().search;
  const queryStringInStorage = useGetFiltersByPage(queryKey || "");
  const [firstTime, setFirstTime] = useState(true);
  useEffect(() => {
    if (firstTime && (queryString || queryStringInStorage) && !search) {
      navigate(
        { search: queryString || queryStringInStorage },
        { replace: true }
      );
    }
    if (firstTime) {
      setFirstTime(false);
    }
  }, [queryString, queryStringInStorage, firstTime]);
  return <div>{!firstTime && children}</div>;
}

import { useTranslation } from "react-i18next";
import { DialogModalProps } from "./Modal.types";
import { Modal } from "react-bootstrap";
import { Button } from "../button";

export function DialogModal<T>({
  show,
  onAccept,
  onCancel,
  loading = false,
  description,
  acceptLabel,
  cancelLabel,
  title,
}: DialogModalProps<T>) {
  const { t } = useTranslation();

  function getDescription() {
    return (description as any).raw ? (description as any).raw : t(description as string);
  }

  return (
    <Modal show={show} animation onHide={onCancel}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {t(title)}
        </h5>
        <div onClick={onCancel}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body> {getDescription()}</Modal.Body>
      <Modal.Footer>
        <Button type="button" className="btn btn-secondary" onClick={onCancel}>
          {t(cancelLabel)}
        </Button>
        <Button isLoading={loading} type="button" className="btn btn-danger" onClick={onAccept}>
          {t(acceptLabel)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

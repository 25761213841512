import { useTranslation } from "react-i18next";
import { DigitalSignatureCardInfoProps } from "..";
import { FormPreview } from "../../../components/form-preview";
import { Icon } from "../../../modules/icon";

export const DigitalSignatureCardInfo = ({
  customerEmail,
  fileName,
  status,
}: DigitalSignatureCardInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex-row" style={{ gap: "8px" }}>
      <div
        className="flex-row align-items-center justify-content-center bg-white rounded"
        style={{ height: "200px", minWidth: "130px" }}
      >
        <div className="flex-column gap-2 align-items-center">
          <Icon type="fa-regular fa-file" size={64} title={fileName} />
          <h6>{status[0]?.toUpperCase() + status.slice(1)}</h6>
        </div>
      </div>
      <FormPreview
        metaDataForm={{
          email: {
            label: t("DOCUMENTS.CUSTOMER_EMAIL"),
            defaultValue: customerEmail,
            type: "input",
            name: "",
          },
          name: {
            label: t("TABLE.NAME"),
            defaultValue: fileName,
            type: "input",
            name: "",
          },
        }}
      />
    </div>
  );
};

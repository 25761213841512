import { IDocument } from "../../../entities/IDocument";

export const isDocumentProcessedChecker = (item: IDocument): boolean => {
  if (!item.docTypeId) {
    return false;
  }
  if (item.metaData && JSON.stringify(item.metaData) !== "{}") {
    return true;
  }
  return false;
};

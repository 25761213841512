import moment from "moment";
import { CreateArchiveBookItemDTO } from "../../../entities/IArchiveBookItem";

export const processArchiveBookItemPayload = (
  payload: CreateArchiveBookItemDTO
): CreateArchiveBookItemDTO => {
  return {
    ...payload,
    periodFrom: moment(payload.periodFrom).startOf("day").toDate(),
    periodTo: moment(payload.periodTo).endOf("day").toDate(),
  };
};

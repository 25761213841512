import React, { useEffect, useMemo, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { TableRow } from "../../components/table";
import { DocumentStatus } from "../../entities/IDocument";
import { useUser } from "../../store/auth/AuthSelectors";
import { documentTableHeaders } from "./Documents.types";
import { useNavigate } from "react-router";
import { screens } from "../Navigation.types";
import { useDocumentService } from "../../services/useDocumentService";
import { useLocation } from "react-router-dom";
import { documentFilters as documentDefaultFilters } from "./Documents.types";
import { mapDocumentToTableRow } from "./utils/mapDocumentToTableRow";

export function useDocuments() {
  const user = useUser();
  const navigate = useNavigate();
  const { getDocFileUrl, useCreateLocalFileUrl } = useDocumentService();
  const location = useLocation();
  const { useGetDocumentCategories, useGetDocumentTypes } = useDocumentService();
  const { documentCategories } = useGetDocumentCategories();
  const { documentTypes } = useGetDocumentTypes();

  // states
  const [docTableData, setDocTableData] = React.useState<TableRow[]>([]);
  const [selectedDocuments, setSelectedDocuments] = React.useState<number[]>([]);
  const [showArchiveBookModal, setShowArchiveBookModal] = React.useState(false);

  const userDocumentsFilters = `&filters=${JSON.stringify({
    name: "status",
    operator: "eq",
    value: DocumentStatus.PENDING,
    type: "string",
  })}`;

  useEffect(() => {
    execute((location.search || "?") + userDocumentsFilters);
  }, [location.search]);

  const [downloadUrl, setDownloadUrl] = useState<string>("");
  const { url } = useCreateLocalFileUrl(downloadUrl, true);

  // requests
  const { data, execute, loading } = useBaseRequest(DocumentGateway.getDocuments, {
    onCompleted: (data) => {
      const { documents } = data;
      const docTable: TableRow[] = [];
      documents.forEach((doc) => {
        docTable.push(mapDocumentToTableRow(doc, setDownloadUrl));
      });
      setDocTableData(docTable);
    },
  });

  // handlers
  const onRowPress = (id: number) => {
    navigate(screens.documents + `/${id}`);
  };

  const onArchiveBookItemCreate = () => {
    setShowArchiveBookModal(false);
    setSelectedDocuments([]);
  };

  // filters
  const documentFilters = useMemo(
    () => ({
      ...documentDefaultFilters,
      docTypeId: {
        ...documentDefaultFilters.docTypeId,
        options:
          documentTypes && documentTypes.length
            ? documentTypes.map((type) => {
                return {
                  value: type.id.toString(),
                  label: type.name,
                };
              })
            : [],
      },
      "metaData.documentCategory": {
        ...documentDefaultFilters["metaData.documentCategory"],
        options:
          documentCategories && documentCategories.length
            ? documentCategories.map((category) => {
                return {
                  value: category.name,
                  label: category.name,
                };
              })
            : [],
      },
    }),
    [documentCategories, documentTypes]
  );

  return {
    docTableData,
    count: data?.count || 0,
    getDocuments: execute,
    loading,
    user,
    selectedDocuments,
    setSelectedDocuments,
    showArchiveBookModal,
    openArchiveBookModal: () => setShowArchiveBookModal(true),
    closeArchiveBookModal: () => setShowArchiveBookModal(false),
    onArchiveBookItemCreate,
    documentTableHeaders,
    onRowPress,
    documentFilters,
    documentCategories,
  };
}

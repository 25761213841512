import { Link } from "react-router-dom";
import { Badge } from "../../components/badge";
import Card from "../../components/card/Card";
import { ITaskComment } from "../../components/comments";
import Header from "../../components/header/Header";
import { DocStatusBadgeMap, IDocument, InvoiceStatusBadgeMap } from "../../entities/IDocument";
import { ILabel } from "../../entities/ILabel";
import { Icon } from "../../modules/icon";
import { screens } from "../Navigation.types";
import Comment from "../../components/comments/Comment";
import { ExecuteTaskAttachmentActionDTO } from "./Task.types";
import { Button } from "../../components/button";
import { useTranslation } from "react-i18next";
import { useFilePreview } from "../../components/file-preview/useFilePreview";

export const TaskAttachment = ({
  id,
  title,
  description,
  labels,
  type,
  content,
  onEdit,
  onDelete,
  onDocumentAction,
}: {
  id: number;
  num: number;
  title: string;
  description?: string;
  labels: ILabel[] | undefined;
  type: "document" | "text";
  content: ITaskComment | IDocument | null | any;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onDocumentAction: (payload: ExecuteTaskAttachmentActionDTO) => void;
}) => {
  const onAprrove = () => {
    content?.docType?.provider?.id && onDocumentAction({ id, action: "approve" });
  };
  const onReject = () => {
    content?.docType?.provider?.id && onDocumentAction({ id, action: "reject" });
  };
  const { t } = useTranslation();
  const { url } = useFilePreview(content?.files ? content?.files[0]?.url : "");
  return (
    <Card className="ps-3">
      <Header
        title={
          title +
          (content?.metaData?.subjectNumber ? " (" + content?.metaData?.subjectNumber + ")" : "")
        }
        badge={
          content?.metaData?.invoiceStatus ? (
            <Badge
              type={
                InvoiceStatusBadgeMap[
                  content?.metaData?.invoiceStatus as keyof typeof InvoiceStatusBadgeMap
                ]
              }
              className="fs-7 fw-normal ml-2"
            >
              {t(`INVOICE_STATUS.${content?.metaData?.invoiceStatus.toLowerCase()}`)}
            </Badge>
          ) : null
        }
        actions={
          <div className="btn-group" role="group" style={{ boxShadow: "none" }}>
            <button
              id="btnGroupDrop1"
              type="button"
              className="btn btn-text dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ boxShadow: "none" }}
            >
              <Icon
                title={"Vidi akcije"}
                type="fa-solid fa-ellipsis-vertical"
                color="black"
                size={16}
              />
            </button>
            <ul
              style={{
                minWidth: "min-content",
                minHeight: "min-content",
                backgroundColor: "white",
              }}
              className="dropdown-menu"
              aria-labelledby="btnGroupDrop1"
            >
              <li style={{ maxWidth: "min-content", padding: "8px" }}>
                <Icon
                  onClick={() => {
                    onEdit(id);
                  }}
                  title={"Izmeni"}
                  type="fa-solid fa-pen"
                  color="black"
                  size={16}
                />
              </li>
              <li style={{ maxWidth: "min-content", padding: "8px" }}>
                <Icon
                  onClick={() => {
                    onDelete(id);
                  }}
                  title={"Obriši"}
                  type="fa-solid fa-trash"
                  color="black"
                  size={16}
                />
              </li>
              {content && type === "document" ? (
                <>
                  <li style={{ maxWidth: "min-content", padding: "8px" }}>
                    <Link
                      to={screens.documents + `/${content.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon
                        title={"Vidi dokument"}
                        type="fa-solid fa-folder-open"
                        color="black"
                        size={16}
                      />
                    </Link>
                  </li>
                  {url ? (
                    <li style={{ maxWidth: "min-content", padding: "8px" }}>
                      <Link to={url} target="_blank" rel="noreferrer">
                        <Icon
                          title={"Vidi fajl"}
                          type="fa-solid fa-download"
                          color="black"
                          size={16}
                        />
                      </Link>
                    </li>
                  ) : null}
                </>
              ) : null}
            </ul>
          </div>
        }
      />
      <div className="card-body">
        <div className="flex-row d-flex flex-wrap">
          {labels?.map((item) => (
            <Badge
              key={item.id}
              variant="outlined"
              className="me-2"
              type={"primary"}
              color={item.color}
            >
              {item.name}
            </Badge>
          ))}
          {content && content?.labels && content?.labels.length > 0
            ? content?.labels?.map((item: ILabel) => (
                <Badge
                  key={item.id}
                  variant="outlined"
                  className="me-2"
                  type={"primary"}
                  color={item.color}
                >
                  {item.name}
                </Badge>
              ))
            : null}
          {content && type === "document" && content?.docType?.name ? (
            <Badge variant="outlined" className="me-2" type={"primary"} color={"#000000"}>
              {content?.docType?.name}
            </Badge>
          ) : null}
        </div>
        <p className="card-text mt-2">{description}</p>
        {!!content && type === "text" && (
          <Comment
            isPinned={true}
            id={content.id}
            text={content.text}
            createdAt={content.createdAt}
            createdBy={content.createdBy}
          />
        )}
        {content &&
          type === "document" &&
          !!content?.docType?.provider?.id &&
          content?.metaData?.invoiceStatus !== "Approved" &&
          content?.metaData?.invoiceStatus !== "Rejected" && (
            <div className="flex-row gap-2 justify-content-end">
              <Button onClick={onAprrove} className="btn btn-success">
                {t("APPROVE_BUTTON")}
              </Button>
              <Button onClick={onReject} className="btn btn-danger">
                {t("REJECT_BUTTON")}
              </Button>
            </div>
          )}
      </div>
    </Card>
  );
};

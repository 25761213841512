import { MetaDataType, createCustomFieldDTO } from "../../../entities/IMetaData";

export const processCustomFieldPayload = (payload: createCustomFieldDTO): createCustomFieldDTO => {
  const { options, ...newPayload } = payload;

  if (payload.dataType === MetaDataType.SELECT && options) {
    return { ...newPayload, options } as createCustomFieldDTO;
  }
  return newPayload as createCustomFieldDTO;
};

import { IEnvironment } from "./environment";

export const localEnv: IEnvironment = {
  env: "local",
  api: {
    protocol: "http",
    host: "localhost",
    port: "8080",
  },
  oidc: {
    clientId: "275613763489562630@aurora",
    issuerEndpoint: "http://localhost:8081",
    loginCallbackUrl: "http://localhost:3000/code",
    authorizationEndpoint: "http://localhost:8081/oauth/v2/authorize",
    keyListEndpoint: "http://localhost:8081/oauth/v2/keys",
    userInfoEndpoint: "http://localhost:8081/oidc/v1/userinfo",
    roleFieldName: "urn:zitadel:iam:org:project:275613473730199558:roles",
    endSessionEndpoint: "http://localhost:8081/oidc/v1/end_session",
  },
  modulesEnabled: {
    archive: true,
    digitalSignature: true,
    tasks: true,
    sefProvider: true,
    emailListenerProvider: true,
    networkShareProvider: true,
    ocr: true,
  },
};

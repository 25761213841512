import { Color } from "@rc-component/color-picker";
import { isValidHexColor } from "./utils/isValidHexColor";
import { useState } from "react";

export const useColorpicker = (onChange: (color: string) => void, value?: string) => {
  const onChangeHandler = (color: Color) => {
    onChange(color.toHexString() as any);
  };
  const onChangeHandlertEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as any);
  };
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const validValue = value && isValidHexColor(value) ? value : "#000000";
  return {
    validValue,
    isColorPickerOpen,
    setIsColorPickerOpen,
    onChangeHandler,
    onChangeHandlertEvent,
    toggleColorPickerVisibility: () => setIsColorPickerOpen(!isColorPickerOpen),
  };
};

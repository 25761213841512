import { ITaskComment } from "../components/comments";
import { Filter } from "../components/filters";
import { TableHeaders } from "../components/table";
import { IDbEntity } from "./IDbEntity";
import { IDocument } from "./IDocument";
import { ILabel } from "./ILabel";

export interface ITask extends IDbEntity {
  title: string;
  docTypeDoc: string;
  description: string;
  status: TaskStatuses;
  processDescription: string;
  createdBy: {
    id: number;
    name: string;
  };
  basket: {
    id: number;
    name: string;
  };
  basketId: number;
  comments: ITaskComment[];
  labels: ILabel[];
  participants: {
    id: number;
    name: string;
  }[];
  sections: ITaskSection[];
}
export interface ITaskSection extends IDbEntity {
  title: string;
  description: string;
  elements: ITaskElement[];
}
export interface ITaskElement extends IDbEntity {
  type: "text" | "document";
  content: {
    title: string;
    description: string;
    labels: ILabel[];
  };
  labels: ILabel[];
  document: IDocument | null;
  message: ITaskComment | null;
}

export type TaskStatus = keyof typeof taskStatuses;

export enum TaskStatuses {
  OPEN = "open",
  ASSIGNED = "assigned",
  INPROGRESS = "inprogress",
  ONHOLD = "onhold",
  RESOLVED = "resolved",
  CANCELED = "canceled",
}

export const tasksTableHeaders: TableHeaders[] = [
  { name: "ID", key: "id", sortable: true },
  { name: "TITLE", key: "title", sortable: true },
  { name: "STATUS", key: "status", sortable: true },
  { name: "BASKET", key: "basket.name" },
  { name: "CREATED_BY", key: "createdBy.name" },
  { name: "DATE_CREATED", key: "createdAt", sortable: true },
  { name: "ACTIONS", key: "action" },
];

export const TASK_SUCCESS_MESSAGES = {
  created: "Task successfully created!",
};
export interface CreateTaskDTO {
  title: string;
  basketId: number | string;
  description?: string;
  labels: number[];
  processDescription?: string;
}
export interface IGetTasksByBasketIdApi {
  count: number;
  tasks: ITask[];
  basketName: string;
}

export interface IGetTasksApi {
  count: number;
  tasks: ITask[];
  baskets: {
    id: number;
    name: string;
  }[];
}

export const tableFilters: Filter[] = [
  {
    type: "single-choice",
    name: "Show only mine tasks",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    buildQuery: (filter, values) => {
      return {
        name: "myTasks",
        type: "string",
        operator: values[0] === "yes" ? "ne" : "eq",
        value: null,
      };
    },
  },
];

export const taskStatuses = {
  open: "Open",
  assigned: "Assigned",
  inprogress: "In Progress",
  onhold: "On Hold",
  resolved: "Resolved",
  canceled: "Canceled",
};

export const taskStatusesColors = {
  open: "unassigned",
  assigned: "assigned",
  inprogress: "inprogress",
  onhold: "onhold",
  resolved: "success",
  canceled: "closed",
};

export interface AddCommentToTaskDTO {
  taskId: number;
  text: string;
}

export interface GetTaskCommentDTO {
  count: number;
  comments: ITaskComment[];
}

export interface TaskReassignDTO {
  id: number;
  userId: number;
}

export interface TaskChangeStatusDTO {
  id: number;
  status: "resolved" | "onhold" | "inprogress";
}

export interface TaskMoveToAnotherBasketDTO {
  id: number;
  basketId: number;
}

export interface GetTaskStatsResponse {
  totalOlderThen8: number;
  totalOlderThen6: number;
  totalUnassigned: number;
  totalAssigned: number;
  totalResolved: number;
  aht: number;
  art: number;
  baskets: {
    name: string;
    total: number;
    resolved: number;
    inprogress: number;
    onhold: number;
    assigned: number;
    unassigned: number;
  }[];
}

export interface TaskBulkReassignDTO {
  taskIds: number[];
  data: {
    userId: number;
  };
}

export interface TaskBulkMoveToAnotherBasketDTO {
  taskIds: number[];
  data: {
    basketId: number;
  };
}

export interface UpdateTaskDTO {
  id: number;
  description: string;
  labels: number[];
  participants: number[];
  processDescription?: string;
}

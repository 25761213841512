import { useTranslation } from "react-i18next";
import { Loader } from "../../modules/loader";
import Header from "../header/Header";
import { Log } from "./";
import useLogs from "./useLogs";
const Logs = () => {
  const { logs, loading } = useLogs();
  const { t } = useTranslation();
  if (loading)
    return (
      <div className="w-100 flex-row justify-content-center">
        <Loader size="l" color="black" />
      </div>
    );
  return (
    <div className="mt-4 flex-column gap-3">
      <Header title={t("TASKS.LOGS_TAB")} />
      <div className="flex-column bg-white p-3 rounded-2">
        {logs.map((item) => (
          <Log key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};
export default Logs;

import { useTranslation } from "react-i18next";
import { Button } from "../../components/button";
import { FiltersWithQuery } from "../../components/filters";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchWithQueryInput } from "../../components/search-input";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { Icon } from "../../modules/icon";
import {
  createDocumentCategoryForm,
  documentCategoryTableHeaders,
} from "./DocumentCategories.types";
import { useDocumentCategories } from "./useDocumentCategories";
import { FormModal } from "../../components/modal";
import { DialogModal } from "../../components/modal/DialogModal";

export const DocumentCategoriesScreen = () => {
  const { t } = useTranslation();
  const {
    docCategoryTableData,
    docCategoryCount,
    fetchingDocumentCategories,
    hideCreateDocCategoryModalHandler,
    isVisibleCreateDocCategoryModal,
    showCreateDocCategoryModalHandler,
    hideDeleteDocCategoryModalHandler,
    deleteDocCategoryId,
    deleteDocCategory,
    deletingDocumentCategory,
    createDocumentCategory,
    creatingDocumentCategory,
    documentCategoryFilters,
  } = useDocumentCategories();
  return (
    <PageLayout
      title={t("DOCUMENT_CATEGORY.TITLE")}
      filter={<FiltersWithQuery filters={documentCategoryFilters} />}
      search={<SearchWithQueryInput placeHolder={t("DOCUMENT_CATEGORY.SEARCH")} />}
      titleRight={
        <div className="text-right flex-row gap-2">
          <div style={{ height: "100%", width: "2px", backgroundColor: "black" }}></div>
          <Button className="btn btn-dark" onClick={showCreateDocCategoryModalHandler}>
            <Icon type="fa fa-plus" title={t("DOCUMENT_CATEGORY.CREATE_LABEL")} />
          </Button>
        </div>
      }
    >
      <FormModal
        isVisible={isVisibleCreateDocCategoryModal}
        onDismiss={hideCreateDocCategoryModalHandler}
        onSubmit={createDocumentCategory}
        loading={creatingDocumentCategory}
        form={createDocumentCategoryForm}
        submitButtonLabel="CREATE_BUTTON"
        title="DOCUMENT_CATEGORY.CREATE_LABEL"
      />
      <DialogModal
        loading={deletingDocumentCategory}
        show={deleteDocCategoryId != null}
        onAccept={deleteDocCategory}
        onCancel={hideDeleteDocCategoryModalHandler}
        acceptLabel="DELETE_BUTTON"
        cancelLabel="CANCEL_BUTTON"
        description="DOCUMENT_CATEGORY.DELETE_LABEL"
        title="DOCUMENT_CATEGORY.DELETE"
      />
      <Row>
        <Column>
          <TableWithQueryPaginationAndSort
            headers={documentCategoryTableHeaders}
            data={docCategoryTableData}
            totalItems={docCategoryCount}
            selection={false}
            loading={fetchingDocumentCategories}
          />
        </Column>
      </Row>
    </PageLayout>
  );
};

import { Modal } from "react-bootstrap";
import { taskStatuses } from "../../entities/ITask";
import { TaskOtherActionsModalProps } from "./Task.types";
import { Button } from "../../components/button";
import { useTranslation } from "react-i18next";

export function TaskOtherActionsModal({
  show,
  onSubmit,
  onCancel,
  submiting,
  action,
}: TaskOtherActionsModalProps) {
  const { t } = useTranslation();
  return (
    <Modal show={show} animation onHide={onCancel}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {t("TASKS.STATUS_CHANGE_TITLE")}
        </h5>
        <div onClick={onCancel}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t("TASKS.STATUS_CHANGE_MESSAGE_CONFIRMATION")} {taskStatuses[action]?.toLowerCase()}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          isLoading={submiting}
          className="btn btn-primary"
          onClick={() => {
            onSubmit(action);
          }}
          disabled={submiting}
        >
          {t("YES")}
        </Button>
        <Button type="button" className="btn btn-secondary" onClick={onCancel} disabled={submiting}>
          {t("NO")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export const createFilterQueryWithObjectKeys = (
  params: Record<string, any>
): string => {
  const filteredParams: Record<string, any> = Object.keys(params)
    .filter((key) => {
      const value = params[key];
      return (
        value !== undefined &&
        value !== null &&
        value !== "" &&
        !(Array.isArray(value) && value.length === 0)
      );
    })
    .reduce((filtered: any, key) => {
      filtered[key] = params[key];
      return filtered;
    }, {});

  const queryParams = Object.keys(filteredParams)
    .map((key) => {
      const value = filteredParams[key];

      if (Array.isArray(value)) {
        if (value.length > 0) {
          return value
            .map((item) => {
              if (typeof item === "object") {
                return `${encodeURIComponent(key)}=${encodeURIComponent(
                  JSON.stringify(item)
                )}`;
              }
              return `${encodeURIComponent(key)}=${encodeURIComponent(item)}`;
            })
            .join("&");
        } else {
          return null;
        }
      } else if (typeof value === "object") {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          JSON.stringify(value)
        )}`;
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .filter((item) => item !== null)
    .join("&");

  return queryParams ? `?${queryParams}` : "";
};

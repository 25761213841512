import "./BasketCard.style.css";
import Card from "../../../components/card/Card";
import { BasketCardProps } from "../Basket.types";
import BasketCardInfo from "./BasketCardInfo";
import BasketCardActions from "./BasketCardActions";

const BasketCard = (basket: BasketCardProps) => (
  <Card
    aspectRatio={1 / 1.2}
    className="basketItem cursor-pointer"
    onClick={basket.onClick}
  >
    <BasketCardInfo {...basket} />
    <BasketCardActions {...basket} />
  </Card>
);
export default BasketCard;

import { Modal } from "react-bootstrap";
import { Button } from "../button";

import { ViewDigitalSignatureModalProps } from "./RequestDigitalSignatureModal.types";
import { FormPreview } from "../form-preview";
import { DigitalSignatureRequestStatuses } from "../../entities/IDigitalSignatureRequest";
import { Icon } from "../../modules/icon";
import { useTranslation } from "react-i18next";

export const ViewDigitalSignatureModal = ({
  isVisible,
  onDismiss,
  fields,
  onDelete,
  onResend,
}: ViewDigitalSignatureModalProps) => {
  const showDeleteOption = !!(
    fields?.status?.defaultValue !==
    DigitalSignatureRequestStatuses.SIGNED[0].toUpperCase() +
      DigitalSignatureRequestStatuses.SIGNED.slice(1)
  );
  const showResendConfirmation = false; // make it false for now
  const { t } = useTranslation();
  /* !!(
    fields.find((item) => item.label === "Status")?.value ===
    DigitalSignatureRequestStatuses.PENDING[0].toUpperCase() +
      DigitalSignatureRequestStatuses.PENDING.slice(1)
  ); */
  return (
    <Modal show={isVisible} animation onHide={onDismiss}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {t("DOCUMENTS.DIGITAL_SIGNATURE_INFO")}
        </h5>
        <div onClick={onDismiss}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <FormPreview metaDataForm={fields} />
        {showDeleteOption && (
          <Button onClick={onDelete} className="btn btn-danger w-100 mt-4">
            {t("DOCUMENTS.DELETE_DIGITAL_SIGNATURE_REQUEST")}
          </Button>
        )}
        {showResendConfirmation && (
          <Button
            className="btn btn-primary w-100 btn-secondary-washed"
            onClick={(event) => {
              event?.stopPropagation();
              onResend();
            }}
          >
            <Icon type={"fa-solid fa-arrows-rotate"} />
            &nbsp;{t("DOCUMENTS.RESEND_CONFIRMATION")}
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};

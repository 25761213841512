import moment from "moment";
import { GenericFormControlTypes } from "../generic-form/GenericForm.types";
import { Badge } from "../badge";
import { RichTextInput } from "../../modules/rich-text-input";
export const FormItem = ({
  label,
  type,
  value,
}: {
  label: string;
  type: GenericFormControlTypes;
  value: string | string[] | { label: string; color: string }[] | boolean | null | undefined;
}) => {
  const item = () => {
    switch (type) {
      case "input" || "password" || "textarea" || "select" || "autocompleteSingleChoice":
        return value || "-";
      case "date":
        return value && typeof value === "string" ? moment(value).format("DD.MM.YYYY") : "-";
      case "checkbox":
        return value ? "Yes" : "No";
      case "autocompleteMultipleChoice":
        return value && Array.isArray(value) && value.length > 0
          ? value.map((value, i) => (
              <Badge
                key={i}
                variant="outlined"
                color={typeof value === "string" ? undefined : value.color}
                type={"dark"}
              >
                {typeof value === "string" ? value : value.label}
              </Badge>
            ))
          : "-";
      case "richTextInput":
        return value && typeof value === "string" ? (
          <RichTextInput
            className="form-control bg-white p-0"
            disabled={true}
            placeholder="-"
            onChange={() => {}}
            value={value}
          />
        ) : (
          "-"
        );
      default:
        return value || "-";
    }
  };
  return (
    <div
      className={"flex-column"}
      style={{
        padding: "8px 12px",
        borderRadius: 8,
        backgroundColor: "#FFFFFF",
        gap: 0,
        flex: 1,
      }}
    >
      <label className="small" style={{ fontWeight: "bold" }}>
        {label}
      </label>
      {type === "autocompleteMultipleChoice" ? (
        <div className="flex-row d-flex gap-2 flex-wrap">{item() as any}</div>
      ) : (
        <label style={{ wordBreak: "break-word" }}>{item() as any}</label>
      )}
    </div>
  );
};

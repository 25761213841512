import { Modal } from "react-bootstrap";
import { Button } from "../../components/button";

import { DeleteBasketModalProps } from "./Basket.types";
import { useTranslation } from "react-i18next";

const DeleteBasketModal = ({
  isVisible,
  onDismiss,
  onDelete,
  deleting,
}: DeleteBasketModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal show={isVisible} animation onHide={onDismiss}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {t("BASKETS.DELETE_LABEL")}
        </h5>
        <div onClick={onDismiss}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>{t("BASKETS.DELETE_MESSAGE")}</Modal.Body>
      <Modal.Footer>
        <Button type="button" className="btn btn-secondary" onClick={onDismiss}>
          {t("CANCEL_BUTTON")}
        </Button>
        <Button isLoading={deleting} type="button" className="btn btn-danger" onClick={onDelete}>
          {t("DELETE_BUTTON")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteBasketModal;

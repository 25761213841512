import { useTranslation } from "react-i18next";
import { FiltersWithQuery } from "../../components/filters";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchWithQueryInput } from "../../components/search-input";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { sefReportsTableHeaders } from "./SefReports.types";
import { useSefReports } from "./useSefReports";

export const SefReportsScreen = () => {
  const { t } = useTranslation();
  const { reportCount, reportTableData, fetchingReports, sefReportFilters } = useSefReports();
  return (
    <PageLayout
      title={t("SEF_REPORTS.TITLE")}
      filter={<FiltersWithQuery filters={sefReportFilters} />}
      search={<SearchWithQueryInput placeHolder={t("SEF_REPORTS.SEARCH")} />}
    >
      <Row>
        <Column>
          <TableWithQueryPaginationAndSort
            totalItems={reportCount}
            loading={fetchingReports}
            onRowPress={() => {}}
            headers={sefReportsTableHeaders}
            data={reportTableData}
            selection={false}
          />
        </Column>
      </Row>
    </PageLayout>
  );
};

import { useLocation } from "react-router-dom";
import { useToast } from "../../components/toast";
import { useState, useEffect, useMemo, useCallback } from "react";
import {
  DocumentTypeAccessConfigForm,
  DocumentTypeFilterKeys,
  createDocumentTypeForm as createDocumentTypeDefaultForm,
} from "./DocumentTypes.types";
import { useDocumentService } from "../../services/useDocumentService";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import {
  CreateDocumentTypeDTO,
  DOCUMENT_TYPE_SUCCESS_MESSAGES,
  IDocumentType,
  IDocumentTypeMapping,
} from "../../entities/IDocumentType";
import useBaseRequest from "../../api/BaseRequest";
import DocumentTypeGateway from "../../api/gateways/DocumentTypeGateway";
import { TableRow } from "../../components/table";
import { mapDocumentTypeToTableRow } from "./utils/mapDocumentTypeToTableRow";
import { useDocumentCategoriesSelector } from "../../store/documents/DocumentSelector";
import { useTranslation } from "react-i18next";
import { IOption } from "../../components/select";
import { DocumentTypeOCRMappingGateway } from "../../api/gateways/DocumentTypeOCRMappingGateway";
import { mapAccessConfigToForm } from "./utils/mapAccessConfigToForm";
import { mapAccessControlFormToPayload } from "./utils/mapAccessControlFormToPayload";

export const useDocumentTypes = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { success, danger } = useToast();
  useDocumentService().useGetDocumentCategories();
  const documentCategories = useDocumentCategoriesSelector();

  const [docTypeCount, setDocTypeCount] = useState<number>(0);
  const [docTypeTableData, setDocTypeTableData] = useState<TableRow[]>([]);
  const [isVisibleCreateDocTypeModal, setIsVisibleCreateDocTypeModal] = useState<boolean>(false);

  const [deleteDocTypeId, setDeleteDocTypeId] = useState<number | null>(null);

  let documentTypeFilters: DocumentTypeFilterKeys = useMemo(
    () => ({
      docCategoryId: {
        type: "multiple-choice",
        name: "FILTERS.DOCUMENT_CATEGORY",
        options:
          documentCategories && documentCategories.length
            ? documentCategories.map((category) => {
                return {
                  value: category.id.toString(),
                  label: category.name,
                };
              })
            : [],
        buildQuery: (filter, values) => {
          return {
            name: "docCategoryId",
            type: "numberArray",
            operator: "in",
            value: JSON.stringify(values),
          };
        },
      },
    }),
    [documentCategories]
  );
  const showCreateDocTypeModalHandler = () => setIsVisibleCreateDocTypeModal(true);
  const hideCreateDocTypeModalHandler = () => setIsVisibleCreateDocTypeModal(false);

  const showDeleteDocTypeModalHandler = (id: number) => setDeleteDocTypeId(id);
  const hideDeleteDocTypeModalHandler = () => setDeleteDocTypeId(null);
  const deleteDocType = () => deleteDocTypeId && deleteDocumentTypeFn(deleteDocTypeId);

  const {
    data: docTypeOCRKeys,
    loading: loadingDocTypeOCRMapping,
    execute: getDocumentTypeOCRMapping,
  } = useBaseRequest(DocumentTypeOCRMappingGateway.getAllDocumentTypeOCRMapping, {
    autoFetch: true,
    initialPayload: {},
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  useEffect(() => {
    if (docTypeOCRKeys) {
      getDocumentTypes(location.search || "?");
    }
  }, [location.search, docTypeOCRKeys]);

  const {
    data,
    execute: getDocumentTypes,
    loading: fetchingDocumentTypes,
  } = useBaseRequest(DocumentTypeGateway.getDocumentTypes, {
    onCompleted: (data) => {
      const docTypeTable = data.types
        ? data.types.map((type) =>
            mapDocumentTypeToTableRow(
              type,
              docTypeOCRKeys?.docTypeMappings.find((item) => item.docTypeId === type.id)?.key || "",
              setDeleteDocTypeId,
              onShowLinkedDocumentTypeModal,
              showDocTypeFieldsModalHandler,
              showDocTypeOCRMappingModalHandler,
              showAccessConfigModalHandler
            )
          )
        : [];
      setDocTypeTableData(docTypeTable);
      setDocTypeCount(data.count);
    },
  });

  const { execute: createDocumentType, loading: creatingDocumentType } = useBaseRequest(
    DocumentTypeGateway.createDocumentType,
    {
      onCompleted: (data) => {
        success(t(DOCUMENT_TYPE_SUCCESS_MESSAGES.created));
        setIsVisibleCreateDocTypeModal(false);
        getDocumentTypes(location.search || "?");
      },
    }
  );

  const { execute: deleteDocumentTypeFn, loading: deletingDocumentType } = useBaseRequest(
    DocumentTypeGateway.deleteDocumentType,
    {
      onCompleted: () => {
        success(t(DOCUMENT_TYPE_SUCCESS_MESSAGES.deleted));
        getDocumentTypes(location.search || "?");
        setDeleteDocTypeId(null);
      },
    }
  );

  const createDocumentTypeForm: GenericForm<CreateDocumentTypeDTO> = useMemo(
    () => ({
      ...createDocumentTypeDefaultForm,
      docCategoryId: {
        ...createDocumentTypeDefaultForm.docCategoryId,
        options: documentCategories
          ? documentCategories.map((category) => ({
              label: category.name,
              value: `${category.id}`,
            }))
          : [],
        defaultValue: documentCategories && documentCategories.length && documentCategories[0].id,
      },
    }),
    [documentCategories]
  );

  const [selectedDocumentType, setSelectedDocumentType] = useState<IDocumentType | null>(null);
  const [action, setAction] = useState<"" | "fields" | "links" | "ocr-mappings" | "access-config">(
    ""
  );
  const onDismissLinkedDocumentTypeModal = () => {
    setSelectedDocumentType(null);
    setAction("");
    setSelectedDocumentTypeFields([]);
  };
  const onShowLinkedDocumentTypeModal = (documentType: IDocumentType) => {
    setSelectedDocumentType(documentType);
    setAction("links");
    getDocumentTypeMetaData(documentType.id);
  };
  const [selectedDocumentTypeFields, setSelectedDocumentTypeFields] = useState<IOption<number>[]>(
    []
  );

  // ACCESS CONFIG
  const {
    data: selectedDocTypeAccessConfig,
    execute: getDocumentTypeAccessConfiguration,
    loading: isFetchingAccessConfig,
  } = useBaseRequest(DocumentTypeGateway.getDocumentTypeAccessConfiguration, {
    onError: (err) => {
      hideAccessConfigModalHandler();
      danger(err.message);
    },
  });

  const { execute: editDocumentTypeAccessConfiguration, loading: isEditingAccessConfig } =
    useBaseRequest(DocumentTypeGateway.editDocumentTypeAccessConfiguration, {
      onCompleted: () => {
        success(t("DOCUMENT_TYPE.ACCESS_CONFIG_SAVED", { docType: selectedDocumentType?.name }));
        hideAccessConfigModalHandler();
      },
    });

  const isVisibleAccessConfigModal = !!selectedDocumentType && action === "access-config";
  const hideAccessConfigModalHandler = () => setSelectedDocumentType(null);
  const showAccessConfigModalHandler = (documentType: IDocumentType) => {
    setSelectedDocumentType(documentType);
    setAction("access-config");
    getDocumentTypeAccessConfiguration(documentType.id);
  };

  const accessConfigForm: GenericForm<DocumentTypeAccessConfigForm> = useMemo(() => {
    return mapAccessConfigToForm(selectedDocTypeAccessConfig, t);
  }, [selectedDocTypeAccessConfig]);

  // CUSTOM FIELDS
  const { execute: getDocumentTypeMetaData } = useBaseRequest(
    DocumentTypeGateway.getDocumentTypeMetaDataById,
    {
      onCompleted: (data: any) => {
        if (data)
          setSelectedDocumentTypeFields(
            data.map((item: any) => ({ value: item.item.id, label: item.item.name }))
          );
      },
    }
  );

  const isVisibleDocTypeFieldsModal = !!selectedDocumentType && action === "fields";
  const hideDocTypeFieldsModalHandler = () => setSelectedDocumentType(null);
  const showDocTypeFieldsModalHandler = (documentType: IDocumentType) => {
    setSelectedDocumentType(documentType);
    setAction("fields");
    getDocumentTypeMetaData(documentType.id);
  };

  // OCR MAPPING
  const isVisibleDocTypeOCRMappingModal = !!selectedDocumentType && action === "ocr-mappings";
  const hideDocTypeOCRMappingModalHandler = () => setSelectedDocumentType(null);
  const showDocTypeOCRMappingModalHandler = (documentType: IDocumentType) => {
    setSelectedDocumentType({
      ...documentType,
      ocrKeyMapping: docTypeOCRKeys?.docTypeMappings.find(
        (item) => item.docTypeId === documentType.id
      ),
    });
    setAction("ocr-mappings");
  };

  const updateDocumentTypeOCRMapping = () => {
    getDocumentTypeOCRMapping({});
  };

  return {
    isVisibleCreateDocTypeModal,
    showCreateDocTypeModalHandler,
    hideCreateDocTypeModalHandler,
    createDocumentTypeForm,
    createDocumentType,
    creatingDocumentType,
    getDocumentTypes,
    updateDocumentTypeOCRMapping,
    fetchingDocumentTypes,
    docTypeCount,
    docTypeTableData,
    documentTypeFilters,
    deleteDocType,
    hideDeleteDocTypeModalHandler,
    deleteDocTypeId,
    deletingDocumentType,
    isVisibleLinkedDocumentTypeModal: !!selectedDocumentType && action === "links",
    onDismissLinkedDocumentTypeModal,
    selectedDocumentType: {
      ...selectedDocumentType,
      customFields: selectedDocumentTypeFields,
    },
    accessConfigForm: {
      // DO NOT RAISE MODAL UNTIL DATA NEEDED IS FETCHED
      isVisible: isVisibleAccessConfigModal && !isFetchingAccessConfig,
      hide: hideAccessConfigModalHandler,
      form: accessConfigForm,
      loading: isEditingAccessConfig,
      submit: (accessConfig: DocumentTypeAccessConfigForm) =>
        editDocumentTypeAccessConfiguration(
          mapAccessControlFormToPayload(selectedDocumentType?.id as number, accessConfig)
        ),
    },
    isVisibleDocTypeFieldsModal,
    hideDocTypeFieldsModalHandler,
    selectedDocTypeAccessConfig,
    isVisibleDocTypeOCRMappingModal,
    hideDocTypeOCRMappingModalHandler,
    showDocTypeOCRMappingModalHandler,
    loading: loadingDocTypeOCRMapping || fetchingDocumentTypes || isFetchingAccessConfig,
  };
};

import { Modal } from "react-bootstrap";
import { Button } from "../../../components/button";
import { useTranslation } from "react-i18next";
import { GenericForm } from "../../../components/generic-form";
import { DocumentTypeOCRMappingModalProps } from "./DocumentTypeOCRMapping.types";
import { useDocumentTypeOCRMapping } from "./useDocumentTypeOCRMapping";

export const DocumentTypeOCRMappingModal = ({
  isVisible,
  onDismiss,
  onChange,
  docType,
}: DocumentTypeOCRMappingModalProps) => {
  const { t } = useTranslation();
  const {
    form,
    loading,
    onSubmit: onSubmitDocType,
  } = useDocumentTypeOCRMapping(docType, onChange, onDismiss);
  return (
    <Modal show={isVisible} animation onHide={onDismiss}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {t("DOCUMENT_TYPE.EDIT_DOCUMENT_TYPE_OCR_KEY")}
        </h5>
        <div onClick={onDismiss}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <GenericForm
          form={form}
          onSubmit={onSubmitDocType}
          submitControl={(submit) => (
            <div className="flex-row">
              <Button
                isLoading={loading}
                loader={{ size: "s" }}
                className="btn btn-primary mt-3 w-100"
                type="submit"
              >
                {t("SAVE_BUTTON")}
              </Button>
            </div>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

import { TableRow } from "../../../components/table";
import { IDocumentCategory } from "../../../entities/IDocumentCategory";
import { Icon } from "../../../modules/icon";
import { ArchiveDateStartingPoint } from "./ArchiveDateStartingPoint";

export const mapDocumentCategoryTotableRow = (
  category: IDocumentCategory,
  onDeleteClick: (id: number) => void
): TableRow => [
  category.name,
  category.archivePeriod,
  category.classificationCode || "",
  category.archiveDateStartingPoint ? (
    <ArchiveDateStartingPoint startingPoint={category.archiveDateStartingPoint} />
  ) : (
    ""
  ),
  <Icon
    title={"DELETE_BUTTON"}
    type="fa-solid fa-trash"
    onClick={(event) => {
      event?.stopPropagation();
      onDeleteClick(category.id);
    }}
  />,
];

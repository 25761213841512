import { BadgeProps } from "./Badge.types";
import { getTextColor } from "./utils/getTextColor";

export function Badge({
  type = "primary",
  className,
  title,
  children,
  variant = "filled",
  color,
}: BadgeProps) {
  const style =
    variant === "filled"
      ? {
          backgroundColor: color ? color : "",
          color: color ? getTextColor(color) : "",
        }
      : {
          border: `2px solid`,
          borderColor: color ? color : "",
          color: color ? color : "",
        };
  const classNameColor = color
    ? ""
    : `border-${type} ${variant === "filled" ? `bg-${type}` : `text-${type}`}`;
  return (
    <span
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      title={title}
      className={`badge border-2 ${classNameColor}
      ${className ? " " + className : ""} `}
      style={style}
    >
      {children}
    </span>
  );
}

import { useState } from "react";
import moment from "moment";

import "./Datepicker.style.css";
import { DatepickerProps } from "./Datepicker.types";
import { Icon } from "../../modules/icon";

export const Datepicker = ({
  onChange,
  format = "DD.MM.YYYY",
  className = "",
  disabled = false,
  initialValue,
}: DatepickerProps) => {
  const [date, setDate] = useState<Date | undefined>(initialValue);

  const formatDateOutput = (date: Date | undefined) => {
    return moment(date).format(format);
  };

  return (
    <>
      <div id="datepicker">
        <input
          type="text"
          className={`${className}`}
          id="datepicker-input"
          value={date ? formatDateOutput(date) : ""}
          readOnly
          onChange={() => {}}
          disabled={disabled}
        />
        <Icon type={`fa-regular fa-calendar-days datepicker-toggle-button`} size={18} />
        <input
          type="date"
          className="datepicker-hidden-input"
          value={date ? moment(date).format("YYYY-MM-DD") : undefined}
          onChange={(e) => {
            setDate(e.target.value ? new Date(e.target.value) : undefined);
            onChange(e);
          }}
          disabled={disabled}
        />
      </div>
    </>
  );
};

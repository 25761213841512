import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IOrganization } from "../../entities/IOrganization";
import { IArchiveBook } from "../../entities/IArchiveBook";

interface OrganizationState {
  organizations: IOrganization[];
  archiveBooks: IArchiveBook[];
}

const initialState: OrganizationState = {
  organizations: [],
  archiveBooks: [],
};

export const organizationSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganizations: (state: OrganizationState, action: PayloadAction<IOrganization[]>) => {
      state.organizations = action.payload;
    },
    setArchiveBooks: (state: OrganizationState, action: PayloadAction<IArchiveBook[]>) => {
      state.archiveBooks = action.payload;
    },
  },
});

export const organizationActions = organizationSlice.actions;

export default organizationSlice.reducer;

import useBaseRequest from "../../api/BaseRequest";
import SefGateway from "../../api/gateways/SefGateway";
import { TableRow } from "../../components/table";
import { useState, useEffect } from "react";
import { mapReportToTableRow } from "./utils/mapReportToTableRow";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { translation } from "../../components/translations";
import { sefReportFilters as sefReportDefaultFilters } from "./SefReports.types";
import { InvoiceKind, InvoiceStatus } from "../sef-integration/SefIntegration.types";

export const useSefReports = () => {
  const { t } = useTranslation();
  const [reportTableData, setReportTableData] = useState<TableRow[]>([]);
  const [reportCount, setReportCount] = useState(0);
  const location = useLocation();

  useEffect(() => {
    getReports(location.search || "");
  }, [location.search, translation.language]);

  // Requests
  const {
    data,
    execute: getReports,
    loading: fetchingReports,
  } = useBaseRequest(SefGateway.getReports, {
    onCompleted: (data) => {
      const reportsTable = data.reports
        ? data.reports.map((report) => mapReportToTableRow(report, t))
        : [];

      setReportTableData(reportsTable);
      setReportCount(data.count);
    },
  });

  const sefReportFilters = {
    ...sefReportDefaultFilters,
    "requestParameters.kind": {
      ...sefReportDefaultFilters["requestParameters.kind"],
      options: Object.keys(InvoiceKind).map((kind) => ({
        label: t("SEF_SYNC." + kind.toUpperCase()),
        value: kind.toLowerCase(),
      })),
    },
    "requestParameters.status": {
      ...sefReportDefaultFilters["requestParameters.status"],
      options: Object.keys(InvoiceStatus).map((status) => ({
        label: t("SEF_SYNC." + status.toUpperCase()),
        value: status,
      })),
    },
  };

  return {
    reportCount,
    reportTableData,
    fetchingReports,
    sefReportFilters,
  };
};

import { FilterProps } from "./Filters.types";
import "./Filters.style.css";
import AutocompleteOpt from "../autocomplete-opt/AutocompleteOpt";
import { IOption } from "../select";
import { AutocompleteOptProps } from "../autocomplete-opt/AutocompleteOpt.types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const autocompleteMultiple = (
  type: "users" | "baskets" | "document-types" | "labels",
  placeholder: string,
  selectedOptions: any[] | [],
  onSelectOption: (options: IOption<any>[] | undefined) => void
): AutocompleteOptProps => {
  return {
    label: "",
    placeholder,
    isMultiple: true,
    defaultValue: selectedOptions.map((item) => (["document-types"].includes(type) ? item : +item)),
    onSelectOption: (options: IOption<any>[] | IOption<any> | undefined) => {
      if (Array.isArray(options) || typeof options === "undefined") {
        onSelectOption(options);
      }
    },
    limit: type === "baskets" ? 1000 : undefined,
    minCharsToSearch: type === "baskets" ? 0 : undefined,
    endpoint: `/${type}/search-autocomplete`,
    showRemoveAll: true,
  };
};
export function MultipleChoiceAutocompleteFilter({ filter, onRemove, onFilterSet }: FilterProps) {
  const [defaultValue, setDefaultValue] = useState<number[] | undefined>(
    filter.defaultValues as number[] | undefined
  );
  const onSelectOption = (options: IOption<any>[] | undefined) => {
    if (options && options?.length !== 0 && !defaultValue) {
      onFilterSet(filter.buildQuery(filter, [...options.map((opt) => opt.value)]), filter);
    }
    if (defaultValue) setDefaultValue(undefined);
    if (!options || options?.length === 0) {
      onRemove(filter);
    }
  };
  const { t } = useTranslation();
  const searchItem = (filter.options ? filter.options[0].value : "users") as
    | "users"
    | "baskets"
    | "document-types"
    | "labels";
  const searchItemText = t(`FILTERS.SEARCH_${searchItem.toUpperCase()}`);
  return (
    <div className="mr-3 mb-1" style={{ marginTop: "3.5px" }}>
      <AutocompleteOpt
        {...autocompleteMultiple(
          (filter.options ? filter.options[0].value : "users") as
            | "users"
            | "baskets"
            | "document-types"
            | "labels",
          searchItemText,
          (filter?.defaultValues as any[]) || [],

          onSelectOption
        )}
      />
    </div>
  );
}

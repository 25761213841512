import { Button } from "../button";
import Header from "../header/Header";
import { AddCommentModal } from "./AddCommentModal ";
import { CommentsProps } from "./comments.types";
import Comment from "./Comment";
import { useTranslation } from "react-i18next";

export const Comments = ({
  comments,
  showMoreCommentsToggle,
  showMoreComments,
  showAddCommentModal,
  addModalData,
  togglePin,
}: CommentsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <AddCommentModal {...addModalData} />
      <div>
        <div className="mt-3 flex-column gap-2">
          <Header
            title={t("TASKS.COMMENTS_TITLE")}
            actions={
              <Button className={`btn btn-dark`} onClick={showAddCommentModal}>
                {t("CREATE_BUTTON")}
              </Button>
            }
          />
          <div className="flex-column gap-3 bg-white p-3 rounded-2">
            {comments.map((comment) => (
              <Comment
                isPinned={!!comment.isPinned}
                togglePin={togglePin}
                key={comment.id}
                {...comment}
              />
            ))}
          </div>
        </div>
        {comments.length > 2 && (
          <Button
            className={`btn btn-primary text-primary mt-4 bg-transparent border-0 shadow-none w-100`}
            onClick={showMoreCommentsToggle}
          >
            {showMoreComments ? t("TASKS.SHOW_LESS") : t("TASKS.SHOW_MORE")}
          </Button>
        )}
      </div>
    </>
  );
};

import { useMemo, useState, useEffect } from "react";
import { useDocumentService } from "../../services/useDocumentService";
import { archiveBookItemFilters as archiveBookItemDefaultFilters } from "./ArchiveBookDetail.types";
import { createArchiveBookItemForm as createItemDefaultForm } from "./ArchiveBookDetail.types";
import { TableRow } from "../../components/table";
import useBaseRequest from "../../api/BaseRequest";
import ArchiveBookGateway from "../../api/gateways/ArchiveBookGateway";
import { mapItemToTableRow } from "./utils/mapItemToTableRow";
import { useToast } from "../../components/toast";
import {
  ARCHIVE_BOOK_ITEM_INFO_MESSAGES,
  ARCHIVE_BOOK_ITEM_SUCCESS_MESSAGES,
  CreateArchiveBookItemDTO,
} from "../../entities/IArchiveBookItem";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadBookExport } from "./utils/downloadBookExport";
import { processArchiveBookItemPayload } from "./utils/processArchiveBookItemPayload";
import { screens } from "../Navigation.types";
import { useTranslation } from "react-i18next";

export const useArchiveBookDetail = (id: string | undefined) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { useGetDocumentCategories } = useDocumentService();
  const { documentCategories } = useGetDocumentCategories();
  const { success, danger, info } = useToast();
  const navigate = useNavigate();

  const [archiveBookName, setArchiveBookName] = useState<string>("");
  const [isVisibleCreateItemModal, setIsVisibleCreateItemModal] = useState<boolean>(false);
  const [itemCount, setItemCount] = useState<number>(0);
  const [deleteItemId, setDeleteItemId] = useState<number | null>(null);
  const [itemTableData, setIttemTableData] = useState<TableRow[]>([]);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    } else {
      getArchiveBookName(+id);
    }
  }, []);

  const getFiltersForArchiveBook = () =>
    (location.search || "?") +
    `&filters=${JSON.stringify({
      name: "archiveBookId",
      operator: "eq",
      value: id as string,
      type: "number",
    })}`;

  useEffect(() => {
    getItems(getFiltersForArchiveBook());
  }, [location.search]);

  // Handlers
  const showCreateItemModalHandler = () => setIsVisibleCreateItemModal(true);
  const hideCreateItemModalHandler = () => setIsVisibleCreateItemModal(false);
  const showDeleteItemModalHandler = (id: number) => setDeleteItemId(id);
  const hideDeleteItemModalHandler = () => setDeleteItemId(null);
  const deleteItem = () => deleteItemId && deleteItemFn(deleteItemId);
  const exportItems = () =>
    exportItemsFn({ archiveBookId: +(id as string), query: location.search });

  // Requests
  const {
    data,
    execute: getItems,
    loading: fetchingItems,
  } = useBaseRequest(ArchiveBookGateway.getArchiveBookItems, {
    onCompleted: (data) => {
      const itemsTable = data.archiveBookItems
        ? data.archiveBookItems.map((item) => mapItemToTableRow(item, showDeleteItemModalHandler))
        : [];

      setIttemTableData(itemsTable);
      setItemCount(data.count);
    },
  });

  const { execute: createItem, loading: creatingItem } = useBaseRequest(
    (payload: CreateArchiveBookItemDTO) =>
      ArchiveBookGateway.createArchiveBookItem(
        processArchiveBookItemPayload(payload),
        +(id as string)
      ),
    {
      onCompleted: (data) => {
        success(t(ARCHIVE_BOOK_ITEM_SUCCESS_MESSAGES.created));
        navigate(screens.archiveSimple + `/${data.id}`);
        setIsVisibleCreateItemModal(false);
      },
      onError: (error) => {
        if (+error.code === 204) {
          const message = error.message || t(ARCHIVE_BOOK_ITEM_INFO_MESSAGES.noItemsFound);
          info(message);
          setIsVisibleCreateItemModal(false);
          return;
        }
        danger(error?.message || "Something went wrong.");
      },
    }
  );

  const { execute: deleteItemFn, loading: deletingItem } = useBaseRequest(
    ArchiveBookGateway.deleteArchiveBookItems,
    {
      onCompleted: () => {
        success(t(ARCHIVE_BOOK_ITEM_SUCCESS_MESSAGES.deleted));
        getItems(getFiltersForArchiveBook());
        setDeleteItemId(null);
      },
    }
  );

  const { execute: getArchiveBookName, loading } = useBaseRequest(
    ArchiveBookGateway.getArchiveBookById,
    {
      onCompleted: (data) => {
        const archiveBook = data.archiveBooks[0];
        if (archiveBook) {
          setArchiveBookName(archiveBook?.name);
        }
      },
    }
  );

  const { execute: exportItemsFn, loading: exportingItems } = useBaseRequest(
    ArchiveBookGateway.exportArchiveBookItems,
    {
      onCompleted: (data) => {
        downloadBookExport(data, +(id as string), archiveBookName);
      },
    }
  );

  // Creation form
  const createItemForm = useMemo(
    () => ({
      ...createItemDefaultForm,
      docCategoryId: {
        ...createItemDefaultForm.docCategoryId,
        options: documentCategories
          ? documentCategories.map((category) => ({
              label: category.name,
              value: `${category.id}`,
            }))
          : [],
        defaultValue: documentCategories && documentCategories.length && documentCategories[0].id,
      },
    }),
    [documentCategories]
  );

  // Filters
  const archiveBookItemFilters = useMemo(
    () => ({
      ...archiveBookItemDefaultFilters,
      docCategoryId: {
        ...archiveBookItemDefaultFilters.docCategoryId,
        options:
          documentCategories && documentCategories.length
            ? documentCategories.map((category) => {
                return {
                  value: category.id.toString(),
                  label: category.name,
                };
              })
            : [],
      },
    }),
    [documentCategories]
  );

  return {
    archiveBookItemFilters,
    showCreateItemModalHandler,
    hideCreateItemModalHandler,
    isVisibleCreateItemModal,
    createItemForm,
    createItem,
    creatingItem,
    getItems,
    fetchingItems,
    deleteItem,
    deletingItem,
    itemTableData,
    itemCount,
    deleteItemId,
    hideDeleteItemModalHandler,
    exportItems,
    exportingItems,
    title: archiveBookName ? archiveBookName : "Archive Book",
  };
};

import { useTranslation } from "react-i18next";
import { IOption } from "../../components/select";
import { ITask, TaskStatuses } from "../../entities/ITask";
import { IUser, UserRoles } from "../../entities/IUser";

export function useTaskActions() {
  const { t } = useTranslation();
  const getActions = (task: ITask, user: IUser | null): IOption<string>[] => {
    const actions: IOption<string>[] = [];
    taskActions.forEach((callAction) => {
      const ta = callAction(task, user);
      if (!ta) return;
      actions.push(ta);
    });
    return actions;
  };

  const taskActions: Array<(task: ITask, user: IUser | null) => IOption<string> | null> = [
    (task, user) => {
      const action = { label: t("TASK_ACTIONS.RESOLVE"), value: "resolved" };
      if ([TaskStatuses.INPROGRESS, TaskStatuses.ONHOLD].indexOf(task.status) < 0) {
        return null;
      }
      if (
        user?.role === UserRoles.ADMIN ||
        user?.role === UserRoles.SUPERVISOR ||
        task?.participants?.find((item) => item.id === +(user?.id as string))
      ) {
        return action;
      }
      return null;
    },
    (task, user) => {
      const action = { label: t("TASK_ACTIONS.PUT_ON_HOLD"), value: "onhold" };
      if ([TaskStatuses.INPROGRESS, TaskStatuses.ASSIGNED].indexOf(task.status) < 0) {
        return null;
      }
      if (
        user?.role === UserRoles.ADMIN ||
        user?.role === UserRoles.SUPERVISOR ||
        task?.participants?.find((item) => item.id === +(user?.id as string))
      ) {
        return action;
      }
      return null;
    },
    (task, user) => {
      if (!task?.participants?.length) {
        return null;
      }
      const action = { label: t("TASK_ACTIONS.ACTIVATE"), value: "inprogress" };
      if (
        [
          TaskStatuses.ONHOLD,
          TaskStatuses.ASSIGNED,
          TaskStatuses.CANCELED,
          TaskStatuses.RESOLVED,
        ].indexOf(task.status) < 0
      ) {
        return null;
      }
      if (
        user?.role === UserRoles.ADMIN ||
        user?.role === UserRoles.SUPERVISOR ||
        task?.participants?.find((item) => item.id === +(user?.id as string))
      ) {
        return action;
      }
      return null;
    },
    (task, user) => {
      if (task.status === TaskStatuses.CANCELED) {
        return null;
      }
      const action = { label: t("TASK_ACTIONS.CANCEL"), value: "canceled" };
      if (
        user?.role === UserRoles.ADMIN ||
        user?.role === UserRoles.SUPERVISOR ||
        task?.participants?.find((item) => item.id === +(user?.id as string))
      ) {
        return action;
      }
      return null;
    },
  ];

  return { getActions };
}

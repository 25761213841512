// TODO: Replace bookId with book and organization name when APIs are done
export const downloadBookExport = (data: any, bookId: number, bookName: string) => {
  const bookNameUnderscores = bookName.replaceAll(" ","_")
  const outputFilename = `archive_book_${bookNameUnderscores}.xlsx`;

  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", outputFilename);
  document.body.appendChild(link);
  link.click();
};

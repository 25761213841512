import React from "react";
import BackButton from "../back-button/BackButton";
interface IHeader {
  title: string;
  actions?: React.ReactNode;
  backBtn?: boolean;
  size?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  textColor?: "light" | "dark";
  badge?: React.ReactNode;
  className?: string;
}

const Header = (props: IHeader) => {
  const { title, actions, size = "h5", textColor = "dark", badge, className = "" } = props;
  const innerComponents = (
    <>
      {title}
      {badge ? badge : null}
    </>
  );
  return (
    <div className={`flex-row align-items-center justify-content-between width-100 ${className}`}>
      <div className="flex-row gap-16">
        {props.backBtn ? <BackButton /> : null}
        {size === "h1" ? (
          <h1 className={`m-0 text-${textColor}`}>innerComponents</h1>
        ) : size === "h2" ? (
          <h2 className={`m-0 text-${textColor}`}>{innerComponents}</h2>
        ) : size === "h3" ? (
          <h3 className={`m-0 text-${textColor}`}>{innerComponents}</h3>
        ) : size === "h4" ? (
          <h4 className={`m-0 text-${textColor}`}>{innerComponents}</h4>
        ) : size === "h5" ? (
          <h5 className={`m-0 text-${textColor}`}>{innerComponents}</h5>
        ) : size === "h6" ? (
          <h6 className={`m-0 text-${textColor}`}>{innerComponents}</h6>
        ) : (
          <h1 className={`m-0 text-${textColor}`}>{innerComponents}</h1>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 0,
          maxWidth: "100%",
        }}
      >
        {actions}
      </div>
    </div>
  );
};

export default Header;

import { useTranslation } from "react-i18next";
import { PageLayout } from "../../components/page-layout";
import { FileUpload } from "../../modules/file-upload";
import { ContinuePreviousUpload } from "./ContinuePreviousUpload";
import { SplitPdfModal } from "./SplitPdfModal";
import { useUploadDocument } from "./useUploadDocument";
import { DialogModal } from "../../components/modal/DialogModal";

export function UploadDocumentScreen() {
  const { t } = useTranslation();
  const {
    onUpload,
    documents,
    loading,
    onContinueDraft,
    showDismissModal,
    showSplitPdfModal,
    closeDismissModal,
    openDismissModal,
    onDismiss,
    onSplitPdf,
    onDontSplitPdf,
    dismissFileUpload,
  } = useUploadDocument();

  if (!!documents && documents.length) {
    return (
      <PageLayout title={t("UPLOAD.TITLE")} backBtn loading={loading}>
        <ContinuePreviousUpload
          openModal={openDismissModal}
          onContinueDraft={onContinueDraft}
          type={documents.length && documents[0].uploadSessionId ? "Multiple" : "Single"}
        />
        <DialogModal
          onAccept={onDismiss}
          show={showDismissModal}
          onCancel={closeDismissModal}
          title="UPLOAD.DISMISS_PREVIOUS"
          description="UPLOAD.DISMISS_LABEL"
          acceptLabel="YES"
          cancelLabel="NO"
        />
      </PageLayout>
    );
  }
  return (
    <PageLayout title={t("UPLOAD.TITLE")} backBtn loading={loading}>
      <FileUpload onUpload={onUpload} />
      <DialogModal
        onAccept={onDismiss}
        show={showDismissModal}
        onCancel={closeDismissModal}
        title="UPLOAD.DISMISS_PREVIOUS"
        description="UPLOAD.DISMISS_LABEL"
        acceptLabel="YES"
        cancelLabel="NO"
      />
      <SplitPdfModal
        show={showSplitPdfModal}
        onSelectSplitFile={onSplitPdf}
        onSelectDontSplitFile={onDontSplitPdf}
        onDismissUpload={dismissFileUpload}
      />
    </PageLayout>
  );
}

import { GenericForm } from "../../../components/generic-form/GenericForm.types";
import { IDocumentType, IDocumentTypeMapping } from "../../../entities/IDocumentType";

export const DocumentTypeOCRMappingForm: GenericForm<IDocumentTypeMapping> = {
  key: {
    type: "input",
    name: "key",
    label: "DOCUMENT_TYPE.DOCUMENT_TYPE_OCR_KEY",
    defaultValue: "",
  },
  docTypeId: {
    type: "hidden",
    name: "docTypeId",
    label: "DOCUMENT_TYPE.DOCUMENT_TYPE_OCR_KEY",
    defaultValue: -1,
  },
  id: {
    type: "hidden",
    name: "id",
    label: "ID",
    defaultValue: -1,
  },
};

export interface DocumentTypeOCRMappingModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  onChange: () => void;
  docType: IDocumentType | null;
}

import "./Drawer.style.css";
import React, { createContext, useContext } from "react";
import { DrawerOverlayProps, DrawerProps, DrawerSideProps } from "./Drawer.types";

const DrawerContext = createContext({ isOpen: false });

export function Drawer({
  initialVisibility = "opened",
  type = "overlay",
  position = "left",
  width = 240,
  minWidth = 70,
  drawerRef,
  children,
  drawerContent,
}: DrawerProps) {
  const [isOpen, setIsOpen] = React.useState(initialVisibility === "opened" ? true : false);

  const close = () => {
    setIsOpen(false);
  };

  const open = () => {
    setIsOpen(true);
  };

  const toggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  React.useEffect(() => {
    if (!!drawerRef) {
      drawerRef.current.open = open;
      drawerRef.current.close = close;
      drawerRef.current.toggle = toggle;
    }
  }, [drawerRef, toggle]);

  return type === "overlay"
    ? DrawerOverlay({
        isOpen,
        open,
        close,
        children,
        drawerContent,
        position,
        width,
      })
    : DrawerSide({
        isOpen,
        open,
        close,
        children,
        drawerContent,
        position,
        width,
        minWidth,
      });
}

function DrawerOverlay(props: DrawerOverlayProps) {
  const position = props.position === "right" ? { right: 0 } : { left: 0 };
  const translateClosed =
    props.position === "right"
      ? { transform: "translate(100%)" }
      : { transform: "translate(-100%)" };
  return (
    <div>
      <div
        className={`drawer-overlay`}
        style={{
          width: props.width,
          ...position,
          ...(props.isOpen ? { transform: "translate(0)" } : translateClosed),
          boxShadow: props.isOpen ? "1px 0px 7px rgba(0, 0, 0, 0.5)" : "none",
        }}
      >
        {props.drawerContent
          ? props.drawerContent({
              isOpen: props.isOpen,
              open: props.open,
              close: props.close,
            })
          : null}
      </div>
      {props.isOpen ? (
        <div className="overlay-backdrop" style={position} onClick={props.close}></div>
      ) : null}
      {props.children}
    </div>
  );
}

function DrawerSide(props: DrawerSideProps) {
  const position = props.position === "right" ? { right: 0 } : { left: 0 };
  const sideWidth = props.isOpen ? props.width : props.minWidth;
  return (
    <div className="drawer-side-wrapper">
      {props.position === "right" ? (
        <div className="drawer-side-main" style={{ paddingLeft: sideWidth }}>
          {props.children}
        </div>
      ) : null}
      <div
        className="drawer-side"
        style={{
          width: sideWidth,
          zIndex: 2,
          ...position,
        }}
      >
        {props.drawerContent
          ? props.drawerContent({
              isOpen: props.isOpen,
              open: props.open,
              close: props.close,
            })
          : null}
      </div>
      {props.position === "left" ? (
        <div
          className="drawer-side-main"
          style={{
            paddingLeft: sideWidth,
            width: "100%",
          }}
        >
          <DrawerContext.Provider value={{ isOpen: props.isOpen }}>
            {props.children}
          </DrawerContext.Provider>
        </div>
      ) : null}
    </div>
  );
}

export function useDrawerContext() {
  return useContext(DrawerContext);
}

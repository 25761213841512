import { DataType } from "../../Filters.types";

export function buildDateQueryOperator(
  values: string[] | Date[] | number[] | (undefined | string | number | Date)[]
) {
  let value = null;
  let operator = "between";
  let type: DataType = "dateArray";
  if (
    values.length &&
    values.length &&
    values.length > 1 &&
    !values[0] &&
    values[1]
  ) {
    operator = "lte";
    value = values[1];
    type = "date";
  }
  if (
    values.length &&
    values.length &&
    values.length > 1 &&
    !values[1] &&
    values[0]
  ) {
    operator = "gte";
    value = values[0];
    type = "date";
  }

  return { value: value || values, operator, type };
}

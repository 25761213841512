import { CreateDigitalSignatureRequestDTO } from "../../entities/IDigitalSignatureRequest";
import BaseInstance from "../BaseInstance";

const DigitalSignatureGateway = {
  createDigitalSignatureRequest: async (payload: CreateDigitalSignatureRequestDTO) => {
    return BaseInstance.post("/digital-sign-requests", payload);
  },
  deleteDigitalSignatureRequest: async (id: number) => {
    return BaseInstance.delete(`/digital-sign-requests/${id}`);
  },
};

export default DigitalSignatureGateway;

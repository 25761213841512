import { GetOptionsResponse } from "../../components/autocomplete-opt/AutocompleteOpt.types";
import BaseInstance from "../BaseInstance";

export const ConfigurationGateway = {
  getProviders: async (query: string): Promise<GetOptionsResponse> => {
    return BaseInstance.get(`/providers/search-autocomplete${query}`);
  },
  deleteProvider: async (id: number): Promise<void> => {
    return BaseInstance.delete(`/providers/${id}`);
  },
  createProvider: async ({ name }: { name: string }): Promise<void> => {
    return BaseInstance.post(`/providers`, { name });
  },
};

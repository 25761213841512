import ColorPickerModal from "@rc-component/color-picker";
import { ColorpickerProps } from "./Colorpicker.types";
import { useColorpicker } from "./useColorpicker";

export const ColorPicker = ({
  className,
  value = "#000000",
  onChange,
  disabled,
}: ColorpickerProps) => {
  const {
    onChangeHandler,
    onChangeHandlertEvent,
    validValue,
    toggleColorPickerVisibility,
    isColorPickerOpen,
  } = useColorpicker(onChange, value);
  return (
    <div style={{ position: "relative" }}>
      <input
        type="text"
        className={`${className}`}
        id="colopicker-input"
        value={value}
        onChange={onChangeHandlertEvent}
        disabled={disabled}
      />
      <div
        style={{
          backgroundColor: validValue,
          border: "1px solid #000",
          width: "24px",
          height: "24px",
          right: "8px",
          top: "7px",
          borderRadius: "50%",
          position: "absolute",
          zIndex: 1000,
        }}
        onClick={toggleColorPickerVisibility}
      />

      <ColorPickerModal
        style={{ width: "100%", marginTop: "4px", display: isColorPickerOpen ? "block" : "none" }}
        value={validValue}
        onChangeComplete={onChangeHandler}
      />
    </div>
  );
};

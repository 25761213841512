const publicUrl = (window as any).public_url || "";

export const screens = {
  documents: "/documents",
  digitalSignature: "/digital-signature",
  documentCategories: "/document-categories",
  documentTypes: "/document-types",
  customFields: "/custom-fields",
  splitFile: "/split-file",
  login: "/login",
  uploadDocument: "/upload-document",
  archive: "/archive/:archiveItemId?",
  archiveSimple: "/archive",
  newDocument: "/new-document/:id",
  search: "/search",
  baskets: "/baskets",
  basketTasks: "/baskets/:id",
  basketRules: "/baskets-rules",
  tasks: "/tasks",
  myTasks: "/my-tasks",
  task: {
    root: "/task/:id",
    comments: "comments",
    details: "details",
    logs: "logs",
  },
  codeCallback: "code",
  taskStats: "/task-stats",
  archiveStats: "/archive-stats",
  archiveBooks: "/archive-books",
  root: publicUrl + "/",
  reports: "/reports",
  uploadSession: "/upload-session/:sessionId",
  sefIntegrations: "/integrations/sef",
  sefReports: "/integrations/sef/reports",
  sefReportOverview: "/integrations/sef/reports/:id",
  labels: "/labels",
  configuration: "/configuration",
};

import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { useToast } from "../../components/toast";
import { useMemo, useState } from "react";
import { AddTaskAttachmentDTO } from "./Task.types";
import { useTranslation } from "react-i18next";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
export const useAddAtachment = (
  onDismiss: () => void,
  addAttachmentForm: GenericForm<AddTaskAttachmentDTO>,
  onSubmit: (data: { payload: AddTaskAttachmentDTO; onClose?: () => void }) => void
) => {
  const { t } = useTranslation();
  const getTotalFileSize = (files: File[]) => {
    var totalSize = 0;
    for (var i = 0; i < files.length; i++) {
      totalSize += files[i].size;
    }
    return totalSize / (1024 * 1024);
  };

  const onDismissHandler = () => {
    onDismiss();
    setTimeout(() => setAddExistingDocument(undefined), 500);
    setNewDocumentID(undefined);
    newDocumentID && dismissDocument({ id: newDocumentID.toString() });
  };
  const [addExistingDocument, setAddExistingDocument] = useState<boolean>();
  const [newDocumentID, setNewDocumentID] = useState<number | undefined>();
  const { danger, success } = useToast();
  const { execute: createDraft, loading: creating } = useBaseRequest(
    DocumentGateway.createDocument,
    {
      onCompleted: (data) => {
        if (!data) return;
        setNewDocumentID(data.id);
      },
      onError: (error) => {
        danger(error.message);
      },
    }
  );
  const onUpload = (files: File[]) => {
    if (!files || !files.length) return;
    if (getTotalFileSize(files) > 100) {
      danger(t("DOCUMENTS.FILES_TOO_BIG"));
      return;
    }
    createDraft({ file: files[0], draft: true });
  };

  const onClose = () => {
    setNewDocumentID(undefined);
    setAddExistingDocument(undefined);
  };

  const onSubmitHandler = (data: AddTaskAttachmentDTO) => {
    onSubmit({ payload: data, onClose });
  };

  const addAttachmentFormUpdated = useMemo(() => {
    return {
      ...addAttachmentForm,
      documentId: {
        ...addAttachmentForm.documentId,
        defaultValue: newDocumentID ? newDocumentID : undefined,
        type: newDocumentID ? "hidden" : "autocompleteSingleChoice",
        visibleIf: () => !newDocumentID,
        validators: [GenericFormValidator.required()],
      },
    };
  }, [addAttachmentForm, newDocumentID]);
  const { execute: dismissDocument } = useBaseRequest(DocumentGateway.dismissDocument, {
    onCompleted: (data) => {},
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });
  return {
    onDismissHandler,
    onSubmitHandler,
    addExistingDocument,
    newDocumentID,
    setAddExistingDocument,
    onUpload,
    addAttachmentFormUpdated,
  };
};

import moment from "moment";
import { TableRow } from "../../../components/table";
import { Language } from "../../../components/translations/language";
import { IMetaDataItem } from "../../../entities/IMetaData";
import { Icon } from "../../../modules/icon";

export const mapCustomFieldToTableRow = (
  item: IMetaDataItem,
  translationFunc: (value: string) => string
): TableRow => {
  return [
    item?.translations ? item?.translations[Language.EN] : "",
    item?.translations ? item?.translations[Language.RS] : "",
    translationFunc("CUSTOM_FIELDS.DATA_TYPE." + item.dataType),
    item.createdAt ? moment(item.createdAt).format("DD.MM.YYYY") : "",
  ];
};

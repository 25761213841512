import { Filter } from "../../components/filters";
import { buildNumberRangeQueryOperator } from "../../components/filters/NumberRangeFilter/utils/buildNumberRangeQuery";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
import { TableHeaders } from "../../components/table";
import {
  ArchiveDateStartingPoint,
  CreateDocumentCategoryDTO,
} from "../../entities/IDocumentCategory";
import { isDisabledArchiveStartingPoint } from "./utils/isDisabledArchiveStartingPoint";

export const documentCategoryTableHeaders: TableHeaders[] = [
  { name: "NAME", key: "name" },
  { name: "ARCHIVE_PERIOD", key: "archivePeriod" },
  { name: "CLASSIFICATION_CODE", key: "classificationCode" },
  { name: "ARCHIVE_DATE_STARTING_POINT", key: "archiveDateStartingPoint" },
  { name: "ACTIONS", key: "action" },
];

export type DocumentCategoryFilterKeys = {
  archivePeriod: Filter;
  archiveDateStartingPoint: Filter;
};

export const documentCategoryFilters: DocumentCategoryFilterKeys = {
  archivePeriod: {
    type: "number-range",
    name: "FILTERS.ARCHIVE_PERIOD",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildNumberRangeQueryOperator(values);
      return {
        name: "archivePeriod",
        type,
        operator,
        value: JSON.stringify(value),
      };
    },
  },
  archiveDateStartingPoint: {
    type: "single-choice",
    name: "FILTERS.ARCHIVE_DATE_STARTING_POINT",
    options: Object.values(ArchiveDateStartingPoint).map((value) => {
      return {
        value,
        label: value,
      };
    }),
    buildQuery: (filter, values) => {
      return {
        name: "archiveDateStartingPoint",
        type: "string",
        operator: "eq",
        value: values, //check this
      };
    },
  },
};

export const createDocumentCategoryForm: GenericForm<CreateDocumentCategoryDTO> = {
  name: {
    type: "input",
    name: "name",
    label: "TABLE.TITLE",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
  classificationCode: {
    type: "input",
    name: "classificationCode",
    label: "TABLE.CLASSIFICATION_CODE",
    defaultValue: "",
    validators: [
      GenericFormValidator.maxChar({ limit: 128 }),
      GenericFormValidator.minChar({ limit: 3 }),
    ],
  },
  archivePeriod: {
    type: "input",
    name: "archivePeriod",
    label: "TABLE.ARCHIVE_PERIOD",
    defaultValue: "0",
    validators: [GenericFormValidator.required(), GenericFormValidator.numericOnly()],
  },
  archiveDateStartingPoint: {
    type: "select",
    name: "archiveDateStartingPoint",
    label: "TABLE.ARCHIVE_DATE_STARTING_POINT",
    defaultValue: ArchiveDateStartingPoint.CASE_COMPLETION,
    disabledIf: isDisabledArchiveStartingPoint,
    options: Object.values(ArchiveDateStartingPoint).map((value) => {
      return {
        value,
        label: "DOCUMENT_CATEGORY." + value,
      };
    }),
    validators: [GenericFormValidator.required()],
  },
};

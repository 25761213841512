import { IOption } from "../components/select";
import { IDbEntity } from "./IDbEntity";
import { IDocumentCategory } from "./IDocumentCategory";
import { ILabel } from "./ILabel";
import { IMetaDataItem } from "./IMetaData";
import { IRolePermission } from "./IRolePermission";

export interface IDocumentType extends IDbEntity {
  name: string;
  key: string;
  docCategoryId: number;
  docCategory?: IDocumentCategory;
  customFields?: IOption<number>[];
  providerId?: number;
  triggerLabelId?: number;
  ocrKeyMapping?: IDocumentTypeMapping;
  provider?: {
    id: number;
    name: string;
  };
}

export interface CreateDocumentTypeDTO {
  name: string;
  docCategoryId: number;
  customFields: number[];
  providerId?: number;
}
export interface IRelatedDocumentType extends IDbEntity {
  initiatorDocTypeId: number;
  responderDocTypeId: number;
  initiatorItemId: number;
  responderItemId: number;
  triggerLabelId: number;
  initiatorDocType: IDocumentType;
  responderDocType: IDocumentType;
  initiatorItem: IMetaDataItem;
  responderItem: IMetaDataItem;
  triggerLabel: ILabel;
}

export interface IRelatedDocumentTypeList {
  count: number;
  relatedDocumentTypes: IRelatedDocumentType[];
}

export interface IRelatedDocumentTypeDTO {
  sourceDocumentTypeId?: number;
  targetDocumentTypeId?: number;
  triggerLabelId?: number;
  initiatorItemId?: number;
  responderItemId?: number;
}

export interface IUpdateAccessConfigDTO {
  id: number;
  accessConfig: Omit<IRolePermission, "id">[];
}

export const DOCUMENT_TYPE_SUCCESS_MESSAGES = {
  created: "DOCUMENT_TYPE.CREATED_MESSAGE",
  deleted: "DOCUMENT_TYPE.DELETED_MESSAGE",
};

export interface IDocumentTypeMapping extends IDbEntity {
  key: string;
  docTypeId: number;
}

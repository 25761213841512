import { SideNavBarProps } from "./SideNav.types";
import "./SideNav.style.css";
import { Logo } from "../logo";
import { SideNavMenuItem } from "./SideNavMenuItem";
import { Icon } from "../../modules/icon";
import { useSideNav } from "./useSideNav";
import { useUser } from "../../store/auth/AuthSelectors";
import { ConfigurationGuard, RoleBasedAccess } from "../guard";
import { useTranslation } from "react-i18next";
import { LanguagePicker } from "../translations/LanguagePicker";

export function SideNavBar({ isOpen, open, close, openOnHover }: SideNavBarProps) {
  const { t } = useTranslation();
  const { navigate, logOut, activeRoute, sideMenuItems } = useSideNav();
  const user = useUser();
  const handlerToggle = () => {
    if (!openOnHover) return;
    if (isOpen) {
      close();
    } else {
      open();
    }
  };
  return (
    <div className={"side-nav-bar"} onMouseEnter={handlerToggle} onMouseLeave={handlerToggle}>
      <div>
        {sideMenuItems.map((menuItem, index) => (
          <ConfigurationGuard
            fallback={{ kind: "hide" }}
            isEnabled={typeof menuItem.isEnabled === "boolean" ? menuItem.isEnabled : true}
          >
            <RoleBasedAccess allowedRoles={menuItem.allowedRoles} key={index}>
              <SideNavMenuItem
                menuItem={menuItem}
                isOpen={isOpen}
                activeRoute={activeRoute}
                navigate={navigate}
              />
            </RoleBasedAccess>
          </ConfigurationGuard>
        ))}
      </div>
      <div className="mt-auto">
        <LanguagePicker isOpen={isOpen} />

        <div
          className={`side-nav-user
          ${isOpen ? "" : "justify-content-center"}`}
        >
          <div className={`side-nav-menu-item-icon${isOpen ? "" : " mx-0"}`}>
            <Icon type="fa-solid fa-user" size={18} />
          </div>
          <div className={`side-nav-menu-item-label${isOpen ? "" : " opacity-0"}`}>
            <span className="side-nav-menu-item-label-text">{user?.name}</span>
          </div>
        </div>

        <div
          className={`side-nav-user
          ${isOpen ? "" : "justify-content-center"}`}
          onClick={logOut}
        >
          <div className={`side-nav-menu-item-icon${isOpen ? "" : " mx-0"}`}>
            <Icon type={"fa-solid fa-right-from-bracket"} size={18} />
          </div>
          <div className={`side-nav-menu-item-label${isOpen ? "" : " opacity-0"}`}>
            <span className="side-nav-menu-item-label-text">{t("LOG_OUT")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

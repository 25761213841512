import { useRef } from "react";
import useDoughnutChart from "./useDoughnutChart";
import Legend from "./Legend";

function DoughnutChart({
  data,
  total,
  legendOptions = {
    isVisible: true,
    position: "right",
    total: { title: "Total", isVisible: true },
  },
}: {
  data: { title: string; value: number; color: string }[];
  total: number;
  legendOptions?: {
    isVisible?: boolean;
    position?: "bottom" | "top" | "left" | "right";
    total?: { title?: string; isVisible: boolean };
  };
}) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { isVisible, position } = legendOptions;
  useDoughnutChart(
    canvasRef,
    data.filter((item) => item.value),
    total
  );
  const positionClass =
    position === "left"
      ? "flex-row flex-row-reverse"
      : position === "right"
      ? "flex-row"
      : position === "top"
      ? "flex-column flex-column-reverse"
      : "flex-column";
  return (
    <div
      className={`${positionClass} align-items-center gap-2 justify-content-between w-100`}
    >
      <canvas ref={canvasRef} width={195} height={195} />
      {isVisible && (
        <Legend
          total={
            legendOptions.total
              ? { value: total, title: legendOptions.total.title || "Total" }
              : undefined
          }
          data={data}
        />
      )}
    </div>
  );
}
export default DoughnutChart;

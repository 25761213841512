import { useEffect, useMemo, useState } from "react";
import {
  ArchiveBookFilterKeys,
  createArchiveBookForm as createArchiveBookDefaultForm,
} from "./ArchiveBooks.types";
import {
  ARCHIVE_BOOK_SUCCESS_MESSAGES,
  CreateArchiveBookDTO,
} from "../../entities/IArchiveBook";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { useOrganizationService } from "../../services/useOrganizationService";
import useBaseRequest from "../../api/BaseRequest";
import ArchiveBookGateway from "../../api/gateways/ArchiveBookGateway";
import { useToast } from "../../components/toast";
import { TableRow } from "../../components/table";
import { mapArchiveBookToTableRow } from "./utils/mapArchiveBookToTableRow";
import { useLocation, useNavigate } from "react-router-dom";
import { screens } from "../Navigation.types";
import { translation } from "../../components/translations";
import { useTranslation } from "react-i18next";

export const useArchiveBooks = () => {
  const { t } = useTranslation();
  const [isVisibleCreateArchiveBookModal, setIsVisibleCreateArchiveBookModal] =
    useState<boolean>(false);
  const [archiveBookTableData, setArchiveBookTableData] = useState<TableRow[]>([]);
  const location = useLocation();
  const [archiveBooksCount, setArchiveBooksCount] = useState<number>(0);
  const [deleteArchiveBookId, setDeleteArchiveBookId] = useState<number | null>(null);
  const navigate = useNavigate();

  const { useGetOrganizations } = useOrganizationService();
  const { success, danger } = useToast();
  const { organizations } = useGetOrganizations();

  useEffect(() => {
    getArchiveBooks(location.search || "");
  }, [location.search]);

  const archiveBookFilters: ArchiveBookFilterKeys = {
    organizationId: {
      type: "multiple-choice",
      name: "FILTERS.ORGANIZATION",
      options:
        organizations && organizations.length
          ? organizations.map((organization) => {
              return {
                value: organization.id.toString(),
                label: `${organization.name} (${organization.mb})`,
              };
            })
          : [],
      buildQuery: (filter, values) => {
        return {
          name: "organizationId",
          type: "numberArray",
          operator: "in",
          value: JSON.stringify(values),
        };
      },
    },
  };

  const showCreateArchiveBookModalHandler = () => setIsVisibleCreateArchiveBookModal(true);
  const hideCreateArchiveBookModalHandler = () => setIsVisibleCreateArchiveBookModal(false);
  const showDeleteArchiveBookModalHandler = (id: number) => setDeleteArchiveBookId(id);
  const hideDeleteArchiveBookModalHandler = () => setDeleteArchiveBookId(null);
  const deleteArchiveBook = () => deleteArchiveBookId && deleteArchiveBookFn(deleteArchiveBookId);
  const NavigateToArchiveBookDetails = (id: number) => {
    navigate(screens.archiveBooks + `/${id}`);
  };

  const {
    data,
    execute: getArchiveBooks,
    loading: fetchingArchiveBooks,
  } = useBaseRequest(ArchiveBookGateway.getArchiveBooks, {
    onCompleted: (data) => {
      const archiveBookTable = data.archiveBooks
        ? data.archiveBooks.map((archiveBook) =>
            mapArchiveBookToTableRow(archiveBook, showDeleteArchiveBookModalHandler)
          )
        : [];

      setArchiveBookTableData(archiveBookTable);
      setArchiveBooksCount(data.count);
    },
  });

  const { execute: createArchiveBook, loading: creatingArchiveBook } = useBaseRequest(
    ArchiveBookGateway.createArchiveBook,
    {
      onCompleted: (data) => {
        success(t(ARCHIVE_BOOK_SUCCESS_MESSAGES.created));
        setIsVisibleCreateArchiveBookModal(false);
        getArchiveBooks("");
      },
    }
  );

  const { execute: deleteArchiveBookFn, loading: deletingArchiveBook } = useBaseRequest(
    ArchiveBookGateway.deleteArchiveBook,
    {
      onCompleted: () => {
        success(t(ARCHIVE_BOOK_SUCCESS_MESSAGES.deleted));
        getArchiveBooks("");
        setDeleteArchiveBookId(null);
      },
    }
  );

  const createArchiveBookForm: GenericForm<CreateArchiveBookDTO> = useMemo(
    () => ({
      ...createArchiveBookDefaultForm,
      organizationId: {
        ...createArchiveBookDefaultForm.organizationId,
        options: organizations
          ? organizations.map((organization) => ({
              label: organization.name,
              value: `${organization.id}`,
            }))
          : [],
        defaultValue: organizations && organizations.length && organizations[0].id,
      },
    }),
    [organizations, translation.language]
  );

  return {
    isVisibleCreateArchiveBookModal,
    showCreateArchiveBookModalHandler,
    hideCreateArchiveBookModalHandler,
    createArchiveBookForm,
    createArchiveBook,
    creatingArchiveBook,
    fetchingArchiveBooks,
    archiveBookTableData,
    archiveBooksCount,
    archiveBookFilters,
    deleteArchiveBookId,
    deletingArchiveBook,
    deleteArchiveBook,
    hideDeleteArchiveBookModalHandler,
  };
};

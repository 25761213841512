import { IUpdateAccessConfigDTO } from "../../../entities/IDocumentType";
import { PermissionType } from "../../../entities/IRolePermission";
import { DocumentTypeAccessConfigForm } from "../DocumentTypes.types";

export const mapAccessControlFormToPayload = (
  id: number,
  form: DocumentTypeAccessConfigForm
): IUpdateAccessConfigDTO => ({
  id,
  accessConfig: Object.keys(form).map((role) => ({
    entityIdentifier: `${id}`,
    accessType: parseInt(form[role]),
    role,
    type: PermissionType.DOCUMENT_TYPES,
  })),
});

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationEnglish from "./en.json";
import { Language } from "./language";
import translationSerbian from "./rs.json";

const resources = {
  [Language.EN]: {
    translation: translationEnglish,
  },
  [Language.RS]: {
    translation: translationSerbian,
  },
};

const translation = i18next.createInstance();
translation.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") || Language.RS,
});

export { translation };

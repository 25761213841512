import { Link } from "react-router-dom";
import { TableRow } from "../../../components/table";
import { IArchiveBook } from "../../../entities/IArchiveBook";
import { Icon } from "../../../modules/icon";

export const mapArchiveBookToTableRow = (
  book: IArchiveBook,
  onDeleteClick: (id: number) => void
): TableRow => [
  <Link to={"/archive-books/" + book.organizationId}>{book.name}</Link>,
  book.organization ? book.organization.name : "",
  book.organization ? book.organization.mb : "",
  book.numItems,
  <>
    <Link to={"/archive-books/" + book.organizationId}>
      <Icon
        type="fa-solid fa-circle-info"
        color="black"
        size={16}
        title={"ARCHIVE_BOOK.DETAILS_LABEL"}
      />
    </Link>
    <Icon
      title={"DELETE_BUTTON"}
      type="fa-solid fa-trash"
      onClick={(event) => {
        event?.stopPropagation();
        onDeleteClick(book.organizationId);
      }}
    />
  </>,
];

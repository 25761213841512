import { useTranslation } from "react-i18next";
import { Button } from "../../components/button";
import { FiltersWithQuery } from "../../components/filters";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchWithQueryInput } from "../../components/search-input";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { Icon } from "../../modules/icon";
import { documentTypeTableHeaders } from "./DocumentTypes.types";
import { useDocumentTypes } from "./useDocumentTypes";
import { FormModal } from "../../components/modal";
import { DialogModal } from "../../components/modal/DialogModal";
import { LinkedDocumentTypeModal } from "./LinkedDocumentTypesModal";
import { DocumentTypeFieldsModal } from "./DocumentTypeFieldsModal";
import { DocumentTypeOCRMappingModal } from "./ocr-mapping/DocumentTypeOCRMappingModal";

export const DocumentTypesScreen = () => {
  const { t } = useTranslation();
  const {
    isVisibleCreateDocTypeModal,
    showCreateDocTypeModalHandler,
    hideCreateDocTypeModalHandler,
    createDocumentTypeForm,
    createDocumentType,
    creatingDocumentType,
    docTypeCount,
    docTypeTableData,
    fetchingDocumentTypes,
    documentTypeFilters,
    deleteDocType,
    hideDeleteDocTypeModalHandler,
    deleteDocTypeId,
    deletingDocumentType,
    isVisibleLinkedDocumentTypeModal,
    onDismissLinkedDocumentTypeModal,
    selectedDocumentType,
    isVisibleDocTypeFieldsModal,
    isVisibleDocTypeOCRMappingModal,
    hideDocTypeFieldsModalHandler,
    hideDocTypeOCRMappingModalHandler,
    updateDocumentTypeOCRMapping,
    loading,
    accessConfigForm,
  } = useDocumentTypes();

  return (
    <PageLayout
      search={<SearchWithQueryInput placeHolder={t("DOCUMENT_TYPE.SEARCH")} className="ml-auto" />}
      filter={<FiltersWithQuery filters={documentTypeFilters} />}
      title={t("DOCUMENT_TYPE.TITLE")}
      titleRight={
        <div className="text-right flex-row gap-2">
          <div style={{ height: "100%", width: "2px", backgroundColor: "black" }}></div>
          <Button className="btn btn-dark" onClick={showCreateDocTypeModalHandler}>
            <Icon type={"fa-solid fa-add"} title={t("DOCUMENT_TYPE.CREATE_LABEL")} />
          </Button>
        </div>
      }
    >
      <FormModal
        isVisible={isVisibleCreateDocTypeModal}
        onDismiss={hideCreateDocTypeModalHandler}
        onSubmit={createDocumentType}
        loading={creatingDocumentType}
        form={createDocumentTypeForm}
        submitButtonLabel="CREATE_BUTTON"
        title="DOCUMENT_TYPE.CREATE_LABEL"
      />
      <FormModal
        isVisible={accessConfigForm.isVisible}
        onDismiss={accessConfigForm.hide}
        onSubmit={accessConfigForm.submit}
        loading={accessConfigForm.loading}
        form={accessConfigForm.form}
        submitButtonLabel="SAVE_BUTTON"
        title="ACCESS_CONFIG.TITLE"
        titleProps={{ name: selectedDocumentType.name as string }}
      />
      <DialogModal
        loading={deletingDocumentType}
        show={deleteDocTypeId != null}
        onAccept={deleteDocType}
        onCancel={hideDeleteDocTypeModalHandler}
        acceptLabel="DELETE_BUTTON"
        cancelLabel="CANCEL_BUTTON"
        description="DOCUMENT_TYPE.DELETE_LABEL"
        title="DOCUMENT_TYPE.DELETE"
      />
      <LinkedDocumentTypeModal
        isVisible={isVisibleLinkedDocumentTypeModal}
        onDismiss={onDismissLinkedDocumentTypeModal}
        selectedItem={selectedDocumentType as any}
      />
      <DocumentTypeFieldsModal
        isVisible={isVisibleDocTypeFieldsModal}
        onDismiss={hideDocTypeFieldsModalHandler}
        selectedItem={selectedDocumentType as any}
      />
      <DocumentTypeOCRMappingModal
        isVisible={isVisibleDocTypeOCRMappingModal}
        onDismiss={hideDocTypeOCRMappingModalHandler}
        onChange={updateDocumentTypeOCRMapping}
        docType={selectedDocumentType as any}
      />
      <Row>
        <Column>
          <TableWithQueryPaginationAndSort
            headers={documentTypeTableHeaders}
            data={docTypeTableData}
            totalItems={docTypeCount}
            loading={loading}
            selection={false}
          />
        </Column>
      </Row>
    </PageLayout>
  );
};

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { EditorState } from "lexical";
import { useEffect, useState } from "react";

export const OnChangePlugin = ({
  initalValue,
  onChange,
}: {
  initalValue: string;
  onChange: (value: EditorState) => void;
}) => {
  const [editor] = useLexicalComposerContext();
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);

      if (initalValue) {
        const initialEditorState = editor.parseEditorState(initalValue);
        editor.setEditorState(initialEditorState);
      }
    }
  }, [isFirstRender, initalValue, editor]);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      onChange(editorState);
    });
  }, [editor, onChange]);
  return null;
};

export type GetOptionsFunction = (options: {
  skip: number;
  resetOpts: boolean;
  textSearch: string;
  limit: number;
  endpoint: string;
  selected?: string[];
}) => void;

export function debounce<T extends (...args: any[]) => void>(
  fn: T,
  delay: number
): T {
  let timerId: ReturnType<typeof setTimeout>;

  return function (...args: Parameters<T>) {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      fn(...args);
    }, delay);
  } as T;
}

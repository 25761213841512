import { useState } from "react";
import { PageLayout } from "../../components/page-layout";
import useTask from "./useTask";
import { taskStatusesColors } from "../../entities/ITask";
import { TaskCancelModal } from "./TaskCancelModal";
import TaskBadges from "./TaskBadges";
import { TaskMoveToAnotherBasketModal } from "./TaskMoveToAnotherBasketModal";
import Tabs from "../../components/tabs/Tabs";
import { Outlet, useLocation } from "react-router-dom";
import { TaskOtherActionsModal } from "./TaskOtherActionsModal";
import { Badge } from "../../components/badge";
import { TaskPutOnHoldModal } from "./TaskPutOnHoldModal";
import { RoleBasedAccess } from "../../components/guard";
import { UserRoles } from "../../entities/IUser";
import { TaskActions } from "./TaskActions";
import { TaskResolvedModal } from "./TaskResolvedModal";
import { useTranslation } from "react-i18next";

const TaskScreen = () => {
  const {
    task,
    loading,
    taskId,
    selectedAction,
    onChangeAction,
    cancelTask,
    cancelingTask,
    taskCancelForm,
    moveToAnotherBasket,
    movingTask,
    changeStatus,
    changingStatus,
    putOnHold,
    puttingOnHold,
    taskPutOnHoldForm,
    taskTabs,
    user,
    shouldPermitBOExpertToManageTask,
    resolveTask,
    resolvingTask,
    taskResolvedForm,
    refetchTask,
    pinnedComments,
    taskAttachments,
  } = useTask();
  const location = useLocation().pathname.split("/")[3];
  const [selected, setSelected] = useState(
    location === undefined || location === "details" ? 0 : location === "comments" ? 1 : 2
  );
  const { t } = useTranslation();
  if (!!!task || !!!document) return <></>;
  return (
    <PageLayout
      loading={loading}
      title={task.title || `${t("TASKS.TASK")} #${taskId ? taskId : ""}`}
      badge={
        <Badge type={taskStatusesColors[task.status] as any} className="fs-6 fw-normal">
          {t(`TASK_STATUS.${task.status}`)}
        </Badge>
      }
      titleRight={
        <RoleBasedAccess
          allowedRoles={[UserRoles.ADMIN, UserRoles.SUPERVISOR, UserRoles.BO_EXPERT]}
        >
          {user?.role === UserRoles.BO_EXPERT && shouldPermitBOExpertToManageTask() ? null : (
            <div className="flex-row justify-content-end mr-3">
              <TaskActions onActionSelected={onChangeAction} task={task} user={user} />
            </div>
          )}
        </RoleBasedAccess>
      }
      pageInfo={<TaskBadges task={task} />}
      pageContainerClassname="overflow-hidden p-4"
      backBtn
    >
      <TaskCancelModal
        show={selectedAction === "canceled"}
        onSubmit={cancelTask}
        onDismiss={() => {
          onChangeAction("");
        }}
        changing={cancelingTask}
        taskStatusWithCommentForm={taskCancelForm}
      />
      <TaskPutOnHoldModal
        show={selectedAction === "onhold"}
        onSubmit={putOnHold}
        onDismiss={() => {
          onChangeAction("");
        }}
        changing={puttingOnHold}
        taskStatusWithCommentForm={taskPutOnHoldForm}
      />
      <RoleBasedAccess allowedRoles={[UserRoles.ADMIN, UserRoles.SUPERVISOR, UserRoles.BO_EXPERT]}>
        <TaskMoveToAnotherBasketModal
          show={selectedAction === "changeBasket"}
          onSubmit={moveToAnotherBasket}
          onCancel={() => {
            onChangeAction("");
          }}
          currentBasketId={task.basket.id}
          moving={movingTask}
        />
      </RoleBasedAccess>
      <TaskOtherActionsModal
        show={selectedAction === "inprogress"}
        onSubmit={changeStatus}
        onCancel={() => {
          onChangeAction("");
        }}
        submiting={changingStatus}
        action={selectedAction as "inprogress"}
      />
      <TaskResolvedModal
        show={selectedAction === "resolved"}
        onSubmit={resolveTask}
        onDismiss={() => {
          onChangeAction("");
        }}
        changing={resolvingTask}
        taskStatusWithCommentForm={taskResolvedForm}
      />
      <Tabs tabs={taskTabs} value={selected} setValue={setSelected}>
        <Outlet context={{ refetchTask, pinnedComments, taskAttachments }} />
      </Tabs>
    </PageLayout>
  );
};

export default TaskScreen;

import { Modal, Row } from "react-bootstrap";
import { DialogModal } from "../../components/modal/DialogModal";
import { useProvider } from "./useProvider";
import { useTranslation } from "react-i18next";
import { Column } from "../../components/grid";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { createProviderForm, providerTableHeaders } from "./Provider.types";
import { FormModal } from "../../components/modal";
import { Button } from "../../components/button";

export const ProviderModal = ({
  isVisible,
  onDismiss,
}: {
  isVisible: boolean;
  onDismiss: () => void;
}) => {
  const {
    providerCount,
    providerTableData,
    deleteProvider,
    createProvider,
    loading,
    creating,
    deleting,
    deleteId,
    showDeleteProviderModal,
    showCreateModal,
    onDismissCreateModal,
    onShowCreateModal,
    onDismissDeleteModal,
  } = useProvider();
  const { t } = useTranslation();
  return (
    <Modal fullscreen show={isVisible} animation onHide={onDismiss}>
      <FormModal
        isVisible={showCreateModal}
        onDismiss={onDismissCreateModal}
        onSubmit={createProvider}
        loading={creating}
        form={createProviderForm}
        submitButtonLabel="CREATE_BUTTON"
        title="CONFIGURATION.ADD_PROVIDER"
      />
      <DialogModal
        loading={deleting}
        show={showDeleteProviderModal}
        onAccept={() => deleteId && deleteProvider(deleteId)}
        onCancel={onDismissDeleteModal}
        acceptLabel="DELETE_BUTTON"
        cancelLabel="CANCEL_BUTTON"
        description="CONFIGURATION.DELETE_PROVIDER_LABEL"
        title="CONFIGURATION.DELETE_PROVIDER"
      />
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {t("CONFIGURATION.PROVIDERS")}
        </h5>
        <div onClick={onDismiss}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Button className="btn btn-dark" onClick={onShowCreateModal}>
          {t("CONFIGURATION.ADD_PROVIDER")}
        </Button>
        <Row>
          <Column>
            <TableWithQueryPaginationAndSort
              headers={providerTableHeaders}
              data={providerTableData}
              totalItems={providerCount}
              onRowPress={() => {}}
              selection={false}
              loading={loading}
            />
          </Column>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

import { useState, useEffect } from "react";
import useBaseRequest from "../../api/BaseRequest";
import DocumentCategoryGateway from "../../api/gateways/DocumentCategoryGateway";
import { useToast } from "../../components/toast";
import {
  ArchiveDateStartingPoint,
  DOCUMENT_CATEGORY_SUCCESS_MESSAGES,
} from "../../entities/IDocumentCategory";
import { TableRow } from "../../components/table";
import { useLocation } from "react-router";
import { mapDocumentCategoryTotableRow } from "./utils/mapDocumentCategoryTotableRow";
import { documentCategoryFilters as documentCategoryDefaultFilters } from "./DocumentCategories.types";
import { useTranslation } from "react-i18next";

export const useDocumentCategories = () => {
  const { t } = useTranslation();
  const [isVisibleCreateDocCategoryModal, setIsVisibleCreateDocCategoryModal] =
    useState<boolean>(false);
  const [deleteDocCategoryId, setDeleteDocCategoryId] = useState<number | null>(null);
  const [docCategoryTableData, setDocCategoryTableData] = useState<TableRow[]>([]);
  const [docCategoryCount, setDocCategoryCount] = useState<number>(0);
  const location = useLocation();
  const { success, danger } = useToast();

  const showCreateDocCategoryModalHandler = () => setIsVisibleCreateDocCategoryModal(true);
  const hideCreateDocCategoryModalHandler = () => setIsVisibleCreateDocCategoryModal(false);
  const showDeleteDocCategoryModalHandler = (id: number) => setDeleteDocCategoryId(id);
  const hideDeleteDocCategoryModalHandler = () => setDeleteDocCategoryId(null);
  const deleteDocCategory = () =>
    deleteDocCategoryId && deleteDocumentCategoryFn(deleteDocCategoryId);

  useEffect(() => {
    getDocumentCategories(location.search || "");
  }, [location.search]);

  const {
    data,
    execute: getDocumentCategories,
    loading: fetchingDocumentCategories,
  } = useBaseRequest(DocumentCategoryGateway.getDocumentCategories, {
    onCompleted: (data) => {
      const docCategoryTable = data.categories.map((category) =>
        mapDocumentCategoryTotableRow(category, setDeleteDocCategoryId)
      );
      setDocCategoryTableData(docCategoryTable);
      setDocCategoryCount(data.count);
    },
  });

  const { execute: createDocumentCategory, loading: creatingDocumentCategory } = useBaseRequest(
    DocumentCategoryGateway.createDocumentCategory,
    {
      onCompleted: (data) => {
        success(t(DOCUMENT_CATEGORY_SUCCESS_MESSAGES.created));
        setIsVisibleCreateDocCategoryModal(false);
        getDocumentCategories(location.search || "?");
      },
    }
  );

  const { execute: deleteDocumentCategoryFn, loading: deletingDocumentCategory } = useBaseRequest(
    DocumentCategoryGateway.deleteDocumentCategory,
    {
      onCompleted: () => {
        success(t(DOCUMENT_CATEGORY_SUCCESS_MESSAGES.deleted));
        getDocumentCategories(location.search || "?");
        setDeleteDocCategoryId(null);
      },
    }
  );

  const documentCategoryFilters = {
    ...documentCategoryDefaultFilters,
    archiveDateStartingPoint: {
      ...documentCategoryDefaultFilters.archiveDateStartingPoint,
      options: Object.values(ArchiveDateStartingPoint).map((value) => {
        return {
          value,
          label: t("DOCUMENT_CATEGORY." + value),
        };
      }),
    },
  };

  return {
    docCategoryTableData,
    docCategoryCount,
    fetchingDocumentCategories,
    isVisibleCreateDocCategoryModal,
    deleteDocCategoryId,
    showCreateDocCategoryModalHandler,
    hideCreateDocCategoryModalHandler,
    showDeleteDocCategoryModalHandler,
    hideDeleteDocCategoryModalHandler,
    createDocumentCategory,
    creatingDocumentCategory,
    deleteDocCategory,
    deletingDocumentCategory,
    documentCategoryFilters,
  };
};

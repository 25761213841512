import { useLocation, useNavigate } from "react-router-dom";
import { SearchWithQueryInputProps } from "./SearchInput.types";
import { SearchInput } from "./SearchInput";

export const SearchWithQueryInput = ({
  queryParamName = "textSearch",
  placeHolder,
  className,
}: SearchWithQueryInputProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const onSearchSubmit = (text: string) => {
    queryParams.delete("textSearch");
    if (text) { 
      queryParams.set("textSearch", text);
      queryParams.delete("sort");
      queryParams.delete("skip") 
    }
    navigate({ search: queryParams.toString() });
  };

  return (
    <SearchInput
      onSearchSubmit={onSearchSubmit}
      placeHolder={placeHolder}
      className={className}
      defaultValue={queryParams.get(queryParamName) || ""}
    />
  );
};

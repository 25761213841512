import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { TableRow } from "../../components/table";
import { DocumentStatus } from "../../entities/IDocument";
import { Icon } from "../../modules/icon";
import { useDocumentService } from "../../services/useDocumentService";
import { screens } from "../Navigation.types";
import { archiveTableHeaders } from "./Archive.types";
import { Link, useLocation, useParams } from "react-router-dom";
import { Badge } from "../../components/badge";
import { documentFilters as documentDefaultFilters, mapDocumentToTableRow } from "../documents";

import ArchiveBookGateway from "../../api/gateways/ArchiveBookGateway";
import { useTranslation } from "react-i18next";

export function useArchive() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getDocFileUrl, useCreateLocalFileUrl } = useDocumentService();
  const location = useLocation();
  const { useGetDocumentCategories, useGetDocumentTypes } = useDocumentService();
  const { documentCategories } = useGetDocumentCategories();
  const { documentTypes } = useGetDocumentTypes();
  const { archiveItemId } = useParams();
  const [itemSerialNumber, setItemSerialNumber] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (archiveItemId) {
      getItemSerialNumber(+archiveItemId);
    }
  }, [archiveItemId]);

  React.useEffect(() => {
    let filter =
      (location.search || "?") +
      `&filters=${JSON.stringify({
        name: "status",
        operator: "eq",
        value: DocumentStatus.ARCHIVED,
        type: "string",
      })}`;

    // Filter for archive item
    if (archiveItemId) {
      filter =
        filter +
        `&filters=${JSON.stringify({
          name: "archiveItemId",
          operator: "eq",
          value: archiveItemId.toString(),
          type: "number",
        })}`;
    }

    execute(filter);
  }, [location.search]);

  const { execute: getItemSerialNumber, loading: loadingItem } = useBaseRequest(
    ArchiveBookGateway.getArchiveBookItemById,
    {
      onCompleted: (data) => {
        const archiveBookItem = data.archiveBookItems[0];
        if (archiveBookItem) {
          setItemSerialNumber(archiveBookItem?.serialNumber.toString());
        }
      },
    }
  );
  const [downloadUrl, setDownloadUrl] = useState<string>("");
  const { url } = useCreateLocalFileUrl(downloadUrl, true);
  const [docTableData, setDocTableData] = React.useState<TableRow[]>([]);
  const { data, execute, loading } = useBaseRequest(DocumentGateway.getDocuments, {
    autoFetch: false,
    onCompleted: (data) => {
      const { documents } = data;
      const docTable: TableRow[] = [];
      documents.forEach((doc) => {
        docTable.push(mapDocumentToTableRow(doc, setDownloadUrl));
      });
      setDocTableData(docTable);
    },
  });

  // filters
  const archiveFilters = React.useMemo(
    () => ({
      ...documentDefaultFilters,
      docTypeId: {
        ...documentDefaultFilters.docTypeId,
        options:
          documentTypes && documentTypes.length
            ? documentTypes.map((type) => {
                return {
                  value: type.id.toString(),
                  label: type.name,
                };
              })
            : [],
      },
      "metaData.documentCategory": {
        ...documentDefaultFilters["metaData.documentCategory"],
        options:
          documentCategories && documentCategories.length
            ? documentCategories.map((category) => {
                return {
                  value: category.name,
                  label: category.name,
                };
              })
            : [],
      },
    }),
    [documentCategories, documentTypes]
  );

  const onRowPress = (id: number) => {
    navigate(screens.documents + `/${id}`);
  };

  return {
    count: data?.count || 0,
    getDocuments: execute,
    loading,
    docTableData,
    archiveTableHeaders,
    onRowPress,
    // TODO: Replace itemID with serialNumber, when get item route is ready
    title: itemSerialNumber
      ? t("ARCHIVE_BOOK_ITEM.TITLE") + ` ${itemSerialNumber}`
      : t("ARCHIVE.TITLE"),
    archiveFilters,
    backBtn: itemSerialNumber ? true : false,
  };
}

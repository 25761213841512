import { FilterProps } from "./Filters.types";
import { DateRangeFilter } from "./DateRangeFilter/DateRangeFilter";
import { SingleChoiceFilter } from "./SingleChoiceFilter";
import { MultipleChoiceFilter } from "./MultipleChoiceFilter";
import { MultipleChoiceAutocompleteFilter } from "./MultipleChoiceAutocompleteFilter";
import { NumberRangeFilter } from "./NumberRangeFilter";

export function Filter(props: FilterProps) {
  switch (props.filter.type) {
    case "date-range":
      return <DateRangeFilter {...props} />;
    case "single-choice":
      return <SingleChoiceFilter {...props} />;
    case "multiple-choice":
      return <MultipleChoiceFilter {...props} />;
    case "multiple-choice-autocomplete":
      return <MultipleChoiceAutocompleteFilter {...props} />;
    case "number-range":
      return <NumberRangeFilter {...props} />;
    default:
      return null;
  }
}

import React, { ChangeEvent } from "react";
import { useNavigate } from "react-router";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { useToast } from "../../components/toast";
import { DocumentStatus } from "../../entities/IDocument";
import { IMetaDataRule, MetaDataForm } from "../../entities/IMetaData";
import { useWindowDimensions } from "../../modules/window";
import { useDocumentService } from "../../services/useDocumentService";
import { screens } from "../Navigation.types";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IOption } from "../../components/select";
import LabelGateway from "../../api/gateways/LabelGateway";

export function useNewDocument(
  documentId?: string,
  closeDocumentModal?: (isDocumentCreated?: boolean) => void
) {
  //states
  const { t } = useTranslation();
  const id = useParams()?.id;
  const sessionId = useParams()?.sessionId;
  const navigate = useNavigate();
  const { success, danger } = useToast();
  const { mapMetaDataRulesToForm, useGetDocumentTypes, getDocFileUrl } = useDocumentService();
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const { documentTypes } = useGetDocumentTypes();
  const [documentTypeId, setDocumentTypeId] = React.useState(0);
  const [metaDataRules, setMetaDataRules] = React.useState<IMetaDataRule[]>([]);
  const [showDismissModal, setShowDismissModal] = React.useState(false);
  const [labels, setLabels] = React.useState<IOption<number>[]>([]);

  React.useEffect(() => {
    if (documentId) {
      getDraft({
        id: documentId,
      });
      return;
    }
    if (id) {
      getDraft({
        id,
      });
    }
  }, [id, documentId]);

  //requests
  const { execute: processDocument, loading: processing } = useBaseRequest(
    DocumentGateway.processDocument,
    {
      onCompleted: (data) => {
        if (!data || !data.length) {
          setMetaDataRules([]);
          return;
        }
        setMetaDataRules(data);
      },
    }
  );

  const { execute: updateDoc, loading: creating } = useBaseRequest(DocumentGateway.updateDocument, {
    onCompleted: (data, payload) => {
      !closeDocumentModal && success(t("DOCUMENTS.UPLOADED_MESSAGE"));
      !closeDocumentModal &&
        navigate(screens.documents + `/${data.id}`, {
          replace: true,
        });
      closeDocumentModal && closeDocumentModal(true);
    },
    onError: (error) => {
      closeDocumentModal && closeDocumentModal(false);
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  const { execute: updateSessionDoc, loading: creatingSessionDoc } = useBaseRequest(
    DocumentGateway.updateSessionDocument,
    {
      onCompleted: () => {
        success(t("DOCUMENTS.UPLOADED_MESSAGE"));
        closeDocumentModal && closeDocumentModal(true);
      },
      onError: (error) => {
        closeDocumentModal && closeDocumentModal(false);
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const {
    execute: getDraft,
    loading: fetching,
    data: draftDocument,
  } = useBaseRequest(DocumentGateway.getDocumentByIdAndMetaData, {
    onCompleted: async (data) => {
      if (!data || !data.document || !data.document.files || !data.document.files.length) {
        navigate(screens.uploadDocument);
        return;
      }
      if (data.document.docTypeId) {
        setDocumentTypeId(data.document.docTypeId);
      }
      if (data.metaDataRules) {
        setMetaDataRules(data.metaDataRules);
      }
    },
  });

  const { execute: dismiss } = useBaseRequest(DocumentGateway.dismissDocument, {
    onCompleted: (data) => {
      closeModal();
      navigate(-1);
    },
  });

  //handlers
  const onDocumentTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setDocumentTypeId(+value);
    if (!value) {
      setMetaDataRules([]);
      return;
    }
    processDocument({
      docTypeId: value,
      id: (draftDocument?.document?.id || "").toString(),
    });
  };

  const onSubmitDraft = (mtdForm: MetaDataForm) => {
    const document = {
      id: (draftDocument?.document?.id || "").toString(),
      metaData: mtdForm,
      documentTypeId: documentTypeId,
      status: DocumentStatus.PENDING,
    };
    const findNewLabels = labels
      .filter((item) => !draftDocument?.document.labels?.find((label) => label.id === item.value))
      .map((item) => item.value);
    const findDeletedLabels = draftDocument?.document.labels
      ?.filter((item) => !labels.find((label) => label.value === item.id))
      .map((item) => item.id);

    if (findNewLabels.length && draftDocument?.document.id) {
      addLabelsToElement({
        id: draftDocument?.document.id,
        labelIds: findNewLabels,
        type: "document",
      });
    }
    if (draftDocument?.document.id && findDeletedLabels && findDeletedLabels.length) {
      removeLabelsFromElement({
        id: draftDocument?.document.id,
        labelIds: findDeletedLabels,
        type: "document",
      });
    }
    if (sessionId) {
      updateSessionDoc({ ...document, sessionId });
      return;
    }
    updateDoc({ ...document, labels: labels.map((l) => l.value) });
  };

  const closeModal = () => setShowDismissModal(false);
  const openModal = () => setShowDismissModal(true);

  const onDismiss = () => {
    if (!draftDocument) return;
    dismiss({ id: draftDocument?.document?.id?.toString() || "" });
  };

  const updateLabels = (labels: IOption<number>[]) => {
    setLabels(labels);
  };

  const { execute: addLabelsToElement, loading: addLabelsLoading } = useBaseRequest(
    LabelGateway.addLabelsToElement,
    {
      onCompleted: () => {},
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );
  const { execute: removeLabelsFromElement, loading: removeLabelsLoading } = useBaseRequest(
    LabelGateway.removeLabelsFromElement,
    {
      onCompleted: () => {},
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  return {
    draftDocument: draftDocument?.document,
    documentTypeId,
    documentTypes,
    onDocumentTypeChange,
    metaDataForm: metaDataRules.length ? mapMetaDataRulesToForm(metaDataRules) : null,
    loadingMetaDataForm: processing,
    onSubmitDraft,
    creating: creating || creatingSessionDoc,
    windowHeight,
    windowWidth,
    closeModal,
    openModal,
    onDismiss,
    fetching,
    getDocFileUrl,
    showDismissModal,
    labels,
    updateLabels,
  };
}

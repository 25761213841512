import React, { useMemo } from "react";
import { useOrganizationService } from "../../services/useOrganizationService";
import useBaseRequest from "../../api/BaseRequest";
import {
  ARCHIVE_BOOK_ITEM_SUCCESS_MESSAGES,
  CreateArchiveBookItemBulkDTO,
} from "../../entities/IArchiveBookItem";
import ArchiveBookGateway from "../../api/gateways/ArchiveBookGateway";
import { useToast } from "../../components/toast";
import { useTranslation } from "react-i18next";
import { createArchiveBookItemBulkForm as createItemBulkDefaultForm } from "./Documents.types";
import { useNavigate } from "react-router-dom";
import { screens } from "../Navigation.types";
import { IDocumentCategory } from "../../entities/IDocumentCategory";
import { FormModal } from "../../components/modal";

export type CreateArchiveBookItemModalProps = {
  documentCategories: IDocumentCategory[];
  onCreate: () => void;
  onDismiss: () => void;
  isVisible: boolean;
  selectedDocuments: number[];
};

export function CreateArchiveBookItemModal({
  documentCategories,
  isVisible,
  onCreate,
  onDismiss,
  selectedDocuments,
}: CreateArchiveBookItemModalProps) {
  const { archiveBooks } = useOrganizationService().useGetArchiveBooks();
  const { success } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { execute: createArchiveBookItem, loading: creatingArchiveBookItem } = useBaseRequest(
    (payload: CreateArchiveBookItemBulkDTO) =>
      ArchiveBookGateway.createArchiveBookItemBulk(payload, selectedDocuments),
    {
      onCompleted: (data) => {
        success(t(ARCHIVE_BOOK_ITEM_SUCCESS_MESSAGES.created));
        onCreate();
        navigate(screens.archiveSimple + `/${data.id}`);
      },
    }
  );

  // form
  const createItemBulkForm = useMemo(
    () => ({
      ...createItemBulkDefaultForm,
      docCategoryId: {
        ...createItemBulkDefaultForm.docCategoryId,
        options: documentCategories
          ? documentCategories.map((category) => ({
              label: category.name,
              value: `${category.id}`,
            }))
          : [],
        defaultValue: documentCategories && documentCategories.length && documentCategories[0].id,
      },
      archiveBookId: {
        ...createItemBulkDefaultForm.archiveBookId,
        options: archiveBooks
          ? archiveBooks.map((book) => ({
              label: book.name,
              value: `${book.organizationId}`,
            }))
          : [],
        defaultValue: archiveBooks && archiveBooks.length && archiveBooks[0].organizationId,
      },
    }),
    [documentCategories, archiveBooks]
  );

  return (
    <FormModal
      isVisible={isVisible}
      onSubmit={createArchiveBookItem}
      onDismiss={onDismiss}
      loading={creatingArchiveBookItem}
      form={createItemBulkForm}
      submitButtonLabel="CREATE_BUTTON"
      title="ARCHIVE_BOOK_ITEM.CREATE_LABEL"
    />
  );
}

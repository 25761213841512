import moment from "moment";
import { TableRow } from "../../../components/table";
import { IArchiveBookItem } from "../../../entities/IArchiveBookItem";
import { Icon } from "../../../modules/icon";
import { Link } from "react-router-dom";

export const mapItemToTableRow = (
  item: IArchiveBookItem,
  onDeleteClick: (id: number) => void
): TableRow => [
  <Link to={"/archive/" + item.id}>{item.serialNumber}</Link>,
  moment(item.entryDate).format("DD.MM.YYYY"),
  item.content,
  `(${moment(item.periodFrom).format("DD.MM.YYYY")} - ${moment(item.periodTo).format(
    "DD.MM.YYYY"
  )})`,
  item.docCategory ? item.docCategory.name : "",
  item.docCategory && item.docCategory.classificationCode
    ? item.docCategory.classificationCode
    : "",
  item.docCategory ? item.docCategory.archivePeriod : "",
  item.docCategory ? item.docCategory.categoryListConsentNumber : "",
  item.amountOfDocuments,
  item.location,
  item.remark ? item.remark : "",
  <>
    <Link to={"/archive/" + item.id}>
      <Icon
        type="fa-solid fa-circle-info"
        color="black"
        size={16}
        title={"ARCHIVE_BOOK_ITEM.DETAILS_LABEL"}
        className="mr-3"
      />
    </Link>
    <Icon
      title={"DELETE_BUTTON"}
      type="fa-solid fa-trash"
      onClick={(event) => {
        event?.stopPropagation();
        onDeleteClick(item.id);
      }}
    />
  </>,
];

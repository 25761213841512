import { useEffect, useState } from "react";
import { IOption } from "../select";
import OptionListGateway from "../../api/gateways/OptionListGateway";
import useBaseRequest from "../../api/BaseRequest";
import { useToast } from "../toast";
import { debounce } from "./util";
import { GetOptionsDTO } from "./AutocompleteOpt.types";

const useAutocompleteOpt = (
  onSelectOption: (option?: IOption<any> | IOption<any>[]) => void,
  endpoint: string,
  limit: number,
  excluded: any[],
  defaultValue?: any | any[],
  minCharsToSearch?: number,
  filters?: string,
) => {
  const { danger } = useToast();
  const [options, setOptions] = useState<IOption<any>[]>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<
    IOption<any> | undefined | IOption<any>[]
  >(undefined);
  const [searchInput, setSearchInput] = useState<string>("");
  const [skip, setSkip] = useState<number>(0);
  const [defaultV, setDefaultV] = useState(defaultValue);
  
  const { execute: getOptions, loading } = useBaseRequest(OptionListGateway.getOptions, {
    onCompleted: (data, payload) => {
      if (defaultV) {
        if (Array.isArray(defaultV)) {
          const selected = data.excluded.filter((item) => defaultV.includes(item.value));
          setSelectedOption(selected);
          onSelectOption(selected);
        } else if (!Array.isArray(defaultV)) {
          const selected = data.data.find((item) => item.value === defaultV);
          setSelectedOption(selected);
          onSelectOption(selected);
        }
        setDefaultV(undefined);
      }
      setOptions((prev) => (payload.resetOpts ? [...data.data] : [...prev, ...data.data]));
      setSkip(payload.skip + limit);
      setCount(data.count);
    },
  });

  useEffect(() => {
    if (
      defaultValue &&
      ((Array.isArray(defaultValue) && defaultValue.length > 0) ||
        !Array.isArray(defaultValue))
    ) {
      const selected = [
        ...(Array.isArray(defaultValue)
          ? defaultValue.map((item) => item)
          : defaultValue
          ? [defaultValue]
          : []),
        ...excluded,
      ];
      const selectedIds = Array.isArray(selectedOption)
        ? selectedOption.map((item) => item.value)
        : selectedOption?.value
        ? [selectedOption.value]
        : [];
      if (
        Array.isArray(defaultValue) &&
        defaultValue.every((item) => selectedIds.includes(item))
      ) {
        return;
      }
      
      getOptions({
        skip: 0,
        resetOpts: true,
        textSearch: "",
        limit,
        filters,
        endpoint,
        selected,
      });
    } else {
      setDefaultV(undefined);
    }
  }, []);

  // When an option is selected, get updated option list that excludes the selected one
  useEffect(() => {
    if (selectedOption) {
      getOptions({
        skip: 0,
        resetOpts: true,
        textSearch: searchInput,
        limit,
        filters,
        endpoint,
        selected: [
          ...(Array.isArray(selectedOption)
            ? selectedOption.map((item) => item.value)
            : selectedOption?.value
            ? [selectedOption.value]
            : []),
          ...excluded,
        ],
      });
    }
  }, [selectedOption]);

  const onChange = (
    selected: IOption<number> | IOption<number>[] | undefined
  ) => {
    if (!selected) {
      setSelectedOption(undefined);
      onSelectOption(undefined);
      return;
    }
    if (!Array.isArray(selected)) {
      setSelectedOption(selected);
      onSelectOption(selected);
    }
    if (Array.isArray(selected)) {
      setSelectedOption(selected);
      onSelectOption(selected);
    }
    setSearchInput("");
    if (minCharsToSearch === 0) {
      getOptions({
        skip: 0,
        resetOpts: true,
        textSearch: "",
        filters,
        limit,
        endpoint,
        selected: [
          ...(Array.isArray(selected)
            ? selected.map((item) => item.value)
            : selected?.value
            ? [selected.value]
            : []),
          ...excluded,
        ],
      });
      return;
    }
    setOptions([]);
  };

  const onLoadMore = () => {
    getOptions({
      skip,
      resetOpts: false,
      textSearch: searchInput,
      limit,
      filters,
      endpoint,
      selected: [
        ...(Array.isArray(selectedOption)
          ? selectedOption.map((item) => item.value)
          : selectedOption?.value
          ? [selectedOption.value]
          : []),
        ...excluded,
      ],
    });
  };

  const onChangeSearchInput = (text: string) => {
    setSearchInput(text);
    const apiCall = (options: GetOptionsDTO) => getOptions(options);
    if (minCharsToSearch !== undefined && text.length < minCharsToSearch)
      return;
    const searchFn = debounce(apiCall, 500);
    searchFn({
      skip: 0,
      resetOpts: true,
      textSearch: text,
      limit,
      filters,
      endpoint,
      selected: [
        ...(Array.isArray(selectedOption)
          ? selectedOption.map((item) => item.value)
          : selectedOption?.value
          ? [selectedOption.value]
          : []),
        ...excluded,
      ],
    });
  };

  const onInputFocus = () => {
    if (minCharsToSearch === 0) {
      getOptions({
        skip: 0,
        resetOpts: true,
        textSearch: searchInput,
        limit,
        filters,
        endpoint,
        selected: [
          ...(Array.isArray(selectedOption)
            ? selectedOption.map((item) => item.value)
            : selectedOption?.value
            ? [selectedOption.value]
            : []),
          ...excluded,
        ],
      });
    }
  };
  return {
    options,
    count,
    selectedOption,
    searchInput,
    loading,
    limit,
    skip,
    getOptions,
    onChange,
    onChangeSearchInput,
    onLoadMore,
    isEnd: count === options.length,
    onInputFocus,
  };
};

export default useAutocompleteOpt;

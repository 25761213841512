import Header from "../../components/header/Header";
import CardContainer from "../../components/card/CardContainer";
import { DraftDocumentCard } from "./DraftDocumentCard";
import { DraftDocumentsProps } from "./UploadDocumentsSession.types";
import { Badge } from "../../components/badge";
import { AddDocumentCard } from "./AddDocumentCard";
import { isDocumentProcessedChecker } from "./utility/isDocumentProcessedChecker";
import { useTranslation } from "react-i18next";

export const DraftDocuments = ({
  draftDocuments,
  onDismiss,
  onAdd,
  onView,
}: DraftDocumentsProps) => {
  const { t } = useTranslation();
  return (
    <>
      {!!draftDocuments.length && (
        <section className="flex-column gap-2">
          <Header
            title={t("UPLOAD.DRAFT_PLURAL")}
            size="h3"
            badge={
              <Badge className="ms-2 px-1" type={"dark"} variant="outlined">{`${
                draftDocuments.length
              } ${
                draftDocuments.length > 4 ? t("UPLOAD.INTO_FILES_PLURAL") : t("UPLOAD.INTO_FILES")
              }`}</Badge>
            }
          />
          <CardContainer
            children={[
              ...draftDocuments.map((item, i) => (
                <DraftDocumentCard
                  key={i}
                  onDismiss={onDismiss}
                  onClick={() => {
                    onView(item.id.toString());
                  }}
                  status={item.status}
                  name={`${t("DOCUMENTS.DOCUMENT")} #${item.id}`}
                  id={item.id}
                  isProcessed={isDocumentProcessedChecker(item)}
                />
              )),
              <AddDocumentCard key={draftDocuments.length} onUpload={onAdd} />,
            ]}
            cardGap={16}
            cardMinWidth={160}
            cardMaxWidth={208}
            cardMaxNumberPerRow={5}
          />
        </section>
      )}
    </>
  );
};

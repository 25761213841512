import { Icon } from "../../modules/icon";
import { ContinuePreviousUploadProps } from "./UploadDocument.types";
import assests from "../../config/assets";
import Header from "../../components/header/Header";
import Card from "../../components/card/Card";
import { useTranslation } from "react-i18next";

export const ContinuePreviousUpload = ({
  openModal,
  onContinueDraft,
  type,
}: ContinuePreviousUploadProps) => {
  const { t } = useTranslation();
  const title = type === "Single" ? t("UPLOAD.CONTINUE") : t("UPLOAD.CONTINUE_SESSION");
  const image =
    type === "Single" ? assests.images.documentDraft : assests.images.documentUploadSession;
  const iconTitle = type === "Single" ? t("UPLOAD.DRAFT") : t("UPLOAD.SESSION");
  const altText = type === "Single" ? t("UPLOAD.DRAFT") : t("UPLOAD.SESSION");
  return (
    <div className="d-flex justify-content-center">
      <Card onClick={onContinueDraft} className="gap-2" style={{ maxWidth: "min-content" }}>
        <Header
          size="h3"
          title={title}
          actions={
            <Icon
              title={t("NEW_DOCUMENT.DISMISS")}
              type="fa-solid fa-trash"
              className="text-danger cursor-pointer"
              size={18}
              onClick={(event) => {
                openModal();
                event?.stopPropagation();
              }}
            />
          }
        />
        <img className="cursor-pointer rounded-1" src={image} alt={altText} width={500} />
      </Card>
    </div>
  );
};

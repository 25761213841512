const Legend = ({
  data,
  total,
}: {
  data: { title: string; value: number; color: string }[];
  total: { title: string; value: number } | undefined;
}) => {
  return (
    <div className="w-100 flex-column gap-1">
      {data.map((item, index) => (
        <div key={index} className="flex-row justify-content-between gap-3">
          <div
            className="flex-row gap-2"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <div
              style={{
                width: "16px",
                height: "16px",
                backgroundColor: item.color,
              }}
            />
            <h6 style={{ margin: 0, fontWeight: "normal" }}>{item.title}</h6>
          </div>
          <h6 style={{ margin: 0 }}>{item.value}</h6>
        </div>
      ))}
      {total && (
        <div
          className="flex-row justify-content-between gap-3 pt-1"
          style={{ borderTop: "2px solid black", marginTop: "2px" }}
        >
          <div
            className="flex-row gap-2"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <h6 style={{ margin: 0, fontWeight: "normal" }}>{total.title}</h6>
          </div>
          <h6 style={{ margin: 0 }}>{total.value}</h6>
        </div>
      )}
    </div>
  );
};
export default Legend;

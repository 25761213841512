import { useEffect, useMemo, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import BasketGateWay from "../../api/gateways/BasketGateway";
import { useToast } from "../../components/toast";
import {
  IGetBasket,
  createBasketForm,
  updateBasketForm as updateBasketFormDefault,
} from "./Basket.types";
import { useTranslation } from "react-i18next";

const useBaskets = () => {
  const { success, danger } = useToast();
  const [baskets, setBaskets] = useState<IGetBasket[]>([]);
  useEffect(() => {
    execute({});
  }, []);
  const { t } = useTranslation();
  //Create Basket
  const [isVisibleCreateBasketModal, setIsVisibleCreateBasketModal] = useState(false);
  const { execute: createBasket, loading: creatingBasket } = useBaseRequest(
    BasketGateWay.createBasket,
    {
      onCompleted: (data) => {
        success(t("BASKETS.BASKET_CREATED_SUCCESS_MESSAGES"));
        setIsVisibleCreateBasketModal(false);
        execute({});
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const showCreateBasketModalHandler = () => setIsVisibleCreateBasketModal(true);
  const hideCreateBasketModalHandler = () => setIsVisibleCreateBasketModal(false);

  //Get Baskets
  const { execute, loading } = useBaseRequest(BasketGateWay.getBaskets, {
    autoFetch: true,
    onCompleted: (data) => {
      setBaskets(data.baskets);
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  //Update Basket
  const [updateBasketID, setUpdateBasketID] = useState<number | null>(null);
  const { execute: updateBasket, loading: updatingBasket } = useBaseRequest(
    BasketGateWay.updateBasket,
    {
      onCompleted: (data) => {
        success(t("BASKETS.BASKET_UPDATED_SUCCESS_MESSAGES"));
        execute({});
        setUpdateBasketID(null);
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );
  const hideUpdateBasketModalHandler = () => setUpdateBasketID(null);

  const showUpdateBasketModalHandler = (id: number) => {
    setUpdateBasketID(id);
  };
  const updateBasketForm = useMemo(
    () => ({
      ...updateBasketFormDefault,
      processDescription: {
        ...updateBasketFormDefault.processDescription,
        defaultValue:
          baskets.find((basket) => basket.id === updateBasketID)?.processDescription || "",
      },
      name: {
        ...updateBasketFormDefault.name,
        defaultValue: baskets.find((basket) => basket.id === updateBasketID)?.name || "",
      },
      id: {
        ...updateBasketFormDefault.id,
        defaultValue: updateBasketID || 0,
      },
    }),
    [updateBasketID, baskets]
  );

  //Delete Basket
  const [deleteBasketID, setDeleteBasketID] = useState<number | null>(null);
  const { execute: deleteBasketFn, loading: deletingBasket } = useBaseRequest(
    BasketGateWay.deleteBasket,
    {
      onCompleted: (data) => {
        success(t("BASKETS.BASKET_DELETED_SUCCESS_MESSAGES"));
        execute({});
        setDeleteBasketID(null);
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const hideDeleteBasketModalHandler = () => setDeleteBasketID(null);
  const deleteBasket = () => {
    typeof deleteBasketID === "number" && deleteBasketFn(deleteBasketID);
  };

  //Bookmark Basket
  const { execute: bookmarkBasket } = useBaseRequest(BasketGateWay.bookmarkBasket, {
    onCompleted: (data) => {
      success(t("BASKETS.BASKET_BOOKMARKED_SUCCESS_MESSAGES"));
      execute({});
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });
  const { execute: unbookmarkBasket } = useBaseRequest(BasketGateWay.unbookmarkBasket, {
    onCompleted: (data) => {
      success(t("BASKETS.BASKET_UNBOOKMARKED_SUCCESS_MESSAGES"));
      execute({});
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });
  const toggleBookmark = (id: number) => {
    if (baskets.find((item) => item.id === id)?.isBookmarked) {
      unbookmarkBasket(id);
    } else {
      bookmarkBasket(id);
    }
  };

  return {
    //
    getBaskets: execute,
    loadingBaskets: loading,
    baskets,
    //
    createBasketForm,
    isVisibleCreateBasketModal,
    createBasket,
    creatingBasket,
    showCreateBasketModalHandler,
    hideCreateBasketModalHandler,
    //
    isVisibleUpdateBasketModal: typeof updateBasketID === "number",
    setUpdateBasketID: showUpdateBasketModalHandler,
    updateBasketForm,
    hideUpdateBasketModalHandler,
    hideDeleteBasketModalHandler,
    updateBasket,
    updatingBasket,
    deleteBasket,
    deletingBasket,
    setDeleteBasketID,
    isVisibleDeleteBasketModal: typeof deleteBasketID === "number",
    bookmarkedBaskets: baskets.length ? baskets.filter((basket) => basket.isBookmarked) : [],
    toggleBookmark,
  };
};

export default useBaskets;

import { useEffect } from "react";
import useBaseRequest from "../api/BaseRequest";
import TaskGateway from "../api/gateways/TaskGateway";
import { useUser } from "../store/auth/AuthSelectors";
import { counterActions } from "../store/counters/CounterReducer";
import {
  useGetAllTasksCount,
  useGetCommentsCount,
  useGetMyTasksCount,
} from "../store/counters/CounterSelectors";
import { useAppDispatch } from "../store";

export const useCounterService = () => {
  const dispatch = useAppDispatch();
  const userId = useUser()?.id;
  const taskStatusQuery = `?filters=${JSON.stringify({
    name: "status",
    type: "stringArray",
    operator: "in",
    value: JSON.stringify(["open", "onhold", "assigned", "inprogress"]),
  })}`;
  const taskUserQuery = `&filters=${JSON.stringify({
    name: "user=>id",
    type: "number",
    operator: "eq",
    value: JSON.stringify(userId),
  })}`;

  useEffect(() => {
    if (userId) {
      getData(taskStatusQuery + taskUserQuery);
    }
    getData(taskStatusQuery);
  }, [userId]);

  const allTasksCounter = useGetAllTasksCount();
  const myTasksCounter = useGetMyTasksCount();
  const commentCounter = useGetCommentsCount();

  const {
    execute: getData,
    loading,
    data,
  } = useBaseRequest(TaskGateway.getTasks, {
    onCompleted: (data, payload) => {
      if (payload.includes("user=>id")) {
        dispatch(counterActions.setMyTasksCount(data.count));
        return;
      }
      dispatch(counterActions.setAllTasksCount(data.count));
    },
  });

  const { execute: getCommentsCount } = useBaseRequest(
    TaskGateway.getCommentsByTaskId,
    {
      onCompleted: (data) => {
        dispatch(counterActions.setCommentsCount(data.count));
      },
    }
  );

  const onRefresh = () => {
    getData(taskStatusQuery + taskUserQuery);
    getData(taskStatusQuery);
  };
  return {
    allTasksCounter,
    myTasksCounter,
    refreshTaskCounters: onRefresh,
    refreshCommentsCount: getCommentsCount,
    commentCounter,
  };
};

import { useTranslation } from "react-i18next";
import { LinkedDocumentTypeModalProps, linkedDocumentHeader } from "./DocumentTypes.types";
import { Modal } from "react-bootstrap";
import { Table } from "../../components/table";
import { useLinkedDocumentType } from "./useLinkedDocumentType";
import { DialogModal } from "../../components/modal/DialogModal";
import { LinkDocumentTypesModal } from "./LinkDocumentTypesModal";
import { Button } from "../../components/button";

export const LinkedDocumentTypeModal = ({
  isVisible,
  onDismiss,
  selectedItem,
}: LinkedDocumentTypeModalProps) => {
  const { t } = useTranslation();

  const {
    linkedDocumentTypeForm,
    linkDocumentType,
    linkedDocuments,
    linkedDocumentType,
    onSetLinkedDocumentType,
    deleteLinkId,
    deleteLinkedDocumentTypeHandler,
    deletingLinkedDocumentType,
    hideDeleteDocTypeModalHandler,
    onShowLinkDocumentTypesModal,
    onHideLinkDocumentTypesModal,
    showLinkDocumentTypesModal,
  } = useLinkedDocumentType(selectedItem);
  return (
    <Modal fullscreen show={isVisible} animation onHide={onDismiss}>
      <LinkDocumentTypesModal
        isVisible={showLinkDocumentTypesModal}
        onDismiss={onHideLinkDocumentTypesModal}
        onSubmit={linkDocumentType}
        linking={false}
        linkedDocumentTypeForm={linkedDocumentTypeForm as any}
        onSetLinkedDocumentType={onSetLinkedDocumentType}
        selectedItemId={selectedItem?.id}
        linkedDocumentType={linkedDocumentType}
      />
      <DialogModal
        loading={deletingLinkedDocumentType}
        show={deleteLinkId != null}
        onAccept={deleteLinkedDocumentTypeHandler}
        onCancel={hideDeleteDocTypeModalHandler}
        acceptLabel="DELETE_BUTTON"
        cancelLabel="CANCEL_BUTTON"
        description="DOCUMENT_TYPE.DELETE_LABEL"
        title="DOCUMENT_TYPE.DELETE"
      />
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {t("DOCUMENT_TYPE.LINKED_DOCUMENT_TYPES")}
        </h5>
        <div onClick={onDismiss}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Button className="btn btn-dark" onClick={onShowLinkDocumentTypesModal}>
          {t("DOCUMENT_TYPE.CREATE_LINKED_DOCUMENT_TYPE")}
        </Button>
        {!!linkedDocuments.length && (
          <Table
            headers={linkedDocumentHeader}
            data={linkedDocuments}
            pagination={false}
            selection={false}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

import { useTranslation } from "react-i18next";
import { PageLayout } from "../../components/page-layout";
import CardContainer from "../../components/card/CardContainer";
import Card from "../../components/card/Card";
import Header from "../../components/header/Header";
import { ProviderModal } from "./ProviderModal";
import { useConfiguration } from "./useConfiguration";
export const ConfigurationScreen = () => {
  const { t } = useTranslation();
  const { showModal, dismissModal, modalToShow } = useConfiguration();
  return (
    <PageLayout title={t("CONFIGURATION.TITLE")}>
      <ProviderModal isVisible={modalToShow === "provider"} onDismiss={dismissModal} />
      <Header title={t("CONFIGURATION.INTEGRATION")} size="h3" className="mb-2" />
      <CardContainer
        children={[
          <Card
            onClick={() => {
              showModal("provider");
            }}
            className="p-3 cursor-pointer"
          >
            <Header title={t("CONFIGURATION.PROVIDERS")} size="h6" />
          </Card>,
        ]}
        cardGap={16}
        cardMinWidth={128}
        cardMaxNumberPerRow={3}
      />
    </PageLayout>
  );
};

export function getTextColor(bgColor: string): string {
  // Convert hex color to RGB
  const hexToRgb = (hex: string): number[] => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
    } else {
      throw new Error("Invalid HEX color");
    }
  };

  // Calculate brightness
  const brightness = (r: number, g: number, b: number): number => {
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  // Calculate luminance
  const luminance = (r: number, g: number, b: number): number => {
    const a = [r, g, b].map((value) => {
      value /= 255;
      return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
  };

  // Calculate relative luminance
  const relativeLuminance = (r: number, g: number, b: number): number => {
    const a = [r, g, b].map((value) => {
      value /= 255;
      return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
  };

  const [r, g, b] = hexToRgb(bgColor);
  const brightnessValue = brightness(r, g, b);
  const luminanceValue = luminance(r, g, b);
  const relativeLuminanceValue = relativeLuminance(r, g, b);

  // Choose text color based on brightness
  if (brightnessValue > 125) {
    return "#000000"; // Black text for light backgrounds
  } else {
    return "#ffffff"; // White text for dark backgrounds
  }
}

import { IDocumentStats } from "../../entities/IDocument";

export interface StatCardProps extends IDocumentStats {
  title: string;
  link: string;
}

export const taskStatColors = {
  resolved: "rgb(25, 135, 84)",
  inprogress: "#337AFF",
  onhold: "#FFCB33",
  assigned: "#FF8C33",
  unassigned: "#B2B2B2",
};

export const statColors = [
  "#FF5733", // Reddish orange
  "#FFCB33", // Gold
  "#337AFF", // Blue
  "#33FF77", // Mint
  "#33FF57", // Greenish
  "#A533FF", // Purple
  "#B733FF", // Violet
  "#33FFD5", // Turquoise
  "#FF33A5", // Pink
  "#FF8C33", // Orange
];

import { Drawer, useDrawerRef } from "../../modules/drawer";
import { useWindowDimensions } from "../../modules/window";
import { breakPoints } from "../grid";
import { SideNavBar } from "./SideNavBar";
import { SideNavHeader } from "./SideNavHeader";
import "./SideNav.style.css";
import { Outlet } from "react-router-dom";

export function SideNav() {
  const { width } = useWindowDimensions();
  const drawerRef = useDrawerRef();
  const autoCloseSideNav = (window as any)._env_.AUTOCLOSE_SIDE_NAV;
  const toggle = () => {
    drawerRef.current?.toggle();
  };

  return (
    <>
      <SideNavHeader
        visibleToggleButton={autoCloseSideNav ? width <= breakPoints.m : true}
        toggle={toggle}
      />
      <Drawer
        initialVisibility={autoCloseSideNav ? "closed" : "opened"}
        type={width <= breakPoints.m ? "overlay" : "side"}
        drawerContent={(props) => (
          <SideNavBar {...props} openOnHover={autoCloseSideNav ? width > breakPoints.m : false} />
        )}
        drawerRef={drawerRef}
      >
        <Outlet />
      </Drawer>
    </>
  );
}

import { useEffect, useRef, useState } from "react";
import { DateRangeTypeValues } from "../Filters.types";
import { ApplyButton } from "./ApplyButton";
import { CustomDateRangeProps } from "./DateRangeFilter.types";
import { Datepicker } from "../../datepicker";
import { useTranslation } from "react-i18next";

export const CustomDateRange = ({
  fromDate,
  onChangeFrom,
  toDate,
  onChangeTo,
  dateRangeType,
  onApplyFilter,
  parentBoundingClientRect,
}: CustomDateRangeProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [openToLeft, setOpenToLeft] = useState(false);

  const handleResize = () => {
    if (ref.current && parentBoundingClientRect) {
      const viewportWidth = window.innerWidth;
      const popupWidth = ref.current.getBoundingClientRect().width;

      // Check if there's not enough space to the right to fit the popup
      setOpenToLeft(parentBoundingClientRect.right + popupWidth > viewportWidth);
    }
  };

  useEffect(() => {
    if (parentBoundingClientRect) {
      handleResize();
    }
  }, [parentBoundingClientRect]);

  useEffect(() => {
    // Add a resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`p-2 bg-white border rounded`}
      style={{
        position: "absolute",
        left:
          openToLeft && ref.current
            ? `calc(-${ref.current.getBoundingClientRect().width}px - 0.5rem)`
            : `calc(${
                parentBoundingClientRect.width > 158 ? parentBoundingClientRect.width : 158
              }px + 0.5rem)`,
        top: "100%",
        zIndex: 1,
        marginTop: "2px",
      }}
    >
      {dateRangeType !== DateRangeTypeValues.TO && (
        <div className="form-group">
          <label>{t("FILTERS.FROM")}</label>
          <Datepicker
            className="form-control"
            initialValue={new Date(fromDate)}
            onChange={onChangeFrom}
          />
        </div>
      )}
      {dateRangeType !== DateRangeTypeValues.FROM && (
        <div className="form-group">
          <label>{t("FILTERS.TO")}</label>
          <Datepicker
            className="form-control"
            initialValue={new Date(toDate)}
            onChange={onChangeTo}
          />
        </div>
      )}
      <ApplyButton onApplyFilter={onApplyFilter} />
    </div>
  );
};

import { DocumentDataPreviewProps } from "./Document.types";
import { useTranslation } from "react-i18next";
import { FormPreview } from "../../components/form-preview";

export function DocumentDataPreview({ docType, metaDataForm, labels }: DocumentDataPreviewProps) {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column">
      <div>
        {metaDataForm ? (
          <div>
            <h5>{t("NEW_DOCUMENT.DATA")}</h5>
            <FormPreview metaDataForm={metaDataForm} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

import { TableHeaders } from "../../components/table";

export const archiveTableHeaders: TableHeaders[] = [
  { name: "ID", key: "id", sortable: true },
  { name: "SUBJECT_NUMBER", key: "metaData.subjectNumber" },
  { name: "DESCRIPTION", key: "metaData.description" },
  { name: "DATE_CREATED", key: "metaData.dateCreated", sortable: true },
  { name: "DATE_ARCHIVED", key: "metaData.dateArchived", sortable: true },
  { name: "TYPE", key: "documentType" },
  { name: "FORMAT", key: "metaData.format" },
  { name: "SUBJECT_STATUS", key: "metaData.subjectStatus" },
  { name: "DOCUMENT_STATUS", key: "metaData.documentStatus" },
  { name: "ACTIONS", key: "action" },
];

import { NoResultsProps } from "./AutoComplete.types";

const NoResults = ({
  isShowing,
  message = "No results for given input",
}: NoResultsProps) => {
  return (
    <>
      {isShowing && (
        <div className="dropdown-item flex-column gap-1">
          <label>{message}</label>
        </div>
      )}
    </>
  );
};
export default NoResults;

import { FilterProps } from "../Filters.types";
import { Button } from "../../button";
import { Icon } from "../../../modules/icon";
import { ButtonGroup, DropdownButton } from "react-bootstrap";
import { useDateRangeFilter } from "./useDateRangeFilter";
import { DateRangeTypeList } from "./DateRangeTypeList";
import { CustomDateRange } from "./CustomDateRange";
import { useRef } from "react";
export function DateRangeFilter({
  filter,
  onRemove,
  onFilterSet,
}: FilterProps) {
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const {
    dateRangeType,
    onShowDateRangeTypes,
    title,
    onChange,
    fromDate,
    toDate,
    onChangeFrom,
    onChangeTo,
    onApplyFilter,
    showCustomDateRange,
  } = useDateRangeFilter(filter, onFilterSet, ref2);

  return (
    <div className="mr-3 my-1" ref={ref2}>
      <div className="btn-group" style={{ position: "relative" }}>
        <DropdownButton
          as={ButtonGroup}
          variant="outline-dark"
          autoClose={showCustomDateRange ? false : true}
          onToggle={onShowDateRangeTypes}
          title={title}
        >
          <div ref={ref1}>
            <DateRangeTypeList
              name={filter.name}
              dateRangeType={dateRangeType}
              onChange={onChange}
              onApplyFilter={onApplyFilter}
            />
          </div>
        </DropdownButton>

        {showCustomDateRange && ref1.current && (
          <CustomDateRange
            {...{
              fromDate,
              onChangeFrom,
              toDate,
              onChangeTo,
              dateRangeType,
              onApplyFilter,
              parentBoundingClientRect: ref1.current.getBoundingClientRect(),
            }}
          />
        )}
        <Button
          className="btn btn-outline-dark"
          onClick={() => onRemove(filter)}
        >
          <Icon title="Remove" type="fa-solid fa-xmark" size={16} />
        </Button>
      </div>
    </div>
  );
}

import { on } from "events";
import { TableRow } from "../../../components/table";
import { IDocumentType } from "../../../entities/IDocumentType";
import { Icon } from "../../../modules/icon";
import { ConfigurationGuard } from "../../../components/guard";
import environment from "../../../config/environment";

export const mapDocumentTypeToTableRow = (
  type: IDocumentType,
  docTypeKey: string,
  onDeleteClick: (id: number) => void,
  onShowLinkedDocumentTypeModal: (type: IDocumentType) => void,
  onShowDocTypeFieldsModal: (type: IDocumentType) => void,
  onShowDocTypeOCRMappingModal: (type: IDocumentType) => void,
  onShowDocTypeAccessConfiguration: (type: IDocumentType) => void
): TableRow => [
  type.name,
  type.docCategory ? type.docCategory.name : "",
  type.provider ? type.provider.name : "",
  docTypeKey,
  <>
    <Icon
      title={"DELETE_BUTTON"}
      type="fa-solid fa-trash"
      size={16}
      onClick={(event) => {
        event?.stopPropagation();
        onDeleteClick(type.id);
      }}
    />
    <ConfigurationGuard fallback={{ kind: "hide" }} isEnabled={environment.modulesEnabled.tasks}>
      <Icon
        title={"LINK_BUTTON"}
        type="fa-solid fa-link"
        size={16}
        onClick={(event) => {
          event?.stopPropagation();
          onShowLinkedDocumentTypeModal(type);
        }}
      />
    </ConfigurationGuard>
    <Icon
      title={"DOCUMENT_TYPE.FIELDS"}
      type="fa-solid fa-table"
      size={16}
      onClick={(event) => {
        event?.stopPropagation();
        onShowDocTypeFieldsModal(type);
      }}
    />
    <Icon
      title={"DOCUMENT_TYPE.FIELDS"}
      type="fa-solid fa-lock"
      size={16}
      onClick={(event) => {
        event?.stopPropagation();
        onShowDocTypeAccessConfiguration(type);
      }}
    />
    <ConfigurationGuard fallback={{ kind: "hide" }} isEnabled={environment.modulesEnabled.ocr}>
      <Icon
        title={"DOCUMENT_TYPE.EDIT_DOCUMENT_TYPE_OCR_KEY"}
        type="fa-solid fa-clone"
        size={16}
        onClick={(event) => {
          event?.stopPropagation();
          onShowDocTypeOCRMappingModal(type);
        }}
      />
    </ConfigurationGuard>
  </>,
];

import { useRef } from "react";
import { Icon } from "../../modules/icon";
import useAutocomplete from "./useAutocomplete";
import "./AutoComplete.style.css";
import { Loader } from "../../modules/loader";
import { AutoCompleteProps } from "./AutoComplete.types";
import LoadMoreButton from "./LoadMoreButton";
import NoResults from "./NoResults";
import Options from "./Options";
import AutoCompleteInputForm from "./AutoCompleteInputForm";
import { useTranslation } from "react-i18next";

export default function Autocomplete<T>({
  isMultiple = false,
  value,
  options,
  placeholder,
  label = "Search options",
  onChange,
  inputValue,
  onInputChange,
  onLoadMore,
  isEnd,
  isLoading,
  onFocused,
  showRemoveAll,
  minCharsToSearch = 1,
}: AutoCompleteProps<T>) {
  const inputRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const { t } = useTranslation();
  const {
    onInputChangeHandler,
    showOptions,
    onSelectOption,
    onRemove,
    onRemoveAll,
    onFocusFirstTime,
  } = useAutocomplete<T>(
    inputValue,
    onChange,
    onInputChange,
    value,
    options,
    isMultiple,
    inputRef,
    dropdownRef,
    minCharsToSearch
  );

  return (
    <div className="flex-column gap-1 w-100">
      {label && <label>{label}</label>}
      <div className="btn-group" ref={inputRef}>
        <div className="flex-row form-control autocomplete-focus">
          <AutoCompleteInputForm
            value={value}
            inputValue={inputValue}
            onInputChangeHandler={onInputChangeHandler}
            placeholder={placeholder}
            isMultiple={isMultiple}
            onRemove={onRemove}
            onFocused={() => {
              onFocused && onFocused();
              onFocusFirstTime();
            }}
          />
          {isLoading && <Loader color="primary" size="s" />}
          {(showRemoveAll || value) && (
            <Icon
              onClick={onRemoveAll}
              type="fa-solid fa-times"
              color="black"
              size={16}
              className="ms-2 h-100 flex-row justify-content-between align-items-center"
            />
          )}
        </div>
        <ul
          ref={dropdownRef}
          className={`dropdown-menu w-100 ${showOptions ? "show" : ""} `}
          style={{ top: "102%" }}
          onClick={onSelectOption}
        >
          {showOptions && (
            <>
              <Options options={options} />
              <LoadMoreButton onLoadMore={onLoadMore} isShowing={!!!isLoading && !isEnd} />
            </>
          )}
          <NoResults isShowing={!options.length && !!inputValue} message={t("TABLE.NO_RESULTS")} />
        </ul>
      </div>
    </div>
  );
}

import { GenericForm } from "../../../components/generic-form/GenericForm.types";
import { ILabel } from "../../../entities/ILabel";
import { updateLabelForm } from "../Labels.types";
export const fillUpdateForm = (label?: ILabel): GenericForm<ILabel> => {
  return label
    ? {
        ...updateLabelForm,
        id: {
          ...updateLabelForm.id,
          defaultValue: label.id,
        },
        name: {
          ...updateLabelForm.name,
          defaultValue: label.name,
        },
        color: {
          ...updateLabelForm.color,
          defaultValue: label.color,
        },
      }
    : updateLabelForm;
};

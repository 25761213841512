import { PageContainerProps } from "./SplitFile.types";
import React from "react";
import CardContainer from "../../components/card/CardContainer";
import { PageItem } from "./PageItem";
import { Loader } from "../../modules/loader";

const PageContainer: React.FC<PageContainerProps> = ({
  isLoading,
  pages,
  addSeparator,
  removeSeparator,
  deletePage,
  pagesPerRow,
  seePage,
}) => {
  if (isLoading) {
    return (
      <div className="w-100 flex-row justify-content-center">
        <Loader size="l" color="black" />
      </div>
    );
  }

  return (
    <CardContainer
      children={[
        ...pages.map((item, i) => (
          <PageItem
            key={item.id}
            addSeparator={addSeparator}
            removeSeparator={removeSeparator}
            deletePage={deletePage}
            seePage={seePage}
            id={item.id}
            pdf={item.pdf}
            imagePromise={item.imagePromise}
            isMarkedToSplitAfter={item.isMarkedToSplitAfter}
            pageNumber={item.pageNumber}
            color={item.color}
            isLast={i === pages.length - 1}
          />
        )),
      ]}
      cardGap={16}
      cardMinWidth={160}
      cardMaxNumberPerRow={pagesPerRow}
    />
  );
};

export default PageContainer;

import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const ApplyButton = ({ onApplyFilter }: { onApplyFilter: () => void }) => {
  const {t} = useTranslation()
  return (
    <div
      style={{
        position: "sticky",
        bottom: -8,
        margin: "-8px",
        padding: "8px",
        marginBottom: "-8px",
        marginTop: "0px",
        paddingTop: "0.1px",
        paddingBottom: "8px",
      }}
      className="apply bg-white"
    >
      <Dropdown.Item className="btn btn-primary mt-3" onClick={onApplyFilter}>
        {t("APPLY")}
      </Dropdown.Item>
    </div>
  );
};

import { Modal } from "react-bootstrap";
import { DeleteDigitalSignatureModalProps } from "./DeleteDigitalSignatureModal.types";
import { useTranslation } from "react-i18next";

export function DeleteDigitalSignatureModal({
  show,
  onCancel,
  onDelete,
}: DeleteDigitalSignatureModalProps) {
  const { t } = useTranslation();
  return (
    <Modal show={show} animation onHide={onCancel}>
      <Modal.Header>
        <h5 className="modal-title" id="Delete Modal">
          {t("DOCUMENTS.DELETE_DIGITAL_SIGNATURE_REQUEST")}
        </h5>
        <div onClick={onCancel}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>{t("DOCUMENTS.DELETE_DIGITAL_SIGNATURE_REQUEST_LABEL")}</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={onCancel}>
          {t("CANCEL_BUTTON")}
        </button>
        <button type="button" className="btn btn-danger" onClick={onDelete}>
          {t("DELETE_BUTTON")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

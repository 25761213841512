import { useLocation, useNavigate } from "react-router";
import { screens } from "../../screens/Navigation.types";
import { useEffect, useMemo, useState } from "react";
import { MenuItem } from "./SideNav.types";
import { useCounterService } from "../../services/useCounterService";
import { UserRoles } from "../../entities/IUser";
import { useGetFiltersByPage } from "../../store/filters/FilterSelectors";
import { useTranslation } from "react-i18next";
import { translation } from "../translations";
import { useLogout } from "./utils/useLogout";
import environment from "../../config/environment";
function isStringInMenuArray(menuArray: MenuItem[], searchString: string) {
  for (const item of menuArray) {
    if (item.path === searchString) {
      return true;
    }

    if (item.children) {
      if (isStringInMenuArray(item.children, searchString)) {
        return true;
      }
    }
  }

  return false;
}

export function useSideNav() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [history, setHistory] = useState<string[]>([]);
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState<string>(
    location.pathname.split(screens.root)[1]
      ? location.pathname.split(screens.root)[1]
      : screens.documents.replace("/", "")
  );
  const { allTasksCounter, myTasksCounter } = useCounterService();
  const myTasksFilters = useGetFiltersByPage(screens.myTasks);
  const allTasksFilters = useGetFiltersByPage(screens.tasks);
  const documentsFilters = useGetFiltersByPage(screens.documents);
  const archiveFilters = useGetFiltersByPage(screens.archiveSimple);
  const archiveBooksFilters = useGetFiltersByPage(screens.archiveBooks);
  const documentCategoryFilters = useGetFiltersByPage(screens.documentCategories);
  const documentTypeFilters = useGetFiltersByPage(screens.documentTypes);
  const taskStatsFilters = useGetFiltersByPage(screens.taskStats);
  const reportsFilters = useGetFiltersByPage(screens.reports);
  const digitalSignatureFilters = useGetFiltersByPage(screens.digitalSignature);
  const { logOut } = useLogout();

  useEffect(() => {
    setHistory((prev) => [location.pathname.split(screens.root)[1], ...prev]);

    if (isStringInMenuArray(sideMenuItems, location.pathname)) {
      setActiveRoute(location.pathname);
    }
  }, [location]);

  const generateSideMenuItems = () => {
    return [
      {
        icon: "fa-regular fa-folder-open",
        label: t("DOCUMENTS.LABEL"),
        allowedRoles: [],
        children: [
          {
            icon: "",
            label: t("DOCUMENTS.CLERK"),
            path: screens.documents,
            allowedRoles: [],
            query: documentsFilters,
          },
          {
            icon: "",
            label: t("ARCHIVE.LABEL"),
            path: screens.archiveSimple,
            allowedRoles: [],
            query: archiveFilters,
            isEnabled: environment.modulesEnabled.archive,
          },
          {
            icon: "",
            label: t("DOCUMENT_TYPE.LABEL"),
            path: screens.documentTypes,
            allowedRoles: [],
            query: documentTypeFilters,
          },
          {
            icon: "",
            label: t("DOCUMENT_CATEGORY.LABEL"),
            path: screens.documentCategories,
            allowedRoles: [],
            query: documentCategoryFilters,
          },
          {
            icon: "",
            label: t("CUSTOM_FIELDS.TITLE"),
            path: screens.customFields,
            allowedRoles: [],
            query: documentCategoryFilters,
          },
          {
            icon: "",
            label: t("DOCUMENTS.DIGITAL_SIGNATURES"),
            value: "digital-signature",
            path: screens.digitalSignature,
            allowedRoles: [],
            query: digitalSignatureFilters,
            isEnabled: environment.modulesEnabled.digitalSignature,
          },
        ],
      },
      {
        icon: "fa-solid fa-book",
        label: t("ARCHIVE_BOOK.LABEL"),
        path: screens.archiveBooks,
        allowedRoles: [UserRoles.ADMIN, UserRoles.SUPERVISOR],
        query: archiveBooksFilters,
        isEnabled: environment.modulesEnabled.archive,
      },
      {
        icon: "fa-solid fa-link",
        label: "SEF",
        allowedRoles: [],
        isEnabled: environment.modulesEnabled.sefProvider,
        children: [
          {
            icon: "",
            label: t("SEF_SYNC.LABEL"),
            path: screens.sefIntegrations,
            allowedRoles: [],
          },
          {
            icon: "",
            label: t("SEF_REPORTS.LABEL"),
            path: screens.sefReports,
            allowedRoles: [],
          },
        ],
      },
      {
        icon: "fa-solid fa-tags",
        label: t("LABELS.LABEL"),
        path: screens.labels,
        allowedRoles: [],
        isEnabled: environment.modulesEnabled.tasks,
      },
      {
        icon: "fa-solid fa-tasks",
        label: t("TASKS.LABEL"),
        isEnabled: environment.modulesEnabled.sefProvider,
        allowedRoles: [UserRoles.ADMIN, UserRoles.SUPERVISOR, UserRoles.BO_EXPERT],
        children: [
          {
            icon: "",
            label: t("ALL_TASK.LABEL"),
            value: "tasks",
            path: screens.tasks,
            counter: allTasksCounter,
            allowedRoles: [UserRoles.ADMIN, UserRoles.SUPERVISOR, UserRoles.BO_EXPERT],
            query: allTasksFilters,
          },
          {
            icon: "",
            label: t("MY_TASK.LABEL"),
            value: "my-tasks",
            path: screens.myTasks,
            counter: myTasksCounter,
            allowedRoles: [UserRoles.ADMIN, UserRoles.SUPERVISOR, UserRoles.BO_EXPERT],
            query: myTasksFilters,
          },
          {
            icon: "",
            label: t("BASKETS.LABEL"),
            value: "baskets",
            path: screens.baskets,
            allowedRoles: [],
          },
          {
            icon: "",
            label: t("DASHBOARD.LABEL"),
            value: "task-stats",
            path: screens.taskStats,
            allowedRoles: [UserRoles.ADMIN, UserRoles.SUPERVISOR],
            query: taskStatsFilters,
          },
          {
            icon: "",
            label: t("REPORTS.LABEL"),
            value: "reports",
            path: screens.reports,
            allowedRoles: [UserRoles.ADMIN, UserRoles.SUPERVISOR],
            query: reportsFilters,
          },
        ],
      },
      {
        icon: "fa-solid fa-cogs",
        label: t("CONFIGURATION.TITLE"),
        path: screens.configuration,
        allowedRoles: [UserRoles.ADMIN],
      },
    ];
  };

  const sideMenuItems = useMemo(
    () => generateSideMenuItems(),
    [documentsFilters, archiveFilters, translation.language]
  );
  return {
    navigate,
    activeRoute,
    logOut,
    history,
    sideMenuItems,
  };
}

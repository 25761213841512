import { IEnvironment } from "./environment";

export const prodEnv: IEnvironment = {
  env: "production",
  api: {
    protocol: `${window.location.protocol.replace(":", "")}`,
    host:
      window.location.host === "partnerconnect.a1.rs"
        ? "partnerconnect.a1.rs/cvpn/http/aurora-backoffice.apps.ocpprod1.topnet.rs/api"
        : "aurora-api.apps.ocpprod1.topnet.rs",
  },
  oidc: {
    clientId: "237923339606228995@braco-test",
    issuerEndpoint: "https://zitadel.puvaron.com",
    loginCallbackUrl: "http://localhost:3000/code",
    authorizationEndpoint: "https://zitadel.puvaron.com/oauth/v2/authorize",
    keyListEndpoint: "https://zitadel.puvaron.com/oauth/v2/keys",
    userInfoEndpoint: "https://zitadel.puvaron.com/oidc/v1/userinfo",
    roleFieldName: "",
    endSessionEndpoint: "https://zitadel.puvaron.com/oidc/v1/end_session",
  },
  modulesEnabled: {
    archive: false,
    digitalSignature: true,
    tasks: true,
    sefProvider: true,
    emailListenerProvider: true,
    networkShareProvider: true,
    ocr: false,
  },
};

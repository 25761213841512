import { Loader } from "../../modules/loader";
import { CheckBox } from "../checkbox";
import { TableProps, itemsPerPageOptions } from "./Table.types";
import { useTable } from "./useTable";
import "./Table.style.css";
import { Icon } from "../../modules/icon";
import { useTranslation } from "react-i18next";
import { NoData } from "./NoData";

export function Table({
  headers,
  data,
  pagination = {
    totalItems: 10,
    initialItemsPerPage: itemsPerPageOptions[0],
  },
  selection = {
    selected: [],
    onSelectionChanged: () => [],
  },
  loading,
  sort,
  onRowPress,
}: TableProps) {
  const { t } = useTranslation();
  const {
    onMainCheckBoxChanged,
    onRowCheckBoxChanged,
    onItemsPerPageChange,
    itemsPerPage,
    currentPage,
    numberOfPages,
    setPage,
    renderTableItemValue,
    renderTooltip,
  } = useTable(data, selection, pagination);

  let touchStartX = 0;
  let touchEndX = 0;

  const handleTouchStart = (e: any) => {
    touchStartX = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = (e: any) => {
    touchEndX = e.changedTouches[0].screenX;
    handleGesture();
  };

  const handleGesture = () => {
    if (touchEndX < touchStartX) {
      console.log("Swiped left");
    }
    if (touchEndX > touchStartX) {
      console.log("Swiped right");
    }
  };
  return (
    <div
      className="position-relative"
      style={{ backgroundColor: "#FAFFFA", padding: "16px", borderRadius: "8px", overflow: "auto" }}
    >
      {loading ? (
        <div className="position-absolute h-100 w-100 d-flex flex-column align-items-center justify-content-center">
          <Loader color="black" />
        </div>
      ) : null}
      {
        <div className="w-100 h-100 overflow-auto">
          <table className="table table-striped">
            <thead>
              <tr>
                {selection ? (
                  <th style={{ width: 0 }}>
                    <CheckBox
                      className="form-check-input cursor-pointer"
                      intermediate={
                        !!selection?.selected?.length && selection?.selected.length < data.length
                      }
                      checked={selection?.selected?.length === data.length && data.length > 0}
                      onChange={onMainCheckBoxChanged}
                    />
                  </th>
                ) : null}
                {headers
                  .filter((item) => item.key !== "action")
                  .map((header) => (
                    <th scope="col" key={header.key}>
                      <span className="d-flex flex-row align-items-center gap-1">
                        <span>{t(`TABLE.${header.name}`)}</span>
                        {header.sortable ? (
                          <span className="d-flex flex-row">
                            <Icon
                              type="fa-solid fa-arrow-up"
                              size={16}
                              className={`table-action-icon ${
                                sort?.activeSort === header.key && sort?.activeOrder === "ASC"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                sort?.activeSort === header.key &&
                                sort?.activeOrder === "ASC" &&
                                sort?.onClearSort
                                  ? sort?.onClearSort()
                                  : sort?.onSort(header.key, "ASC")
                              }
                              title={t("TABLE.SORT_ASC")}
                            />
                            <Icon
                              type="fa-solid fa-arrow-down"
                              size={16}
                              className={`table-action-icon ${
                                sort?.activeSort === header.key && sort?.activeOrder === "DESC"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                sort?.activeSort === header.key &&
                                sort?.activeOrder === "DESC" &&
                                sort?.onClearSort
                                  ? sort?.onClearSort()
                                  : sort?.onSort(header.key, "DESC")
                              }
                              title={t("TABLE.SORT_DESC")}
                            />
                          </span>
                        ) : null}
                      </span>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr id={"row" + rowIndex} key={rowIndex} className="p-3">
                  {selection ? (
                    <td style={{ width: 0 }}>
                      <CheckBox
                        className="form-check-input cursor-pointer"
                        checked={!!selection?.selected?.find((sr) => sr === row[0])}
                        onChange={(event) => onRowCheckBoxChanged(event, row[0] as number)}
                      />
                    </td>
                  ) : null}
                  <td
                    data-bs-toggle="tooltip"
                    title={renderTooltip(row[0])}
                    className={onRowPress ? "cursor-pointer text-primary td-pressable" : undefined}
                    onClick={onRowPress ? () => onRowPress(row[0] as number) : undefined}
                  >
                    {row[0]}
                  </td>
                  {row.map((value, index) =>
                    index === 0 ||
                    (index === row.length - 1 &&
                      headers[headers.length - 1].key === "action") ? null : (
                      <td
                        style={
                          headers[headers.length - 1].key === "action" && index === row.length - 2
                            ? { paddingRight: "200px" }
                            : undefined
                        }
                        data-bs-toggle="tooltip"
                        title={renderTooltip(value)}
                        key={index}
                      >
                        {renderTableItemValue(value)}
                      </td>
                    )
                  )}
                  {headers[headers.length - 1].key === "action" ? (
                    <>
                      <div
                        className="mr-3 table-actions"
                        style={{
                          height:
                            (document.getElementById("row" + rowIndex)?.clientHeight || 0) - 2,
                          marginTop: 1,
                        }}
                      >
                        {row[row.length - 1]}
                      </div>
                    </>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
          {!data.length && !loading && (
            <div
              style={{
                position: "sticky",
                left: 0,
                width: "100%",
              }}
            >
              <NoData />
            </div>
          )}
        </div>
      }
      <div style={{ position: "sticky", left: 0 }} className="width-100 ">
        {pagination && !!data.length ? (
          <>
            <div className="w-100 d-flex flex-md-row flex-column align-items-md-center justify-content-between mt-3 gap-2">
              <div className="d-flex flex-row gap-2 align-items-center w-100">
                {/* Go to first */}

                <span
                  aria-hidden="true"
                  className="font-24 cursor-pointer btn-outline-primary btn d-flex align-items-center justify-content-center"
                  style={{ width: "34px", height: "34px", borderRadius: "4px" }}
                  onClick={() => setPage(1)}
                >
                  <i className="fa-solid fa-angles-left" style={{ fontSize: 16 }}></i>
                </span>

                {/* Go to previous */}
                <span
                  aria-hidden="true"
                  className="font-24 cursor-pointer btn-outline-primary btn d-flex align-items-center justify-content-center "
                  style={{ width: "34px", height: "34px", borderRadius: "4px" }}
                  onClick={() => setPage(Math.max(currentPage - 1, 1))}
                >
                  <i className="fa-solid fa-angle-left" style={{ fontSize: 16 }}></i>
                </span>

                {/* current */}
                <span
                  aria-hidden="true"
                  className="mx-2 flex-row justify-content-center flex-md-grow-0 flex-grow-1"
                >
                  {currentPage === 1
                    ? pagination.totalItems
                      ? currentPage
                      : pagination.totalItems
                    : (currentPage - 1) * itemsPerPage + 1}{" "}
                  - {Math.min(itemsPerPage * currentPage, pagination.totalItems)}
                  &nbsp; {t("TABLE.OF")} &nbsp;
                  {Math.min(numberOfPages * itemsPerPage, pagination.totalItems)}
                </span>

                {/* Go to next */}
                <span
                  aria-hidden="true"
                  className="font-24 cursor-pointer btn-outline-primary btn d-flex align-items-center justify-content-center"
                  style={{ width: "34px", height: "34px", borderRadius: "4px" }}
                  onClick={() => setPage(Math.min(currentPage + 1, numberOfPages))}
                >
                  <i className="fa-solid fa-angle-right" style={{ fontSize: 16 }}></i>
                </span>

                {/* Go to last */}

                <span
                  aria-hidden="true"
                  className="font-24 cursor-pointer btn-outline-primary btn d-flex align-items-center justify-content-center"
                  style={{ width: "34px", height: "34px", borderRadius: "4px" }}
                  onClick={() => setPage(numberOfPages)}
                >
                  <i className="fa-solid fa-angles-right" style={{ fontSize: 16 }}></i>
                </span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <span style={{ whiteSpace: "nowrap" }}>{t("TABLE.ROWS_PER_PAGE")} </span>
                <select
                  className="form-select"
                  style={{ minWidth: "80px" }}
                  value={itemsPerPage}
                  onChange={onItemsPerPageChange}
                >
                  {itemsPerPageOptions.map((ip, i) => (
                    <option value={ip} key={i}>
                      {ip}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

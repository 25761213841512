import { Modal } from "react-bootstrap";
import { FormModalProps } from "./Modal.types";
import { useTranslation } from "react-i18next";
import { GenericForm } from "../generic-form";
import { Button } from "../button";

export function FormModal<T>({
  isVisible,
  onSubmit,
  onDismiss,
  loading,
  form,
  submitButtonLabel,
  title,
  titleProps,
}: FormModalProps<T>) {
  const { t } = useTranslation();
  return (
    <Modal show={isVisible} animation onHide={onDismiss}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {titleProps ? t(title, titleProps) : t(title)}
        </h5>
        <div onClick={onDismiss}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <GenericForm
          form={form}
          onSubmit={onSubmit}
          submitControl={(submit) => (
            <div className="flex-row">
              <Button
                isLoading={loading}
                loader={{ size: "s" }}
                className="btn btn-primary mt-3 w-100"
                type="submit"
              >
                {t(submitButtonLabel)}
              </Button>
            </div>
          )}
        />
      </Modal.Body>
    </Modal>
  );
}

import { IDocument } from "../../entities/IDocument";
import { ISefCollectionLog } from "../../entities/ISefCollectionLog";
import { processSyncSefInvoicesPayload } from "../../screens/sef-integration";
import {
  ISyncSefInvoicesRequest,
  ISyncSefInvoicesResponse,
} from "../../screens/sef-integration/SefIntegration.types";
import BaseInstance from "../BaseInstance";

const SefGateway = {
  syncSefInvoices: async (payload: ISyncSefInvoicesRequest): Promise<ISyncSefInvoicesResponse> => {
    return BaseInstance.post(`/sef/collect-invoices`, null, {
      params: processSyncSefInvoicesPayload(payload),
    });
  },

  getReports: async (query: string): Promise<{ count: number; reports: ISefCollectionLog[] }> => {
    return BaseInstance.get(`/sef/reports${query}`);
  },

  getReportDocuments(
    documentIds: number[],
    query: string
  ): Promise<{
    count: number;
    documents: IDocument[];
  }> {
    const queryParams = new URLSearchParams(query);
    const reportFilter = `{"name":"id","type":"numberArray","operator":"in","value":"[${documentIds}]"}`;
    queryParams.append("filters", reportFilter);
    return BaseInstance.get("/documents", {
      params: queryParams,
    });
  },
};

export default SefGateway;

import { useTranslation } from "react-i18next";

export const NoData = () => {
  const { t } = useTranslation();
  return (
    <div className="td-no-data">
      <h3
        style={{
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "32px",
        }}
      >
        {t("TABLE.NO_RESULTS")}
      </h3>
    </div>
  );
};

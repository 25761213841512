import { useLocation, useNavigate } from "react-router";
import { useToast } from "../components/toast";
import { RequestOptions, useRequest } from "../modules/request";
import { screens } from "../screens/Navigation.types";
import { useAppDispatch } from "../store";
import { authActions } from "../store/auth/AuthReducer";
import { useTranslation } from "react-i18next";

export default function useBaseRequest<TData, TPayload>(
  request: (payload: TPayload) => Promise<TData>,
  options: RequestOptions<TData, TPayload> = {}
) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { danger } = useToast();

  const onError = (error: any) => {
    if (error?.statusCode === 401) {
      danger(t("ERRORS.SESSION_EXPIRED"));
      dispatch(authActions.setUser(null));
      navigate(screens.login, { state: { returnTo: pathname }, replace: true });
      return;
    }

    if (options.onError) {
      options.onError(error);
      return;
    }
    console.log("request error", error);
    danger(error?.message || t("ERRORS.UNKNOWN"));
  };

  return useRequest(request, {
    ...options,
    onError,
  });
}

import { useTranslation } from "react-i18next";
import { useToast } from "../../components/toast";
import { useState } from "react";
import {
  AddTaskAttachmentForm as AddForm,
  UpdateTaskAttachmentDTO,
  UpdateTaskAttachmentForm,
} from "./Task.types";
import useBaseRequest from "../../api/BaseRequest";
import TaskGateway from "../../api/gateways/TaskGateway";
import { useOutletContext } from "react-router-dom";
import { ITaskElement } from "../../entities/ITask";
import LabelGateway from "../../api/gateways/LabelGateway";

export const useTaskAttachments = (taskId: number) => {
  const { danger, success } = useToast();
  const { t } = useTranslation();
  const { taskAttachments: attachments, refetchTask } = useOutletContext<{
    taskAttachments: ITaskElement[];
    refetchTask: () => void;
  }>();

  const [action, setAction] = useState<"create" | "update" | "delete" | undefined>();
  const [attachmentID, setAttachmentID] = useState<number | undefined>();
  const onAddAttachmentStart = () => {
    setAction("create");
  };
  const { execute: add, loading: adding } = useBaseRequest(TaskGateway.addAttachmentToTask, {
    onCompleted: (data, payload) => {
      success(t("TASKS.ATTACHMENT_ADDED_MESSAGE"));
      setAction(undefined);
      setAttachmentID(undefined);
      refetchTask();
      payload.onClose && payload.onClose();
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });
  const onEdit = (attachmentID: number) => {
    setAction("update");
    setAttachmentID(attachmentID);
  };
  const onDelete = (attachmentID: number) => {
    setAction("delete");
    setAttachmentID(attachmentID);
  };
  const onDismiss = () => {
    setAction(undefined);
    setAttachmentID(undefined);
  };

  const addAttachmentForm = {
    ...AddForm,
    taskId: {
      ...AddForm.taskId,
      defaultValue: taskId,
    },
  };

  const { execute: edit, loading: editing } = useBaseRequest(TaskGateway.editAttachment, {
    onCompleted: (data) => {
      success(t("TASKS.ATTACHMENT_EDIT_MESSAGE"));
      setAction(undefined);
      setAttachmentID(undefined);
      refetchTask();
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  const { execute: addLabelsToElement, loading: addLabelsLoading } = useBaseRequest(
    LabelGateway.addLabelsToElement,
    {
      onCompleted: () => {
        refetchTask();
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );
  const { execute: removeLabelsFromElement, loading: removeLabelsLoading } = useBaseRequest(
    LabelGateway.removeLabelsFromElement,
    {
      onCompleted: () => {
        refetchTask();
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const editAttachmentHandler = (data: UpdateTaskAttachmentDTO) => {
    const taskAttachment = attachments.find((a) => a.id === data.id);
    if (!taskAttachment) return;
    const labels = taskAttachment?.labels || [];
    const findNewLabels =
      data?.labels?.filter((item) => !labels.find((label) => label.id === item)) || [];
    const findDeletedLabels = labels?.filter(
      (item) => !data?.labels?.find((label) => label === item.id)
    );
    if (findNewLabels.length) {
      addLabelsToElement({ id: data.id, labelIds: findNewLabels, type: "task-element" });
    }
    if (findDeletedLabels && findDeletedLabels.length) {
      removeLabelsFromElement({
        id: data.id,
        labelIds: findDeletedLabels.map((item) => item.id),
        type: "task-element",
      });
    }
    edit({ ...data });
  };

  const editAttachmentForm = {
    ...UpdateTaskAttachmentForm,
    id: { ...UpdateTaskAttachmentForm.id, defaultValue: attachmentID },
    title: {
      ...UpdateTaskAttachmentForm.title,
      defaultValue: attachments.find((a) => a.id === attachmentID)?.content.title,
    },
    description: {
      ...UpdateTaskAttachmentForm.description,
      defaultValue: attachments.find((a) => a.id === attachmentID)?.content.description,
    },
    labels: {
      ...UpdateTaskAttachmentForm.labels,
      defaultValue:
        attachments.find((a) => a.id === attachmentID)?.labels?.map((l) => l.id) || undefined,
    },
  };

  const { execute: deleteAttachment } = useBaseRequest(TaskGateway.deleteAttachment, {
    onCompleted: (data) => {
      success(t("TASKS.ATTACHMENT_DELETED_MESSAGE"));
      setAction(undefined);
      setAttachmentID(undefined);
      refetchTask();
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  const { execute: executeAction } = useBaseRequest(TaskGateway.executeAttachmentAction, {
    onCompleted: (data, payload) => {
      success(
        t(
          payload.action === "approve"
            ? "TASKS.ATTACHMENT_APPROVED_ACTION_SUCCESS"
            : "TASKS.ATTACHMENT_REJECTED_ACTION_SUCCESS"
        )
      );
      setAction(undefined);
      setAttachmentID(undefined);
      refetchTask();
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  return {
    attachments,
    action,
    attachmentID,
    onAddAttachmentStart,
    onAdd: add,
    addingTaskAttachment: adding,
    onEdit,
    onDelete,
    onDismiss,
    addAttachmentForm,
    editAttachmentForm,
    edit: editAttachmentHandler,
    editing,
    onExecuteAction: executeAction,
    deleteAttachment: () => {
      if (typeof attachmentID === "number") deleteAttachment(attachmentID);
    },
  };
};

import { GenericForm } from "../generic-form/GenericForm.types";
import { GenericFormValidator } from "../generic-form/utility/GenericFormValidator";
import { CreateDigitalSignatureRequestDTO } from "../../entities/IDigitalSignatureRequest";
import { MetaDataForm } from "../../entities/IMetaData";

export const requestDigitalSignatureForm: GenericForm<CreateDigitalSignatureRequestDTO> = {
  email: {
    type: "input",
    name: "email",
    label: "DOCUMENTS.CUSTOMER_EMAIL",
    defaultValue: "",
    validators: [GenericFormValidator.required(), GenericFormValidator.email()],
  },
  documentId: {
    type: "hidden",
    visibleIf: () => false,
    name: "documentId",
    label: "",
    defaultValue: -1,
    validators: [],
  },
};

export interface RequestDigitalSignatureModalProps {
  onDismiss: () => void;
  onSubmit: (data: CreateDigitalSignatureRequestDTO) => void;
  creating: boolean;
  isVisible: boolean;
  requestDigitalSignatureForm: GenericForm<CreateDigitalSignatureRequestDTO>;
}

export interface ViewDigitalSignatureModalProps {
  onDismiss: () => void;
  isVisible: boolean;
  fields: GenericForm<MetaDataForm> | null;
  onDelete: () => void;
  onResend: () => void;
}

import { useEffect, useState } from "react";
import { TableData, TableRow } from "../../../components/table";
import { IReport } from "../../../entities/IReport";
import { useTranslation } from "react-i18next";
import { translation } from "../../../components/translations";
import moment from "moment";

export const useMapToReportTable = (data?: IReport[] | null) => {
  const { t } = useTranslation();
  const language = translation.language;
  const [tableData, setTableData] = useState<TableRow[]>([]);

  useEffect(() => {
    if (!data) return;
    const tableRows: TableRow[] = [];
    data.forEach((report) => {
      const newRow: TableData[] = [];

      if (!!report.basketId) {
        newRow.push(report.basketId);
        newRow.push(report.basketName!);
      }

      if (!!report.userId && !!report.name) {
        newRow.push(report.userId);
        newRow.push(report.name);
      }

      if (!!report.docTypeName) {
        newRow.push(report.docTypeName);
      }

      if (report.aht !== undefined)
        newRow.push(
          report.aht > 0
            ? report.aht > 24
              ? `${Math.floor(report.aht / 24)} ${
                  Math.floor(report.aht / 24) === 1 ? t("DASHBOARD.DAY") : t("DASHBOARD.DAYS")
                }`
              : `${Math.floor(report.aht)} ${
                  Math.floor(report.aht) === 1 ? t("DASHBOARD.HOUR") : t("DASHBOARD.HOURS")
                }`
            : "0 " + t("DASHBOARD.HOURS")
        );
      if (report.art !== undefined)
        newRow.push(
          report.art > 0
            ? report.art > 24
              ? `${Math.floor(report.art / 24)} ${
                  Math.floor(report.art / 24) === 1 ? t("DASHBOARD.DAY") : t("DASHBOARD.DAYS")
                }`
              : `${Math.floor(report.art)} ${
                  Math.floor(report.art) === 1 ? t("DASHBOARD.HOUR") : t("DASHBOARD.HOURS")
                }`
            : "0 " + t("DASHBOARD.HOURS")
        );

      tableRows.push([
        ...newRow,
        report.assignedTasks || 0,
        report.inProgressTasks || 0,
        report.doneTasks || 0,
        report.oldestTask ? moment(report.oldestTask).format("DD.MM.YYYY") : "-",
      ]);
    });
    setTableData(tableRows);
  }, [data, language]);
  return {
    tableData,
  };
};

import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { AuthGuard, ConfigurationGuard } from "../components/guard";
import { SideNav } from "../components/side-nav";
import { ArchiveScreen } from "./archive";
import { DocumentScreen } from "./document";
import { DocumentsScreen } from "./documents";
import { LoginScreen } from "./login";
import { screens } from "./Navigation.types";
import { NewDocumentScreen } from "./new-document";
import { UploadDocumentScreen } from "./upload-document";
import ArchiveStatsScreen from "./archive-stats/ArchiveStatsScreen";
import QueryAppender from "../components/query-appender/QueryAppender";
import { UserRoles } from "../entities/IUser";
import { UploadDocumentsSessionScreen } from "./upload-documents-session";
import { useEffect } from "react";
import { useAppDispatch } from "../store";
import { filterActions } from "../store/filters/FilterReducer";
import { SplitFileScreen } from "./split-file";
import { CodeCallbackScreen } from "./code-callback/CodeCallbackScreen";
import { DocumentCategoriesScreen } from "./document-categories";
import { DocumentTypesScreen } from "./document-types";
import { ArchiveBooksScreen } from "./archive-book";
import { ArchiveBookDetailScreen } from "./archive-book-detail";
import { SefIntegrationScreen } from "./sef-integration";
import { SefReportsScreen } from "./sef-reports";
import { SefReportOverviewScreen } from "./sef-report-overview";
import { CustomFieldsScreen } from "./custom-fields";
import { LabelsScreen } from "./labels";
import { BasketsScreen } from "./baskets";
import BasketTasksScreen from "./basket-tasks/BasketTasksScreen";
import MyTasksScreen from "./my-tasks/MyTasksScreen";
import TaskComments from "./task/TaskComments";
import TaskDetails from "./task/TaskDetails";
import TaskLogs from "./task/TaskLogs";
import TaskScreen from "./task/TaskScreen";
import TasksScreen from "./tasks/TasksScreen";
import { ReportsScreen } from "./reports";
import TaskStatsScreen from "./task-stats/TaskStatsScreen";
import { ConfigurationScreen } from "./configuration/ConfigurationScreen";
import { DigitalSignatureScreen } from "./digital-signature";
import environment from "../config/environment";

export default function Navigation() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    dispatch(
      filterActions.setFilterByPage({
        page: location.pathname,
        filter: queryParams.toString(),
      })
    );
  }, [location]);

  return (
    <Routes>
      <Route
        path=""
        element={
          <AuthGuard>
            <SideNav />
          </AuthGuard>
        }
      >
        <Route index path="" element={<Navigate to={screens.documents} />} />
        <Route path={screens.documents}>
          <Route
            path=""
            element={
              <QueryAppender queryKey={screens.documents}>
                <DocumentsScreen />
              </QueryAppender>
            }
          />
          <Route path=":id" element={<DocumentScreen />} />
        </Route>
        <Route
          path={screens.labels}
          element={
            <ConfigurationGuard
              fallback={{ kind: "redirect", path: screens.root }}
              isEnabled={environment.modulesEnabled.tasks}
            >
              <LabelsScreen />
            </ConfigurationGuard>
          }
        ></Route>
        <Route path={screens.uploadDocument} element={<UploadDocumentScreen />} />
        <Route path={screens.newDocument} element={<NewDocumentScreen />} />
        <Route path={screens.documentCategories} element={<DocumentCategoriesScreen />} />
        <Route path={screens.customFields} element={<CustomFieldsScreen />} />
        <Route
          path={screens.documentTypes}
          element={
            <QueryAppender queryKey={screens.documentTypes}>
              <DocumentTypesScreen />
            </QueryAppender>
          }
        />
        <Route path={screens.archiveBooks}>
          <Route
            path=""
            element={
              <ConfigurationGuard
                fallback={{ kind: "redirect", path: screens.root }}
                isEnabled={environment.modulesEnabled.archive}
              >
                <ArchiveBooksScreen />
              </ConfigurationGuard>
            }
          />
          <Route
            path=":id"
            element={
              <ConfigurationGuard
                fallback={{ kind: "redirect", path: screens.root }}
                isEnabled={environment.modulesEnabled.archive}
              >
                <ArchiveBookDetailScreen />
              </ConfigurationGuard>
            }
          />
        </Route>
        <Route path={screens.baskets} element={<BasketsScreen />} />
        <Route
          path={screens.basketTasks}
          element={
            <ConfigurationGuard
              fallback={{ kind: "redirect", path: screens.root }}
              isEnabled={environment.modulesEnabled.tasks}
            >
              <QueryAppender queryKey={screens.basketTasks}>
                <BasketTasksScreen />
              </QueryAppender>
            </ConfigurationGuard>
          }
        />
        <Route
          path={screens.taskStats}
          element={
            <ConfigurationGuard
              fallback={{ kind: "redirect", path: screens.root }}
              isEnabled={environment.modulesEnabled.tasks}
            >
              <QueryAppender queryKey={screens.taskStats}>
                <TaskStatsScreen />
              </QueryAppender>
            </ConfigurationGuard>
          }
        />

        <Route
          path={screens.archive}
          element={
            <ConfigurationGuard
              fallback={{ kind: "redirect", path: screens.root }}
              isEnabled={environment.modulesEnabled.archive}
            >
              <QueryAppender queryKey={screens.archive}>
                <ArchiveScreen />
              </QueryAppender>
            </ConfigurationGuard>
          }
        />
        <Route
          path={screens.sefIntegrations}
          element={
            <ConfigurationGuard
              fallback={{ kind: "redirect", path: screens.root }}
              isEnabled={environment.modulesEnabled.sefProvider}
            >
              <QueryAppender queryKey={screens.sefIntegrations}>
                <SefIntegrationScreen />
              </QueryAppender>
            </ConfigurationGuard>
          }
        />
        <Route
          path={screens.sefReports}
          element={
            <QueryAppender queryKey={screens.sefReports}>
              <SefReportsScreen />
            </QueryAppender>
          }
        />
        <Route
          path={screens.sefReportOverview}
          element={
            <QueryAppender queryKey={screens.sefReportOverview}>
              <SefReportOverviewScreen />
            </QueryAppender>
          }
        />
        <Route path={screens.configuration} element={<ConfigurationScreen />} />
        <Route
          path={screens.myTasks}
          element={
            <ConfigurationGuard
              fallback={{ kind: "redirect", path: screens.root }}
              isEnabled={environment.modulesEnabled.tasks}
            >
              <QueryAppender queryKey={screens.myTasks}>
                <MyTasksScreen />
              </QueryAppender>
            </ConfigurationGuard>
          }
        />
        <Route
          path={screens.task.root}
          element={
            <ConfigurationGuard
              fallback={{ kind: "redirect", path: screens.root }}
              isEnabled={environment.modulesEnabled.tasks}
            >
              <TaskScreen />
            </ConfigurationGuard>
          }
        >
          <Route
            path={screens.task.details}
            element={
              <ConfigurationGuard
                fallback={{ kind: "redirect", path: screens.root }}
                isEnabled={environment.modulesEnabled.tasks}
              >
                <TaskDetails />
              </ConfigurationGuard>
            }
          />
          <Route
            path={screens.task.comments}
            element={
              <ConfigurationGuard
                fallback={{ kind: "redirect", path: screens.root }}
                isEnabled={environment.modulesEnabled.tasks}
              >
                <TaskComments />
              </ConfigurationGuard>
            }
          />
          <Route
            path={screens.task.logs}
            element={
              <ConfigurationGuard
                fallback={{ kind: "redirect", path: screens.root }}
                isEnabled={environment.modulesEnabled.tasks}
              >
                <TaskLogs />
              </ConfigurationGuard>
            }
          />
          <Route index element={<Navigate to={screens.task.details} replace />} />
        </Route>
        <Route
          path={screens.tasks}
          element={
            <ConfigurationGuard
              fallback={{ kind: "redirect", path: screens.root }}
              isEnabled={environment.modulesEnabled.tasks}
            >
              <QueryAppender queryKey={screens.tasks}>
                <TasksScreen />
              </QueryAppender>
            </ConfigurationGuard>
          }
        />
        <Route
          path={screens.archiveStats}
          element={
            <QueryAppender queryKey={screens.archiveStats}>
              <ArchiveStatsScreen />
            </QueryAppender>
          }
        />
        <Route
          path={screens.reports}
          element={
            <QueryAppender queryKey={screens.reports}>
              <ReportsScreen />
            </QueryAppender>
          }
        />
        <Route path={screens.uploadSession} element={<UploadDocumentsSessionScreen />} />
        <Route path={screens.splitFile} element={<SplitFileScreen />} />
        <Route
          path={screens.digitalSignature}
          element={
            <ConfigurationGuard
              fallback={{ kind: "redirect", path: screens.root }}
              isEnabled={environment.modulesEnabled.digitalSignature}
            >
              <QueryAppender queryKey={screens.digitalSignature}>
                <DigitalSignatureScreen />
              </QueryAppender>
            </ConfigurationGuard>
          }
        />
      </Route>
      <Route path={screens.login} element={<LoginScreen />} />
      <Route path={screens.codeCallback} element={<CodeCallbackScreen />}></Route>
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}

import * as pdfLib from "pdf-lib";
import * as pdfjs from "pdfjs-dist";

pdfjs.GlobalWorkerOptions.workerPort = new Worker(
  new URL("pdfjs-dist/build/pdf.worker.js", import.meta.url)
);

class PDF {
  public async splitIntoPages(file: File): Promise<File[]> {
    const pdfFile = await pdfLib.PDFDocument.load(await file.arrayBuffer());

    const pdfs: File[] = [];

    for (let i = 0; i < pdfFile.getPageCount(); i++) {
      const newPdf = await pdfLib.PDFDocument.create();
      const [page] = await newPdf.copyPages(pdfFile, [i]);
      newPdf.addPage(page);

      const buffer = (await newPdf.save()).buffer;
      pdfs.push(
        new File([buffer], `file${i}.pdf`, { type: "application/pdf" })
      );
    }

    return pdfs;
  }

  // Return unresolved image promises, so that they could be resolved by components
  public async splitIntoImagePromises(file: File): Promise<Promise<string>[]> {
    const pdfFile = await pdfjs.getDocument(await file.arrayBuffer()).promise;
    const imagePromises: Promise<string>[] = [];

    for (let i = 0; i < pdfFile.numPages; i++) {
      const imagePromise = pdfFile.getPage(i + 1).then((page) => {
        const viewport = page.getViewport({ scale: 1.5 });

        let canvas = document.createElement("canvas");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderContext = {
          canvasContext: canvas.getContext("2d") as CanvasRenderingContext2D,
          viewport: viewport,
        };

        return page.render(renderContext).promise.then(() => {
          const dataURL = canvas.toDataURL("image/png");
          return dataURL;
        });
      });

      imagePromises.push(imagePromise);
    }

    return imagePromises;
  }

  public async splitIntoImages(file: File): Promise<string[]> {
    const pdfFile = await pdfjs.getDocument(await file.arrayBuffer()).promise;
    const images: string[] = [];

    for (let i = 0; i < pdfFile.numPages; i++) {
      const page = await pdfFile.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1.5 });

      let canvas = document.createElement("canvas");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderContext = {
        canvasContext: canvas.getContext("2d") as CanvasRenderingContext2D,
        viewport: viewport,
      };
      await page.render(renderContext).promise;

      const dataURL = canvas.toDataURL("image/png");
      images.push(dataURL);
    }

    return images;
  }

  public async groupIntoSingle(files: File[], name: string): Promise<File> {
    const newPdf = await pdfLib.PDFDocument.create();

    for (let i = 0; i < files.length; i++) {
      const pdfFile = await pdfLib.PDFDocument.load(
        await files[i].arrayBuffer()
      );

      for (let index of pdfFile.getPageIndices()) {
        const [newPage] = await newPdf.copyPages(pdfFile, [index]);
        newPdf.addPage(newPage);
      }
    }

    const pdfBytes = await newPdf.save();

    return new File([pdfBytes.buffer], name, { type: "application/pdf" });
  }
}

export default new PDF();

import { BasketCardActionProps } from "../Basket.types";
import { Icon } from "../../../modules/icon";
import { RoleBasedAccess } from "../../../components/guard";
import { UserRoles } from "../../../entities/IUser";
import { useTranslation } from "react-i18next";

const BasketCardActions = ({
  id,
  isBookmarked,
  onBookmark,
  onDelete,
  onEdit,
}: BasketCardActionProps) => {
  const { t } = useTranslation();
  return (
    <div
      className="flex-row basketcard-actions"
      onClick={(event) => {
        event?.stopPropagation();
      }}
    >
      <Icon
        title={isBookmarked ? "Unbookmark" : "Bookmark"}
        type="fa-solid fa-bookmark"
        color={isBookmarked ? "#3dc7a4" : "#666666"}
        className="bookmark-icon"
        onClick={(event) => {
          event?.stopPropagation();
          onBookmark(id);
        }}
      />
      <div className="other-actions">
        <RoleBasedAccess allowedRoles={[UserRoles.ADMIN]}>
          <Icon
            title={t("EDIT_BUTTON")}
            color={"#666666"}
            type="fa-solid fa-pen"
            onClick={(event) => {
              event?.stopPropagation();
              onEdit(id);
            }}
          />
        </RoleBasedAccess>
        <RoleBasedAccess allowedRoles={[UserRoles.ADMIN]}>
          <Icon
            title={t("DELETE_BUTTON")}
            color={"#666666"}
            type="fa-solid fa-trash"
            onClick={(event) => {
              event?.stopPropagation();
              onDelete(id);
            }}
          />
        </RoleBasedAccess>
      </div>
    </div>
  );
};

export default BasketCardActions;

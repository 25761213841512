import { GenericForm } from "../../../components/generic-form/GenericForm.types";
import { IRolePermission, PermissionAccessType } from "../../../entities/IRolePermission";
import { DocumentTypeAccessConfigForm } from "../DocumentTypes.types";

export const mapAccessConfigToForm = (
  accessConfig: IRolePermission[] | null,
  t: (value: string) => string
): GenericForm<DocumentTypeAccessConfigForm> => {
  return accessConfig
    ? accessConfig.reduce((form, rp) => {
        return {
          ...form,
          [rp.role]: {
            type: "select",
            name: rp.role,
            label: rp.role,
            options: Object.keys(PermissionAccessType)
              .filter((x) => isNaN(parseInt(x, undefined)))
              .map((accessType) => ({
                label: t(`ACCESS_CONFIG.${accessType}`),
                value: PermissionAccessType[accessType as keyof typeof PermissionAccessType],
              })),
            defaultValue: `${rp.accessType}`,
            validators: [],
          },
        };
      }, {})
    : {};
};

import { useTranslation } from "react-i18next";
import { Button } from "../../components/button";
import { FiltersWithQuery } from "../../components/filters";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchWithQueryInput } from "../../components/search-input";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { Icon } from "../../modules/icon";
import { archiveBookTableHeaders } from "./ArchiveBooks.types";
import { useArchiveBooks } from "./useArchiveBooks";
import { FormModal } from "../../components/modal";
import { DialogModal } from "../../components/modal/DialogModal";

export const ArchiveBooksScreen = () => {
  const { t } = useTranslation();
  const {
    isVisibleCreateArchiveBookModal,
    hideCreateArchiveBookModalHandler,
    showCreateArchiveBookModalHandler,
    createArchiveBookForm,
    createArchiveBook,
    creatingArchiveBook,
    fetchingArchiveBooks,
    archiveBookTableData,
    archiveBooksCount,
    archiveBookFilters,
    deleteArchiveBookId,
    deletingArchiveBook,
    deleteArchiveBook,
    hideDeleteArchiveBookModalHandler,
  } = useArchiveBooks();

  return (
    <PageLayout
      title={t("ARCHIVE_BOOK.TITLE")}
      search={<SearchWithQueryInput placeHolder={t("ARCHIVE_BOOK.SEARCH")} />}
      filter={<FiltersWithQuery filters={archiveBookFilters} />}
      titleRight={
        <div className="text-right flex-row gap-2">
          <div style={{ height: "100%", width: "2px", backgroundColor: "black" }} />
          <Button className="btn btn-dark" onClick={showCreateArchiveBookModalHandler}>
            <Icon type="fa fa-plus" title={t("ARCHIVE_BOOK.CREATE_LABEL")} />
          </Button>
        </div>
      }
    >
      <FormModal
        isVisible={isVisibleCreateArchiveBookModal}
        onDismiss={hideCreateArchiveBookModalHandler}
        onSubmit={createArchiveBook}
        form={createArchiveBookForm}
        title="ARCHIVE_BOOK.CREATE_LABEL"
        loading={creatingArchiveBook}
        submitButtonLabel="CREATE_BUTTON"
      />
      <DialogModal
        loading={deletingArchiveBook}
        show={deleteArchiveBookId != null}
        onAccept={deleteArchiveBook}
        onCancel={hideDeleteArchiveBookModalHandler}
        title="ARCHIVE_BOOK.DELETE"
        acceptLabel="DELETE_BUTTON"
        cancelLabel="CANCEL_BUTTON"
        description="ARCHIVE_BOOK.DELETE_LABEL"
      />
      <Row>
        <Column>
          <TableWithQueryPaginationAndSort
            headers={archiveBookTableHeaders}
            data={archiveBookTableData}
            totalItems={archiveBooksCount}
            onRowPress={() => {}}
            selection={false}
            loading={fetchingArchiveBooks}
          />
        </Column>
      </Row>
    </PageLayout>
  );
};

import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { useToast } from "../../components/toast";
import { DocumentActionsTypes, DocumentStatus, IDocument } from "../../entities/IDocument";
import { IMetaDataRule, MetaData, MetaDataForm } from "../../entities/IMetaData";
import { useWindowDimensions } from "../../modules/window";
import { useDocumentService } from "../../services/useDocumentService";
import { screens } from "../Navigation.types";
import { TASK_SUCCESS_MESSAGES } from "../../entities/ITask";
import TaskGateway from "../../api/gateways/TaskGateway";
import { createTaskForm as createTaskFormDefault } from "../../components/create-task/CreateTaskModal.types";
import OptionListGateway from "../../api/gateways/OptionListGateway";
import { useCounterService } from "../../services/useCounterService";
import { useUser } from "../../store/auth/AuthSelectors";
import { UserRoles } from "../../entities/IUser";
import { useTranslation } from "react-i18next";
import { IOption } from "../../components/select";
import LabelGateway from "../../api/gateways/LabelGateway";
import DigitalSignatureGateway from "../../api/gateways/DigitalSignatureGateway";
import { requestDigitalSignatureForm as requestDigitalSignatureFormDefault } from "../../components/request-digital-signature";
import { GenericForm } from "../../components/generic-form/GenericForm.types";

export function useDocument(id: string) {
  const { t } = useTranslation();
  const [metaDataRules, setMetaDataRules] = React.useState<IMetaDataRule[]>([]);
  const [metaData, setMetaData] = React.useState<MetaData>({});
  const [docFile, setDocFile] = React.useState<{
    uri: string;
    type: string;
    name: string;
    file: File | null;
    edited: boolean;
  } | null>(null);
  const [labels, setLabels] = React.useState<IOption<number>[]>([]);

  // states
  const [editing, setEditing] = React.useState(false);
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const [showDismissModal, setShowDismissModal] = React.useState(false);
  const navigate = useNavigate();
  const { success, danger } = useToast();
  const [task, setTask] = useState<IDocument["task"]>();
  const user = useUser();

  // requests
  const { mapMetaDataRulesToForm, getDocFileUrl } = useDocumentService();

  const {
    data,
    loading,
    execute: fetch,
  } = useBaseRequest(DocumentGateway.getDocumentByIdAndMetaData, {
    initialPayload: { id },
    autoFetch: true,
    onCompleted: (data) => {
      if (!data) return;
      if (data.document.metaData) {
        setMetaData(data.document.metaData);
      }
      if (data.metaDataRules) {
        setMetaDataRules(data.metaDataRules);
      }
      if (data.document.labels) {
        setLabels(
          data.document.labels.map((label) => ({
            label: label.name,
            value: label.id,
            color: label.color,
          }))
        );
      }
      setDefaultFile(data.document);
      setTask(data.document.task);
    },
  });

  const { execute: update, loading: updating } = useBaseRequest(DocumentGateway.updateDocument, {
    onCompleted: (data, payload) => {
      fetch({ id: payload.id });
      setEditing(false);
      success(t("DOCUMENTS.UPDATED_MESSAGE"));
      onClearAction();
    },
  });

  const { execute: dismiss } = useBaseRequest(DocumentGateway.dismissDocument, {
    onCompleted: (data) => {
      // refreshTaskCounters();
      closeModal();
      navigate(screens.documents);
      success(t("DOCUMENTS.DELETED_MESSAGE"));
    },
  });

  const { execute: getAllBaskets, data: allBaskets } = useBaseRequest(
    OptionListGateway.getOptions,
    {
      autoFetch: true,
      initialPayload: {
        textSearch: "",
        skip: 0,
        limit: 1000,
        endpoint: "/baskets/search-autocomplete",
      },

      onCompleted: (data) => {
        if (!data) return;
      },
    }
  );

  // handlers
  const onEdit = () => {
    setEditing(true);
  };

  const onEditCancel = () => {
    setEditing(false);
    setDefaultFile(data?.document);
  };

  const setDefaultFile = (doc?: IDocument) => {
    const file = doc?.files && doc.files.length ? doc.files[doc.files.length - 1] : null;
    if (!file) {
      setDocFile(null);
      return;
    }
    const fileUrl = getDocFileUrl(file.url);
    setDocFile({
      uri: fileUrl,
      type: file.mimeType || "",
      name: file.name,
      file: null,
      edited: false,
    });
  };

  const onFileEditCancel = () => setDefaultFile(data?.document);
  const onFileEdit = (newFile: File) => {
    setDocFile({
      edited: true,
      type: newFile.type,
      name: newFile.name,
      file: newFile,
      uri: URL.createObjectURL(newFile),
    });
  };

  const onUpdateDoc = (metaData: MetaDataForm) => {
    const params: any = {
      id: data?.document?.id?.toString(),
      metaData,
    };
    if (!!docFile && docFile.file && docFile.edited) {
      params.file = docFile.file;
    }
    const findNewLabels = labels
      .filter((item) => !data?.document.labels?.find((label) => label.id === item.value))
      .map((item) => item.value);
    const findDeletedLabels = data?.document.labels
      ?.filter((item) => !labels.find((label) => label.value === item.id))
      .map((item) => item.id);

    if (findNewLabels.length && data?.document.id) {
      addLabelsToElement({ id: data?.document.id, labelIds: findNewLabels, type: "document" });
    }
    if (data?.document.id && findDeletedLabels && findDeletedLabels.length) {
      removeLabelsFromElement({
        id: data?.document.id,
        labelIds: findDeletedLabels,
        type: "document",
      });
    }
    update(params);
  };

  const closeModal = () => setShowDismissModal(false);
  const openModal = () => setShowDismissModal(true);

  const onDismiss = () => {
    dismiss({ id: data?.document.id?.toString() || "" });
  };

  const updateLabels = (labels: IOption<number>[]) => {
    setLabels(labels);
  };

  const { execute: addLabelsToElement, loading: addLabelsLoading } = useBaseRequest(
    LabelGateway.addLabelsToElement,
    {
      onCompleted: () => {},
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );
  const { execute: removeLabelsFromElement, loading: removeLabelsLoading } = useBaseRequest(
    LabelGateway.removeLabelsFromElement,
    {
      onCompleted: () => {},
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  //Digital Signature
  const requestDigitalSignatureForm = useMemo(() => {
    return {
      ...requestDigitalSignatureFormDefault,
      documentId: {
        ...requestDigitalSignatureFormDefault.documentId,
        defaultValue: data?.document.id || -1,
      },
    };
  }, [data?.document]);

  const { execute: requestDigitalSignature, loading: requestingDigitalSignature } = useBaseRequest(
    DigitalSignatureGateway.createDigitalSignatureRequest,
    {
      onCompleted: (data) => {
        fetch({ id });
        success(t("DOCUMENTS.REQUEST_SUCCESS"));
        onClearAction();
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const digitalSignatureFields = useMemo(() => {
    if (!data?.document.digitalSignRequest) return null;
    const fields = data?.document.digitalSignRequest;
    const form: GenericForm<MetaDataForm> | null = {
      email: {
        label: "Customer Email",
        defaultValue: fields.email,
        type: "color",
        name: "",
      },
      status: {
        label: "Status",
        defaultValue: fields.status[0].toUpperCase() + fields.status.slice(1),
        type: "select",
        name: "",
      },
      confirmedAt: {
        label: "Confirmed At",
        defaultValue: fields.confirmedAt || "",
        type: "date",
        name: "",
      },
      signedAt: {
        label: "Signed At",
        defaultValue: fields.signedAt || "",
        type: "date",
        name: "",
      },
    };
    return form;
  }, [data?.document.digitalSignRequest]);

  const [selectedAction, setSelectedAction] = useState<string | undefined>();
  const onActionSelected = (action: string) => {
    const task = data?.document.task;
    if (action === DocumentActionsTypes.VIEW_TASK && task) {
      navigate(screens.task.root.replace(":id", task.id.toString()));
      return;
    }
    setSelectedAction(action);
  };
  const onClearAction = () => {
    setSelectedAction(undefined);
  };
  const { execute: deleteDigitalSignatureRequest, loading: deletingSignature } = useBaseRequest(
    DigitalSignatureGateway.deleteDigitalSignatureRequest,
    {
      onCompleted: (data) => {
        success(t("DOCUMENTS.DELETE_SIGNATURE_SUCCESS"));
        closeDeleteDigitalSignatureModal(true);
        fetch({ id });
        onClearAction();
      },
    }
  );
  const [showDeleteSignatureModal, setShowDeletesignatureModal] = useState(false);

  const closeDeleteDigitalSignatureModal = (dontShowDigitalSignature?: boolean) => {
    if (dontShowDigitalSignature === undefined || !dontShowDigitalSignature) {
      setSelectedAction(DocumentActionsTypes.VIEW_DIGITAL_SIGNATURE);
    }
    setShowDeletesignatureModal(false);
  };
  const onStartDeleteDigitalSignature = () => {
    setShowDeletesignatureModal(true);
    setSelectedAction(undefined);
  };
  const onDeleteDigitalSignature = () => {
    data?.document.digitalSignRequest?.id &&
      deleteDigitalSignatureRequest(data?.document.digitalSignRequest?.id);
  };

  return {
    document: data?.document,
    metaDataForm: mapMetaDataRulesToForm(metaDataRules, metaData),
    loading,
    windowHeight,
    windowWidth,
    onUpdateDoc,
    editing,
    docFile,
    onFileEdit,
    onFileEditCancel,
    closeModal,
    openModal,
    onDismiss,
    updating,
    user,
    labels: labels || [],
    updateLabels,
    previewLabels: data?.document.labels || [],
    onRequestDigitalSignature: requestDigitalSignature,
    requestingDigitalSignature,
    requestDigitalSignatureForm,
    digitalSignatureFields,
    onActionSelected,
    onClearAction,
    selectedAction,
    onResendDigitalSignature: () => {},
    onStartDeleteDigitalSignature,
    onDeleteDigitalSignature,
    showDeleteSignatureModal,
    closeDeleteDigitalSignatureModal,
  };
}

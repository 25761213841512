import { useEffect, useState } from "react";
import BaseInstance from "../api/BaseInstance";
import useBaseRequest from "../api/BaseRequest";
import DocumentCategoryGateway from "../api/gateways/DocumentCategoryGateway";
import DocumentGateway from "../api/gateways/DocumentGateway";
import DocumentTypeGateway from "../api/gateways/DocumentTypeGateway";
import { useFilePreview } from "../components/file-preview/useFilePreview";
import {
  GenericForm,
  GenericFormControlTypes,
  validatorFunc,
} from "../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../components/generic-form/utility/GenericFormValidator";
import { translation } from "../components/translations";
import { Language } from "../components/translations/language";
import {
  IMetaDataRule,
  IValidationRule,
  MetaData,
  MetaDataForm,
  MetaDataType,
} from "../entities/IMetaData";
import { RequestOptions } from "../modules/request";
import { useAppDispatch } from "../store";
import { documentsActions } from "../store/documents/DocumentReducer";
import {
  useDocumentCategoriesSelector,
  useDocumentTypesSelector,
} from "../store/documents/DocumentSelector";

export function useDocumentService() {
  const dispatch = useAppDispatch();

  function useGetDocumentTypes() {
    const documentTypes = useDocumentTypesSelector();
    const { execute, loading } = useBaseRequest(DocumentTypeGateway.getAllDocumentTypes, {
      autoFetch: true,
      initialPayload: {},
      onCompleted: (data) => {
        dispatch(documentsActions.setDocumentTypes(data));
      },
    });

    return { getDocumentTypes: execute, loading, documentTypes };
  }

  function useGetDocumentCategories() {
    const documentCategories = useDocumentCategoriesSelector();
    const { execute, loading } = useBaseRequest(
      () => DocumentCategoryGateway.getAllDocumentCategories(),
      {
        autoFetch: true,
        initialPayload: {},
        onCompleted: (data) => {
          dispatch(documentsActions.setDocumentCategories(data));
        },
      }
    );
    return { getDocumentCategories: execute, loading, documentCategories };
  }

  function useGetMetaDataForDocType(options?: RequestOptions<IMetaDataRule[], { id: number }>) {
    const {
      execute: getMetaDataForDocType,
      loading,
      data: metaData,
    } = useBaseRequest(DocumentGateway.getMetaDataForDocType, options);
    return { getMetaDataForDocType, metaData, loading };
  }

  const metaDataItemTypeToControlType: {
    [key in MetaDataType]: GenericFormControlTypes;
  } = {
    boolean: "checkbox",
    date: "date",
    integer: "input",
    select: "select",
    string: "input",
  };

  function mapMetaDataRuleValidatorsToFormValidators(
    validators?: IValidationRule[]
  ): validatorFunc[] | undefined {
    if (!validators) return;
    const formValidators: validatorFunc[] = [];
    validators.forEach((val) => {
      const formValidatorMethod = GenericFormValidator[val.type];
      if (!formValidatorMethod) return;
      formValidators.push(formValidatorMethod(val.params as any));
    });
    return formValidators;
  }

  function getMetaDataRuleDefaultValue<T>(
    value: T | null,
    type: MetaDataType,
    options?: {
      label: string;
      value: T;
    }[]
  ): T {
    if (!!value) {
      if (type === MetaDataType.DATE) {
        return new Date(value as string) as any as T;
      }
      return value;
    }
    if (options?.length) return options[0].value;
    if (type === MetaDataType.NUMBER) return 0 as any as T;
    if (type === MetaDataType.DATE) return undefined as any as T;
    return "" as any as T;
  }

  function mapMetaDataRulesToForm(
    metaDataRules: IMetaDataRule[],
    metaDataValues: MetaData = {}
  ): GenericForm<MetaDataForm> {
    const form: GenericForm<MetaDataForm> = {};
    metaDataRules.forEach((mtdRule) => {
      const options = mtdRule.item.allowedValues
        ? mtdRule.item.allowedValues.map((aw) => ({
            label: aw.value,
            value: aw.value,
          }))
        : undefined;
      form[mtdRule.item.key] = {
        name: mtdRule.item.key,
        type: metaDataItemTypeToControlType[mtdRule.item.dataType],
        options: options,
        label: mtdRule.item.translations
          ? mtdRule.item.translations[translation.language as Language]
          : "",
        defaultValue: getMetaDataRuleDefaultValue(
          metaDataValues[mtdRule.item.key]
            ? metaDataValues[mtdRule.item.key] || ""
            : mtdRule.defaultValue,
          mtdRule.item.dataType,
          options
        ),
        validators: mapMetaDataRuleValidatorsToFormValidators(mtdRule.validation),
        disabledIf: () => !mtdRule.editable,
      };
    });
    return form;
  }

  function getDocFileUrl(url: string): string {
    if (!url) return "";
    const urlSegments = url.split("/");
    const relativeUrlSegements = urlSegments.splice(3, urlSegments.length - 1);
    const uri = relativeUrlSegements.join("/");
    const apiEndpoint = BaseInstance.getUri();

    return apiEndpoint + uri;
  }

  function useCreateLocalFileUrl(downloadUrl: string, openPreview: boolean = true) {
    const {
      execute,
      loading,
      data: file,
    } = useBaseRequest(DocumentGateway.getDocumentFile, {
      autoFetch: true,
      initialPayload: getDocFileUrl(downloadUrl),
    });
    const [localUrl, setLocalUrl] = useState<string | undefined>();

    useEffect(() => {
      if (!downloadUrl) return;
      execute(getDocFileUrl(downloadUrl));
    }, [downloadUrl]);

    useEffect(() => {
      if (!file) return;
      const url = URL.createObjectURL(file);
      setLocalUrl(url);
      openPreview && window.open(url, "_blank");
      return () => {
        URL.revokeObjectURL(url);
      };
    }, [file]);

    return { loading, url: localUrl };
  }

  function removeQueryParams(url: string, paramsToRemove: string[]): string {
    const [baseUrl, queryString] = url.split("?");

    if (!queryString) {
      return url;
    }

    // Filter out the parameters to be removed from the query string
    const params = queryString.split("&");
    const newParams = params.filter((param) => {
      const [paramName] = param.split("=");
      return !paramsToRemove.includes(paramName);
    });

    // Rebuild the URL with the modified query string
    const newUrl = baseUrl + (newParams.length > 0 ? `?${newParams.join("&")}` : "");

    return newUrl;
  }

  return {
    useGetDocumentTypes,
    useGetDocumentCategories,
    useGetMetaDataForDocType,
    mapMetaDataRulesToForm,
    getDocFileUrl,
    removeQueryParams,
    useCreateLocalFileUrl,
  };
}

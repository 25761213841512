import { Modal } from "react-bootstrap";
import { Button } from "../button";
import AutocompleteOpt from "../autocomplete-opt/AutocompleteOpt";
import { IOption } from "../select";
import { useState } from "react";
import { TaskBulkMoveToAnotherBasketModalProps } from "./TaskBulkActions.types";

export function TaskBulkMoveToAnotherBasketModal({
  show,
  onSubmit,
  onCancel,
  processing,
  taskIds,
}: TaskBulkMoveToAnotherBasketModalProps) {
  const [selectedBasket, setSelectedBasket] = useState<
    IOption<number> | undefined
  >(undefined);
  const [errMsg, setErrMsg] = useState<string>("");
  return (
    <Modal show={show} animation onHide={onCancel}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          Move tasks to another Basket
        </h5>
        <div onClick={onCancel}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>Please select a basket to move selected tasks to.</p>
        <AutocompleteOpt
          label="Select basket"
          placeholder="Search basket"
          onSelectOption={(basket) => {
            !Array.isArray(basket) && setSelectedBasket(basket);
            setErrMsg("");
          }}
          limit={1000}
          minCharsToSearch={0}
          endpoint="/baskets/search-autocomplete"
        />
        <p className={"text-danger mt-2"}>{errMsg}</p>
        <div className="flex-row">
          <Button
            isLoading={processing}
            loader={{ size: "s" }}
            className="btn btn-primary mt-3 w-100"
            onClick={() => {
              typeof selectedBasket !== "undefined"
                ? onSubmit({
                    taskIds,
                    data: {
                      basketId: selectedBasket.value,
                    },
                  })
                : setErrMsg("Basket must be chosen.");
            }}
          >
            Confirm moving to another basket
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import moment from "moment";
import { CommentProps } from "./comments.types";
import { Icon } from "../../modules/icon";

const Comment = ({ createdAt, createdBy, text, togglePin, isPinned, id }: CommentProps) => {
  return (
    <div className={"flex-row gap-2"}>
      <div
        className="text-light rounded-circle d-flex justify-content-center align-items-center"
        style={{ width: "32px", height: "32px", backgroundColor: "#003366" }}
      >
        {createdBy.name[0]}
      </div>
      <div className="flex-column w-50">
        <div
          className="flex-column w-100 p-3"
          style={{ borderRadius: "16px", backgroundColor: "#e1f7ee" }}
        >
          <div className="flex-row gap-2 justify-content-between align-items-center">
            <span className="fw-bold">{createdBy.name}</span>
            {!!togglePin && (
              <Icon
                title={isPinned ? "Unpin comment" : "Pin comment"}
                type="fa-solid fa-thumbtack"
                color={isPinned ? "#3dc7a4" : "#6C757D"}
                size={16}
                onClick={() => togglePin && togglePin(id)}
              />
            )}
          </div>
          <span>{text}</span>
        </div>
        <span className="text-muted ml-3" style={{ fontSize: "14px" }}>
          {createdAt ? moment(createdAt).format("DD.MM.YYYY HH:mm") : ""}
        </span>
      </div>
    </div>
  );
};
export default Comment;

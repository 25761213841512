import { IDocumentTypeMapping } from "../../entities/IDocumentType";
import BaseInstance from "../BaseInstance";

export const DocumentTypeOCRMappingGateway = {
  getDocumentTypeOCRMapping: async (docTypeId: number): Promise<any> => {
    const query =
      `?filters=` +
      JSON.stringify({
        name: "docTypeId",
        operator: "eq",
        value: +docTypeId,
        type: "number",
      });
    return BaseInstance.get(` /document-type-mappings?${query}`);
  },
  getAllDocumentTypeOCRMapping: async (): Promise<{
    count: number;
    docTypeMappings: IDocumentTypeMapping[];
  }> => {
    return BaseInstance.get(`/document-type-mappings`);
  },
  createDocumentTypeOCRMapping: async (payload: IDocumentTypeMapping) => {
    return await BaseInstance.post(`/document-type-mappings`, {
      key: payload.key,
      docTypeId: payload.docTypeId,
    });
  },
  updateDocumentTypeOCRMapping: async (payload: IDocumentTypeMapping) => {
    return await BaseInstance.put(`/document-type-mappings/${payload.id}`, {
      key: payload.key,
    });
  },
  deleteDocumentTypeOCRMapping: async (key: number) => {
    return BaseInstance.delete(`/document-type-mappings/${key}`);
  },
};

import { Icon } from "../../modules/icon";
import { Badge } from "../badge";
import { AutoCompleteInputFormProps } from "./AutoComplete.types";

export default function AutoCompleteInputForm<T>({
  value,
  onRemove,
  inputValue,
  onInputChangeHandler,
  placeholder,
  isMultiple,
  onFocused,
}: AutoCompleteInputFormProps<T>) {
  return (
    <div className="flex-row flex-wrap gap-2 w-100">
      {value instanceof Array ? (
        value.map((item, index) => (
          <Badge
            type="light"
            key={index}
            className="fs-6 fw-normal text-dark py-0 flex-row align-items-center"
          >
            {item.label}
            <Icon
              type="fa-solid fa-times"
              color="black"
              size={16}
              onClick={() => {
                onRemove(item.value);
              }}
              className="ms-2"
            />
          </Badge>
        ))
      ) : value ? (
        <span
          className="w-100 flex-row justify-content-between align-items-center"
          style={{ paddingTop: "1px", paddingBottom: "1px" }}
        >
          {value.label}
        </span>
      ) : null}

      {(!value || isMultiple) && (
        <input
          placeholder={placeholder}
          onFocus={() => {
            onFocused && onFocused();
          }}
          value={inputValue}
          className="autocomplete-input"
          style={{
            width: "min-content",
            border: "none",
            minWidth: "min-content",
          }}
          onChange={(event) => onInputChangeHandler(event)}
        />
      )}
    </div>
  );
}

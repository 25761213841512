import { URLSearchParams } from "url";
import { QueryFilter } from "../Filters.types";

export function parseQueryStringFilters(urlParams: URLSearchParams): any[] {
  return urlParams.getAll("filters").map((item) => {
    if (!item) return null;
    const filter: QueryFilter = JSON.parse(item);
    if (filter.type === "string") {
      return filter;
    }
    if (filter.type === "number") {
      filter.value = parseFloat(filter.value);
      return filter;
    }
    filter.value = JSON.parse(filter.value);
    return filter;
  });
}

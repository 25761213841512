import { useState } from "react";
import { translation } from "./i18n";
import { Language } from "./language";
import { LanguagePickerProps } from "./LanguagePicker.types";
import { Icon } from "../../modules/icon";
import "../side-nav/SideNav.style.css";

export const LanguagePicker = ({ isOpen }: LanguagePickerProps) => {
  const onLanguageChangeClick = (language: Language) => {
    translation.changeLanguage(language);
    localStorage.setItem("language", language);
  };

  const getLanguageToDisplay = () => {
    const languages = Object.values(Language);
    const currentLanguageIndex = languages.indexOf(translation.language as Language);
    return languages[(currentLanguageIndex + 1) % languages.length];
  };

  return (
    <div
      className={`side-nav-menu-item flex-column
          ${isOpen ? "" : "justify-content-center"}`}
    >
      <div
        className="flex-row w-100  align-items-center"
        onClick={() => onLanguageChangeClick(getLanguageToDisplay())}
      >
        <div className="side-nav-menu-item-icon">
          <Icon type="fa-solid fa-globe" size={18} />
        </div>
        <span className="side-nav-menu-item-label-text">
          {getLanguageToDisplay().toUpperCase()}
        </span>
      </div>
    </div>
  );
};

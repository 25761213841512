import { useTranslation } from "react-i18next";
import "./Icon.style.css";
import { IconProps, defaultIconSize } from "./Icon.types";

export function Icon({ type, size, onClick, color, title, id, className }: IconProps) {
  const { t } = useTranslation();

  return (
    <i
      id={id}
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      className={`${type}${className ? " " + className : ""}`}
      onClick={onClick}
      title={t(title || "")}
      style={{
        fontSize: size || defaultIconSize,
        cursor: !!onClick ? "pointer" : undefined,
        color,
      }}
    />
  );
}

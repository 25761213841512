import { ChangeEvent, useEffect, useState } from "react";
import { FilterProps, NumberRangeTypeValues } from "../Filters.types";
import { useTranslation } from "react-i18next";

export const useNumberRangeFilter = (
  filter: FilterProps["filter"],
  onFilterSet: FilterProps["onFilterSet"],
  ref: any
) => {
  const {t} = useTranslation()
  const [numberRangeType, setNumberRangeType] = useState<NumberRangeTypeValues | undefined>(
    undefined
  );
  const [appliedNumberRangeType, setAppliedNumberRangeType] = useState<
    NumberRangeTypeValues | undefined
  >(undefined);
  const [showRangeValuePicker, setShowRangeValuePicker] = useState<boolean>(false);
  const [fromNumber, setFromNumber] = useState<number>(0);
  const [toNumber, setToNumber] = useState<number>(0);
  const [equalNumber, setEqualNumber] = useState<number>(0);

  useEffect(() => {
    setFromNumber(0);
    setToNumber(0);
    setEqualNumber(0);
  }, [numberRangeType]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowRangeValuePicker(false);
      }
    }

    if (showRangeValuePicker) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showRangeValuePicker]);

  const onApplyFilter = () => {
    if (!numberRangeType) return;
    setShowRangeValuePicker(false);
    setAppliedNumberRangeType(numberRangeType);
    onFilterSet(
      filter.buildQuery(
        filter,
        numberRangeType === NumberRangeTypeValues.EQUAL
          ? [equalNumber]
          : numberRangeType === NumberRangeTypeValues.GREATER_THAN
          ? [fromNumber, undefined]
          : numberRangeType === NumberRangeTypeValues.LESS_THAN
          ? [undefined, toNumber]
          : numberRangeType === NumberRangeTypeValues.BETWEEN
          ? [fromNumber, toNumber]
          : [undefined]
      ),
      filter
    );
  };

  const setTitle = () => {
    let title = t(filter.name);

    if (appliedNumberRangeType) {
      title += `: ${t("FILTERS." + appliedNumberRangeType)} `;
      const valueLabel =
        appliedNumberRangeType === NumberRangeTypeValues.EQUAL
          ? `${equalNumber}`
          : appliedNumberRangeType === NumberRangeTypeValues.GREATER_THAN
          ? `${fromNumber}`
          : appliedNumberRangeType === NumberRangeTypeValues.LESS_THAN
          ? `${toNumber}`
          : appliedNumberRangeType === NumberRangeTypeValues.BETWEEN
          ? `(${fromNumber} - ${toNumber})`
          : "";
      title += " " + valueLabel;
    }
    return title;
  };

  const title = setTitle();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const type = event.target.value as NumberRangeTypeValues;
    setNumberRangeType(type);
    setShowRangeValuePicker(true);
  };

  const onChangeFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fromNumber = +event.target.value;
    if (fromNumber && fromNumber >= 0) {
      setFromNumber(fromNumber);
    }
  };

  const onChangeTo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const toNumber = +event.target.value;
    if (toNumber && toNumber >= 0) {
      setToNumber(toNumber);
    }
  };

  const onChangeEqual = (event: React.ChangeEvent<HTMLInputElement>) => {
    const equalNumber = +event.target.value;
    if (typeof equalNumber === "number" && equalNumber >= 0) {
      setEqualNumber(equalNumber);
    }
  };

  return {
    onChange,
    onChangeFrom,
    onChangeTo,
    onChangeEqual,
    onApplyFilter,
    numberRangeType,
    showRangeValuePicker,
    fromNumber,
    toNumber,
    equalNumber,
    title,
  };
};

import moment from "moment";
import { TableRow } from "../../../components/table";
import { IDocument } from "../../../entities/IDocument";
import { Link } from "react-router-dom";
import { Icon } from "../../../modules/icon";
import { Dispatch, SetStateAction } from "react";

export const mapDocumentToTableRow = (
  doc: IDocument,
  downloadDocFile: Dispatch<SetStateAction<string>>
): TableRow => {
  return [
    doc.id,
    `${doc.metaData?.subjectNumber || ""}`,
    `${doc.metaData?.description || ""}`,
    doc.metaData?.dateCreated ? moment(doc.metaData?.dateCreated).format("DD.MM.YYYY") : "",
    doc.metaData?.dateArchived ? moment(doc.metaData?.dateArchived).format("DD.MM.YYYY") : "",
    doc.docType?.name || "",
    `${doc.metaData?.format || ""}`,
    `${doc?.metaData?.subjectStatus || ""}`,
    `${doc?.metaData?.documentStatus || ""}`,
    <>
      <Icon
        type="fa-solid fa-file"
        onClick={() => {
          if (doc.files && doc.files.length) downloadDocFile(doc.files[doc.files.length - 1].url);
        }}
        size={16}
        title={"DOCUMENTS.SEE_FILE"}
      />
      <Link to={`/documents/${doc.id}`}>
        <Icon type="fa-solid fa-circle-info" size={16} title={"DOCUMENTS.INFO_LABEL"} />
      </Link>
    </>,
  ];
};

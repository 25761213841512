import { Filter } from "../../components/filters";
import { buildDateQueryOperator } from "../../components/filters/DateRangeFilter/utility/buildDateQuery";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
import { TableHeaders } from "../../components/table";
import { CreateArchiveBookItemBulkDTO } from "../../entities/IArchiveBookItem";

export type DocumentFilterKeys = {
  "metaData.dateCreated": Filter;
  docTypeId: Filter;
  "metaData.documentCategory": Filter;
};
export const documentFilters: DocumentFilterKeys = {
  "metaData.dateCreated": {
    type: "date-range",
    name: "FILTERS.DATE_CREATED",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildDateQueryOperator(values);
      return {
        name: "metaData.dateCreated",
        type,
        operator,
        value: JSON.stringify(value),
      };
    },
  },
  docTypeId: {
    type: "multiple-choice",
    name: "FILTERS.DOCUMENT_TYPE",
    options: [],
    buildQuery: (filter, values) => {
      return {
        name: "docTypeId",
        type: "numberArray",
        operator: "in",
        value: JSON.stringify(values),
      };
    },
  },
  "metaData.documentCategory": {
    type: "multiple-choice",
    name: "FILTERS.DOCUMENT_CATEGORY",
    options: [],
    buildQuery: (filter, values) => {
      return {
        name: "metaData.documentCategory",
        type: "stringArray",
        operator: "in",
        value: JSON.stringify(values),
      };
    },
  },
};

export const documentTableHeaders: TableHeaders[] = [
  { name: "ID", key: "id", sortable: true },
  { name: "SUBJECT_NUMBER", key: "metaData.subjectNumber" },
  { name: "DESCRIPTION", key: "metaData.description" },
  { name: "DATE_CREATED", key: "metaData.dateCreated", sortable: true },
  { name: "DATE_ARCHIVED", key: "metaData.dateArchived", sortable: true },
  { name: "TYPE", key: "documentType" },
  { name: "FORMAT", key: "metaData.format" },
  { name: "SUBJECT_STATUS", key: "metaData.subjectStatus" },
  { name: "DOCUMENT_STATUS", key: "metaData.documentStatus" },
  { name: "ACTIONS", key: "action" },
];

export interface DocumentsTableProps {
  textSearch?: string;
}

export interface CreateArchiveBookItemBulkModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  onSubmit: (data: CreateArchiveBookItemBulkDTO) => void;
  creating: boolean;
  createItemForm: GenericForm<CreateArchiveBookItemBulkDTO>;
}

export const createArchiveBookItemBulkForm: GenericForm<CreateArchiveBookItemBulkDTO> = {
  serialNumber: {
    type: "input",
    name: "serialNumber",
    label: "TABLE.SERIAL_NUMBER",
    defaultValue: "0",
    validators: [GenericFormValidator.required(), GenericFormValidator.numericOnly()],
  },
  archiveBookId: {
    type: "select",
    name: "archiveBookId",
    label: "TABLE.ARCHIVE_BOOK",
    defaultValue: 0,
    options: [],
    validators: [GenericFormValidator.required()],
    props: { skipOptionTranslation: true },
  },
  docCategoryId: {
    type: "select",
    name: "docCategoryId",
    label: "TABLE.CATEGORY",
    defaultValue: 0,
    options: [],
    validators: [GenericFormValidator.required()],
    props: { skipOptionTranslation: true },
  },
  content: {
    type: "input",
    name: "content",
    label: "TABLE.CONTENT",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
  location: {
    type: "input",
    name: "location",
    label: "TABLE.LOCATION",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
  remark: {
    type: "input",
    name: "remark",
    label: "TABLE.REMARK",
    defaultValue: undefined,
    validators: [],
  },
};

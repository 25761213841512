import { CreateDocumentCategoryDTO, IDocumentCategory } from "../../../entities/IDocumentCategory";

export const processDocumentCategoryPayload = (
  payload: CreateDocumentCategoryDTO
): Omit<IDocumentCategory, "id" | "categoryListConsentNumber"> => {
  const processedPayload: Omit<IDocumentCategory, "id" | "categoryListConsentNumber"> = {
    name: payload.name,
    archivePeriod: +payload.archivePeriod,
    classificationCode: payload?.classificationCode || undefined,
  };
  if (processedPayload.archivePeriod != 0) {
    processedPayload.archiveDateStartingPoint = payload.archiveDateStartingPoint;
  }

  return processedPayload;
};

import { Filter } from "../../components/filters";
import { buildDateQueryOperator } from "../../components/filters/DateRangeFilter/utility/buildDateQuery";
import { TableHeaders } from "../../components/table";
import { InvoiceKind, InvoiceStatus } from "../sef-integration/SefIntegration.types";

export const sefReportsTableHeaders: TableHeaders[] = [
  { name: "NUMBER_OF_DOCUMENTS", key: "totalInvoicesFound" },
  { name: "TIMESTAMP", key: "createdAt", sortable: true },
  { name: "KIND", key: "requestParameters.kind" },
  { name: "STATUS", key: "(requestParamteres.status)" },
  { name: "PERIOD", key: "period" },
  { name: "ERRORS", key: "errorDetails" },
];

export type SefReportFilterKeys = {
  "requestParameters.kind": Filter;
  "requestParameters.status": Filter;
  createdAt: Filter;
  "requestParameters.periodFrom": Filter;
  "requestParameters.periodTo": Filter;
};

export const sefReportFilters: SefReportFilterKeys = {
  "requestParameters.kind": {
    type: "multiple-choice",
    name: "FILTERS.KIND",
    options: Object.keys(InvoiceKind).map((kind) => ({ label: kind, value: kind.toLowerCase() })),
    buildQuery: (filter, values) => {
      return {
        name: "requestParameters.kind",
        type: "stringArray",
        operator: "in",
        value: JSON.stringify(values),
      };
    },
  },
  "requestParameters.status": {
    type: "multiple-choice",
    name: "FILTERS.STATUS",
    options: Object.keys(InvoiceStatus).map((status) => ({ label: status, value: status })),
    buildQuery: (filter, values) => {
      return {
        name: "requestParameters.status",
        type: "stringArray",
        operator: "in",
        value: JSON.stringify(values),
      };
    },
  },
  createdAt: {
    type: "date-range",
    name: "FILTERS.DATE_CREATED",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildDateQueryOperator(values);
      return {
        name: "createdAt",
        type,
        operator,
        value: JSON.stringify(value),
      };
    },
  },
  "requestParameters.periodFrom": {
    type: "date-range",
    name: "FILTERS.PERIOD_FROM",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildDateQueryOperator(values);
      return {
        name: "requestParameters.periodFrom",
        type,
        operator,
        value: JSON.stringify(value),
      };
    },
  },
  "requestParameters.periodTo": {
    type: "date-range",
    name: "FILTERS.PERIOD_TO",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildDateQueryOperator(values);
      return {
        name: "requestParameters.periodTo",
        type,
        operator,
        value: JSON.stringify(value),
      };
    },
  },
};

import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
import { IDbEntity } from "../../entities/IDbEntity";
import { ITask, UpdateTaskDTO } from "../../entities/ITask";
import { IUser } from "../../entities/IUser";

export interface TaskStatusWithCommentModalProps {
  show: boolean;
  onSubmit: (data: TaskStatusWithCommentChangeDTO) => void;
  onDismiss: () => void;
  changing: boolean;
  taskStatusWithCommentForm: GenericForm<TaskStatusWithCommentChangeDTO>;
}
export interface TaskStatusWithCommentChangeDTO {
  id: number;
  comment: string;
}
export const taskCancelForm: GenericForm<TaskStatusWithCommentChangeDTO> = {
  comment: {
    type: "input",
    name: "comment",
    label: "TABLE.COMMENT",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
  id: {
    type: "hidden",
    visibleIf: () => false,
    name: "id",
    label: "id",
    defaultValue: 0,
    validators: [],
  },
};

export const taskPutOnHoldForm: GenericForm<TaskStatusWithCommentChangeDTO> = {
  comment: {
    type: "input",
    name: "comment",
    label: "TABLE.COMMENT",
    defaultValue: "",
    validators: [],
  },
  id: {
    type: "hidden",
    visibleIf: () => false,
    name: "id",
    label: "id",
    defaultValue: 0,
    validators: [],
  },
};

export interface TaskBadgesProps {
  task: ITask;
}

export interface TaskReassignModalProps {
  show: boolean;
  onSubmit: (data: number) => void;
  onCancel: () => void;
  reassigning: boolean;
  currentAssigneeId?: number;
  task: ITask;
  user: IUser | null;
}

export interface TaskUnassignModalProps {
  show: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  unassigning: boolean;
  task: ITask;
  user: IUser | null;
}

export interface TaskMoveToAnotherBasketModalProps {
  show: boolean;
  onSubmit: (data: number) => void;
  onCancel: () => void;
  moving: boolean;
  currentBasketId?: number;
}

export const TASK_TABS = [
  { id: 0, name: "TASKS.DETAILS_TAB", link: "details" },
  { id: 1, name: "TASKS.COMMENTS_TAB", link: "comments", counter: 0 },
  { id: 2, name: "TASKS.LOGS_TAB", link: "logs" },
];

export interface TaskOtherActionsModalProps {
  show: boolean;
  onSubmit: (status: "resolved" | "inprogress") => void;
  onCancel: () => void;
  submiting: boolean;
  action: "resolved" | "inprogress";
}

export const updateTaskForm: GenericForm<UpdateTaskDTO> = {
  description: {
    type: "textarea",
    name: "description",
    label: "TABLE.DESCRIPTION",
    defaultValue: "",
  },
  id: {
    type: "hidden",
    visibleIf: () => false,
    name: "id",
    defaultValue: -1,
    validators: [],
  },
  labels: {
    type: "autocompleteMultipleChoice",
    name: "labels",
    label: "TABLE.LABELS",
    defaultValue: [],
    options: [{ value: "labels", label: "Labels" }],
  },
  participants: {
    type: "autocompleteMultipleChoice",
    name: "participants",
    label: "TABLE.ASSIGNEES",
    defaultValue: [],
    options: [{ value: "users", label: "users" }],
  },
  processDescription: {
    type: "richTextInput",
    name: "processDescription",
    label: "TABLE.DESCRIPTION",
    defaultValue: "",
  },
};

export interface TaskActionsProps {
  onActionSelected: (action: string) => any;
  task: ITask;
  user: IUser | null;
}

export interface AddAttachmentModalProps {
  show: boolean;
  onDismiss: () => void;
  onSubmit: (data: { payload: AddTaskAttachmentDTO; onClose?: () => void }) => void;
  adding: boolean;
  addAttachmentForm: GenericForm<AddTaskAttachmentDTO>;
}

export interface ITaskAttachments extends IDbEntity {
  type: "text" | "document";
  content?: string;
  documentId?: number;
  createdAt: string;
  updatedAt: string;
}

export interface AddTaskAttachmentDTO {
  title: string;
  description?: string;
  type: "text" | "document";
  documentId?: number;
  messageId?: number;
  taskId: number;
  labels?: number[];
}

export const AddTaskAttachmentForm: GenericForm<AddTaskAttachmentDTO> = {
  documentId: {
    type: "autocompleteSingleChoice",
    name: "documentId",
    label: "TABLE.DOCUMENT",
    defaultValue: undefined,
    options: [{ value: "documents", label: "Dokument" }],
    validators: [],
  },
  messageId: {
    type: "hidden",
    name: "messageId",
    label: "messageId",
    defaultValue: undefined,
    visibleIf: () => false,
    validators: [],
  },
  title: {
    type: "input",
    name: "title",
    label: "TABLE.TITLE",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
  description: {
    type: "textarea",
    name: "content",
    label: "TABLE.DESCRIPTION",
    defaultValue: "",
    validators: [],
  },
  type: {
    type: "hidden",
    name: "type",
    label: "TABLE.TYPE",
    defaultValue: "document",
    visibleIf: () => false,
    validators: [GenericFormValidator.required()],
  },
  taskId: {
    type: "hidden",
    name: "taskId",
    label: "taskId",
    defaultValue: -1,
    visibleIf: () => false,
    validators: [GenericFormValidator.required()],
  },
  labels: {
    type: "autocompleteMultipleChoice",
    name: "labels",
    label: "TABLE.LABELS",
    defaultValue: undefined,
    options: [{ value: "labels", label: "Labels" }],
    validators: [],
  },
};
export interface UpdateAttachmentModalProps {
  show: boolean;
  onDismiss: () => void;
  onSubmit: (data: UpdateTaskAttachmentDTO) => void;
  editing: boolean;
  updateAttachmentForm: GenericForm<UpdateTaskAttachmentDTO>;
}

export interface UpdateTaskAttachmentDTO {
  title: string;
  description?: string;
  id: number;
  labels?: number[];
}

export interface ExecuteTaskAttachmentActionDTO {
  id: number;
  action: "approve" | "reject";
}

export const UpdateTaskAttachmentForm: GenericForm<UpdateTaskAttachmentDTO> = {
  title: {
    type: "input",
    name: "title",
    label: "TABLE.TITLE",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
  description: {
    type: "textarea",
    name: "content",
    label: "TABLE.DESCRIPTION",
    defaultValue: "",
    validators: [],
  },
  id: {
    type: "hidden",
    name: "id",
    label: "ID",
    defaultValue: -1,
    visibleIf: () => false,
    validators: [GenericFormValidator.required()],
  },
  labels: {
    type: "autocompleteMultipleChoice",
    name: "labels",
    label: "TABLE.LABELS",
    defaultValue: undefined,
    options: [{ value: "labels", label: "Labels" }],
    validators: [],
  },
};

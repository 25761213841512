import { useEffect, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import { useToast } from "../../components/toast";
import TaskGateway from "../../api/gateways/TaskGateway";
import { useOutletContext, useParams } from "react-router-dom";
import {
  taskAddCommentForm as taskAddCommentFormDefault,
  ITaskComment,
} from "../../components/comments/comments.types";
import { AddTaskAttachmentForm } from "./Task.types";
import { useCounterService } from "../../services/useCounterService";
import { useTranslation } from "react-i18next";

const useTaskComments = () => {
  const { success, danger } = useToast();
  const id = +(useParams<{ id: string }>().id || -1);
  const { refreshCommentsCount } = useCounterService();
  const { t } = useTranslation();
  const { pinnedComments, refetchTask } = useOutletContext<{
    pinnedComments: { messageId: number; elementId: number }[];
    refetchTask: () => void;
  }>();
  useEffect(() => {
    id && getComments(id);
  }, [id]);

  const { execute: addCommentToTask, loading: addingComment } = useBaseRequest(
    TaskGateway.addCommentToTask,
    {
      onCompleted: (data) => {
        success(t("TASKS.COMMENT_ADDED_MESSAGE"));
        getComments(id);
        setShowCommentForm(false);
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const taskAddCommentForm = {
    ...taskAddCommentFormDefault,
    taskId: {
      ...taskAddCommentFormDefault.taskId,
      defaultValue: id || -1,
    },
  };

  const [showCommentForm, setShowCommentForm] = useState<boolean>(false);

  const [comments, setComments] = useState<ITaskComment[]>([]);
  const { execute: getComments, loading: loadingComments } = useBaseRequest(
    TaskGateway.getCommentsByTaskId,
    {
      onCompleted: (data) => {
        setComments(data.comments);
        refreshCommentsCount(id);
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const [showMoreComments, setShowMoreComments] = useState<boolean>(false);
  const showCommentFormHandler = () => {
    setShowCommentForm(true);
  };
  const hideCommentFormHandler = () => {
    setShowCommentForm(false);
  };

  const { execute: deleteAttachment, loading: deletingAttachment } = useBaseRequest(
    TaskGateway.deleteAttachment,
    {
      onCompleted: (data) => {
        success(t("TASKS.ATTACHMENT_DELETED_MESSAGE"));
        refetchTask();
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const { execute: addAttachment, loading: addingAttachment } = useBaseRequest(
    TaskGateway.addAttachmentToTask,
    {
      onCompleted: (data) => {
        success(t("TASKS.ATTACHMENT_ADDED_MESSAGE"));
        dismissAttachmentForm();
        refetchTask();
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const [messageId, setMessageId] = useState<number | undefined>();
  const addAttachmentForm = {
    ...AddTaskAttachmentForm,
    taskId: {
      ...AddTaskAttachmentForm.taskId,
      defaultValue: id,
    },
    messageId: {
      ...AddTaskAttachmentForm.messageId,
      defaultValue: messageId ? messageId : undefined,
    },
    type: {
      ...AddTaskAttachmentForm.type,
      defaultValue: "text",
    },
    documentId: {
      ...AddTaskAttachmentForm.documentId,
      type: "hidden",
    },
  };
  const dismissAttachmentForm = () => {
    setMessageId(undefined);
  };

  const togglePin = (id: number) => {
    const comment = pinnedComments.find((c) => c.messageId === id);
    if (!comment) {
      setMessageId(id);
      return;
    }
    deleteAttachment(comment.elementId);
  };

  return {
    addCommentToTask,
    addingComment,
    taskAddCommentForm,
    showCommentFormHandler,
    hideCommentFormHandler,
    showCommentForm,
    comments: comments
      .slice(0, showMoreComments ? comments.length : 3)
      .map((c) => ({ ...c, isPinned: !!pinnedComments.find((p) => p.messageId === c.id) })),
    loadingComments,
    showMoreComments,
    setShowMoreComments,
    togglePin,
    showAttachmentForm: messageId !== undefined,
    dismissAttachmentForm,
    addAttachment,
    addingAttachment,
    addAttachmentForm,
  };
};

export default useTaskComments;

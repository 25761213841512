import React from "react";
import { Filter, QueryFilter } from "./Filters.types";

export function useFilters(
  filters: Filter[],
  onFilterChange: (queryFilters: QueryFilter[]) => any
) {
  const [selected, setSelected] = React.useState<Filter[]>([]);
  const [queryFilters, setQueryFilters] = React.useState<{ name: string; query: QueryFilter }[]>(
    []
  );

  // When, for example, filter options update, selected filter be updated accordingly
  React.useEffect(() => {
    const updatedSelected = filters.filter((filter) =>
      selected.find((s) => (s.name === filter.name))
    );

    setSelected(updatedSelected);
  }, [filters]);

  const onAddFilter = (filter: Filter) => {
    if (!selected.find((s) => s.name === filter.name)) {
      setSelected([...selected, filter]);
    }
  };

  const onAddDefaultFilters = (filters: Filter[]) => {
    setSelected([...filters]);
    setQueryFilters(
      filters.map((f) => ({
        name: f.name,
        query: f.buildQuery(f, f.defaultValues as any),
      }))
    );
  };

  const onRemoveFilter = (filter: Filter) => {
    setSelected(selected.filter((sl) => sl.name !== filter.name));
    if (
      queryFilters.find((f) => {
        return f.name === filter.name;
      })
    ) {
      const newQuerFilters = queryFilters.filter((qf) => {
        return qf.name !== filter.name;
      });
      setQueryFilters(newQuerFilters);
      onFilterChange(newQuerFilters.map((nqf) => nqf.query));
    }
  };

  const onSet = (queryFilter: QueryFilter, filter: Filter) => {
    let newFilters = [];
    if (
      !!queryFilters.find((qf) => {
        return qf.query.name === queryFilter.name;
      })
    ) {
      newFilters = queryFilters
        .filter((qf) => {
          return qf.query.name !== queryFilter.name;
        })
        .concat([{ name: filter.name, query: queryFilter }]);
    } else {
      newFilters = queryFilters.concat([{ name: filter.name, query: queryFilter }]);
    }
    setQueryFilters(newFilters);
    onFilterChange(newFilters.map((nf) => nf.query));
  };

  return { selected, onAddFilter, onRemoveFilter, onSet, onAddDefaultFilters };
}

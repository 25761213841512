import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface IKeyValue {
  key: string;
  value: string;
}
type FilterState = {
  pages: IKeyValue[];
};

const initialState: FilterState = {
  pages: [
    { key: "/documents", value: "" },
    { key: "/archive", value: "" },
    // {
    //   key: "/baskets/:id",
    //   value:
    //     `?filters=` +
    //     JSON.stringify({
    //       name: "status",
    //       type: "stringArray",
    //       operator: "in",
    //       value: JSON.stringify(["open"]),
    //     }),
    // },
    // { key: "/baskets-rules", value: "" },
    // { key: "/tasks", value: "" },
    // {
    //   key: "/my-tasks",
    //   value:
    //     `?filters=` +
    //     JSON.stringify({
    //       name: "status",
    //       type: "stringArray",
    //       operator: "in",
    //       value: JSON.stringify(["onhold", "assigned", "inprogress"]),
    //     }),
    // },
    // { key: "/task-stats", value: "" },
    // { key: "/archive-stats", value: "" },
    // { key: "/reports", value: "" },
  ],
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilterByPage: (
      state: FilterState,
      action: PayloadAction<{ page: string; filter: string }>
    ) => {
      const { page, filter } = action.payload;
      const index = state.pages.findIndex(
        (f) => f.key === page.replace(/\d+/g, ":id")
      );
      if (index !== -1) {
        state.pages[index].value = filter ? "?" + filter : "";
      }
    },
  },
});

const persistConfig: PersistConfig<FilterState> = {
  key: "filter",
  storage,
};

export const filterActions = filterSlice.actions;

export default persistReducer(persistConfig, filterSlice.reducer);

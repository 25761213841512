import { DocumentMetaDataPreviewProps } from "../document-form/DocumentForm.types";
import { FormItem } from "./FormItem";

export const FormPreview = ({ metaDataForm }: DocumentMetaDataPreviewProps) => {
  const formFields = metaDataForm
    ? Object.keys(metaDataForm).map((key: any) => {
        const field = metaDataForm[key];
        return {
          label: field.label || "",
          value: field.defaultValue ? field.defaultValue.toString() : "",
          type: field.type,
        };
      })
    : [];

  return (
    <div className={"flex-column gap-2 w-100"}>
      {formFields.map((field, i) => (
        <FormItem key={i} {...field} />
      ))}
    </div>
  );
};

import { useEffect, useMemo, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import { useToast } from "../../components/toast";
import { ITask, TaskStatuses } from "../../entities/ITask";
import TaskGateway from "../../api/gateways/TaskGateway";
import { useParams } from "react-router-dom";
import {
  taskCancelForm as taskCancelFormDefault,
  taskPutOnHoldForm as taskPutOnHoldFormDefault,
} from "./Task.types";
import { useCounterService } from "../../services/useCounterService";
import { useUser } from "../../store/auth/AuthSelectors";
import { useTranslation } from "react-i18next";

const useTask = () => {
  const { success, danger } = useToast();
  const id = +(useParams<{ id: string }>().id || -1);
  const [task, setTask] = useState<ITask>();
  const user = useUser();
  const { refreshTaskCounters, refreshCommentsCount } = useCounterService();
  const { t } = useTranslation();
  const { execute: getData, loading } = useBaseRequest(TaskGateway.getTaskById, {
    onCompleted: (data) => {
      setTask(data);
      refreshTaskCounters();
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });
  useEffect(() => {
    getData(id);
    refreshCommentsCount(id);
  }, [id]);

  const [selectedAction, setSelectedAction] = useState<string>("");
  const onChangeAction = (action: string) => {
    setSelectedAction(action);
  };

  const { execute: cancelTask, loading: cancelingTask } = useBaseRequest(
    TaskGateway.cancelTaskById,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        refreshCommentsCount(id);
        success(t("TASKS.CANCELED_MESSAGE"));
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const taskCancelForm = {
    ...taskCancelFormDefault,
    id: { ...taskCancelFormDefault.id, defaultValue: task?.id || -1 },
  };

  const { execute: reassignTask, loading: reassigningTask } = useBaseRequest(
    TaskGateway.reassignTaskById,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        success(t("TASKS.REASSIGNED_MESSAGE"));
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );
  const reassignTaskHandler = (userId: number) => {
    reassignTask({ id, userId });
  };

  const { execute: moveToAnotherBasket, loading: movingTask } = useBaseRequest(
    TaskGateway.moveToAnotherBasket,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        success(t("TASKS.MOVED_MESSAGE"));
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const { execute: changeStatus, loading: changingStatus } = useBaseRequest(
    TaskGateway.changeStatus,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        success(t("TASKS.STATUS_CHANGED_MESSAGE"));
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );
  const moveToAnotherBasketHandler = (basketId: number) => {
    moveToAnotherBasket({ id, basketId });
  };

  const changeStatusHandler = (status: "resolved" | "inprogress") => {
    changeStatus({ id, status });
  };

  const { execute: unassignTask, loading: unassigning } = useBaseRequest(
    TaskGateway.unassignTaskById,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        success(t("TASKS.UNASSIGNED_MESSAGE"));
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );
  const { execute: putOnHold, loading: puttingOnHold } = useBaseRequest(
    TaskGateway.onHoldTaskById,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        refreshCommentsCount(id);
        success(t("TASKS.PUT_ON_HOLD_MESSAGE"));
      },
    }
  );
  const { commentCounter } = useCounterService();

  const unassignTaskHandler = () => {
    unassignTask(id);
  };

  const taskPutOnHoldForm = {
    ...taskPutOnHoldFormDefault,
    id: { ...taskPutOnHoldFormDefault.id, defaultValue: task?.id || -1 },
  };

  const taskTabs = useMemo(() => {
    return [
      { id: 0, name: t("TASKS.DETAILS_TAB"), link: "details" },
      {
        id: 1,
        name: t("TASKS.COMMENTS_TAB"),
        link: "comments",
        counter: commentCounter,
      },
      { id: 2, name: t("TASKS.LOGS_TAB"), link: "logs" },
    ];
  }, [commentCounter]);

  const shouldPermitBOExpertToManageTask = () => {
    /*if (task?.assignee && task?.assignee?.id !== user?.id) {
      return true;
    }*/
    if (task?.status === TaskStatuses.CANCELED && !task?.participants?.length) {
      return true;
    }
    return false;
  };

  const { execute: resolveTask, loading: resolvingTask } = useBaseRequest(
    TaskGateway.resolveTaskById,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        refreshCommentsCount(id);
        success(t("TASKS.RESOLVED_MESSAGE"));
      },
    }
  );

  const taskResolvedForm = {
    ...taskPutOnHoldFormDefault,
    id: { ...taskPutOnHoldFormDefault.id, defaultValue: task?.id || -1 },
  };

  const taskAttachments = task?.sections
    .sort((a, b) => b.id - a.id)
    .map((section) => section.elements)
    .flat();
  const pinnedComments =
    taskAttachments
      ?.filter((attachment) => !!attachment.message)
      .map((attachment) => ({ messageId: attachment.message?.id, elementId: attachment.id })) || [];

  return {
    task,
    taskId: id,
    loading,
    onChangeAction,
    selectedAction,
    cancelingTask,
    cancelTask,
    taskCancelForm,
    reassignTask: reassignTaskHandler,
    reassigningTask,
    movingTask,
    moveToAnotherBasket: moveToAnotherBasketHandler,
    changingStatus,
    changeStatus: changeStatusHandler,
    unassignTask: unassignTaskHandler,
    unassigning,
    putOnHold,
    puttingOnHold,
    taskPutOnHoldForm,
    taskTabs,
    user,
    shouldPermitBOExpertToManageTask,
    resolveTask,
    resolvingTask,
    taskResolvedForm,
    refetchTask: () => getData(id),
    pinnedComments,
    taskAttachments,
  };
};

export default useTask;

import { Modal } from "react-bootstrap";
import { Button } from "../../components/button";
import AutocompleteOpt from "../../components/autocomplete-opt/AutocompleteOpt";
import { IOption } from "../../components/select";
import { useState } from "react";
import { TaskBulkReassingModalProps } from "./TaskBulkActions.types";

export function TaskBulkReassignModal({
  show,
  onSubmit,
  onCancel,
  processing,
  taskIds,
}: TaskBulkReassingModalProps) {
  const [selectedUser, setSelectedUser] = useState<IOption<number> | undefined>(undefined);
  const [errMsg, setErrMsg] = useState<string>("");
  return (
    <Modal show={show} animation onHide={onCancel}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          Reassign Tasks
        </h5>
        <div onClick={onCancel}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>
          In order to reassign selected tasks you need to select an assignee. You can search user
          using his name.
        </p>
        <AutocompleteOpt
          label="Select user"
          placeholder="Search user"
          onSelectOption={(user) => {
            !Array.isArray(user) && setSelectedUser(user);
            setErrMsg("");
          }}
          endpoint="/users/search-autocomplete"
        />
        <p className={"text-danger mt-2"}>{errMsg}</p>
        <div className="flex-row">
          <Button
            isLoading={processing}
            loader={{ size: "s" }}
            className="btn btn-primary mt-3 w-100"
            onClick={() => {
              typeof selectedUser !== "undefined"
                ? onSubmit({
                    taskIds,
                    data: {
                      userId: selectedUser.value,
                    },
                  })
                : setErrMsg("User must be chosen.");
            }}
          >
            Confirm reassigning tasks
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import moment from "moment";
import { ChangeEvent } from "react";
import { Password } from "../password";
import { AutocompleteType, GenericFormControlProps } from "./GenericForm.types";
import { CheckBox } from "../checkbox";
import AutocompleteOpt from "../autocomplete-opt/AutocompleteOpt";
import { IOption } from "../select";
import { AutocompleteOptProps } from "../autocomplete-opt/AutocompleteOpt.types";
import { Datepicker } from "../datepicker";
import { useTranslation } from "react-i18next";
import { FormArray } from "./FormArray";
import ColorPicker from "../colorpicker";
import "@rc-component/color-picker/assets/index.css";
import { RichTextInput } from "../../modules/rich-text-input";

const autocompleteMultiple = (
  type: AutocompleteType,
  placeholder: string,
  selectedOptions: any[] | [],
  onSelectOption: (options: IOption<any>[] | undefined) => void,
  filters?: string
): AutocompleteOptProps => {
  return {
    label: "",
    placeholder,
    isMultiple: true,
    defaultValue: selectedOptions.map((item) =>
      ["document-types", "users"].includes(type) ? item : +item
    ),
    onSelectOption: (options: IOption<any>[] | IOption<any> | undefined) => {
      if (Array.isArray(options) || typeof options === "undefined") {
        onSelectOption(options);
      }
    },
    limit: type === "baskets" ? 1000 : undefined,
    minCharsToSearch: type === "baskets" ? 0 : undefined,
    endpoint: `/${type}/search-autocomplete`,
    showRemoveAll: false,
    filters,
  };
};

const autocompleteSingle = (
  type: AutocompleteType,
  placeholder: string,
  selectedOption: any,
  onSelectOption: (options: IOption<any> | undefined) => void,
  filters?: string
): AutocompleteOptProps => {
  return {
    label: "",
    placeholder,
    isMultiple: false,
    defaultValue: selectedOption,
    onSelectOption: (options: IOption<any>[] | IOption<any> | undefined) => {
      if (!Array.isArray(options) || typeof options === "undefined") {
        onSelectOption(options);
      }
    },
    limit: type === "baskets" ? 1000 : undefined,
    minCharsToSearch: type === "baskets" ? 0 : undefined,
    endpoint: `/${type}/search-autocomplete`,
    showRemoveAll: false,
    filters,
  };
};

export function GenericFormControl<T, P>({
  control,
  isTouched,
  isSubmitted,
  error,
  value,
  onChange,
  formState,
  props,
}: GenericFormControlProps<T, P>) {
  const { t } = useTranslation();

  function onInputChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = event.target.value;
    onChange(value);
  }

  function onSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value;
    onChange(value);
  }

  function onDateChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    onChange(value ? new Date(value) : null);
  }

  function onCheckedChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.checked;
    onChange(value);
  }

  function onMultipleSelectChange(values: IOption<any>[] | IOption<any> | undefined) {
    if (Array.isArray(values)) onChange(values.map((item) => item.value));
  }

  function onSingleSelectChange(values: IOption<any>[] | IOption<any> | undefined) {
    if (!Array.isArray(values)) onChange(values?.value);
  }

  function renderControl() {
    switch (control.type) {
      case "input":
        return (
          <input
            className={`form-control${
              error && isTouched && isSubmitted
                ? " is-invalid"
                : !error && isTouched && value && isSubmitted
                ? " is-valid"
                : ""
            }`}
            value={value as any}
            onChange={onInputChange}
            disabled={!!control.disabledIf && control.disabledIf(formState) ? true : false}
          />
        );
      case "textarea":
        return (
          <textarea
            className={`form-control${
              error && isTouched && isSubmitted
                ? " is-invalid"
                : !error && isTouched && value && isSubmitted
                ? " is-valid"
                : ""
            }`}
            value={value as any}
            onChange={onInputChange}
            disabled={!!control.disabledIf && control.disabledIf(formState) ? true : false}
          />
        );
      case "password":
        return (
          <Password
            value={value as any}
            onChange={onInputChange}
            className={`form-control${error && isTouched && isSubmitted ? " is-invalid" : ""} ${
              !error && isTouched && value && isSubmitted ? "is-valid" : ""
            }}`}
          />
        );
      case "select":
        return (
          <select
            className={`form-select${error && isTouched && isSubmitted ? " is-invalid" : ""} ${
              !error && isTouched && isSubmitted ? "is-valid" : ""
            }}`}
            value={value as any}
            onChange={onSelectChange}
            disabled={!!control.disabledIf && control.disabledIf(formState) ? true : false}
          >
            {control.options
              ? control.options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {props?.skipOptionTranslation ? option.label : t(option.label)}
                  </option>
                ))
              : null}
          </select>
        );
      case "date":
        return (
          <Datepicker
            onChange={onDateChange}
            className={`form-control ${error && isTouched && isSubmitted ? " is-invalid" : ""} ${
              !error && isTouched && isSubmitted && value ? " is-valid" : ""
            }`}
            initialValue={value ? moment(value).toDate() : undefined}
            disabled={!!control.disabledIf && control.disabledIf(formState) ? true : false}
          />
        );
      case "checkbox":
        return (
          <CheckBox
            scale={1.5}
            checked={value as any}
            onChange={onCheckedChange}
            disabled={!!control.disabledIf && control.disabledIf(formState) ? true : false}
          />
        );
      case "hidden":
        return <></>;
      case "autocompleteMultipleChoice":
        const searchItemMultiple = (
          control.options ? control.options[0].value : "users"
        ) as AutocompleteType;
        const searchItemMultipleText = t(`FILTERS.SEARCH_${searchItemMultiple.toUpperCase()}`);

        return (
          <AutocompleteOpt
            {...autocompleteMultiple(
              (control.options ? control.options[0].value : "users") as AutocompleteType,
              searchItemMultipleText,
              (value as any[]) || [],
              onMultipleSelectChange,
              control.filters
            )}
          />
        );
      case "autocompleteSingleChoice":
        const searchItemSingle = (
          control.options ? control.options[0].value : "users"
        ) as AutocompleteType;
        const searchItemSingleText = t(`FILTERS.SEARCH_${searchItemSingle.toUpperCase()}`);
        return (
          <AutocompleteOpt
            {...autocompleteSingle(
              (control.options ? control.options[0].value : "users") as AutocompleteType,
              searchItemSingleText,
              value as any,
              onSingleSelectChange,
              control.filters
            )}
          />
        );

      case "formArray":
        return (
          <FormArray
            control={control}
            isTouched={isTouched}
            isSubmitted={isSubmitted}
            error={error}
            value={value}
            onChange={onChange}
            formState={formState}
          />
        );
      case "color":
        return (
          <ColorPicker
            className={`form-control${
              error && isTouched && isSubmitted
                ? " is-invalid"
                : !error && isTouched && value && isSubmitted
                ? " is-valid"
                : ""
            }`}
            value={value as string}
            onChange={onChange}
            disabled={!!control.disabledIf && control.disabledIf(formState) ? true : false}
          />
        );
      case "richTextInput":
        return (
          <RichTextInput
            className={`form-control${
              error && isTouched && isSubmitted
                ? " is-invalid"
                : !error && isTouched && value && isSubmitted
                ? " is-valid"
                : ""
            }`}
            value={value as string}
            onChange={onChange}
          />
        );
      default:
        console.warn("Unsupported data type! Please consider adding custom FormControl");
        return <></>;
    }
  }
  return renderControl();
}

import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";

export const sefIntegrationForm: GenericForm<any> = {
  kind: {
    type: "select",
    name: "kind",
    label: "SEF_SYNC.KIND_LABEL",
    defaultValue: "",
    options: [
      { label: "SEF_SYNC.SELECT_KIND", value: "" },
      { label: "SEF_SYNC.PURCHASE", value: "purchase" },
      { label: "SEF_SYNC.SALES", value: "sales" },
    ],
    validators: [GenericFormValidator.required()],
  },
  status: {
    type: "select",
    name: "status",
    label: "SEF_SYNC.STATUS_LABEL",
    defaultValue: "",
    options: [
      { label: "SEF_SYNC.SELECT_STATUS", value: "" },
      { label: "SEF_SYNC.SEEN", value: "Seen" },
      { label: "SEF_SYNC.APPROVED", value: "Approved" },
      { label: "SEF_SYNC.REJECTED", value: "Rejected" },
      { label: "SEF_SYNC.STORNO", value: "Storno" },
      { label: "SEF_SYNC.SENT", value: "Sent" },
      { label: "SEF_SYNC.SENDING", value: "Sending" },
    ],
    validators: [GenericFormValidator.required()],
  },
  dateFrom: {
    type: "date",
    name: "dateFrom",
    label: "SEF_SYNC.DATE_FROM",
    defaultValue: new Date(),
    validators: [GenericFormValidator.required()],
  },
  dateTo: {
    type: "date",
    name: "dateTo",
    label: "SEF_SYNC.DATE_TO",
    defaultValue: new Date(),
    validators: [GenericFormValidator.required()],
  },
};

export interface ISyncSefInvoicesRequest {
  kind?: InvoiceKind;
  status?: InvoiceStatus;
  dateFrom?: Date;
  dateTo?: Date;
}

export interface ISyncSefInvoicesResponse {
  triggerType: SefCollectionTriggerType;
  createdById: string;
  requestParameters: { [key: string]: string | boolean | number | Date };
  totalInvoicesFound: number;
  importedDocumentsIds?: number[];
  errorDetails?: ISefErrorDetail[];
}

export enum SefCollectionTriggerType {
  MANUAL = "manual",
  SCHEDULED = "schedule",
}

export interface ISefErrorDetail {
  purchaseInvoiceId?: number;
  salesInvoiceId?: number;
  error: string;
}

export enum InvoiceKind {
  Purchase = "purchase",
  Sales = "sales",
}

export enum InvoiceStatus {
  Seen = "Seen",
  Approved = "Approved",
  Rejected = "Rejected",
  Storno = "Storno",
  Sent = "Sent",
  Sending = "Sending",
}

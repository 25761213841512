import { Modal } from "react-bootstrap";
import { Button } from "../../components/button";
import { useTranslation } from "react-i18next";
import { GenericForm } from "../../components/generic-form";
import { AddAttachmentModalProps } from "./Task.types";
import { FileUpload } from "../../modules/file-upload";
import { NewDocumentScreen } from "../new-document";
import { useAddAtachment } from "./useAddAtachment";

export const AddAttachmentModal = ({
  show,
  onDismiss,
  onSubmit,
  addAttachmentForm,
  adding,
}: AddAttachmentModalProps) => {
  const { t } = useTranslation();
  const {
    onDismissHandler,
    onSubmitHandler,
    addExistingDocument,
    newDocumentID,
    setAddExistingDocument,
    onUpload,
    addAttachmentFormUpdated,
  } = useAddAtachment(onDismiss, addAttachmentForm, onSubmit);
  return (
    <Modal fullscreen={true} show={show} animation onHide={onDismissHandler}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {t("TASKS.ADD_ATTACHMENT")}
        </h5>
        <div onClick={onDismissHandler}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body className="flex-column gap-2">
        {addExistingDocument ? (
          <GenericForm
            form={addAttachmentFormUpdated as any}
            onSubmit={onSubmitHandler}
            submitControl={(submit) => (
              <div className="flex-row">
                <Button
                  isLoading={adding}
                  loader={{ size: "s" }}
                  className="btn btn-primary mt-3 w-100"
                  type="submit"
                >
                  {t("CREATE_BUTTON")}
                </Button>
              </div>
            )}
          />
        ) : typeof addExistingDocument === "undefined" ? (
          <>
            <Button
              className="btn btn-primary"
              onClick={() => {
                setAddExistingDocument(true);
              }}
            >
              {t("TASKS.ATTACH_EXISTING_DOCUMENT")}
            </Button>
            <Button
              className="btn btn-dark"
              onClick={() => {
                setAddExistingDocument(false);
              }}
            >
              {t("TASKS.ATTACH_NEW_DOCUMENT")}
            </Button>
          </>
        ) : (
          <>
            {newDocumentID ? (
              <NewDocumentScreen
                id={newDocumentID.toString()}
                closeDocumentModal={(isDocumentCreated) => {
                  isDocumentCreated
                    ? setAddExistingDocument(true)
                    : setAddExistingDocument(undefined);
                }}
              />
            ) : (
              <FileUpload maxFiles={1} onUpload={onUpload} />
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

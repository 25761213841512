import { Navigate } from "react-router-dom";
import { ConfigurationGuardProps } from "./Guard.types";

export function ConfigurationGuard({ isEnabled, children, fallback }: ConfigurationGuardProps) {
  return isEnabled ? (
    <>{children}</>
  ) : fallback.kind === "redirect" ? (
    <Navigate to={fallback.path} replace />
  ) : fallback.kind === "replacement" ? (
    fallback.replacementElement
  ) : null;
}

import { Filter } from "../../components/filters";
import { buildDateQueryOperator } from "../../components/filters/DateRangeFilter/utility/buildDateQuery";
import { buildNumberRangeQueryOperator } from "../../components/filters/NumberRangeFilter/utils/buildNumberRangeQuery";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
import { TableHeaders } from "../../components/table";
import { CreateArchiveBookItemDTO } from "../../entities/IArchiveBookItem";

export const archiveBookItemTableHeaders: TableHeaders[] = [
  { name: "SERIAL_NUMBER", key: "serialNumber", sortable: true },
  { name: "ENTRY_DATE", key: "entryDate" },
  { name: "CONTENT", key: "content" },
  { name: "PERIOD", key: "period" },
  { name: "CATEGORY", key: "category.name" },
  { name: "CLASSIFICATION_CODE", key: "category.classificationCode" },
  { name: "STORAGE_PERIOD", key: "category.archivePeriod" },
  { name: "APPROVAL_ID", key: "category.categoryListConsentNumber" },
  { name: "AMOUNT_OF_DOCUMENTS", key: "amountOfDocuments" },
  { name: "LOCATION", key: "location" },
  { name: "REMARK", key: "remark" },
  { name: "ACTIONS", key: "action" },
];

export type ArchiveBookItemFilterKeys = {
  entryDate: Filter;
  periodFrom: Filter;
  periodTo: Filter;
  amountOfDocuments: Filter;
  docCategoryId: Filter;
};

export const createArchiveBookItemForm: GenericForm<CreateArchiveBookItemDTO> = {
  serialNumber: {
    type: "input",
    name: "serialNumber",
    label: "TABLE.SERIAL_NUMBER",
    defaultValue: "0",
    validators: [GenericFormValidator.required(), GenericFormValidator.numericOnly()],
  },
  docCategoryId: {
    type: "select",
    name: "docCategoryId",
    label: "TABLE.CATEGORY",
    defaultValue: 0,
    options: [],
    validators: [GenericFormValidator.required()],
    props: { skipOptionTranslation: true },
  },
  content: {
    type: "input",
    name: "content",
    label: "TABLE.CONTENT",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
  location: {
    type: "input",
    name: "location",
    label: "TABLE.LOCATION",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
  periodFrom: {
    type: "date",
    name: "periodFrom",
    label: "TABLE.PERIOD_FROM",
    defaultValue: new Date(),
    validators: [GenericFormValidator.required()],
  },
  periodTo: {
    type: "date",
    name: "periodTo",
    label: "TABLE.PERIOD_TO",
    defaultValue: new Date(),
    validators: [GenericFormValidator.required()],
  },
  remark: {
    type: "input",
    name: "remark",
    label: "TABLE.REMARK",
    defaultValue: undefined,
    validators: [],
  },
};

export const archiveBookItemFilters: ArchiveBookItemFilterKeys = {
  amountOfDocuments: {
    type: "number-range",
    name: "FILTERS.AMOUNT_OF_DOCUMENTS",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildNumberRangeQueryOperator(values);
      return {
        name: "amountOfDocuments",
        type,
        operator,
        value: JSON.stringify(value),
      };
    },
  },
  entryDate: {
    type: "date-range",
    name: "FILTERS.ENTRY_DATE",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildDateQueryOperator(values);
      return {
        name: "entryDate",
        type,
        operator,
        value: JSON.stringify(value),
      };
    },
  },
  periodFrom: {
    type: "date-range",
    name: "FILTERS.PERIOD_FROM",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildDateQueryOperator(values);
      return {
        name: "periodFrom",
        type,
        operator,
        value: JSON.stringify(value),
      };
    },
  },
  periodTo: {
    type: "date-range",
    name: "FILTERS.PERIOD_TO",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildDateQueryOperator(values);
      return {
        name: "periodTo",
        type,
        operator,
        value: JSON.stringify(value),
      };
    },
  },
  docCategoryId: {
    type: "multiple-choice",
    name: "FILTERS.DOCUMENT_CATEGORY",
    options: [],
    buildQuery: (filter, values) => {
      return {
        name: "docCategoryId",
        type: "numberArray",
        operator: "in",
        value: JSON.stringify(values),
      };
    },
  },
};

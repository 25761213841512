import moment from "moment";
import { Badge } from "../../components/badge";
import { TaskBadgesProps } from "./Task.types";
import { useTranslation } from "react-i18next";

const TaskBadges = ({ task }: TaskBadgesProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex-row gap-1 flex-wrap mt-2">
      <Badge variant="outlined" type="primary" className="fs-6 fw-normal">
        {t("TABLE.BASKET")}: {task.basket.name}
      </Badge>
      <Badge variant="outlined" type="dark" className="fs-6 fw-normal">
        {t("TABLE.CREATED_BY")} {task.createdBy.name}
      </Badge>
      <Badge variant="outlined" type="secondary" className="fs-6 fw-normal">
        {t("TABLE.DATE_CREATED")} {moment(task.createdAt).format("DD.MM.YYYY")}
      </Badge>
    </div>
  );
};

export default TaskBadges;

import { Button } from "../../components/button";
import { PageLayout } from "../../components/page-layout";
import { Icon } from "../../modules/icon";
import { DraftDocuments } from "./DraftDocuments";
import { useDraftDocumentsSession } from "./useDraftDocumentsSession";
import { UploadDocumentModal } from "./upload-document-modal/UploadDocumentModal";
import { useTranslation } from "react-i18next";
import { DialogModal } from "../../components/modal/DialogModal";

export const UploadDocumentsSessionScreen = () => {
  const { t } = useTranslation();
  const {
    onDismiss,
    onDismissDocument,
    draftDocuments,
    onDismissSession,
    onAdd,
    viewId,
    onView,
    onCancelDocumentUpload,
    showDismissSessionModal,
    onCancelDismissSession,
    setShowDismissSessionModal,
    showDismissDocumentModal,
    onCancelDismissDocument,
  } = useDraftDocumentsSession();
  return (
    <PageLayout
      title={t("UPLOAD.SESSION")}
      titleRight={
        <div className="flex-row w-100 justify-content-end mr-4">
          <Button
            type="button"
            className="btn btn-danger mr-3 fw-medium"
            onClick={() => {
              setShowDismissSessionModal(true);
            }}
          >
            <Icon type={`fa-solid fa-trash`} />
            &nbsp;&nbsp;{t("UPLOAD.DISMISS")}
          </Button>
        </div>
      }
      backBtn
    >
      <DraftDocuments
        onDismiss={onDismiss}
        draftDocuments={draftDocuments}
        onAdd={onAdd}
        onView={onView}
      />
      <UploadDocumentModal id={viewId} onCancel={onCancelDocumentUpload} onDismiss={onDismiss} />
      <DialogModal
        show={showDismissDocumentModal}
        onCancel={onCancelDismissDocument}
        onAccept={onDismissDocument}
        acceptLabel="YES"
        cancelLabel="NO"
        description="UPLOAD.DISMISS_LABEL"
        title="UPLOAD.DISMISS"
      />
      <DialogModal
        show={showDismissSessionModal}
        onCancel={onCancelDismissSession}
        onAccept={onDismissSession}
        title="UPLOAD.DISMISS_PREVIOUS"
        description="UPLOAD.DISMISS_LABEL"
        cancelLabel="NO"
        acceptLabel="YES"
      />
    </PageLayout>
  );
};

import { IEnvironment } from "./environment";

export const devEnv: IEnvironment = {
  env: "development",
  api: {
    protocol: "https",
    host: "dev.api.aurora.codebehind.tech",
  },
  oidc: {
    clientId: "240728055608311810@auroradms",
    issuerEndpoint: "https://dev.auth.aurora.codebehind.tech",
    loginCallbackUrl: "https://dev.aurora.codebehind.tech/code",
    authorizationEndpoint: "https://dev.auth.aurora.codebehind.tech/oauth/v2/authorize",
    keyListEndpoint: "https://dev.auth.aurora.codebehind.tech/oauth/v2/keys",
    userInfoEndpoint: "https://dev.auth.aurora.codebehind.tech/oidc/v1/userinfo",
    roleFieldName: "urn:zitadel:iam:org:project:240702449919197186:roles",
    endSessionEndpoint: "https://dev.auth.aurora.codebehind.tech/oidc/v1/end_session",
  },
  modulesEnabled: {
    archive: true,
    digitalSignature: true,
    tasks: true,
    sefProvider: true,
    emailListenerProvider: true,
    networkShareProvider: true,
    ocr: true,
  },
};

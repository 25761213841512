import { useEffect, useMemo, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import DocumentTypeGateway from "../../api/gateways/DocumentTypeGateway";
import { IDocumentType, IRelatedDocumentType } from "../../entities/IDocumentType";
import { linkDocumentTypesForm } from "./DocumentTypes.types";
import { IOption } from "../../components/select";
import { useTranslation } from "react-i18next";
import { useToast } from "../../components/toast";
import { mapDocumentTypeLinkToTableRow } from "./utils/mapDocumentTypeLinkToTableRow";

export const useLinkedDocumentType = (selectedItem: IDocumentType | null) => {
  const { t } = useTranslation();
  const { success, danger } = useToast();
  const { execute, loading: linkingDocumentTypes } = useBaseRequest(
    DocumentTypeGateway.linkDocumentTypes,
    {
      onCompleted: () => {
        success(t("DOCUMENT_TYPE.LINKED_DOCUMENT_TYPE_ADDED"));
        selectedItem?.id && getDocumentTypeLinks(selectedItem?.id);
        onHideLinkDocumentTypesModal();
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const [linkedDocumentType, setLinkedDocumentType] = useState<IOption<number> | undefined>();
  const onSetLinkedDocumentType = (data: IOption<number>) => {
    setLinkedDocumentType(data);
  };

  useEffect(() => {
    if (linkedDocumentType) {
      getDocumentTypeMetaData(linkedDocumentType.value);
    }
  }, [linkedDocumentType]);

  useEffect(() => {
    if (selectedItem?.id) {
      getDocumentTypeLinks(selectedItem.id);
    }
  }, [selectedItem]);

  const { execute: getDocumentTypeMetaData } = useBaseRequest(
    DocumentTypeGateway.getDocumentTypeMetaDataById,
    {
      onCompleted: (data: any) => {
        if (data)
          setTargetCustomFields(
            data.map((item: any) => ({ value: item.item.id, label: item.item.name }))
          );
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const [targetCustomFields, setTargetCustomFields] = useState<IOption<number>[]>([]);

  const linkDocumentTypesFormUpdated = useMemo(() => {
    return {
      ...linkDocumentTypesForm,
      sourceDocumentTypeId: {
        ...linkDocumentTypesForm.sourceDocumentTypeId,
        defaultValue: selectedItem?.id,
      },
      initiatorItemId: {
        ...linkDocumentTypesForm.initiatorItemId,
        options:
          selectedItem?.customFields && linkDocumentTypesForm.initiatorItemId?.options
            ? [
                ...linkDocumentTypesForm.initiatorItemId.options,
                ...selectedItem.customFields,
                { value: "", label: t("SELECT_OPTION") },
              ]
            : undefined,
      },
      targetDocumentTypeId: {
        ...linkDocumentTypesForm.targetDocumentTypeId,
        defaultValue: linkedDocumentType?.value || -1,
        // filter out the selected document type from the list of available document types
      },
      responderItemId: {
        ...linkDocumentTypesForm.responderItemId,
        options:
          targetCustomFields && linkDocumentTypesForm.responderItemId?.options
            ? [
                ...linkDocumentTypesForm.responderItemId.options,
                ...targetCustomFields,
                { value: "", label: t("SELECT_OPTION") },
              ]
            : undefined,
      },
    };
  }, [linkedDocumentType, selectedItem, targetCustomFields]);

  const [deleteLinkId, setDeleteLinkId] = useState<number | null>(null);
  const hideDeleteDocTypeModalHandler = () => {
    setDeleteLinkId(null);
  };
  const onDeleteLinkedDocumentType = (id: number) => {
    setDeleteLinkId(id);
  };

  const { execute: deleteLinkedDocumentType, loading: deletingLinkedDocumentType } = useBaseRequest(
    DocumentTypeGateway.deleteDocumentTypeLink,
    {
      onCompleted: () => {
        success(t("DOCUMENT_TYPE.LINKED_DOCUMENT_TYPE_DELETED"));
        hideDeleteDocTypeModalHandler();
        selectedItem?.id && getDocumentTypeLinks(selectedItem?.id);
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
        hideDeleteDocTypeModalHandler();
      },
    }
  );

  const deleteLinkedDocumentTypeHandler = () => {
    if (deleteLinkId) {
      deleteLinkedDocumentType({
        relationId: deleteLinkId,
      });
    }
  };

  const [linkedDocuments, setLinkedDocuments] = useState<IRelatedDocumentType[]>([]);
  const { execute: getDocumentTypeLinks } = useBaseRequest(
    DocumentTypeGateway.getDocumentTypeLinksByDocumentTypeId,
    {
      onCompleted: (data) => {
        setLinkedDocuments(data.relatedDocumentTypes);
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const [showLinkDocumentTypesModal, setShowLinkDocumentTypesModal] = useState(false);
  const onShowLinkDocumentTypesModal = () => {
    setShowLinkDocumentTypesModal(true);
  };
  const onHideLinkDocumentTypesModal = () => {
    setShowLinkDocumentTypesModal(false);
    setLinkedDocumentType(undefined);
  };

  return {
    linkedDocumentTypeForm: linkDocumentTypesFormUpdated,
    linkedDocuments:
      linkedDocuments?.map((item) =>
        mapDocumentTypeLinkToTableRow(item, onDeleteLinkedDocumentType)
      ) || [],
    linkDocumentType: execute,
    onSetLinkedDocumentType,
    linkingDocumentTypes,
    linkedDocumentType,
    deleteLinkId,
    deleteLinkedDocumentType,
    deletingLinkedDocumentType,
    hideDeleteDocTypeModalHandler,
    onDeleteLinkedDocumentType,
    deleteLinkedDocumentTypeHandler,
    onShowLinkDocumentTypesModal,
    onHideLinkDocumentTypesModal,
    showLinkDocumentTypesModal,
  };
};

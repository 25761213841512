import React from "react";
import { Icon } from "../../modules/icon";
import { PasswordProps } from "./Password.types";
import "./Password.style.css";

export function Password({
  className,
  value,
  onChange,
  placeHolder,
  iconColor,
}: PasswordProps) {
  const [visible, setVisible] = React.useState(false);
  const toggleVisible = () => {
    setVisible(!visible);
  };
  return (
    <div className="input-group">
      <input
        type={visible ? "text" : "password"}
        className={className}
        placeholder={placeHolder}
        aria-describedby="password-icon"
        value={value}
        onChange={onChange}
      />
      <div className="input-group-append">
        <span
          className="input-group-text h-100 eye-icon-right"
          id="password-icon"
        >
          <Icon
            type={visible ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"}
            onClick={toggleVisible}
            size={16}
            color={iconColor}
            title={visible ? "Hide password" : "Show password"}
          />
        </span>
      </div>
    </div>
  );
}

import { GetReportsResponse } from "../../entities/IReport";
import BaseInstance from "../BaseInstance";

const ReportGateway = {
  getReports: async (query: string): Promise<GetReportsResponse> => {
    return BaseInstance.get(`/tasks/report${query}`);
  },

  exportReports: async (query: string): Promise<string> => {
    return BaseInstance.get(`/tasks/export-report${query}`);
  },
};

export default ReportGateway;

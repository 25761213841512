import { Modal } from "react-bootstrap";
import { TaskStatusWithCommentModalProps } from "./Task.types";
import { Button } from "../../components/button";
import { GenericForm } from "../../components/generic-form";
import { useTranslation } from "react-i18next";

export function TaskResolvedModal({
  show,
  onSubmit,
  onDismiss,
  changing,
  taskStatusWithCommentForm,
}: TaskStatusWithCommentModalProps) {
  const { t } = useTranslation();
  return (
    <Modal show={show} animation onHide={onDismiss}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {t("TASKS.RESOLVE_TITLE")}
        </h5>
        <div onClick={onDismiss}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <GenericForm
          form={taskStatusWithCommentForm}
          onSubmit={onSubmit}
          submitControl={(submit) => (
            <div className="flex-row">
              <Button
                isLoading={changing}
                loader={{ size: "s" }}
                className="btn btn-primary mt-3 w-100"
                type="submit"
              >
                {t("APPLY")}
              </Button>
            </div>
          )}
        />
      </Modal.Body>
    </Modal>
  );
}

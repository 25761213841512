import React from "react";
import { PageLayout } from "../../components/page-layout";
import { Button } from "../../components/button";
import { useSplitFile } from "./useSplitFile";
import PageContainer from "./PageContainer";
import { ViewPageModal } from "./ViewPageModal";
import { RangeInput } from "../../components/range-input";
import { useTranslation } from "react-i18next";
import { DialogModal } from "../../components/modal/DialogModal";

export const SplitFileScreen: React.FC = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    pages,
    showDeleteModal,
    hideDeleteModal,
    showPageModal,
    hidePageModal,
    showDismissModal,
    hideDismissModal,
    showModal,
    onDeletePage,
    onAddSeparator,
    onRemoveSeparator,
    onResizePages,
    onSubmit,
    divisionCount,
    pagesPerRow,
    onDismiss,
    uploadingDocuments,
  } = useSplitFile();

  const getPageNum = () =>
    showModal ? pages.find((p) => p.id === showModal?.id)?.pageNumber || 0 : 0;

  return (
    <PageLayout
      title={t("UPLOAD.SPLIT_TITLE")}
      backBtn={showDismissModal}
      titleRight={
        <div className="text-right">
          <div className="d-flex justify-content-end gap-2 align-items-center">
            <RangeInput
              label={t("UPLOAD.RESIZE_PAGES")}
              defaultValue={5}
              min={1}
              max={10}
              step={1}
              onChange={onResizePages}
              disabled={isLoading}
            />
            <Button
              className="btn btn-dark"
              onClick={onSubmit}
              loader={{ size: "s" }}
              isLoading={uploadingDocuments}
            >
              {divisionCount > 1
                ? `${t("UPLOAD.SPLIT_INTO")} ${divisionCount} ${
                    divisionCount < 5 ? t("UPLOAD.INTO_FILES") : t("UPLOAD.INTO_FILES_PLURAL")
                  }`
                : t("UPLOAD.PROCEED_WITHOUT_SPLITTING")}
            </Button>
          </div>
        </div>
      }
    >
      <PageContainer
        isLoading={isLoading}
        pages={pages}
        seePage={showPageModal}
        addSeparator={onAddSeparator}
        removeSeparator={onRemoveSeparator}
        deletePage={showDeleteModal}
        pagesPerRow={pagesPerRow}
      />
      <DialogModal
        show={showModal?.action === "dismiss"}
        onCancel={hideDismissModal}
        onAccept={onDismiss}
        acceptLabel="YES"
        cancelLabel="NO"
        description="UPLOAD.DISMISS_LABEL"
        title="UPLOAD.DISMISS"
      />
      {!!pages.length && (
        <>
          <ViewPageModal
            show={showModal?.action === "see"}
            onClose={hidePageModal}
            page={pages && pages.find((p) => p.id === showModal?.id)}
          />
          <DialogModal
            show={showModal?.action === "delete"}
            onCancel={hideDeleteModal}
            onAccept={onDeletePage}
            cancelLabel="NO"
            acceptLabel="YES"
            title="UPLOAD.DELETE_PAGE"
            description={{ raw: t("UPLOAD.DELETE_PAGE_LABEL", { pageNum: getPageNum() }) }}
          />
        </>
      )}
    </PageLayout>
  );
};

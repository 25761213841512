import { useTranslation } from "react-i18next";
import { Button } from "../../components/button";
import { GenericForm } from "../../components/generic-form";
import { Column, Row } from "../../components/grid";
import Header from "../../components/header/Header";
import { PageLayout } from "../../components/page-layout";
import { useSefIntegration } from "./useSefIntegration";
import { sefIntegrationForm } from "./SefIntegration.types";

export function SefIntegrationScreen() {
  const { t } = useTranslation();
  const { title, syncing, onSubmit } = useSefIntegration();
  return (
    <PageLayout title={title}>
      <Row>
        <Column className="bg-white p-3 rounded-2">
          <div className="mb-2">
            <Header title={t("SEF_SYNC.AUTOMATIC_TITLE")} size="h4" />
          </div>
          <p>{t("SEF_SYNC.AUTOMATIC_LABEL")}</p>
        </Column>
      </Row>
      <Row>
        <Column className="bg-white p-3 rounded-2 mt-2">
          <div className="mb-2">
            <Header title={t("SEF_SYNC.MANUAL_TITLE")} size="h4" />
          </div>
          <GenericForm
            form={sefIntegrationForm}
            onSubmit={onSubmit}
            submitControl={(submit) => (
              <div className="flex-row">
                <Button
                  isLoading={syncing}
                  loader={{ size: "s" }}
                  className="btn btn-dark mt-3 w-100"
                  type="submit"
                >
                  {t("SEF_SYNC.CREATE_LABEL")}
                </Button>
              </div>
            )}
          />
        </Column>
      </Row>
    </PageLayout>
  );
}

import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "./Table";
import { TableWithQueryPaginationAndSortProps, itemsPerPageOptions } from "./Table.types";

export const TableWithQueryPaginationAndSort = ({
  headers = [],
  data = [],
  loading,
  selection,
  onRowPress,
  totalItems,
  defaultSort,
}: TableWithQueryPaginationAndSortProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialItemsPerPage = queryParams.get("limit")
    ? +(queryParams.get("limit") as string)
    : itemsPerPageOptions[0];
  const getInitialPage = () => {
    const limit = +(queryParams.get("limit") as string) || 10;
    const skip = +(queryParams.get("skip") as string);
    return Math.floor(skip / limit) + 1;
  };
  const onItemsPerPageChange = (itemsPerPage: number) => {
    queryParams.delete("limit");
    queryParams.set("limit", itemsPerPage.toString());
    navigate({ search: queryParams.toString() });
  };
  const onPageChange = (page: number) => {
    if (
      queryParams.get("skip") ===
        (page > 0 ? (page - 1) * +(queryParams.get("limit") || 10) : 10).toString() ||
      (queryParams.get("skip") === null && page === 1)
    )
      return;
    queryParams.delete("skip");
    queryParams.set(
      "skip",
      (page > 0 ? (page - 1) * +(queryParams.get("limit") || 10) : 10).toString()
    );
    navigate({ search: queryParams.toString() });
  };
  const onSort = (key: string, order: "DESC" | "ASC") => {
    queryParams.delete("sort");
    queryParams.set("sort", JSON.stringify({ key, value: order }));
    navigate({ search: queryParams.toString() });
  };

  const onClearSort = () => {
    queryParams.delete("sort");
    navigate({ search: queryParams.toString() });
  };

  return (
    <Table
      headers={headers}
      data={data}
      loading={loading}
      selection={selection}
      onRowPress={onRowPress}
      pagination={{
        totalItems,
        onItemsPerPageChange,
        onPageChange,
        initialPage: getInitialPage(),
        initialItemsPerPage,
      }}
      sort={{
        onSort,
        ...defaultSort,
        activeSort: queryParams.get("sort")
          ? JSON.parse(queryParams.get("sort") as string).key
          : defaultSort?.key,
        activeOrder: queryParams.get("sort")
          ? JSON.parse(queryParams.get("sort") as string).value
          : defaultSort?.order,
        onClearSort,
      }}
    />
  );
};

import moment from "moment";
import { ISyncSefInvoicesRequest } from "../SefIntegration.types";

export const processSyncSefInvoicesPayload = (
  payload: ISyncSefInvoicesRequest
): ISyncSefInvoicesRequest => {
  return {
    ...payload,
    dateFrom: moment(payload.dateFrom).startOf("day").toDate(),
    dateTo: moment(payload.dateTo).endOf("day").toDate(),
  };
};

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { documentFilters as documentDefaultFilters, mapDocumentToTableRow } from "../documents";
import useBaseRequest from "../../api/BaseRequest";
import SefGateway from "../../api/gateways/SefGateway";
import { buildFilterForSingleReport } from "./utils/buildFilterForSingleReport";
import { useEffect, useState } from "react";
import { TableRow } from "../../components/table";
import { useDocumentService } from "../../services/useDocumentService";
import moment from "moment";
import { screens } from "../Navigation.types";
import { useTranslation } from "react-i18next";

export const useSefReportOverview = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [docIds, setDocIds] = useState<number[]>([]);
  const [title, setTitle] = useState<string>("");
  const { getDocFileUrl, useCreateLocalFileUrl } = useDocumentService();
  const [reportDocsTable, setReportDocsTable] = useState<TableRow[]>([]);
  const [reportDocsCount, setReportDocsCount] = useState(0);

  useEffect(() => {
    getReport("");
  }, [id]);

  useEffect(() => {
    if (docIds.length) {
      getReportDocuments(location.search || "");
    }
  }, [docIds, location.search]);

  // filters
  const {
    "metaData.documentCategory": categories,
    docTypeId,
    ...sefReportOverviewFilters
  } = documentDefaultFilters;

  // requests
  const { execute: getReport, loading: fetchingReport } = useBaseRequest(
    (query: string) => SefGateway.getReports("?" + buildFilterForSingleReport(+(id as string))),
    {
      onCompleted: (data) => {
        const report = data.reports[0];
        const docIds = report.importedDocumentsIds || [];
        setDocIds(docIds);
        const title = ` ${report.id.toString()} (${moment(
          report.requestParameters["dateFrom"] as Date
        ).format("DD.MM.YYYY")} - ${moment(report.requestParameters["dateTo"] as Date).format(
          "DD.MM.YYYY"
        )})`;
        setTitle(title);
      },
    }
  );
  const [downloadUrl, setDownloadUrl] = useState<string>("");
  const { url } = useCreateLocalFileUrl(downloadUrl, true);
  const { execute: getReportDocuments, loading: fetchingDocumets } = useBaseRequest(
    (query: string) => SefGateway.getReportDocuments(docIds, ""),
    {
      onCompleted: (data) => {
        setReportDocsCount(data.count);
        setReportDocsTable(data.documents.map((doc) => mapDocumentToTableRow(doc, setDownloadUrl)));
      },
    }
  );

  const goToDocument = (id: number) => {
    navigate(screens.documents + `/${id}`);
  };

  return {
    title: t("SEF_REPORT_OVERVIEW.TITLE") + title,
    reportDocsCount,
    reportDocsTable,
    sefReportOverviewFilters,
    fetchingDocumets: fetchingDocumets || fetchingReport,
    goToDocument,
  };
};

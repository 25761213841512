import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../../components/toast";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import useBaseRequest from "../../api/BaseRequest";
import { screens } from "../Navigation.types";
import { getPagesFromFile } from "./utility/getPagesForFile";
import { ISplitPage, initialColor } from "./SplitFile.types";
import { usePDF } from "../../modules/pdf/usePDF";
import { getPdfsForBulk } from "./utility/getPdfsForBulk";
import { changePageDivisions } from "./utility/changePageDivisions";
import { useTranslation } from "react-i18next";

export function useSplitFile() {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [pages, setPages] = useState<ISplitPage[]>([]);
  const navigate = useNavigate();
  const [divisionCount, setDivisionCount] = useState<number>(0);
  const { splitIntoPages, groupIntoSingle, splitIntoImagePromises } = usePDF();
  const { danger } = useToast();
  const file = useLocation().state?.file;

  const { execute: createDocumentSession, loading: creatingSession } = useBaseRequest(
    DocumentGateway.createDocumentSession,
    {
      onCompleted: (data) => {
        if (!data) return;
        navigate(screens.uploadSession.replace(":sessionId", data.id.toString()), {
          replace: true,
        });
      },
      onError: (error) => {
        danger(error.message);
      },
    }
  );

  const { execute: uploadSingleDocument, loading: uploadingSingleDocument } = useBaseRequest(
    DocumentGateway.createDocument,
    {
      onCompleted: (data) => {
        if (!data) return;
        navigate(screens.newDocument.replace(":id", data.id.toString()), {
          replace: true,
        });
      },
      onError: (error) => {
        danger(error.message);
      },
    }
  );

  // Start loader when session starts
  useEffect(() => {
    if (creatingSession) {
      setIsLoading(creatingSession);
    }
  }, [creatingSession]);

  // When file is loaded, seperate it into pages
  useEffect(() => {
    if (file) {
      getPagesFromFile(file, splitIntoImagePromises, splitIntoPages, danger, t).then((pages) => {
        setPages(pages as ISplitPage[]);
        setIsLoading(false);
      });
    }
  }, [file]);

  // If division count is back to 1, change color on all pages to default
  useEffect(() => {
    if (divisionCount === 1) {
      const newPages = pages.map((page) => {
        page.color = initialColor;
        return page;
      });
      setPages(newPages);
    }
  }, [divisionCount]);

  useEffect(() => {
    const newDivisionCount =
      pages.reduce((acc, curr) => acc + (curr.isMarkedToSplitAfter ? 1 : 0), 0) + 1;
    if (newDivisionCount != divisionCount) {
      setDivisionCount(newDivisionCount);
    }
  }, [pages]);

  const onDeletePage = () => {
    // Check if there is only one page, if so, exit early
    if (pages.length === 1) {
      setPages([]);
      navigate(-1);
      return;
    }

    if (showModal) {
      const { id } = showModal;
      const deletePageIndex = pages.findIndex((page) => page.id === id);

      if (deletePageIndex === -1) {
        // Page not found
        hideDeleteModal();
        return;
      }

      const newPages = [...pages];

      // Remove the page to delete from the array
      const deletePage = newPages.splice(deletePageIndex, 1)[0];

      // Check and update isMarkedToSplitAfter for the page before the deleted page
      if (deletePage.isMarkedToSplitAfter && deletePage.pageNumber > 1) {
        newPages[deletePage.pageNumber - 2].isMarkedToSplitAfter = true;
      }

      if (deletePage.pageNumber === pages.length) {
        newPages[newPages.length - 1].isMarkedToSplitAfter = false;
      }
      let colorIndex = 0;

      // Iterate through the updated pages array, adjusting page number and color
      const updatedPages = newPages.map((page, index) => {
        const newPage = { ...page };
        newPage.pageNumber = index + 1; // Update page number
        newPage.color = newPages[colorIndex].color; // Update color
        if (page.isMarkedToSplitAfter) {
          colorIndex = index + 1;
        }
        return newPage;
      });

      // Update the state with the updated pages and divisionCount
      setPages(updatedPages);
      hideDeleteModal();
    }
  };

  const onAddSeparator = (pageId: number) => {
    let newPages = changePageDivisions(pages, pageId, true);
    setPages(newPages);
  };
  const onRemoveSeparator = (pageId: number) => {
    let newPages = changePageDivisions(pages, pageId, false);
    setPages(newPages);
  };

  const [pagesPerRow, setPagesPerRow] = useState<number>(5);
  const onResizePages = (rowSize: number) => {
    setPagesPerRow(rowSize);
  };

  const [showModal, setShowModal] = useState<{
    id: number;
    action: "delete" | "see" | "dismiss";
  } | null>(null);
  const showPageModal = (pageId: number) => {
    setShowModal({ id: pageId, action: "see" });
  };
  const hidePageModal = () => {
    setShowModal(null);
  };
  const showDeleteModal = (pageId: number) => {
    setShowModal({ id: pageId, action: "delete" });
  };
  const hideDeleteModal = () => {
    setShowModal(null);
  };
  const showDismissModal = () => {
    setShowModal({ id: -1, action: "dismiss" });
  };
  const hideDismissModal = () => {
    setShowModal(null);
  };

  const onSubmit = async () => {
    if (divisionCount === 1) {
      uploadSingleDocument({ file: file as File, draft: true });
      return;
    }
    const files = await getPdfsForBulk(pages, groupIntoSingle, danger);
    createDocumentSession({ files });
  };

  const onDismiss = () => {
    hideDismissModal();
    navigate(-1);
  };

  return {
    isLoading,
    pages,
    showDeleteModal,
    hideDeleteModal,
    showPageModal,
    hidePageModal,
    showDismissModal,
    hideDismissModal,
    showModal,
    onDeletePage,
    onAddSeparator,
    onRemoveSeparator,
    onResizePages,
    onSubmit,
    onDismiss,
    divisionCount,
    pagesPerRow,
    uploadingDocuments: creatingSession || uploadingSingleDocument,
  };
}

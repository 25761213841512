import { DataType } from "../../Filters.types";

export function buildNumberRangeQueryOperator(
  values: string[] | Date[] | number[] | (undefined | string | number | Date)[]
) {
  let value = null;
  let operator = "between";
  let type: DataType = "numberArray";

  if (values.length && values.length === 1 && values[0] != undefined) {
    operator = "eq";
    value = values[0];
    type = "number";
  }
  if (values.length && values.length > 1 && values[0] === undefined && values[1] != undefined) {
    operator = "lt";
    value = values[1];
    type = "number";
  }
  if (values.length && values.length > 1 && values[1] === undefined && values[0] != undefined) {
    operator = "gt";
    value = values[0];
    type = "number";
  }

  return { value: value != undefined ? value : values, operator, type };
}

import { TabProps } from "./Tabs.props";

export function Tab({ label, onClick, isActive, counter }: TabProps) {
  return (
    <h6
      style={{
        borderRadius: "4px",
        margin: 0,
        padding: "8px",
        cursor: "pointer",
        alignItems: "center",
      }}
      className={`cursor-pointer flex-row gap-2 text-bold ${isActive ? "active" : "text-muted"} `}
      onClick={onClick}
    >
      {label}
      {!!counter && (
        <span
          style={{
            padding: "2px 6px",
            borderRadius: "4px",
            fontSize: "14px",
            backgroundColor: isActive ? "#003366" : "#e1f7ee",
            color: isActive ? "#ffffff" : "#00264d",
          }}
        >
          {counter}
        </span>
      )}
    </h6>
  );
}

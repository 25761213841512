import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Loader } from "../../modules/loader";
import { useLogin } from "../login/useLogin";

export const CodeCallbackScreen = () => {
  const location = useLocation();
  const { logIn, onLoginError } = useLogin();

  useEffect(() => {
    const code = new URLSearchParams(location.search).get("code");
    const error = new URLSearchParams(location.search).get("error");

    if (error) {
      onLoginError();
    }
    if (code) {
      logIn(code);
    }
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center mt-5">
        <h3>Contacting OIDC provider</h3>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <Loader size="l" color="black" />
      </div>
    </>
  );
};

import { ISplitPage, colors } from "../SplitFile.types";

const getNextColor = (currentColor: string) => {
  const nextColorIndex = (colors.indexOf(currentColor) + 1) % colors.length;
  return colors[nextColorIndex];
};

// change colors and split marks on page array
export const changePageDivisions = (
  pages: ISplitPage[],
  pageId: number,
  isSeparator: boolean
) => {
  let currentColor = colors[0];
  return pages.map((page) => {
    page.color = currentColor;
    if (page.id === pageId) {
      page.isMarkedToSplitAfter = isSeparator;
    }
    if (page.isMarkedToSplitAfter) {
      currentColor = getNextColor(currentColor);
    }
    return page;
  });
};

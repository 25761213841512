import { useState } from "react";

export const useConfiguration = () => {
  const [showModal, setShowModal] = useState<"provider" | undefined>();
  return {
    modalToShow: showModal,
    showModal: (modal: "provider") => setShowModal(modal),
    dismissModal: () => setShowModal(undefined),
  };
};

import { ChangeEvent, useEffect, useState } from "react";
import { DateRangeTypeValues } from "../Filters.types";
import { setDateRangeValuesByType } from "./utility/setDateRange";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const useStandaloneDateRangeFilter = (
  onSet: (value: { fromDate?: Date; toDate?: Date; value: DateRangeTypeValues }) => void,
  defaultValues: {
    value?: DateRangeTypeValues | undefined;
    fromDate?: Date | undefined;
    toDate?: Date | undefined;
  } | null,
  ref: any,
  label: string
) => {
  const { t } = useTranslation();
  const [dateRangeType, setDateRangeType] = useState<DateRangeTypeValues | undefined>(
    defaultValues?.value
  );
  const [appliedDateRangeType, setAppliedDateRangeType] = useState<DateRangeTypeValues | undefined>(
    defaultValues?.value
  );

  const [fromDate, setFromDate] = useState<Date | undefined>(defaultValues?.fromDate);
  const [toDate, setToDate] = useState<Date | undefined>(defaultValues?.toDate);
  const [fromAppliedDate, setFromAppliedDate] = useState<Date | undefined>(defaultValues?.fromDate);
  const [toAppliedDate, setToAppliedDate] = useState<Date | undefined>(defaultValues?.toDate);
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const type = event.target.value as DateRangeTypeValues;
    setDateRangeType(type);
    setFromDate(setDateRangeValuesByType(type)[0]);
    setToDate(setDateRangeValuesByType(type)[1]);
    if (
      [DateRangeTypeValues.FROM, DateRangeTypeValues.TO, DateRangeTypeValues.BETWEEN].includes(type)
    )
      setShowCustomDateRange(true);
    else setShowCustomDateRange(false);
  };

  const [showCustomDateRange, setShowCustomDateRange] = useState<boolean>(false);

  const onApplyFilter = () => {
    if (!dateRangeType) return;
    setShowCustomDateRange(false);
    setAppliedDateRangeType(dateRangeType);
    const fromAppliedDate = moment(fromDate).isValid()
      ? moment(fromDate).toDate()
      : moment(toDate).isValid()
      ? moment(toDate).startOf("day").toDate()
      : moment().startOf("day").toDate();
    const toAppliedDate = moment(toDate).isValid()
      ? moment(toDate).toDate()
      : moment(fromDate).isValid()
      ? moment(fromDate).endOf("day").toDate()
      : moment().endOf("day").toDate();
    setFromAppliedDate(fromAppliedDate);
    setToAppliedDate(toAppliedDate);
    onSet(
      dateRangeType !== DateRangeTypeValues.FROM && dateRangeType !== DateRangeTypeValues.TO
        ? {
            fromDate: fromAppliedDate,
            toDate: toAppliedDate,
            value: dateRangeType,
          }
        : dateRangeType === DateRangeTypeValues.FROM
        ? {
            fromDate: fromAppliedDate,
            toDate: undefined,
            value: dateRangeType,
          }
        : {
            fromDate: undefined,
            toDate: toAppliedDate,
            value: dateRangeType,
          }
    );
  };

  const onShowDateRangeTypes = (show: boolean) => {
    if (show) {
      setDateRangeType(appliedDateRangeType);
      if (
        [DateRangeTypeValues.FROM, DateRangeTypeValues.TO, DateRangeTypeValues.BETWEEN].includes(
          appliedDateRangeType || DateRangeTypeValues.TODAY
        )
      )
        setShowCustomDateRange(true);
    }
    if (!show) setShowCustomDateRange(false);
  };

  const onChangeFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value ? new Date(event.target.value) : undefined;
    if (dateRangeType === DateRangeTypeValues.FROM) {
      setFromDate(selectedDate);
      return;
    }
    moment(toDate).isValid() &&
      moment(selectedDate).isSameOrBefore(toDate) &&
      setFromDate(selectedDate);
    moment(toDate).isValid() && !moment(selectedDate).isSameOrBefore(toDate) && setFromDate(toDate);
  };

  const onChangeTo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value ? new Date(event.target.value) : undefined;
    if (dateRangeType === DateRangeTypeValues.TO) {
      setToDate(selectedDate);
      return;
    }
    moment(fromDate).isValid() &&
      moment(selectedDate).isSameOrAfter(fromDate) &&
      setToDate(selectedDate);
    moment(fromDate).isValid() &&
      !moment(selectedDate).isSameOrAfter(fromDate) &&
      setToDate(fromDate);
  };

  const setTitle = () => {
    let title = "";
    if (appliedDateRangeType) {
      title += `${t("FILTERS." + appliedDateRangeType)} `;
      if (
        DateRangeTypeValues.FROM !== appliedDateRangeType &&
        DateRangeTypeValues.TO !== appliedDateRangeType &&
        DateRangeTypeValues.TODAY !== appliedDateRangeType &&
        DateRangeTypeValues.YESTERDAY !== appliedDateRangeType
      ) {
        title += `(${fromAppliedDate ? moment(fromAppliedDate).format("DD.MM.YYYY") : "To"} ${
          !fromAppliedDate ? "" : "-"
        } ${moment(toAppliedDate).format("DD.MM.YYYY")})`;
      }
      if (
        DateRangeTypeValues.TODAY === appliedDateRangeType ||
        DateRangeTypeValues.YESTERDAY === appliedDateRangeType
      ) {
        title += `(${moment(fromAppliedDate).format("DD.MM.YYYY")})`;
      }
      if (DateRangeTypeValues.FROM === appliedDateRangeType) {
        title += `${moment(fromAppliedDate).format("DD.MM.YYYY")}`;
      }
      if (DateRangeTypeValues.TO === appliedDateRangeType) {
        title += `${moment(toAppliedDate).format("DD.MM.YYYY")}`;
      }
    }
    return title;
  };

  const title = setTitle();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowCustomDateRange(false);
      }
    }

    if (showCustomDateRange) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showCustomDateRange]);

  return {
    onChange,
    onApplyFilter,
    dateRangeType,
    appliedDateRangeType,
    fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "YYYY-MM-DD",
    toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : "YYYY-MM-DD",
    onShowDateRangeTypes,
    title,
    onChangeFrom,
    onChangeTo,
    showCustomDateRange,
  };
};

import { IDbEntity } from "./IDbEntity";
import { IDocumentType } from "./IDocumentType";

export interface IDocumentCategory extends IDbEntity {
  name: string;
  archivePeriod: number;
  classificationCode?: string;
  archiveDateStartingPoint?: ArchiveDateStartingPoint;
  categoryListConsentNumber: string;
  docTypes?: IDocumentType[];
}

export interface CreateDocumentCategoryDTO {
  name: string;
  classificationCode?: string;
  // Field is a numeric string, so it can binded to an input
  archivePeriod: string;
  // Always have value for archiveDateStartingPoint and then omit it if archivePeriod is zero
  archiveDateStartingPoint: ArchiveDateStartingPoint;
}

export enum ArchiveDateStartingPoint {
  LEASE_END = "LEASE_END",
  CASE_COMPLETION = "CASE_COMPLETION",
  EXPERT_OPINION_EXPIRY = "EXPERT_OPINION_EXPIRY",
  HAZARDOUS_SUBSTANCE_CESSATION_USE = "HAZARDOUS_SUBSTANCE_CESSATION_USE",
  DOCUMENT_ENTERED = "DOCUMENT_ENTERED",
}

export const DOCUMENT_CATEGORY_SUCCESS_MESSAGES = {
  created: "DOCUMENT_CATEGORY.CREATED_MESSAGE",
  deleted: "DOCUMENT_CATEGORY.DELETED_MESSAGE",
};

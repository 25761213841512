import { IDocumentStats } from "../../entities/IDocument";

export interface StatCardProps extends IDocumentStats {
  title: string;
  link: string;
}

export const archiveStatsDataLabels = {
  change: "Contract Change",
  newcontract: "New Customer",
  complaint: "Complaint",
  renewal: "Renewal",
};

export const archiveStatsDataColors = {
  change: "#337AFF",
  newcontract: "#33FFD5",
  complaint: "#FF5733",
  renewal: "rgb(25, 135, 84)",
};

export const archiveStatsExportHeaders =
  "Total,Residential Total,Residential New Contract,Residential Change,Residential Complaint,Residential Renewal,Business Total,Business New Contract,Business Change,Business Complaint,Business Renewal" +
  `\n`;

import { translation } from "../../translations";
import { validatorFunc } from "../GenericForm.types";

export class GenericFormValidator {
  field: string;
  value: string;
  constructor(value: string, field: string) {
    this.field = field;
    this.value = typeof value === "string" ? value.trim() : value;
  }
  public static required(params?: { error?: string }): validatorFunc {
    return (value: string, field: string) => {
      if (!value) {
        return {
          valid: false,
          error: params?.error || translation.t("FORM.REQUIRED", { field: translation.t(field) }),
        };
      }
      return {
        valid: true,
      };
    };
  }

  public static email(params?: { error?: string }): validatorFunc {
    return GenericFormValidator.regex({
      pattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      error: params?.error || translation.t("FORM.EMAIL"),
    });
  }

  public static password(params?: { error?: string }): validatorFunc {
    return GenericFormValidator.regex({
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      error:
        params?.error ||
        translation.t("FORM.PASSWORD"),
    });
  }

  public static minChar(params: { limit: number; error?: string }): validatorFunc {
    return (value: string, field: string) => {
      if (!value || (!!value && value.length >= params.limit)) {
        return {
          valid: true,
        };
      }

      return {
        valid: false,
        error: params.error || translation.t(`FORM.MIN_CHAR`, { field: translation.t(field), limit: params.limit }),
      };
    };
  }

  public static maxChar(params: { limit: number; error?: string }): validatorFunc {
    return (value: string, field: string) => {
      if (!value || (!!value && value.length <= params.limit)) {
        return {
          valid: true,
        };
      }
      return {
        valid: false,
        error: params.error || translation.t(`FORM.MAX_CHAR`, { field: translation.t(field), limit: params.limit }),
      };
    };
  }

  public static numericOnly(params?: { error?: string }): validatorFunc {
    return GenericFormValidator.regex({
      pattern: /^[0-9]*$/,
      error: params?.error || translation.t(`FORM.NUMERIC_ONLY`),
    });
  }

  public static regex(params: { pattern: string | RegExp; error?: string }): validatorFunc {
    return (value: string, field: string) => {
      const regex = new RegExp(params.pattern);
      if (!!value && !regex.test(value)) {
        return {
          valid: false,
          error: params.error || translation.t(`FORM.REGEX`, { field: translation.t(field) }),
        };
      }
      return {
        valid: true,
      };
    };
  }

  public static charLength(params: { limit: number; error?: string }): validatorFunc {
    return (value: string, field: string) => {
      if (!!value && value.length !== params.limit) {
        return {
          valid: false,
          error: params.error || translation.t(`FORM.CHAR_LENGTH`, { field: translation.t(field), limit: params.limit }),
        };
      }
      return { valid: true };
    };
  }

  public static number(params?: { error?: string }): validatorFunc {
    return (value: string, field: string) => {
      if (!value) {
        return { valid: true };
      }
      const num = parseFloat(value);
      if (isNaN(num)) {
        return {
          valid: false,
          error: params?.error || translation.t(`FORM.NUMBER`, { field: translation.t(field) }),
        };
      }
      return {
        valid: true,
      };
    };
  }

  public validate(validators: validatorFunc[]): {
    valid: boolean;
    error?: string;
  } {
    for (const validFunc of validators) {
      const validated = validFunc(this.value, this.field);
      if (!validated.valid) {
        return validated;
      }
    }
    return {
      valid: true,
    };
  }
}

import { DateRangeTypeValues } from "../Filters.types";
import { ButtonGroup, DropdownButton } from "react-bootstrap";
import { useStandaloneDateRangeFilter } from "./useStandaloneDateRangeFilter";
import { DateRangeTypeList } from "./DateRangeTypeList";
import { CustomDateRange } from "./CustomDateRange";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
export function StandaloneDateRangeFilter({
  label = "Date",
  defaultVal,
  onSet,
}: {
  label?: string;
  defaultVal?: {
    value: DateRangeTypeValues;
    toDate: Date | undefined;
    fromDate: Date | undefined;
  } | null;
  onSet: (
    value: {
      fromDate?: Date;
      toDate?: Date;
      value: DateRangeTypeValues;
    } | null
  ) => void;
}) {
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const {
    dateRangeType,
    onShowDateRangeTypes,
    title,
    onChange,
    fromDate,
    toDate,
    onChangeFrom,
    onChangeTo,
    onApplyFilter,
    showCustomDateRange,
  } = useStandaloneDateRangeFilter(onSet, defaultVal || null, ref2, label);
  const { t } = useTranslation();
  return (
    <div
      className="flex-column gap-1"
      style={{ marginBottom: "1px", width: "min-content" }}
      ref={ref2}
    >
      <label>{label}</label>
      <div className="btn-group" style={{ position: "relative", boxShadow: "none" }}>
        <DropdownButton
          as={ButtonGroup}
          variant="transparent"
          className="cursor-text p-0 m-0 form-select shadow-none"
          style={{ border: "none" }}
          autoClose={showCustomDateRange ? false : true}
          onToggle={onShowDateRangeTypes}
          title={<div style={{ paddingRight: "24px" }}>{title}</div>}
        >
          <div ref={ref1}>
            <DateRangeTypeList
              name={label}
              dateRangeType={dateRangeType}
              onChange={onChange}
              onApplyFilter={onApplyFilter}
            />
          </div>
        </DropdownButton>

        {showCustomDateRange && ref1.current && (
          <CustomDateRange
            {...{
              fromDate,
              onChangeFrom,
              toDate,
              onChangeTo,
              dateRangeType,
              onApplyFilter,
              parentBoundingClientRect: ref1.current.getBoundingClientRect(),
            }}
          />
        )}
      </div>
    </div>
  );
}

import { BadgeProps } from "../components/badge/Badge.types";
import { QueryFilter } from "../components/filters";
import { IDbEntity } from "./IDbEntity";
import { IDigitalSignatureRequest } from "./IDigitalSignatureRequest";
import { IDocumentType } from "./IDocumentType";
import { ILabel } from "./ILabel";
import { MetaData } from "./IMetaData";
import { IUser } from "./IUser";

export interface IDocument extends IDbEntity {
  status: DocumentStatus;
  createdById: number;
  docTypeId: number;
  docType: IDocumentType;
  createdBy?: IUser;
  files?: IFile[];
  metaData?: MetaData;
  digitalSignRequest: null | IDigitalSignatureRequest;
  task?: {
    id: number;
    status: string;
    basketId: number;
    userId: string;
  };
  uploadSessionId?: number;
  labels?: ILabel[];
}

export interface IFile {
  id?: number;
  createdAt?: number;
  updatedAt?: number;
  url: string;
  mimeType?: string;
  name: string;
  documentId?: number;
}

export enum DocumentStatus {
  DRAFT = "draft",
  PENDING = "pending",
  ARCHIVED = "archived",
  DISMISSED = "dismissed",
  PROCESSING = "processing",
  APPROVED = "approved",
  REJECTED = "rejected",
  SEALED = "sealed",
  SIGNED = "signed",
}

export enum InvoiceStatus {
  APPROVED = "Approved",
  REJECTED = "Rejected",
}

export const DocStatusBadgeMap: {
  [status in DocumentStatus]: BadgeProps["type"];
} = {
  pending: "info",
  archived: "primary",
  dismissed: "danger",
  draft: "secondary",
  processing: "warning",
  approved: "success",
  rejected: "danger",
  sealed: "success",
  signed: "primary",
};

export const InvoiceStatusBadgeMap: {
  [status in InvoiceStatus]: BadgeProps["type"];
} = {
  Approved: "success",
  Rejected: "danger",
};

export interface CreateDocumentDTO {
  file: File;
  docTypeId?: number;
  metaData?: any;
  draft?: boolean;
  labels?: string[];
}

export interface UpdateDocumentDTO {
  file?: File;
  metaData: MetaData;
  id: string;
  documentTypeId: number;
  status?: DocumentStatus;
  labels?: number[];
}

export interface UpdateSessionDocumentDTO extends UpdateDocumentDTO {
  sessionId: string;
}

export interface GetDocumentsDTO {
  textSearch?: string;
  skip?: number;
  limit?: number;
  filters?: QueryFilter[];
  sort?: { key: string; value: string };
}

export interface ValidateDocumentResponse {
  valid: boolean;
  errors?: string[];
  items: Array<{ key: string; valid: boolean; error?: string }>;
}

export interface GetDocumentsStatsDTO {
  pos?: number[];
  dateFrom?: Date;
  dateTo?: Date;
}
export interface IDocumentStats {
  total: number;
  change: number;
  renewal: number;
  complaint: number;
  newcontract: number;
}
export interface GetDocumentsStatsResponse {
  total: number;
  residential: IDocumentStats;
  business: IDocumentStats;
}

export interface CreateDocumentSessionResponse {
  documents: IDocument[];
  id: number;
}
export enum SessionStatus {
  ACTIVE = "active",
  DONE = "done",
}
export interface GetDocumentsBySessionIdResponse {
  createdAt: Date;
  createdById: number;
  documents: IDocument[];
  id: number;
  status: SessionStatus;
  totalDocuments: number;
}

export enum DocumentActionsTypes {
  VIEW_TASK = "view_task",
  CREATE_TASK = "create_task",
  VIEW_DIGITAL_SIGNATURE = "view_digital_signature",
  REQUEST_DIGITAL_SIGNATURE = "request_digital_signature",
  EDIT_DOCUMENT = "edit_document",
  DELETE_DOCUMENT = "delete_document",
}

import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { DocumentStatus } from "../../entities/IDocument";
import { useUser } from "../../store/auth/AuthSelectors";
import { screens } from "../Navigation.types";
import { useToast } from "../../components/toast";
import { useTranslation } from "react-i18next";

export function useUploadDocument() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useUser();
  const { danger, success } = useToast();
  const [showDismissModal, setShowDismissModal] = React.useState(false);
  const [showSplitPdfModal, setShowSplitPdfModal] = React.useState(false);
  // Save the file cache during split pdf modal
  const [fileCache, setFileCache] = React.useState<File>();

  const initialPayload =
    "?" +
    `filters=${JSON.stringify({
      name: "status",
      operator: "in",
      value: JSON.stringify([DocumentStatus.DRAFT, DocumentStatus.PROCESSING]),
      type: "stringArray",
    })}` +
    `&filters=${JSON.stringify({
      name: "createdById",
      operator: "eq",
      value: user?.backEndId.toString(),
      type: "string",
    })}` +
    `&sort=${JSON.stringify({
      key: "createdAt",
      value: "ASC",
    })}`;
  const {
    data,
    loading: fetching,
    execute: getDraftDocuments,
  } = useBaseRequest(DocumentGateway.getDocuments, {
    autoFetch: true,
    initialPayload,
  });

  const { execute: createDraft, loading: creating } = useBaseRequest(
    DocumentGateway.createDocument,
    {
      onCompleted: (data) => {
        if (!data) return;
        navigate(screens.newDocument.replace(":id", data.id.toString()));
      },
      onError: (error) => {
        danger(error.message);
      },
    }
  );

  const { execute: createDocumentSession, loading: creatingSession } = useBaseRequest(
    DocumentGateway.createDocumentSession,
    {
      onCompleted: (data) => {
        if (!data) return;
        navigate(screens.uploadSession.replace(":sessionId", data.id.toString()));
      },
      onError: (error) => {
        danger(error.message);
      },
    }
  );

  const { execute: dismiss } = useBaseRequest(DocumentGateway.dismissDocument, {
    onCompleted: (data) => {
      closeDismissModal();
      getDraftDocuments(initialPayload);
      success(t("DOCUMENTS.DISMISSED_MESSAGE"));
    },
    onError: (error) => {
      danger(error.message);
    },
  });

  const { execute: onDismissSession, loading: dismissing } = useBaseRequest(
    DocumentGateway.dismissDocumentSession,
    {
      onCompleted: () => {
        closeDismissModal();
        getDraftDocuments(initialPayload);
        success(t("DOCUMENTS.DISMISSED_MESSAGE"));
      },
      onError: (error) => {
        danger(error.message);
      },
    }
  );

  const onUpload = (files: File[]) => {
    if (!files || !files.length) return;
    if (getTotalFileSize(files) > 100) {
      danger(t("DOCUMENTS.FILES_TOO_BIG"));
      return;
    }
    if (files.length === 1) {
      if (files[0]?.type === "application/pdf") {
        onSinglePdfUpload(files[0]);
        return;
      }
      createDraft({ file: files[0], draft: true });
      return;
    }
    createDocumentSession({ files });
  };

  const onSinglePdfUpload = (file: File) => {
    setFileCache(file);
    openSplitModal();
  };

  const onSplitPdf = () => {
    closeSplitModal();
    navigate(screens.splitFile, { state: { file: fileCache as File } });
  };

  const onDontSplitPdf = () => {
    closeSplitModal();
    createDraft({ file: fileCache as File, draft: true });
  };

  const getTotalFileSize = (files: File[]) => {
    var totalSize = 0;

    for (var i = 0; i < files.length; i++) {
      totalSize += files[i].size;
    }

    return totalSize / (1024 * 1024);
  };

  const onContinueDraft = () => {
    if (!data?.documents || !data.documents.length) return;
    if (!data.documents[0]?.uploadSessionId) {
      navigate(screens.newDocument.replace(":id", data.documents[0].id.toString()));
      return;
    }
    navigate(
      screens.uploadSession.replace(":sessionId", data.documents[0].uploadSessionId?.toString())
    );
  };

  const closeDismissModal = () => setShowDismissModal(false);
  const openDismissModal = () => setShowDismissModal(true);
  const closeSplitModal = () => setShowSplitPdfModal(false);
  const openSplitModal = () => setShowSplitPdfModal(true);

  const onDismiss = () => {
    if (!data?.documents || !data?.documents.length) return;
    if (!data.documents[0]?.uploadSessionId) {
      dismiss({ id: data?.documents[0].id?.toString() || "" });
      return;
    }
    onDismissSession({ id: data.documents[0].uploadSessionId?.toString() });
    closeDismissModal();
  };

  const dismissFileUpload = () => {
    setFileCache(undefined);
    closeSplitModal();
  };

  return {
    documents: data?.documents,
    onUpload,
    loading: creating || fetching || creatingSession || dismissing,
    onContinueDraft,
    showDismissModal,
    showSplitPdfModal,
    closeDismissModal,
    openDismissModal,
    onDismiss,
    onSplitPdf,
    onDontSplitPdf,
    dismissFileUpload,
  };
}

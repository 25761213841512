import { useEffect, useMemo, useState } from "react";
import { taskStatusesColors } from "../../entities/ITask";
import useBaseRequest from "../../api/BaseRequest";
import TaskGateway from "../../api/gateways/TaskGateway";
import { useToast } from "../../components/toast";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TableRow } from "../../components/table";
import moment from "moment";
import { Icon } from "../../modules/icon";
import { screens } from "../Navigation.types";
import { Badge } from "../../components/badge";
import { BadgeTypes } from "../../components/badge/Badge.types";
import BasketGateWay from "../../api/gateways/BasketGateway";
import { useTranslation } from "react-i18next";
import { useCounterService } from "../../services/useCounterService";
import { createTaskForm as createTaskFormDefault } from "../../components/create-task";

const useBasketTasks = () => {
  const { danger, success } = useToast();
  const navigate = useNavigate();
  const onRowPress = (id: number) => {
    navigate(screens.task.root.replace(":id", id.toString()));
  };
  const id = +(useParams<{ id: string }>().id || -1);
  const [tasks, setTasks] = useState<TableRow[]>([]);
  const [total, setTotal] = useState<number>(0);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (id !== -1)
      getData(
        (location.search || "?") +
          `&filters=${JSON.stringify({
            name: "basketId",
            type: "number",
            operator: "eq",
            value: JSON.stringify(id),
          })}`
      );
  }, [location.search, id]);

  const { execute: getData, loading } = useBaseRequest(TaskGateway.getTasks, {
    onCompleted: (data) => {
      if (data) {
        const taskTable: TableRow[] = [];
        data.tasks.forEach((item) => {
          taskTable.push([
            item.id,
            item.title,
            <Badge type={taskStatusesColors[item.status] as BadgeTypes} className="fw-bold">
              {t(`TASK_STATUS.${item.status}`)}
            </Badge>,
            item.createdBy.name,
            moment(item.createdAt).format("DD.MM.YYYY"),
            <>
              <Link to={screens.task.root.replace(":id", item.id.toString())}>
                <Icon
                  title={t("BASKET_TASKS.SEE_TASK_INFO")}
                  type="fa-solid fa-circle-info"
                  color="black"
                  size={16}
                />
              </Link>
              {!!item.comments.length && (
                <Link
                  to={screens.task.root.replace(":id", item.id.toString()) + `/comments`}
                  title={
                    `${t("BASKET_TASKS.LAST_COMMENT")}: ` +
                    item.comments[item.comments.length - 1].text
                  }
                >
                  <Icon type="fa-solid fa-message" color="black" size={16} />
                </Link>
              )}
            </>,
          ]);
        });
        setTasks(taskTable);
        setTotal(data.count);
      }
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  //Bulk actions
  const [selectedTasks, setSelectedTasks] = useState<number[]>([]);
  const [selectedAction, setSelectedAction] = useState<"" | "assign" | "move">("");
  const { execute: reassignBulkTasks, loading: reassigningTasks } = useBaseRequest(
    TaskGateway.reassignBulkTasks,
    {
      onCompleted: () => {
        setSelectedTasks([]);
        setSelectedAction("");
        getData(
          (location.search || "?") +
            `&filters=${JSON.stringify({
              name: "basketId",
              type: "number",
              operator: "eq",
              value: JSON.stringify(id),
            })}`
        );
        success(t("TASKS.REASSIGNED_MESSAGE"));
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );
  const { execute: moveBulkTasks, loading: movingTasks } = useBaseRequest(
    TaskGateway.moveToAnotherBasketBulkTasks,
    {
      onCompleted: () => {
        setSelectedTasks([]);
        setSelectedAction("");
        getData(
          (location.search || "?") +
            `&filters=${JSON.stringify({
              name: "basketId",
              type: "number",
              operator: "eq",
              value: JSON.stringify(id),
            })}`
        );
        success(t("TASKS.MOVED_MESSAGE"));
      },

      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const { data: baskets } = useBaseRequest(BasketGateWay.getBaskets, {
    autoFetch: true,
    initialPayload: {},
  });

  const [isVisibleCreateTaskModal, setIsVisibleCreateTaskModal] = useState(false);
  const { refreshTaskCounters } = useCounterService();
  const { execute: createTask, loading: creatingTask } = useBaseRequest(TaskGateway.createTask, {
    onCompleted: (data) => {
      getData(
        (location.search || "?") +
          `&filters=${JSON.stringify({
            name: "basketId",
            type: "number",
            operator: "eq",
            value: JSON.stringify(id),
          })}`
      );
      refreshTaskCounters();
      success(t("TASKS.CREATED_MESSAGE"));
      setIsVisibleCreateTaskModal(false);
    },
    onError: (error) => {
      danger(error?.message || "Something went wrong.");
    },
  });

  const taskStartCreate = () => {
    setIsVisibleCreateTaskModal(true);
  };

  const createTaskForm = useMemo(() => {
    return {
      ...createTaskFormDefault,
      basketId: {
        ...createTaskFormDefault.basketId,
        defaultValue: id.toString(),
        type: "hidden",
        visibleIf: () => {
          return false;
        },
      },
      processDescription: {
        ...createTaskFormDefault.processDescription,
        defaultValue: baskets?.baskets.find((item) => item.id === id)?.processDescription || "",
      },
      title: {
        ...createTaskFormDefault.title,
        defaultValue: baskets?.baskets.find((item) => item.id === id)?.name || "",
      },
    };
  }, [id, baskets?.baskets]);
  const hideCreateTaskModal = () => setIsVisibleCreateTaskModal(false);
  return {
    basketTasks: tasks,
    total,
    getData,
    onRowPress,
    loading,
    basketName: baskets?.baskets.find((item) => item.id === id)?.name || "",
    selectedTasks,
    setSelectedTasks,
    selectedAction,
    setSelectedAction,
    reassignBulkTasks,
    reassigningTasks,
    moveBulkTasks,
    movingTasks,
    createTask,
    creatingTask,
    isVisibleCreateTaskModal,
    taskStartCreate,
    createTaskForm,
    hideCreateTaskModal,
  };
};

export default useBasketTasks;

import Header from "../../components/header/Header";
import { AllBasketSectionProps } from "./Basket.types";
import BasketCard from "./BasketCard";
import CardContainer from "../../components/card/CardContainer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AllBaskets = ({ baskets, onBookmark, onDelete, onEdit }: AllBasketSectionProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <section className="flex-column gap-2">
      <Header title={t("BASKETS.ALL_BASKET")} size="h4" />
      <CardContainer
        children={[
          ...baskets.map((item, i) => (
            <BasketCard
              key={i}
              onClick={() => {
                navigate(`/baskets/${item.id}`);
              }}
              onBookmark={onBookmark}
              onDelete={onDelete}
              onEdit={onEdit}
              name={item.name}
              numberOfTasks={item.numberOfTasks}
              id={item.id}
              isBookmarked={item.isBookmarked}
            />
          )),
        ]}
        cardGap={16}
        cardMinWidth={128}
        cardMaxWidth={208}
        cardMaxNumberPerRow={10}
      />
    </section>
  );
};

export default AllBaskets;

import { DigitalSignaturesCardActionProps } from "..";
import { Icon } from "../../../modules/icon";
import { RoleBasedAccess } from "../../../components/guard";
import { UserRoles } from "../../../entities/IUser";
import { useTranslation } from "react-i18next";

export const DigitalSignatureCardActions = ({ id, onDelete }: DigitalSignaturesCardActionProps) => {
  const { t } = useTranslation();
  return (
    <div
      className="flex-row dismiss-signature shadow"
      onClick={(event) => {
        event?.stopPropagation();
      }}
    >
      <RoleBasedAccess allowedRoles={[UserRoles.ADMIN]}>
        <Icon
          title={t("DOCUMENTS.DELETE_DIGITAL_SIGNATURE_REQUEST")}
          color={"#666666"}
          type="fa-solid fa-trash"
          onClick={(event) => {
            event?.stopPropagation();
            onDelete(id);
          }}
        />
      </RoleBasedAccess>
    </div>
  );
};

import axios, { AxiosError } from "axios";
import environment from "../config/environment";
import { store } from "../store";
import { translation } from "../components/translations";

const BaseInstance = axios.create({
  baseURL: `${environment.api.protocol}://${environment.api.host}${
    environment.api.port ? ":" + environment.api.port : ""
  }/${environment.api.version ? environment.api.version + "/" : ""}`,
});

BaseInstance.interceptors.request.use(
  (config) => {
    config = config || {};
    const token = store.getState().auth.user?.token;
    config.headers["accept-language"] = translation.language;
    config.headers["Authorization"] = `${token || ""}`;
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error?.message ? error : defaultRequestError);
  }
);

const defaultRequestError = { statusCode: 500, message: "Server error" };

BaseInstance.interceptors.response.use(
  (config) => {
    // propagate not found response to error handling layer
    if (config.status === 204) {
      return Promise.reject(
        new AxiosError(config.headers["x-status-message"], config.status.toString())
      );
    }
    return Promise.resolve(config.data);
  },
  (error: AxiosError) => {
    console.log(error.response?.data);
    return Promise.reject(error?.response?.data || defaultResponseError);
  }
);

const defaultResponseError = { statusCode: 500, message: "Server error" };

export default BaseInstance;

import { useTranslation } from "react-i18next";
import { useToast } from "../../../components/toast";
import useBaseRequest from "../../../api/BaseRequest";
import { DocumentTypeOCRMappingGateway } from "../../../api/gateways/DocumentTypeOCRMappingGateway";
import { DocumentTypeOCRMappingForm } from "./DocumentTypeOCRMapping.types";
import { IDocumentType, IDocumentTypeMapping } from "../../../entities/IDocumentType";
import { useMemo } from "react";

export const useDocumentTypeOCRMapping = (
  docType: IDocumentType | null,
  onChange: () => void,
  onDismiss: () => void
) => {
  const { t } = useTranslation();
  const { success, danger } = useToast();
  const { execute: update, loading: updating } = useBaseRequest(
    DocumentTypeOCRMappingGateway.updateDocumentTypeOCRMapping,
    {
      onCompleted: () => {
        success(t("DOCUMENT_TYPE.DOCUMENT_TYPE_OCR_KEY_UPDATED"));
        onChange();
        onDismiss();
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );
  const { execute: create, loading: creating } = useBaseRequest(
    DocumentTypeOCRMappingGateway.createDocumentTypeOCRMapping,
    {
      onCompleted: () => {
        success(t("DOCUMENT_TYPE.DOCUMENT_TYPE_OCR_KEY_UPDATED"));
        onChange();
        onDismiss();
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const { execute: deleteKey, loading: deleting } = useBaseRequest(
    DocumentTypeOCRMappingGateway.deleteDocumentTypeOCRMapping,
    {
      onCompleted: () => {
        success(t("DOCUMENT_TYPE.DOCUMENT_TYPE_OCR_KEY_UPDATED"));
        onChange();
        onDismiss();
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );
  const form = useMemo(() => {
    return {
      ...DocumentTypeOCRMappingForm,
      key: {
        ...DocumentTypeOCRMappingForm.key,
        defaultValue: docType ? docType.ocrKeyMapping?.key || "" : "",
      },
      docTypeId: {
        ...DocumentTypeOCRMappingForm.docTypeId,
        defaultValue: docType ? docType.id || -1 : -1,
      },
      id: {
        ...DocumentTypeOCRMappingForm.id,
        defaultValue: docType ? docType.ocrKeyMapping?.id || -1 : -1,
      },
    } as typeof DocumentTypeOCRMappingForm;
  }, [docType]);

  const onSubmit = (data: IDocumentTypeMapping) => {
    if (docType) {
      if (docType.ocrKeyMapping) {
        if (data.key.trim() === "") {
          deleteKey(docType.ocrKeyMapping.id);
        } else {
          update({ ...data });
        }
      } else {
        create(data);
      }
    }
  };

  return { form, loading: updating || deleting || creating, onSubmit };
};

import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LinkedDocumentTypeModalProps } from "./DocumentTypes.types";
import { Badge } from "../../components/badge";

export const DocumentTypeFieldsModal = ({
  isVisible,
  onDismiss,
  selectedItem,
}: LinkedDocumentTypeModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal show={isVisible} animation onHide={onDismiss}>
      {selectedItem?.name ? (
        <>
          <Modal.Header>
            <h5 className="modal-title" id="Dismiss Modal">
              {selectedItem?.name + " " + t("DOCUMENT_TYPE.FIELDS")}
            </h5>
            <div onClick={onDismiss}>
              <span className="font-20 cursor-pointer">&times;</span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="flex-row flex-wrap gap-2">
              {selectedItem?.customFields?.map((field) => (
                <Badge key={field.value} type={"dark"} variant="outlined">
                  {field.label}
                </Badge>
              ))}
            </div>
          </Modal.Body>
        </>
      ) : null}
    </Modal>
  );
};

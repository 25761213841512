import { ILabel } from "../../entities/ILabel";
import { CreateLabelDTO } from "../../screens/labels/Labels.types";
import BaseInstance from "../BaseInstance";

const LabelGateway = {
  async getLabels(query: string): Promise<{ count: number; labels: ILabel[] }> {
    return BaseInstance.get("/labels" + query);
  },
  async createLabel(label: CreateLabelDTO): Promise<ILabel> {
    return BaseInstance.post("/labels", { ...label, color: label.color || "#000000" });
  },
  async updateLabel(label: ILabel): Promise<ILabel> {
    return BaseInstance.put("/labels/" + label.id, {
      name: label.name,
      color: label.color,
    });
  },
  async deleteLabel(id: number): Promise<void> {
    return BaseInstance.delete("/labels/" + id);
  },
  async addLabelsToElement({
    id,
    labelIds,
    type,
  }: {
    id: number | string;
    labelIds: number[];
    type: "task" | "document" | "task-element";
  }): Promise<boolean> {
    return BaseInstance.post(`/labels/${type}/` + id, { labelIds });
  },
  async removeLabelsFromElement({
    id,
    labelIds,
    type,
  }: {
    id: number | string;
    labelIds: number[];
    type: "task" | "document" | "task-element";
  }): Promise<boolean> {
    return BaseInstance.delete(`/labels/${type}/` + id, { data: { labelIds } });
  },
};
export default LabelGateway;

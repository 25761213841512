import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

interface CounterState {
  allTasksCount: number;
  myTasksCount: number;
  commentsCount: number;
}

const initialState: CounterState = {
  allTasksCount: 0,
  myTasksCount: 0,
  commentsCount: 0,
};

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setAllTasksCount: (state: CounterState, action: PayloadAction<number>) => {
      state.allTasksCount = action.payload;
    },
    setMyTasksCount: (state: CounterState, action: PayloadAction<number>) => {
      state.myTasksCount = action.payload;
    },
    setCommentsCount: (state: CounterState, action: PayloadAction<number>) => {
      state.commentsCount = action.payload;
    },
  },
});

const persistConfig: PersistConfig<CounterState> = {
  key: "counter",
  storage,
};

export const counterActions = counterSlice.actions;

export default persistReducer(persistConfig, counterSlice.reducer);

import { Icon } from "../../modules/icon";
import { FiltersProps } from "./Filters.types";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useFilters } from "./useFilters";
import { Filter } from "./Filter";
import "./Filters.style.css";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export function Filters({ filters, onFilterChange }: FiltersProps) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const toggleDropdown = () => setOpen(!open);
  const { selected, onAddFilter, onRemoveFilter, onSet, onAddDefaultFilters } = useFilters(
    filters,
    onFilterChange
  );
  useEffect(() => {
    const filtersDefaut = filters.filter((item) =>
      typeof item.defaultValues === "number" ? true : item.defaultValues
    );
    if (filtersDefaut.length) {
      onAddDefaultFilters(filtersDefaut);
    }
  }, []);

  return (
    <div className="d-flex flex-wrap align-items-center">
      {selected.map((sf, i) => (
        <Filter key={sf.name} filter={sf} onRemove={onRemoveFilter} onFilterSet={onSet} />
      ))}
      {!selected.length || selected.length < filters.length ? (
        <DropdownButton
          onSelect={function () {
            return true;
          }}
          variant="outline-primary"
          title={
            <>
              <Icon type="fa-solid fa-plus" />
              &nbsp; {t("FILTERS.ADD_LABEL")}
            </>
          }
        >
          <div>
            {filters
              .filter((f) => !selected.find((s) => s.name === f.name))
              .map((f, i) => (
                <Dropdown.Item
                  className="dropdown-item dropdown-toggle"
                  key={i}
                  onClick={(event) => {
                    event.preventDefault();
                    onAddFilter(f);
                    toggleDropdown();
                  }}
                >
                  {t(f.name)}
                </Dropdown.Item>
              ))}
          </div>
        </DropdownButton>
      ) : null}
    </div>
  );
}

import { IDbEntity } from "./IDbEntity";

export interface IRolePermission extends IDbEntity, IPermission {
  role: string;
}

export interface IPermission {
  type: PermissionType;
  entityIdentifier: string;
  accessType: PermissionAccessType;
}

export enum PermissionType {
  DOCUMENT_TYPES = "DOCUMENT_TYPES",
}

export enum PermissionAccessType {
  NONE = 0,
  READ = 1,
  EDIT = 2,
  MANAGE = 3,
}

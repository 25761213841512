import { CheckBoxProps } from "./CheckBox.types";
import { useCheckBox } from "./useCheckBox";

export function CheckBox({
  checked = false,
  intermediate = false,
  onChange,
  className,
  scale,
  disabled,
}: CheckBoxProps) {
  const { checkBoxRef } = useCheckBox(intermediate);
  return (
    <input
      style={{ transform: `scale(${scale})` }}
      className={className}
      type="checkbox"
      checked={checked}
      ref={checkBoxRef}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

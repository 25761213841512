import { Filter } from "../../components/filters";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
import { TableHeaders } from "../../components/table";
import { CreateArchiveBookDTO } from "../../entities/IArchiveBook";

export const archiveBookTableHeaders: TableHeaders[] = [
  { name: "NAME", key: "name", sortable: true },
  { name: "ORGANIZATION_NAME", key: "organization.name" },
  { name: "ORGANIZATION_MB", key: "organization.mb" },
  { name: "NUMBER_OF_ITEMS", key: "numItems" },
  { name: "ACTIONS", key: "action" },
];

export type ArchiveBookFilterKeys = {
  organizationId: Filter;
};
export interface DeleteArchiveBookModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  onDelete: () => void;
  deleting: boolean;
}

export const createArchiveBookForm: GenericForm<CreateArchiveBookDTO> = {
  name: {
    type: "input",
    name: "name",
    label: "TABLE.TITLE",
    defaultValue: "",
    validators: [GenericFormValidator.required(), GenericFormValidator.minChar({ limit: 3 })],
  },
  organizationId: {
    type: "select",
    name: "organizationId",
    label: "ARCHIVE_BOOK.ORGANIZATION",
    defaultValue: 0,
    options: [],
    validators: [GenericFormValidator.required()],
    props: { skipOptionTranslation: true },
  },
};

import { useTranslation } from "react-i18next";
import { Button } from "../../components/button";
import { GenericForm } from "../../components/generic-form";
import { RoleBasedAccess } from "../../components/guard";
import Header from "../../components/header/Header";
import { UserRoles } from "../../entities/IUser";
import { Icon } from "../../modules/icon";
import { Loader } from "../../modules/loader";
import useTaskDetails from "./useTaskDetails";
import { TaskAttachments } from "./TaskAttachments";
import { FormItem } from "../../components/form-preview";

const TaskDetails = () => {
  const {
    task,
    loading,
    updateTask,
    updateTaskForm,
    updating,
    updateInProgress,
    onCancelUpdate,
    onUpdateStart,
    user,
  } = useTaskDetails();
  const { t } = useTranslation();
  if (loading) {
    return (
      <div className="w-100 flex-row justify-content-center">
        <Loader size="l" color="black" />
      </div>
    );
  }
  if (!task) {
    return <div>{t("TASKS.TASK_NOT_FOUND")}</div>;
  }
  return (
    <>
      <div
        className={` flex-column gap-2 mt-2 ${updateInProgress ? "bg-white p-3 rounded-2" : ""}`}
      >
        <Header
          actions={
            <RoleBasedAccess
              allowedRoles={[UserRoles.ADMIN, UserRoles.SUPERVISOR, UserRoles.BO_EXPERT]}
            >
              {user?.role === UserRoles.BO_EXPERT &&
              !!!task.participants.find((p) => p.id === +(user?.id as string)) ? null : (
                <Button
                  className={`btn ${updateInProgress ? "btn-danger" : "btn-dark"} `}
                  onClick={updateInProgress ? onCancelUpdate : onUpdateStart}
                >
                  <Icon type={`fa-solid fa-${updateInProgress ? "xmark" : "pen"}`} />
                  &nbsp; {updateInProgress ? t("CANCEL_BUTTON") : t("EDIT_BUTTON")}
                </Button>
              )}
            </RoleBasedAccess>
          }
          title={t("TASKS.TASK_DETAILS_TITLE")}
        />
        {!updateInProgress ? (
          <div className="flex-row gap-2">
            <div className="flex-column gap-2" style={{ flex: "3", whiteSpace: "pre-wrap" }}>
              <FormItem label={t("TABLE.DESCRIPTION")} type={"textarea"} value={task.description} />
            </div>
            <div className="flex-column gap-2" style={{ flex: "1" }}>
              <FormItem
                label={t("TABLE.LABELS")}
                type={"autocompleteMultipleChoice"}
                value={task.labels?.map((item) => ({ label: item.name, color: item.color }))}
              />
              <FormItem
                label={t("TABLE.ASSIGNEES")}
                type={"autocompleteMultipleChoice"}
                value={task.participants?.map((item) => item.name)}
              />
              <FormItem
                label={t("TABLE.PROCESS_DESCRIPTION")}
                type={"richTextInput"}
                value={task.processDescription}
              />
            </div>
          </div>
        ) : (
          <GenericForm
            form={updateTaskForm as any}
            onSubmit={updateTask}
            submitControl={(submit) => (
              <div className="flex-row">
                <Button
                  isLoading={updating}
                  loader={{ size: "s" }}
                  className="btn btn-primary mt-3 w-100"
                  type="submit"
                >
                  {t("SAVE_BUTTON")}
                </Button>
              </div>
            )}
          />
        )}
      </div>
      {task.id && <TaskAttachments taskId={task.id} />}
    </>
  );
};

export default TaskDetails;

import { PageLayout } from "../../components/page-layout";

import useBaskets from "./useBaskets";
import BookmarkedBaskets from "./BookmarkedBaskets";
import AllBaskets from "./AllBaskets";
import CreateBasketModal from "./CreateBasketModal";
import UpdateBasketModal from "./UpdateBasketModal";
import DeleteBasketModal from "./DeleteBasketModal";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { Icon } from "../../modules/icon";
import { RoleBasedAccess } from "../../components/guard";
import { UserRoles } from "../../entities/IUser";

export function BasketsScreen() {
  const {
    baskets,
    bookmarkedBaskets,
    createBasket,
    creatingBasket,
    hideCreateBasketModalHandler,
    showCreateBasketModalHandler,
    isVisibleCreateBasketModal,
    createBasketForm,
    updateBasketForm,
    isVisibleUpdateBasketModal,
    setUpdateBasketID,
    hideUpdateBasketModalHandler,
    updateBasket,
    updatingBasket,
    hideDeleteBasketModalHandler,
    setDeleteBasketID,
    deleteBasket,
    deletingBasket,
    isVisibleDeleteBasketModal,
    toggleBookmark,
  } = useBaskets();
  const { t } = useTranslation();
  return (
    <PageLayout
      title={t("BASKETS.TITLE")}
      titleRight={
        <div className="text-right flex-row gap-2">
          <RoleBasedAccess allowedRoles={[UserRoles.ADMIN]}>
            <Button className="btn btn-dark" onClick={showCreateBasketModalHandler}>
              <Icon type={"fa-solid fa-add"} title={t("BASKETS.CREATE_LABEL")} />
            </Button>
          </RoleBasedAccess>
        </div>
      }
    >
      <CreateBasketModal
        onDismiss={hideCreateBasketModalHandler}
        onSubmit={createBasket}
        creating={creatingBasket}
        isVisible={isVisibleCreateBasketModal}
        createBasketForm={createBasketForm}
      />
      <UpdateBasketModal
        onDismiss={hideUpdateBasketModalHandler}
        onSubmit={updateBasket}
        updating={updatingBasket}
        isVisible={isVisibleUpdateBasketModal}
        updateBasketForm={updateBasketForm as any}
      />
      <DeleteBasketModal
        onDismiss={hideDeleteBasketModalHandler}
        onDelete={deleteBasket}
        deleting={deletingBasket}
        isVisible={isVisibleDeleteBasketModal}
      />
      <div>
        <BookmarkedBaskets
          baskets={bookmarkedBaskets}
          onBookmark={(id) => {
            toggleBookmark(id);
          }}
          onDelete={(id) => {
            setDeleteBasketID(id);
          }}
          onEdit={(id) => {
            setUpdateBasketID(id);
          }}
        />
        <AllBaskets
          baskets={baskets}
          onBookmark={(id) => {
            toggleBookmark(id);
          }}
          onDelete={(id) => {
            setDeleteBasketID(id);
          }}
          onEdit={(id) => {
            setUpdateBasketID(id);
          }}
        />
      </div>
    </PageLayout>
  );
}

import { CreateDocumentCategoryDTO, IDocumentCategory } from "../../entities/IDocumentCategory";
import { processDocumentCategoryPayload } from "../../screens/document-categories/utils/processDocumentCategoryPayload";
import BaseInstance from "../BaseInstance";

const DocumentCategoryGateway = {
  createDocumentCategory: async (payload: CreateDocumentCategoryDTO) => {
    const processedPayload = processDocumentCategoryPayload(payload);    
    return await BaseInstance.post("/document-category", processedPayload);
  },

  getAllDocumentCategories: async (): Promise<IDocumentCategory[]> => {
    return BaseInstance.get("/document-category");
  },

  getDocumentCategories: async (
    query: string
  ): Promise<{
    count: number;
    categories: IDocumentCategory[];
  }> => {
    return BaseInstance.get(`/document-category/search${query}`);
  },

  deleteDocumentCategory: async (id: number) => {
    return BaseInstance.delete(`/document-category/${id}`);
  },
};

export default DocumentCategoryGateway;

import { useTranslation } from "react-i18next";
import { Button } from "../../components/button";
import { FilePreview } from "../../components/file-preview";
import { GenericForm } from "../../components/generic-form";
import { Column, Row, breakPoints } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { Icon } from "../../modules/icon";
import { Loader } from "../../modules/loader";
import { useNewDocument } from "./useNewDocument";
import { DialogModal } from "../../components/modal/DialogModal";
import AutocompleteOpt from "../../components/autocomplete-opt/AutocompleteOpt";

export function NewDocumentScreen({
  id,
  closeDocumentModal,
}: {
  id?: string;
  closeDocumentModal?: (isDocumentCreated?: boolean) => void;
}) {
  const { t } = useTranslation();
  const {
    draftDocument,
    documentTypeId,
    documentTypes,
    onDocumentTypeChange,
    metaDataForm,
    loadingMetaDataForm,
    onSubmitDraft,
    creating,
    windowHeight,
    windowWidth,
    showDismissModal,
    openModal,
    closeModal,
    onDismiss,
    getDocFileUrl,
    labels,
    updateLabels,
  } = useNewDocument(id, closeDocumentModal);

  return (
    <PageLayout
      backBtn={!id}
      title={id ? "" : t("NEW_DOCUMENT.TITLE")}
      titleRight={
        <>
          {!id && (
            <div className="d-flex flex-row align-items-center justify-content-end mr-3">
              <Button className="btn btn-danger" onClick={openModal}>
                <Icon type="fa-solid fa-xmark" size={16} />
                &nbsp; {t("NEW_DOCUMENT.DISMISS")}
              </Button>
            </div>
          )}
        </>
      }
    >
      <Row>
        <Column>
          {draftDocument ? (
            <div
              className="d-flex flex-column align-items-center mb-2"
              style={{ position: "sticky", left: 0, top: "98px" }}
            >
              <FilePreview
                classNameContainer="document-preview-container"
                enableEditing={false}
                type={draftDocument.files![0].mimeType || ""}
                url={draftDocument.files![0].url ? getDocFileUrl(draftDocument.files![0].url) : ""}
                name={draftDocument.files![0].name || ""}
                width={breakPoints.m < windowWidth ? windowWidth * 0.5 : windowWidth - 64}
                height={windowHeight - 262}
              />
            </div>
          ) : null}
        </Column>
        <Column>
          <div className="d-flex flex-column p-3 rounded-2" style={{ backgroundColor: "#fff" }}>
            <div className="form-group">
              <label>{t("NEW_DOCUMENT.DOCUMENT_TYPE")}</label>
              <select
                className="form-select"
                value={documentTypeId}
                onChange={onDocumentTypeChange}
              >
                <option value=""></option>
                {documentTypes.map((docType) => (
                  <option key={docType.id} value={docType.id}>
                    {docType.name}
                  </option>
                ))}
              </select>
            </div>
            <AutocompleteOpt
              label={t("LABELS.LABEL")}
              placeholder={t("LABELS.SEARCH_LABEL")}
              onSelectOption={(labels) => updateLabels(labels || ([] as any))}
              endpoint={"/labels/search-autocomplete"}
              isMultiple
              defaultValue={labels}
            />
            <div className="mt-4">
              {loadingMetaDataForm ? (
                <div className="text-center">
                  <Loader color="#0d6efd" />
                </div>
              ) : metaDataForm ? (
                <div>
                  <h5>{t("NEW_DOCUMENT.DATA")}</h5>
                  <GenericForm
                    className="mt-3"
                    form={metaDataForm}
                    onSubmit={onSubmitDraft}
                    submitFormOnEnter={false}
                    submitControl={(submit) => (
                      <>
                        <Button
                          className="btn btn-secondary w-100 mt-3"
                          type="button"
                          onClick={submit}
                          isLoading={creating}
                          disabled={creating}
                        >
                          {t("DOCUMENTS.UPLOAD")}
                        </Button>
                      </>
                    )}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Column>
      </Row>
      <DialogModal
        onAccept={onDismiss}
        show={showDismissModal}
        onCancel={closeModal}
        acceptLabel="YES"
        cancelLabel="NO"
        description="UPLOAD.DISMISS_LABEL"
        title="UPLOAD.DISMISS"
      />
    </PageLayout>
  );
}

import { useState } from "react";
import { RangeInputProps } from "./RangeInput.types";

export const RangeInput: React.FC<RangeInputProps> = ({
  label = "",
  min,
  max,
  step,
  defaultValue,
  onChange,
  id = "range",
  disabled = false,
}) => {
  const [value, setValue] = useState(defaultValue);
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(+e.target.value);
    onChange(+e.target.value);
  };
  return (
    <div
      className="flex-row p-2 gap-2"
      style={{
        backgroundColor: "#f2f2f2",
        borderRadius: "8px",
      }}
    >
      {!!label && (
        <label
          htmlFor={id}
          style={{
            fontSize: "14px",
            whiteSpace: "nowrap",
          }}
        >
          {label}
        </label>
      )}
      <input
        type="range"
        className="form-range"
        min={min}
        max={max}
        step={step}
        id={id}
        value={value}
        onChange={onChangeHandler}
        disabled={disabled}
      />
    </div>
  );
};

import { io, Socket } from "socket.io-client";
import { SocketConnectOptions } from "../Socket.types";

class SocketIO {
  public socket: Socket | null = null;

  public async connect(url: string, options: SocketConnectOptions = {}) {
    this.disconnect();
    return new Promise((resolve, reject) => {
      this.socket = io(url, {
        ...options,
        transports: ["websocket"],
        retries: 10,
      });
      this.socket.on("connect", () => {
        console.log("\nSOCKET CONNECTED");
        resolve(true);
      });
      this.socket.on("connect_error", (error) => {
        console.log("\nSOCKET CONNECTION ERRORED");
        reject(error);
      });
    });
  }

  public joinRoom(room: string) {
    if (!this.socket || !this.socket.connected) {
      console.log("\nSOCKET NOT CONNECTED");
      return;
    }
    console.log("\nJOINING SOCKET ROOM");
    this.socket.emit("room", room);
  }

  public addListener(event: string, callback: (data: any) => any) {
    if (!this.socket || !this.socket.connected) {
      console.log("\nSOCKET NOT CONNECTED");
      return;
    }
    this.socket.on(event, callback);
  }

  public disconnect() {
    if (this.socket && this.socket.connected) {
      this.socket.removeAllListeners();
      this.socket.disconnect();
      this.socket = null;
      console.log("\nSOCKET DISCONNECTED");
    }
  }
}

export default new SocketIO();

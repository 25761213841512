import { ISplitPage } from "../SplitFile.types";

export const getPdfsForBulk = async (
  pages: ISplitPage[],
  groupIntoSingle: (files: File[], filename: string) => Promise<File | undefined>,
  danger: (text: string) => void
): Promise<File[]> => {
  const files: File[] = [];
  let filePageBuffer: File[] = [];
  let counter = 1;

  const groupFiles = async (filePageBuffer: File[]) => {
    const newFile = await groupIntoSingle(filePageBuffer, `file${counter}.pdf`);
    if (newFile) {
      files.push(newFile);
    } else {
      danger("Error while processing file");
      return [];
    }
  };

  for (const page of pages) {
    filePageBuffer.push(page.pdf);
    if (page.isMarkedToSplitAfter) {
      await groupFiles(filePageBuffer);
      filePageBuffer = [];
      counter++;
    }
  }
  await groupFiles(filePageBuffer);


  return files;
};

import { PageLayout } from "../../components/page-layout";
import useReports from "./useReports";
import { Column, Row } from "../../components/grid";
import { Button } from "react-bootstrap";
import { Icon } from "../../modules/icon";
import { Filters } from "../../components/filters";
import Select from "../../components/select/Select";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { useTranslation } from "react-i18next";

export function ReportsScreen() {
  const {
    loading,
    tableData,
    count,
    tableHeaders,
    exportReport,
    defaultGroupBy,

    onChangeGroupBy,

    onRowPress,
    onFilterChange,
    reportTableFilters,
  } = useReports();
  const { t } = useTranslation();
  return (
    <PageLayout
      title={t("REPORTS.TITLE")}
      titleRight={
        <div className="text-right mb-2">
          <Button className="btn btn-success" onClick={exportReport}>
            <Icon type="fa-solid fa-file-export" />
            &nbsp; {t("EXPORT_BUTTON")}
          </Button>
        </div>
      }
      pageInfo={
        <Row>
          <Column className="d-flex align-items-end">
            <Filters onFilterChange={onFilterChange} filters={reportTableFilters} />
          </Column>
          <Column>
            <div className="d-flex flex-row justify-content-end">
              <Select
                options={[
                  {
                    label: t("GROUP_BY.BASKET"),
                    value: "BASKET",
                  },
                  {
                    label: t("GROUP_BY.ASSIGNEE"),
                    value: "ASSIGNED_USER",
                  },
                  {
                    label: t("GROUP_BY.DOCUMENT_TYPE"),
                    value: "DOCUMENT_TYPE",
                  },
                ]}
                labelPosition="left"
                value={defaultGroupBy}
                onChange={onChangeGroupBy}
                title={t("GROUP_BY.LABEL")}
              />
            </div>
          </Column>
        </Row>
      }
    >
      <Row>
        <Column>
          <TableWithQueryPaginationAndSort
            totalItems={count}
            loading={loading}
            headers={tableHeaders}
            data={tableData}
            onRowPress={onRowPress}
            selection={false}
          />
        </Column>
      </Row>
    </PageLayout>
  );
}

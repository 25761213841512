import React, { ChangeEvent, useEffect } from "react";
import { itemsPerPageOptions } from ".";
import { TableRow, Selection, Pagination, TableData } from "./Table.types";

export function useTable(
  rows: TableRow[],
  selection: Selection | false,
  pagination: Pagination | false
) {
  const [numberOfPages, setNumberOfPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(
    pagination ? pagination.initialItemsPerPage : itemsPerPageOptions[0]
  );
  const [totalItems, setTotalItems] = React.useState(0);

  React.useEffect(() => {
    if (!pagination) return;
    const noOfPages = Math.ceil(pagination.totalItems / itemsPerPage) || 1;
    setNumberOfPages(noOfPages);
    setTotalItems(pagination.totalItems);
  }, [pagination, itemsPerPage]);

  useEffect(() => {
    const initialPage = pagination && pagination.initialPage ? pagination.initialPage : 1;
    setCurrentPage(initialPage);
    if (!!pagination && !!pagination.onPageChange) {
      pagination.onPageChange(initialPage);
    }
  }, [totalItems]);

  const setPage = (page: number) => {
    setCurrentPage(page);
    if (!!pagination && !!pagination.onPageChange) {
      pagination.onPageChange(page);
    }
  };

  const setItemsLimit = (limit: number) => {
    const oldLimit = itemsPerPage;
    setItemsPerPage(limit);
    if (!!pagination && !!pagination.onItemsPerPageChange) {
      pagination.onItemsPerPageChange(limit);
    }
    // Set current page to first partition of the page that was displayed for previous itemsPerPageValue
    setPage(Math.floor(((currentPage - 1) * oldLimit) / limit) + 1);
  };

  const onItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value);
    setItemsLimit(value);
  };

  const setSelected = (selected: any[]) => {
    if (selection && selection.onSelectionChanged) {
      selection.onSelectionChanged(selected);
    }
  };

  const onMainCheckBoxChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (!selection || !selection.selected) return;
    if (event.target.checked && !selection.selected.length) {
      setSelected(rows.map((r) => r[0]));
    } else {
      setSelected([]);
    }
  };

  const onRowCheckBoxChanged = (event: ChangeEvent<HTMLInputElement>, id: number | string) => {
    if (!selection || !selection.selected) return;
    if (event.target.checked) {
      if (!selection.selected.find((sr) => sr === id)) {
        setSelected([...selection.selected, id]);
      }
    } else {
      setSelected(selection.selected.filter((sr) => sr !== id));
    }
  };

  const renderTableItemValue = (value: TableData) => {
    return typeof value === "string"
      ? value.length < 25
        ? value
        : value.substring(0, 25) + "..."
      : value;
  };

  const renderTooltip = (value: TableData) => {
    return typeof value === "string" ? value : undefined;
  };

  return {
    onMainCheckBoxChanged,
    onRowCheckBoxChanged,
    onItemsPerPageChange,
    itemsPerPage,
    currentPage,
    numberOfPages,
    setPage,
    renderTableItemValue,
    renderTooltip,
  };
}

import { useTranslation } from "react-i18next";
import { translation } from "../../../components/translations";
import { GetTaskStatsResponse, TaskStatuses } from "../../../entities/ITask";
import { useEffect, useState } from "react";
import moment from "moment";
import { StatCardProps } from "../../../components/stat-card";
import { taskStatColors } from "../TaskStats.types";
import { useLocation } from "react-router-dom";
import { StatNumberCardProps } from "../../../components/stat-number-card";

export const useSetTaskStatsElements = (data: GetTaskStatsResponse | null) => {
  const language = translation.language;
  const { t } = useTranslation();
  const location = useLocation();
  const [basketsStat, setBasketsStat] = useState<undefined | StatCardProps[]>(undefined);
  const [statUrgentTaskNumbers, setStatUrgentTaskNumbers] = useState<
    undefined | StatNumberCardProps[]
  >(undefined);
  const [statStatusTaskNumbers, setStatStatusTaskNumbers] = useState<
    undefined | StatNumberCardProps[]
  >(undefined);
  const [statAHT, setStatAHT] = useState<undefined | StatNumberCardProps>(undefined);
  const [statART, setStatART] = useState<undefined | StatNumberCardProps>(undefined);
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    if (!data) return;
    setBasketsStat(
      data.baskets.map((item) => ({
        title: item.name,
        total: item.total,
        data: Object.keys(item)
          .filter((itemK) => itemK !== "total" && itemK !== "name")
          .map((itemK, i) => ({
            title: t(`DASHBOARD.${itemK}`),
            value: +item[itemK as keyof typeof item],
            color: taskStatColors[itemK as keyof typeof taskStatColors],
          })),
      }))
    );
    const selectedBaskets = queryParams.getAll("baskets").map((item) => +item) || [];
    setStatUrgentTaskNumbers([
      {
        title: t(`DASHBOARD.totalOlderThen8`),
        total: data.totalOlderThen8,
        color: "#DC3545",
        link: `/tasks?filters=${JSON.stringify({
          name: "status",
          operator: "in",
          value: JSON.stringify([
            TaskStatuses.OPEN,
            TaskStatuses.ASSIGNED,
            TaskStatuses.INPROGRESS,
            TaskStatuses.ONHOLD,
          ]),
          type: "stringArray",
        })}&filters=${JSON.stringify({
          name: "createdAt",
          operator: "lte",
          value: JSON.stringify(moment().subtract(8, "days").startOf("day").toDate()),
          type: "date",
        })}${
          selectedBaskets.length
            ? `&filters=${JSON.stringify({
                name: "basketId",
                operator: "in",
                value: JSON.stringify(selectedBaskets),
                type: "numberArray",
              })}`
            : ""
        }`,
      },
      {
        title: t(`DASHBOARD.totalOlderThen6`),
        total: data.totalOlderThen6,
        color: "#FFC107",
        link: `/tasks?filters=${JSON.stringify({
          name: "status",
          operator: "in",
          value: JSON.stringify([
            TaskStatuses.OPEN,
            TaskStatuses.ASSIGNED,
            TaskStatuses.INPROGRESS,
            TaskStatuses.ONHOLD,
          ]),
          type: "stringArray",
        })}&filters=${JSON.stringify({
          name: "createdAt",
          operator: "lte",
          value: JSON.stringify(moment().subtract(6, "days").startOf("day").toDate()),
          type: "date",
        })}${
          selectedBaskets.length
            ? `&filters=${JSON.stringify({
                name: "basketId",
                operator: "in",
                value: JSON.stringify(selectedBaskets),
                type: "numberArray",
              })}`
            : ""
        }`,
      },
    ]);
    setStatStatusTaskNumbers([
      {
        title: t(`DASHBOARD.totalAssigned`),
        total: data.totalAssigned,
        link: "",
      },
      {
        title: t(`DASHBOARD.totalUnassigned`),
        total: data.totalUnassigned,
        link: "",
      },
      {
        title: t(`DASHBOARD.totalResolved`),
        total: data.totalResolved,
        link: "",
      },
    ]);
    setStatAHT({
      title: t(`DASHBOARD.aht`),
      total:
        (data.aht || 0) > 0
          ? data.aht > 24
            ? `${Math.floor(data.aht / 24)} ${
                Math.floor(data.aht / 24) === 1 ? t("DASHBOARD.DAY") : t("DASHBOARD.DAYS")
              }`
            : `${Math.floor(data.aht)} ${
                Math.floor(data.aht) === 1 ? t("DASHBOARD.HOUR") : t("DASHBOARD.HOURS")
              }`
          : "0 " + t("DASHBOARD.HOURS"),
    });
    setStatART({
      title: t("DASHBOARD.art"),
      total:
        (data.art || 0) > 0
          ? data.art > 24
            ? `${Math.floor(data.art / 24)} ${
                Math.floor(data.art / 24) === 1 ? t("DASHBOARD.DAY") : t("DASHBOARD.DAYS")
              }`
            : `${Math.floor(data.art)} ${
                Math.floor(data.art) === 1 ? t("DASHBOARD.HOUR") : t("DASHBOARD.HOURS")
              }`
          : "0 " + t("DASHBOARD.HOURS"),
    });
  }, [data, language]);
  return {
    basketsStat,
    statUrgentTaskNumbers,
    statStatusTaskNumbers,
    statAHT,
    statART,
  };
};

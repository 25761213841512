import { useTranslation } from "react-i18next";
import { useToast } from "../../components/toast";
import { useSefService } from "../../services/useSefService";
import { ISyncSefInvoicesRequest } from "./SefIntegration.types";

export function useSefIntegration() {
  const { t } = useTranslation();
  const { useSyncSefInvoices } = useSefService();
  const { danger, success } = useToast();

  const { syncSefInvoices, loading: syncing } = useSyncSefInvoices({
    autoFetch: false,
    onCompleted: (response) => {
      success(t("SEF_SYNC.SYNC_MESSAGE", { numSynced: response.importedDocumentsIds?.length }));
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  return {
    title: t("SEF_SYNC.TITLE"),
    syncing,
    onSubmit: (event: ISyncSefInvoicesRequest) => {
      syncSefInvoices(event);
    },
  };
}

import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
import { TableHeaders } from "../../components/table";
export const providerTableHeaders: TableHeaders[] = [
  { name: "ID", key: "id" },
  { name: "TITLE", key: "name" },
  { name: "ACTIONS", key: "action" },
];
export const createProviderForm: GenericForm<{ name: string }> = {
  name: {
    type: "input",
    name: "name",
    label: "TABLE.NAME",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
};

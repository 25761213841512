const publicUrl = (window as any).public_url || "";

const assests = {
  images: {
    logoBigOnDark: publicUrl + "/images/logo-big-on-dark.svg",
    logoSmallOnDark: publicUrl + "/images/logo-small-on-dark.png",
    logoBigOnLight: publicUrl + "/images/logo-big-on-light.png",
    logo: publicUrl + "/images/logo.png",
    reupload: publicUrl + "/images/reupload.png",
    documentDraft: publicUrl + "/images/document-draft.png",
    documentUploadSession: publicUrl + "/images/document-bulk-upload.png",
  },
};

export default assests;

import { Column, Container } from "../grid";
import { PageLayoutProps } from "./PageLayout.types";
import { Loader } from "../../modules/loader";
import BackButton from "../back-button/BackButton";
import "./PageLayout.styles.css";
import { Icon } from "../../modules/icon";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function PageLayout({
  title,
  children,
  fullScreen = true,
  titleRight,
  loading,
  badge,
  backBtn = false,
  search,
  filter,
  pageContainerClassname = "p-md-4 p-3",
  pageInfo,
}: PageLayoutProps) {
  const [showFilter, setShowFilter] = useState(false);
  const location = useLocation();
  const activeFilters = location.search.includes("filter");
  useEffect(() => {
    activeFilters && setShowFilter(true);
  }, [activeFilters]);
  return (
    <div
      className="w100dis"
      style={{
        height: "calc(100vh - 65px)",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {(title || titleRight) && (
        <div className="page-header p-3 gap-2">
          <div className="flex-md-row flex-column gap-2 w-100 align-items-md-center justify-content-between">
            <div className="flex-1">
              {title ? (
                <h3
                  className="flex-row gap-2 mb-0"
                  style={{
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {backBtn ? <BackButton onClick={backBtn} /> : null}

                  {title}

                  {badge ? badge : null}
                </h3>
              ) : null}
            </div>
            <div className="flex-row gap-2 flex-1">
              {search ? search : null}
              {filter ? (
                <button
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                  className="btn btn-light shadow-light"
                  style={{
                    width: "42px",
                    height: "42px",
                    padding: undefined,
                    color: activeFilters ? "#52ccad" : "#00264d",
                  }}
                >
                  <Icon
                    className="flex-row justify-content-center"
                    type={"fa-solid fa-filter"}
                    size={16}
                    title={showFilter ? "Hide Filters" : "Show Filters"}
                  />
                </button>
              ) : null}
              {titleRight ? titleRight : null}
            </div>
          </div>
          {filter && showFilter ? <div>{filter}</div> : null}
          {pageInfo && pageInfo}
        </div>
      )}
      <Container fluid={fullScreen}>
        <Column className={`col ${pageContainerClassname}`}>
          {loading ? (
            <div className="text-center mt-5">
              <Loader color="black" size="m" />
            </div>
          ) : (
            children
          )}
        </Column>
      </Container>
    </div>
  );
}

import { useTranslation } from "react-i18next";
import { useTaskAttachments } from "./useTaskAttachments";
import { AddAttachmentModal } from "./AddAttachmentModal";
import { TaskAttachment } from "./TaskAttachment";
import { UpdateAttachmentModal } from "./UpdateAttachmentModal";
import { DialogModal } from "../../components/modal/DialogModal";
import Header from "../../components/header/Header";
import { RoleBasedAccess } from "../../components/guard";
import { UserRoles } from "../../entities/IUser";

export const TaskAttachments = ({ taskId }: { taskId: number }) => {
  const { t } = useTranslation();
  const {
    attachments,
    action,
    onAddAttachmentStart,
    onDelete,
    onDismiss,
    onEdit,
    addAttachmentForm,
    onAdd,
    addingTaskAttachment,
    editAttachmentForm,
    edit,
    editing,
    deleteAttachment,
    onExecuteAction,
  } = useTaskAttachments(taskId);
  return (
    <div className="mt-3">
      <AddAttachmentModal
        show={action === "create"}
        adding={addingTaskAttachment}
        onDismiss={onDismiss}
        onSubmit={onAdd}
        addAttachmentForm={addAttachmentForm}
      />
      <UpdateAttachmentModal
        show={action === "update"}
        editing={editing}
        onDismiss={onDismiss}
        onSubmit={edit}
        updateAttachmentForm={editAttachmentForm as any}
      />
      <DialogModal
        onAccept={deleteAttachment}
        show={action === "delete"}
        onCancel={onDismiss}
        acceptLabel="DELETE_BUTTON"
        cancelLabel="CANCEL_BUTTON"
        title="TASKS.DELETE_ATTACHMENT"
        description="TASKS.DELETE_ATTACHMENT_LABEL"
      />
      <Header
        className="mb-2"
        actions={
          <RoleBasedAccess
            allowedRoles={[UserRoles.ADMIN, UserRoles.SUPERVISOR, UserRoles.BO_EXPERT]}
          >
            <button className="btn btn-dark" onClick={onAddAttachmentStart}>
              {t("TASKS.ADD_ATTACHMENT")}
            </button>
          </RoleBasedAccess>
        }
        title={t("TASKS.ATTACHMENTS")}
      />
      <div className="flex-column gap-2">
        {attachments.map((attachment, index) => (
          <TaskAttachment
            num={index + 1}
            key={attachment.id}
            type={attachment.type}
            id={attachment.id}
            title={attachment.content ? attachment.content.title : "Attachment " + attachment.id}
            description={attachment.content ? attachment.content.description : ""}
            labels={attachment.labels}
            content={attachment.document || attachment.message}
            onEdit={onEdit}
            onDelete={onDelete}
            onDocumentAction={onExecuteAction}
          />
        ))}
      </div>
    </div>
  );
};

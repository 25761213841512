import { useState, useEffect } from "react";
import { GenericFormControl, GenericFormControlProps } from "./GenericForm.types";
import { GenericFormControl as FormControl } from "./GenericFormControl";
import { GenericFormValidator } from "./utility/GenericFormValidator";
import { useTranslation } from "react-i18next";
import { Icon } from "../../modules/icon";

export const FormArray = <T, P>({
  formState,
  control,
  isSubmitted,
  isTouched,
  onChange,
}: GenericFormControlProps<T, P>) => {
  const { t } = useTranslation();
  const [array, setArray] = useState<GenericFormControl<T, P>[]>([]);
  const [values, setValues] = useState<{ [name: string]: P }>({});

  useEffect(() => {
    onChange(Object.values(values));
  }, [values]);

  const addControl = () => {
    const newControl: GenericFormControl<T, P> = {
      name: array.length.toString(),
      defaultValue: "" as P,
      type: "input",
      validators: [GenericFormValidator.required()],
    };

    setArray([...array, newControl]);
    setValues({ ...values, [array.length.toString()]: newControl.defaultValue });
  };

  const deleteControl = (index: number) => {
    const newArray = array
      .filter((control) => +control.name !== index)
      .map((control, index) => ({ ...control, name: index.toString() }));

    setArray(newArray);

    const newValues = { ...values };
    delete newValues[index.toString()];
    setValues(newValues);
  };

  const onControlChange = (newValue: P, index: number) => {
    setValues({ ...values, [index.toString()]: newValue });
  };

  const renderControls = () => {
    return array.map((control, index) => (
      <div key={index}>
        <div className="row mt-1">
          <div className="col-md-11">
            <FormControl
              control={control}
              value={values[index.toString()] || control.defaultValue}
              formState={formState}
              isSubmitted={isSubmitted}
              isTouched={isTouched}
              onChange={(value) => onControlChange(value, index)}
              error={isTouched && !values[index.toString()] ? "error" : null}
            />
          </div>
          <div className="col-md-1  p-0">
            <Icon
              type="fa-solid fa-times"
              color="black"
              size={16}
              onClick={() => {
                deleteControl(index);
              }}
              className="ms-2"
            />
          </div>
        </div>
        {isTouched && isSubmitted && !values[index.toString()] ? (
          <div className="text-danger">
            <small>{t("FORM.OPTION_NOT_EMPTY")}</small>
          </div>
        ) : null}
      </div>
    ));
  };

  return (
    <>
      {renderControls()}
      <Icon
        type="fa-solid fa-plus"
        color="black"
        size={16}
        onClick={() => {
          addControl();
        }}
        className="ms-2 mt-2"
      />
    </>
  );
};

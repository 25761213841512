import { useTranslation } from "react-i18next";
import { Button } from "../../components/button";
import { GenericForm } from "../../components/generic-form";
import { DocumentDataUpdateProps } from "./Document.types";
import AutocompleteOpt from "../../components/autocomplete-opt/AutocompleteOpt";

export function DocumentDataUpdate({
  docType,
  metaDataForm,
  onUpdate,
  updating = false,
  labels,
  onLabelChange,
}: DocumentDataUpdateProps) {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column p-3 rounded-2" style={{ backgroundColor: "#fff" }}>
      <div className="form-group">
        <label>{t("NEW_DOCUMENT.DOCUMENT_TYPE")}</label>
        <input className="form-control bg-light cursor-text" value={docType.name} disabled />
      </div>
      <AutocompleteOpt
        label={t("LABELS.LABEL")}
        placeholder={t("LABELS.SEARCH_LABEL")}
        onSelectOption={(labels) => onLabelChange(labels || ([] as any))}
        endpoint={"/labels/search-autocomplete"}
        isMultiple
        defaultValue={labels.map((label) => label.value)}
      />
      <div className="mt-4">
        {metaDataForm ? (
          <div>
            <h5>{t("NEW_DOCUMENT.DATA")}</h5>
            <GenericForm
              className="mt-3"
              form={metaDataForm}
              onSubmit={onUpdate}
              submitControl={(submit) => (
                <Button
                  className="btn btn-primary w-100 mt-3"
                  type="button"
                  onClick={submit}
                  isLoading={updating}
                  disabled={updating}
                >
                  {t("SAVE_BUTTON")}
                </Button>
              )}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

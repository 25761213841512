import { IOption } from "../select";
import { useState, useEffect } from "react";
function useAutocomplete<T>(
  inputValue: string,
  onChange: (selected: IOption<T> | IOption<T>[] | undefined) => void,
  onInputChange: (selected: string) => void,
  value: IOption<T> | IOption<T>[] | undefined | null,
  options: IOption<T>[],
  isMultiple: boolean,
  inputRef: React.RefObject<HTMLDivElement>,
  dropdownRef: React.RefObject<HTMLUListElement>,
  minCharsToSearch: number = 1
) {
  //Handling change of input value
  const onInputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onInputChange(event.target.value);
  };

  //Handling show of options
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    if (inputValue.length >= minCharsToSearch && !!!value && clicked) {
      setShowOptions(true);
      return;
    }
    if (
      inputValue.length >= minCharsToSearch &&
      Array.isArray(value) &&
      clicked
    ) {
      setShowOptions(true);
      return;
    }
    setShowOptions(false);
  }, [inputValue, options]);

  //Handling select of option
  const onSelectOption = (event: React.SyntheticEvent<Element, Event>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!!!(event.target as any).value) return;
    if (isMultiple) {
      if (value instanceof Array) {
        onChange([
          ...value.filter(
            (item) =>
              item.value !== JSON.parse((event.target as any).value).value
          ),
          JSON.parse((event.target as any).value),
        ]);
      } else onChange([JSON.parse((event.target as any).value)]);
    } else onChange(JSON.parse((event.target as any).value));
  };

  // Handling remove of selected option
  const onRemove = (val: T) => {
    if (isMultiple) {
      if (value instanceof Array)
        onChange(value.filter((item) => item.value !== val));
    } else onChange(undefined);
  };

  const onRemoveAll = () => {
    onChange(undefined);
  };

  //Handling click outside of input and dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return {
    onInputChangeHandler,
    showOptions,
    onSelectOption,
    onRemove,
    onRemoveAll,
    onFocusFirstTime: () => setClicked(true),
  };
}

export default useAutocomplete;

import React from "react";
import { GenericForm, GenericFormState, UseGenericFormProps } from "./GenericForm.types";
import { GenericFormValidator } from "./utility/GenericFormValidator";
import { translation } from "../translations";
import { FormArrayValidator } from "./utility/FormArrayValidator";

export function useGenericForm<T>({
  onSubmit,
  entity,
  form,
  formRef,
  resetFormOnSubmit,
  submitFormOnEnter,
  onError,
}: UseGenericFormProps<T>) {
  const onFormSubmit = (event: any) => {
    event.preventDefault();
    if (!submitFormOnEnter) return;
    submit();
  };

  entity = entity || ({} as T);
  const onSubmitCb = onSubmit
    ? onSubmit
    : () => {
        return;
      };
  const [formState, setFormState] = React.useState<GenericFormState<T>>(
    buildState<T>(form, entity)
  );

  React.useEffect(() => {
    if (formRef) {
      formRef.current.setState = (state) => {
        setFormState(buildState(form, state));
      };
    }
  }, [formRef, form]);

  React.useEffect(() => {
    const { validatedState } = validateForm(form, formState);

    setFormState(validatedState);
  }, [translation.language]);

  function onValueChange<PValue>(key: keyof T, value: PValue) {
    if (!formState[key]) return;
    const { validatedState } = validateForm(form, {
      ...formState,
      [key]: {
        ...formState[key],
        value: typeof value === "string" ? value.trimStart() : value,
        isTouched: true,
      },
    });

    setFormState({ ...validatedState });
  }

  function submit() {
    const { validatedState, hasErrors } = validateForm(form, formState, true);
    setFormState(validatedState);
    if (hasErrors) {
      onError && onError();
      return;
    }
    const payload: T = populatePayload(form, formState);
    if (resetFormOnSubmit) {
      setFormState(cleanState(form));
    }
    return onSubmitCb(payload);
  }

  return { onFormSubmit, submit, onValueChange, formState };
}

// helper func

function validateForm<FType>(
  F: GenericForm<FType>,
  state: GenericFormState<FType>,
  submitted?: boolean
): {
  hasErrors: boolean;
  validatedState: GenericFormState<FType>;
} {
  let hasErrors = false;
  const validatedState: GenericFormState<FType> = {} as GenericFormState<FType>;
  Object.keys(F).forEach((key) => {
    const k = key as keyof FType;
    validatedState[k] = {
      value:
        typeof state[k].value === "string" ? (state[k].value as any).trimStart() : state[k].value,
      error: state[k].error,
      isTouched: submitted || state[k].isTouched,
      isSubmitted: !!submitted || state[k].isSubmitted,
      required: F[k].validators && F[k].validators?.some((v) => v.toString().includes("REQUIRED")),
    };
    const control = F[k];
    if (!control.validators) {
      return;
    }
    let validator = Array.isArray(state[k].value)
      ? new FormArrayValidator(state[k].value as any, control.label || key)
      : new GenericFormValidator(state[k].value as any, control.label || key);

    const isVisible = F[k].visibleIf ? (F[k] as any).visibleIf(state) : true;

    // If the control is not visible, don't perform validation
    const status = validator.validate(isVisible ? control.validators : []);
    validatedState[k].error = status.error || null;
    if (!status.valid) {
      hasErrors = true;
    }
  });
  return { hasErrors, validatedState };
}

function populatePayload<FType>(F: GenericForm<FType>, FS: GenericFormState<FType>) {
  const fPayload: FType = {} as FType;
  if (!F) {
    return fPayload;
  }
  Object.keys(FS).forEach((key) => {
    const k = key as keyof FType;
    const controlFormState = FS[k];
    let v = controlFormState.value;
    if (typeof v === "string") {
      v = v.trim() as any;
    }
    const parse = F[k].parseOnSubmit;
    if (parse) {
      v = parse(v);
    }
    fPayload[k] = v;
  });
  return fPayload;
}

function cleanState<FType>(F: GenericForm<FType>): GenericFormState<FType> {
  const state: GenericFormState<FType> = {} as GenericFormState<FType>;

  Object.keys(F).forEach((key) => {
    const k = key as keyof FType;
    state[k] = {
      value: F[k].defaultValue,
      error: null,
      isTouched: false,
      isSubmitted: false,
      required: F[k].validators && F[k].validators?.some((v) => v.toString().includes("REQUIRED")),
    };
  });
  return state;
}

function buildState<FType>(F: GenericForm<FType>, entity: FType): GenericFormState<FType> {
  entity = entity || ({} as FType);
  const state: GenericFormState<FType> = {} as GenericFormState<FType>;
  Object.keys(F).forEach((key) => {
    const k = key as keyof FType;
    state[k] = {
      value:
        typeof entity[k] !== "undefined"
          ? typeof entity[k] === "string"
            ? (entity[k] as any).trim()
            : entity[k]
          : F[k].defaultValue,
      error: null,
      isTouched: false,
      isSubmitted: false,
      required: F[k].validators && F[k].validators?.some((v) => v.toString().includes("REQUIRED")),
    };
  });
  return state;
}

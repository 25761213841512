import { Modal } from "react-bootstrap";
import { TaskMoveToAnotherBasketModalProps } from "./Task.types";
import { Button } from "../../components/button";
import AutocompleteOpt from "../../components/autocomplete-opt/AutocompleteOpt";
import { IOption } from "../../components/select";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export function TaskMoveToAnotherBasketModal({
  show,
  onSubmit,
  onCancel,
  moving,
  currentBasketId,
}: TaskMoveToAnotherBasketModalProps) {
  const [selectedBasket, setSelectedBasket] = useState<IOption<number> | undefined>(undefined);
  const [errMsg, setErrMsg] = useState<string>("");
  const { t } = useTranslation();
  return (
    <Modal show={show} animation onHide={onCancel}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {t("TASKS.MOVE_TASK_TITLE")}
        </h5>
        <div onClick={onCancel}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>{t("TASKS.MOVE_TASK_MESSAGE")}</p>
        <AutocompleteOpt
          minCharsToSearch={0}
          limit={1000}
          excluded={currentBasketId ? [currentBasketId] : undefined}
          onSelectOption={(basket) => {
            !Array.isArray(basket) && setSelectedBasket(basket);
            setErrMsg("");
          }}
          endpoint="/baskets/search-autocomplete"
        />
        <p className={"text-danger mt-2"}>{errMsg}</p>
        <div className="flex-row">
          <Button
            isLoading={moving}
            loader={{ size: "s" }}
            className="btn btn-primary mt-3 w-100"
            onClick={() => {
              typeof selectedBasket !== "undefined"
                ? onSubmit(selectedBasket.value)
                : setErrMsg(t("TASKS.BASKET_SELECTION_ERROR"));
            }}
          >
            {t("APPLY")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

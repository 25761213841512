import Header from "../../components/header/Header";
import { BasketSectionProps } from "./Basket.types";
import BasketCard from "./BasketCard";
import CardContainer from "../../components/card/CardContainer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BookmarkedBaskets = ({ baskets, onBookmark, onDelete, onEdit }: BasketSectionProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      {!!baskets.length && (
        <section className="flex-column gap-2">
          <Header title={t("BASKETS.BOOKMARKED_BASKET")} size="h4" />
          <CardContainer
            children={[
              ...baskets.map((item, i) => (
                <BasketCard
                  key={i}
                  onBookmark={onBookmark}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onClick={() => {
                    navigate(`/baskets/${item.id}`);
                  }}
                  name={item.name}
                  numberOfTasks={item.numberOfTasks}
                  id={item.id}
                  isBookmarked={item.isBookmarked}
                />
              )),
            ]}
            cardGap={16}
            cardMinWidth={128}
            cardMaxWidth={208}
            cardMaxNumberPerRow={10}
            isInline
          />
        </section>
      )}
    </>
  );
};

export default BookmarkedBaskets;

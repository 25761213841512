import { IOption } from "../select";

function Option<T>(option: IOption<T>) {
  return (
    <li>
      <option
        className="dropdown-item"
        value={JSON.stringify({ label: option.label, value: option.value })}
      >
        {option.label}
      </option>
    </li>
  );
}

function Options<T>({ options }: { options: IOption<T>[] }) {
  return (
    <>
      {options.map((option, index) => (
        <Option key={index} {...option} />
      ))}
    </>
  );
}
export default Options;

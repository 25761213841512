import useBaseRequest from "../api/BaseRequest";
import SefGateway from "../api/gateways/SefGateway";
import { RequestOptions } from "../modules/request";
import { ISyncSefInvoicesRequest, ISyncSefInvoicesResponse } from "../screens/sef-integration/SefIntegration.types";

export function useSefService() {
  function useSyncSefInvoices(options?: RequestOptions<ISyncSefInvoicesResponse, ISyncSefInvoicesRequest>) {
    const {
      execute: syncSefInvoices,
      loading,
      data: syncResponse,
    } = useBaseRequest(SefGateway.syncSefInvoices, options);
    return { syncSefInvoices, syncResponse, loading };
  }
  
  return {
    useSyncSefInvoices
  }
}
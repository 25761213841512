import { PageLayout } from "../../components/page-layout";
import useTaskStats from "./useTaskStats";
import AutocompleteOpt from "../../components/autocomplete-opt/AutocompleteOpt";
import StatNumberCard from "../../components/stat-number-card/StatNumberCard";
import CardContainer from "../../components/card/CardContainer";
import StatCard from "../../components/stat-card/StatCard";
import { StandaloneDateRangeFilter } from "../../components/filters/DateRangeFilter/StandaloneDateRangeFilter";
import { useTranslation } from "react-i18next";

const TaskStatsScreen = () => {
  const {
    loading,
    onChangeDateRange,
    onChangeBasket,
    defaultBasket,
    defaultDateRange,
    statUrgentTaskNumbers,
    statStatusTaskNumbers,
    basketsStat,
    statAHT,
    statART,
  } = useTaskStats();
  const { t } = useTranslation();
  return (
    <PageLayout
      loading={loading}
      title={t("DASHBOARD.TITLE")}
      pageInfo={
        <div className="flex-row flex-wrap gap-2 mt-2">
          <div>
            <AutocompleteOpt
              label={t("BASKETS.LABEL")}
              placeholder={t("DASHBOARD.BASKET_FILTER_PLACEHOLDER")}
              onSelectOption={(option) => {
                if (Array.isArray(option) || option === undefined)
                  onChangeBasket(option?.map((item) => item.value));
              }}
              defaultValue={defaultBasket}
              isMultiple
              endpoint={"/baskets/search-autocomplete"}
            />
          </div>
          <StandaloneDateRangeFilter
            label={t("FILTERS.DATE")}
            onSet={(value) => {
              onChangeDateRange(value);
            }}
            defaultVal={defaultDateRange}
          />
        </div>
      }
    >
      <div className="flex-column gap-3">
        <section className="flex-column gap-1">
          <h6
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title={t("DASHBOARD.TASK_STATS_DATE_FILTER_EXCEPTION")}
          >
            {t("DASHBOARD.BY_URGENCY")} *
          </h6>
          <div className="flex-md-row flex-column flex-wrap gap-2 w-100">
            {statUrgentTaskNumbers?.map((item, i) => (
              <StatNumberCard {...item} key={i} />
            ))}
          </div>
        </section>
        <section className="flex-column gap-1">
          <h6>{t("DASHBOARD.BY_STATUS")}</h6>
          <div className="flex-md-row flex-column flex-wrap gap-2 w-100">
            {statStatusTaskNumbers?.map((item, i) => (
              <StatNumberCard {...item} key={i} />
            ))}
          </div>
        </section>
        <section className="flex-column gap-1">
          <h6>{t("DASHBOARD.TIME_STATS")}</h6>
          <div className="flex-md-row flex-column flex-wrap gap-2 w-100">
            {statAHT && <StatNumberCard {...statAHT} />}
            {statART && <StatNumberCard {...statART} />}
          </div>
        </section>
        <section className="flex-column gap-1">
          <h6>{t("DASHBOARD.BASKET_STATS")}</h6>
          {basketsStat && (
            <CardContainer
              children={[
                ...basketsStat.map((item, i) => (
                  <StatCard
                    legendOptions={{
                      isVisible: true,
                      position: "bottom",
                      total: { title: t("DASHBOARD.TOTAL"), isVisible: true },
                    }}
                    {...item}
                    key={i}
                  />
                )),
              ]}
              cardGap={16}
              cardMinWidth={240}
              cardMaxNumberPerRow={4}
            />
          )}
        </section>
      </div>
    </PageLayout>
  );
};
export default TaskStatsScreen;

import { LoadMoreButtonProps } from "./AutoComplete.types";

const LoadMoreButton = ({ onLoadMore, isShowing }: LoadMoreButtonProps) => {
  return (
    <>
      {isShowing && (
        <>
          <hr className="dropdown-divider" />
          <div
            className="dropdown-item flex-row justify-content-between align-items-center cursor-pointer"
            onClick={() => {
              onLoadMore && onLoadMore();
            }}
          >
            <span>Load more</span>
          </div>
        </>
      )}
    </>
  );
};
export default LoadMoreButton;

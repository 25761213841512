import { Button } from "../button";
import { TaskBulkActionsProps } from "./TaskBulkActions.types";
import { TaskBulkReassignModal } from "./TaskBulkReassignModal";
import { Row } from "../grid";
import { TaskBulkMoveToAnotherBasketModal } from "./TaskBulkMoveToAnotherBasketModal";

const TaskBulkActions = ({
  selectedTasks,
  selectedAction,
  setSelectedAction,
  onReassign,
  reassigning,
  onMove,
  onDismiss,
  moving,
}: TaskBulkActionsProps) => {
  if (!selectedTasks.length) return null;
  return (
    <Row>
      <TaskBulkReassignModal
        show={selectedAction === "assign"}
        onSubmit={onReassign}
        processing={reassigning}
        taskIds={selectedTasks}
        onCancel={function () {
          setSelectedAction("");
          onDismiss();
        }}
      />
      <TaskBulkMoveToAnotherBasketModal
        show={selectedAction === "move"}
        onSubmit={onMove}
        processing={moving}
        taskIds={selectedTasks}
        onCancel={function () {
          setSelectedAction("");
          onDismiss();
        }}
      />
      <div className="flex-row gap-2">
        <Button
          className="btn btn-secondary"
          onClick={() => {
            setSelectedAction("assign");
          }}
        >
          Reassign ({selectedTasks.length})
        </Button>
        <Button
          className="btn btn-secondary"
          onClick={() => {
            setSelectedAction("move");
          }}
        >
          Move to another basket ({selectedTasks.length})
        </Button>
      </div>
    </Row>
  );
};

export default TaskBulkActions;

import { useTranslation } from "react-i18next";
import { PageLayout } from "../../components/page-layout";
import { Button } from "../../components/button";
import { Icon } from "../../modules/icon";
import { Column, Row } from "../../components/grid";
import { FiltersWithQuery } from "../../components/filters";
import { SearchWithQueryInput } from "../../components/search-input";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { customFieldsTableHeaders } from "./CustomFields.types";
import { FormModal } from "../../components/modal";
import { useCustomFields } from "./useCustomFields";

export function CustomFieldsScreen() {
  const { t } = useTranslation();
  const {
    createCustomField,
    creatingCustomField,
    hideCreateCustomFieldModalHandler,
    isVisibleCreateCustomFieldModal,
    showCreateCustomFieldModalHandler,
    customFieldCount,
    customFieldsTableData,
    fetchingCustomFields,
    customFieldFilters,
    createCustomFieldForm,
  } = useCustomFields();

  return (
    <PageLayout
      title={t("CUSTOM_FIELDS.TITLE")}
      filter={<FiltersWithQuery filters={customFieldFilters} />}
      search={<SearchWithQueryInput placeHolder={t("CUSTOM_FIELDS.SEARCH")} />}
      titleRight={
        <div className="text-right flex-row gap-2">
          <div style={{ height: "100%", width: "2px", backgroundColor: "black" }} />
          <Button className="btn btn-dark" onClick={showCreateCustomFieldModalHandler}>
            <Icon type="fa fa-plus" title={t("CUSTOM_FIELDS.CREATE_LABEL")} />
          </Button>
        </div>
      }
    >
      <FormModal
        loading={creatingCustomField}
        isVisible={isVisibleCreateCustomFieldModal}
        onDismiss={hideCreateCustomFieldModalHandler}
        onSubmit={createCustomField}
        form={createCustomFieldForm}
        submitButtonLabel="CREATE_BUTTON"
        title="DOCUMENT_TYPE.CREATE_CUSTOM_FIELD"
      />
      <Row>
        <Column>
          <TableWithQueryPaginationAndSort
            headers={customFieldsTableHeaders}
            data={customFieldsTableData}
            totalItems={customFieldCount}
            selection={false}
            loading={fetchingCustomFields}
          />
        </Column>
      </Row>
    </PageLayout>
  );
}

export interface FiltersProps {
  filters: Filter[];
  onFilterChange: (queryFilters: QueryFilter[]) => any;
}

export interface FilterProps {
  filter: Filter;
  onRemove: (filter: Filter) => any;
  onFilterSet: (queryFilter: QueryFilter, filter: Filter) => any;
}

export interface Filter {
  type: FilterType;
  name: string;
  defaultValues?: string[] | Date[] | number[] | string | Date | number | undefined;
  buildQuery: (
    filter: Filter,
    value: string[] | Date[] | number[] | (undefined | string | number | Date)[],
    operator?: "in" | "between" | "eq" | "gt" | "lt" | "gte" | "lte" | "ne"
  ) => QueryFilter;
  options?: Array<{ label: string; value: string | number }>;
}

export interface QueryFilter {
  name: string;
  value: any;
  operator: string;
  type: DataType;
}

export type FilterType =
  | "date-range"
  | "single-choice"
  | "multiple-choice"
  | "multiple-choice-autocomplete"
  | "number-range";

export type DataType =
  | "string"
  | "date"
  | "number"
  | "stringArray"
  | "dateArray"
  | "numberArray"
  | "boolean"
  | "booleanArray";

export enum DateRangeTypeValues {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  THIS_WEEK = "THIS_WEEK",
  LAST_WEEK = "LAST_WEEK",
  THIS_MONTH = "THIS_MONTH",
  LAST_MONTH = "LAST_MONTH",
  THIS_YEAR = "THIS_YEAR",
  LAST_YEAR = "LAST_YEAR",
  BETWEEN = "BETWEEN",
  TO = "TO",
  FROM = "FROM",
}

export enum NumberRangeTypeValues {
  EQUAL = "EQUAL",
  BETWEEN = "BETWEEN",
  GREATER_THAN = "GREATER_THAN",
  LESS_THAN = "LESS_THAN",
}

export interface DateRangeValueProps {
  value: DateRangeTypeValues;
  toDate: Date | undefined;
  fromDate: Date | undefined;
}

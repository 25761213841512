import { useParams } from "react-router";
import { Button } from "../../components/button";
import { FilePreview } from "../../components/file-preview";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { Icon } from "../../modules/icon";
import { DocumentDataPreview } from "./DocumentDataPreview";
import { DocumentDataUpdate } from "./DocumentDataUpdate";
import { useDocument } from "./useDocument";
import { useTranslation } from "react-i18next";
import { Badge } from "../../components/badge";
import { breakPoints } from "../../components/grid";
import "./Document.styles.css";
import { ViewDigitalSignatureModal } from "../../components/request-digital-signature/ViewDigitalSignatureModal";
import { DocumentActionsTypes } from "../../entities/IDocument";
import { RequestDigitalSignatureModal } from "../../components/request-digital-signature";
import { DeleteDigitalSignatureModal } from "../../components/delete-digital-signature";
import { DocumentActions } from "./DocumentActions";
import { DialogModal } from "../../components/modal/DialogModal";

export function DocumentScreen() {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    document,
    loading,
    windowHeight,
    windowWidth,
    onUpdateDoc,
    metaDataForm,
    editing,
    docFile,
    onFileEdit,
    onFileEditCancel,
    updating,
    labels,
    updateLabels,
    previewLabels,
    onRequestDigitalSignature,
    requestingDigitalSignature,
    requestDigitalSignatureForm,
    digitalSignatureFields,
    onClearAction,
    selectedAction,
    onResendDigitalSignature,
    onStartDeleteDigitalSignature,
    onDeleteDigitalSignature,
    showDeleteSignatureModal,
    closeDeleteDigitalSignatureModal,
    onActionSelected,
    user,
    onDismiss,
  } = useDocument(id || "");

  return (
    <PageLayout
      title={`${t("DOCUMENTS.DOCUMENT")} #${document?.metaData?.subjectNumber || id}`}
      backBtn
      pageInfo={
        <div className="d-flex flex-row gap-2 flex-wrap">
          <Badge variant="outlined" type="primary" className="fs-6 fw-normal">
            {document?.docType.name}
          </Badge>
          {previewLabels?.map((label, index) => (
            <Badge
              key={index}
              variant="outlined"
              color={label.color}
              type="primary"
              className="fs-6 fw-normal"
            >
              {label.name}
            </Badge>
          ))}
        </div>
      }
      titleRight={
        document ? (
          <div className="d-flex flex-row align-items-center justify-content-end mr-3">
            {selectedAction === DocumentActionsTypes.EDIT_DOCUMENT ? (
              <Button className="btn btn-danger" onClick={onClearAction}>
                <Icon type="fa-solid fa-xmark" size={16} />
                &nbsp; Cancel
              </Button>
            ) : (
              <DocumentActions
                onActionSelected={onActionSelected}
                document={document}
                user={user}
              />
            )}
          </div>
        ) : undefined
      }
      /*document ? (
          <div className="d-flex flex-row align-items-center justify-content-end mr-3">
            {editing ? (
              <Button className="btn btn-danger" onClick={onEditCancel}>
                <Icon type="fa-solid fa-xmark" size={16} />
                &nbsp; {t("CANCEL_BUTTON")}
              </Button>
            ) : (
              <div className="flex-row gap-2">
                {isTaskcreated ? (
                  <Button className="btn btn-secondary" onClick={taskStartCreate}>
                    <Icon type={`fa-solid fa-eye`} />
                    &nbsp;{t("TASKS.VIEW_LABEL")}
                  </Button>
                ) : canManageDocument() && false ? ( //TODO: Implement this feature later
                  <>
                    <Button className="btn btn-secondary" onClick={taskStartCreate}>
                      <Icon type={`fa-solid fa-plus`} />
                      &nbsp;{t("TASKS.CREATE_LABEL")}
                    </Button>
                    <CreateTaskModal
                      onDismiss={hideCreateTaskModal}
                      onSubmit={onCreateTask}
                      creating={creatingTask}
                      isVisible={isVisibleCreateTaskModal}
                      createTaskForm={createTaskForm}
                    />
                  </>
                ) : null}

                {canEditDocument() && (
                  <Button className="btn btn-primary" onClick={onEdit}>
                    <Icon type="fa-solid fa-file-pen" />
                    &nbsp; {t("EDIT_BUTTON")}
                  </Button>
                )}
                {canDeleteDocument() ? (
                  <>
                    <Button className="btn btn-danger" onClick={openModal}>
                      <Icon type="fa-solid fa-folder-minus" />
                      &nbsp; {t("DELETE_BUTTON")}
                    </Button>
                    <DialogModal
                      onAccept={onDismiss}
                      show={showDismissModal}
                      onCancel={closeModal}
                      acceptLabel="DELETE_BUTTON"
                      cancelLabel="CANCEL_BUTTON"
                      title="DOCUMENTS.DELETE"
                      description="DOCUMENTS.DELETE_LABEL"
                    />
                  </>
                ) : null}
              </div>
            )}
          </div>
                ) : undefined*/
    >
      <DialogModal
        onAccept={onDismiss}
        show={selectedAction === DocumentActionsTypes.DELETE_DOCUMENT}
        onCancel={onClearAction}
        acceptLabel="DELETE_BUTTON"
        cancelLabel="CANCEL_BUTTON"
        title="DOCUMENTS.DELETE"
        description="DOCUMENTS.DELETE_LABEL"
      />
      <ViewDigitalSignatureModal
        fields={digitalSignatureFields}
        isVisible={selectedAction === DocumentActionsTypes.VIEW_DIGITAL_SIGNATURE}
        onDismiss={onClearAction}
        onResend={onResendDigitalSignature}
        onDelete={onStartDeleteDigitalSignature}
      />
      <RequestDigitalSignatureModal
        onDismiss={onClearAction}
        onSubmit={onRequestDigitalSignature}
        creating={requestingDigitalSignature}
        isVisible={selectedAction === DocumentActionsTypes.REQUEST_DIGITAL_SIGNATURE}
        requestDigitalSignatureForm={requestDigitalSignatureForm}
      />
      <DeleteDigitalSignatureModal
        show={showDeleteSignatureModal}
        onDelete={onDeleteDigitalSignature}
        onCancel={closeDeleteDigitalSignatureModal}
      />
      {document ? (
        <Row>
          <Column>
            {docFile ? (
              <div
                className="d-flex flex-column align-items-center mb-2"
                style={{ position: "sticky", left: 0, top: "128px" }}
              >
                <FilePreview
                  classNameContainer="document-preview-container"
                  enableCanceling={docFile.edited && editing}
                  onCancel={onFileEditCancel}
                  onFileChosen={onFileEdit}
                  enableEditing={editing}
                  type={docFile.type}
                  url={docFile.uri}
                  name={docFile.name}
                  width={breakPoints.m < windowWidth ? windowWidth * 0.5 : windowWidth - 64}
                  height={windowHeight - 282}
                />
              </div>
            ) : null}
          </Column>
          <Column>
            {selectedAction === DocumentActionsTypes.EDIT_DOCUMENT ? (
              <DocumentDataUpdate
                docType={document.docType}
                metaDataForm={metaDataForm}
                onUpdate={onUpdateDoc}
                updating={updating}
                labels={labels}
                onLabelChange={updateLabels}
              />
            ) : (
              <DocumentDataPreview
                docType={document.docType}
                metaDataForm={metaDataForm}
                labels={document.labels || []}
              />
            )}
          </Column>
        </Row>
      ) : loading ? null : (
        <h4>{t("DOCUMENTS.NO_FOUND")}</h4>
      )}
    </PageLayout>
  );
}

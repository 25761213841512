import moment from "moment";
import { TableRow } from "../../../components/table";
import { ISefCollectionLog } from "../../../entities/ISefCollectionLog";
import { Link } from "react-router-dom";

export const mapReportToTableRow = (
  report: ISefCollectionLog,
  translationFunc: (value: string) => string
): TableRow => {
  return [
    <Link to={`/integrations/sef/reports/${report.id}`}>
      {`${report?.importedDocumentsIds?.length || 0}`}
    </Link>,
    moment(report.createdAt).format("DD.MM.YYYY"),
    translationFunc("SEF_SYNC." + report.requestParameters["kind"].toString().toUpperCase() || ""),
    translationFunc("SEF_SYNC." + report.requestParameters["status"].toString().toUpperCase() || ""),
    `${moment(report.requestParameters["dateFrom"] as string).format("DD.MM.YYYY")} - ${moment(
      report.requestParameters["dateTo"] as string
    ).format("DD.MM.YYYY")}`,
    report.errorDetails
      ? report.errorDetails
          .map((errorDetail) => errorDetail.error)
          .reduce((accumulator, current) => {
            return `${accumulator}, ${current}`;
          }, "")
      : "",
  ];
};

import { GenericForm } from "../generic-form/GenericForm.types";
import { GenericFormValidator } from "../generic-form/utility/GenericFormValidator";
import { CreateTaskDTO } from "../../entities/ITask";

export const createTaskForm: GenericForm<CreateTaskDTO> = {
  basketId: {
    type: "select",
    name: "basketId",
    label: "TABLE.BASKET",
    defaultValue: "",
    options: [{ label: "Select a basket", value: "" }],
    validators: [GenericFormValidator.required()],
  },
  title: {
    type: "input",
    name: "title",
    label: "TABLE.TITLE",
    defaultValue: "",
    validators: [GenericFormValidator.required()],
  },
  description: {
    type: "textarea",
    name: "description",
    label: "TABLE.DESCRIPTION",
    defaultValue: "",
  },
  labels: {
    type: "autocompleteMultipleChoice",
    name: "label",
    label: "TABLE.LABELS",
    defaultValue: [],
    options: [{ value: "labels", label: "Labels" }],
  },
  processDescription: {
    type: "richTextInput",
    name: "processDescription",
    label: "TABLE.PROCESS_DESCRIPTION",
    defaultValue: "",
    visibleIf: () => false,
  },
};

export interface CreateTaskModalProps {
  onDismiss: () => void;
  onSubmit: (data: CreateTaskDTO) => void;
  creating: boolean;
  isVisible: boolean;
  createTaskForm: GenericForm<CreateTaskDTO>;
}

import { validatorFunc } from "../GenericForm.types";
import { translation } from "../../translations";

export class FormArrayValidator {
  field: string;
  value: any[];
  constructor(value: any[], field: string) {
    this.field = field;
    this.value = value ? value : [];
  }

  public static minControls(params: { limit: number; error?: string }): validatorFunc {
    return (value: any[], field: string) => {
      if (value.length < params.limit) {
        return {
          valid: false,
          error:
            params?.error ||
            translation.t("FORM.MIN_OPTIONS", { field: translation.t(field), limit: params.limit }),
        };
      }
      return {
        valid: true,
      };
    };
  }

  public validate(validators: validatorFunc[]): {
    valid: boolean;
    error?: string;
  } {
    for (const validFunc of validators) {
      for (let el of this.value) {
        if (!el) {
          return {
            valid: false,
            error: undefined,
          };
        }
      }
      const validated = validFunc(this.value, this.field);
      if (!validated.valid) {
        return validated;
      }
    }
    return {
      valid: true,
    };
  }
}

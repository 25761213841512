import { IOption } from "../../../components/select";
import { TableRow } from "../../../components/table";
import { Icon } from "../../../modules/icon";

export const MapProviderToTableRow = ({
  provider,
  onDelete,
}: {
  provider: IOption<number>;
  onDelete: (id: number) => void;
}): TableRow => [
  provider.value,
  provider.label,
  <Icon
    title={"DELETE_BUTTON"}
    type="fa-solid fa-trash"
    onClick={(event) => {
      event?.stopPropagation();
      onDelete(provider.value);
    }}
  />,
];

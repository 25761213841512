import { useTranslation } from "react-i18next";
import { FiltersWithQuery } from "../../components/filters";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchWithQueryInput } from "../../components/search-input";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { documentFilters, documentTableHeaders } from "../documents";
import { useSefReportOverview } from "./useSefReportOverview";

export const SefReportOverviewScreen = () => {
  const { t } = useTranslation();
  const {
    title,
    reportDocsCount,
    reportDocsTable,
    sefReportOverviewFilters,
    fetchingDocumets,
    goToDocument,
  } = useSefReportOverview();

  return (
    <PageLayout
      backBtn
      title={title}
      filter={<FiltersWithQuery filters={sefReportOverviewFilters} />}
      search={<SearchWithQueryInput placeHolder={t("DOCUMENTS.SEARCH")} />}
    >
      <Row>
        <Column>
          <TableWithQueryPaginationAndSort
            headers={documentTableHeaders}
            data={reportDocsTable}
            totalItems={reportDocsCount}
            onRowPress={goToDocument}
            selection={false}
            loading={fetchingDocumets}
          />
        </Column>
      </Row>
    </PageLayout>
  );
};

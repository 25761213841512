import moment from "moment";
import { LogProps } from "./Logs.types";

export const Log = ({ message, createdAt }: LogProps) => {
  return (
    <div className="flex-row gap-1">
      <div
        className="flex-column "
        style={{
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "16px",
            height: "16px",
            borderRadius: "8px",
            flex: "0 0 auto",
            backgroundColor: "#3dc7a4",
          }}
        />
        <div className="bg-dark" style={{ width: "2px", height: "100%" }} />
      </div>
      <div className="pb-3" style={{ marginTop: "-4px" }}>
        <div className="log__message">{message}</div>
        <span className="text-muted" style={{ fontSize: "14px" }}>
          {moment(createdAt).format("DD.MM.YYYY HH:mm")}
        </span>
      </div>
    </div>
  );
};

import "./Card.style.css";
import { CardProps } from "./Card.types";

const Card = ({
  children,
  className,
  onClick,
  style,
  aspectRatio,
}: CardProps) => (
  <div
    onClick={onClick}
    className={`p-2 flex-column card-default ${className ? className : ""}`}
    style={{ aspectRatio, ...style }}
  >
    {children}
  </div>
);

export default Card;

import { TableRow } from "../../../components/table";
import { translation } from "../../../components/translations";
import { IRelatedDocumentType } from "../../../entities/IDocumentType";
import { Icon } from "../../../modules/icon";

export const mapDocumentTypeLinkToTableRow = (
  type: IRelatedDocumentType,
  onDeleteClick: (id: number) => void
): TableRow => {
  const selectedLanguage: "en" | "srb" = translation.language as any;
  return [
    type.responderDocType.name?.toString() || "",
    type.initiatorItem.translations?.[selectedLanguage] || "",
    type.responderItem.translations?.[selectedLanguage] || "",
    type.triggerLabel.name?.toString() || "",
    <>
      <Icon
        title={"DELETE_BUTTON"}
        type="fa-solid fa-trash"
        onClick={(event) => {
          event?.stopPropagation();
          onDeleteClick(type.id || -1);
        }}
      />
    </>,
  ];
};

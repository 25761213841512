import { IDbEntity } from "./IDbEntity";
import { IOrganization } from "./IOrganization";

export interface IArchiveBook extends Omit<IDbEntity, "id"> {
  name: string;
  organizationId: number;
  organization?: IOrganization;
  numItems: number
}

export interface CreateArchiveBookDTO {
  name: string;
  organizationId: number;
}

export const ARCHIVE_BOOK_SUCCESS_MESSAGES = {
  created: "ARCHIVE_BOOK.CREATED_MESSAGE",
  deleted: "ARCHIVE_BOOK.DELETED_MESSAGE",
};
import Dropzone from "react-dropzone";
import { useFileUpload } from "../../modules/file-upload/useFileUpload";
import { Icon } from "../../modules/icon";
import { useTranslation } from "react-i18next";

export const AddDocumentCard = ({ onUpload }: { onUpload: (files: File[]) => void }) => {
  const { t } = useTranslation();
  const { _onDrop, onDragEnter, onDragLeave } = useFileUpload(onUpload);
  return (
    <Dropzone onDrop={_onDrop} onDragEnter={onDragEnter} onDragLeave={onDragLeave} maxFiles={1}>
      {({ getRootProps, getInputProps }) => (
        <div className="p-2 flex-column gap-2 add-document-card" {...getRootProps()}>
          <input {...getInputProps()} />
          <div
            className="flex-row justify-content-center align-items-center"
            style={{
              aspectRatio: 1,
            }}
          >
            <Icon type="fa fa-square-plus" size={64} color="rgb(202, 205, 208)" />
          </div>
          <span className="text-center" style={{ color: "#6C757D" }}>
            {t("UPLOAD.ADD_NEW")}
          </span>
        </div>
      )}
    </Dropzone>
  );
};

import useBaseRequest from "../../api/BaseRequest";
import LabelGateway from "../../api/gateways/LabelGateway";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useToast } from "../../components/toast/useToast";
import { useTranslation } from "react-i18next";
import { TableRow } from "../../components/table";
import { labelsTableHeaders } from "./Labels.types";
import { mapLabelToTableRow } from "./utils/mapLabelToTableRow";
import { fillUpdateForm } from "./utils/fillUpdateForm";

export const useLabels = () => {
  const { success, danger } = useToast();
  const { t } = useTranslation();
  const location = useLocation();

  const [labelsTableData, setLabelsTableData] = useState<TableRow[]>([]);

  useEffect(() => {
    getLabels(location.search || "");
  }, [location.search]);
  const {
    execute: getLabels,
    data,
    loading: loadingLabels,
  } = useBaseRequest(LabelGateway.getLabels, {
    autoFetch: true,
    onCompleted: (data) => {
      setLabelsTableData(data.labels.map((label) => mapLabelToTableRow(label, onEdit, onDelete)));
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  const [selectedAction, setSelectedAction] = useState<"create" | "update" | "delete" | undefined>(
    undefined
  );

  const { execute: createLabel, loading: creating } = useBaseRequest(LabelGateway.createLabel, {
    onCompleted: (data) => {
      success(t("LABELS.LABEL_CREATED"));
      setSelectedAction(undefined);
      getLabels(location.search || "");
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  const onDismiss = () => {
    setSelectedAction(undefined);
    setSelectedID(undefined);
  };

  const [selectedID, setSelectedID] = useState<number | undefined>(undefined);
  const onDelete = (id: number) => {
    setSelectedID(id);
    setSelectedAction("delete");
  };

  const { execute: updateLabel, loading: updating } = useBaseRequest(LabelGateway.updateLabel, {
    onCompleted: (data) => {
      success(t("LABELS.LABEL_UPDATED"));
      setSelectedAction(undefined);
      getLabels(location.search || "");
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  const filledUpdateLabelForm = fillUpdateForm(
    data?.labels.find((label) => label.id === selectedID)
  );

  const onEdit = (id: number) => {
    setSelectedAction("update");
    setSelectedID(id);
  };

  const { execute: deleteLabel, loading: deleting } = useBaseRequest(LabelGateway.deleteLabel, {
    onCompleted: (data) => {
      success(t("LABELS.LABEL_DELETED"));
      setSelectedAction(undefined);
      getLabels(location.search || "");
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  return {
    labelsTableData,
    labelsTableHeaders,
    count: data?.count || 0,
    loadingLabels,
    selectedAction,
    onDismiss,
    onCreate: createLabel,
    creating,
    onUpdate: updateLabel,
    updating,
    updateLabelForm: filledUpdateLabelForm,
    deleting,
    setSelectedAction,
    onDelete: () => {
      selectedID && deleteLabel(selectedID);
    },
  };
};

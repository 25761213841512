import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDocumentType } from "../../entities/IDocumentType";
import { IDocumentCategory } from "../../entities/IDocumentCategory";

interface DocumentsState {
  documentTypes: IDocumentType[];
  documentCategories: IDocumentCategory[];
}

const initialState: DocumentsState = {
  documentTypes: [],
  documentCategories: [],
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setDocumentTypes: (state: DocumentsState, action: PayloadAction<IDocumentType[]>) => {
      state.documentTypes = action.payload;
    },
    setDocumentCategories: (state: DocumentsState, action: PayloadAction<IDocumentCategory[]>) => {
      state.documentCategories = action.payload;
    },
  },
});

export const documentsActions = documentsSlice.actions;

export default documentsSlice.reducer;

import { useEffect, useRef, useState } from "react";
import { NumberValuePickerProps } from "./NumberRangeFilter.types";
import { NumberRangeTypeValues } from "../Filters.types";
import { ApplyButton } from "../DateRangeFilter/ApplyButton";
import { useTranslation } from "react-i18next";

export const NumberValuePicker = ({
  toNumber,
  equalNumber,
  fromNumber,
  onChangeFrom,
  onChangeEqual,
  onChangeTo,
  numberRangeType,
  onApplyFilter,
  parentBoundingClientRect,
}: NumberValuePickerProps) => {
  const {t} = useTranslation()
  const ref = useRef<HTMLDivElement>(null);
  const [openToLeft, setOpenToLeft] = useState(false);

  const handleResize = () => {
    if (ref.current && parentBoundingClientRect) {
      const viewportWidth = window.innerWidth;
      const popupWidth = ref.current.getBoundingClientRect().width;

      // Check if there's not enough space to the right to fit the popup
      setOpenToLeft(parentBoundingClientRect.right + popupWidth > viewportWidth);
    }
  };

  useEffect(() => {
    if (parentBoundingClientRect) {
      handleResize();
    }
  }, [parentBoundingClientRect]);

  useEffect(() => {
    // Add a resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`p-2 bg-white border rounded`}
      style={{
        position: "absolute",
        left:
          openToLeft && ref.current
            ? `calc(-${ref.current.getBoundingClientRect().width}px - 0.5rem)`
            : `calc(${
                parentBoundingClientRect.width > 158 ? parentBoundingClientRect.width : 158
              }px + 0.5rem)`,
        top: "100%",
        zIndex: 1,
        marginTop: "2px",
      }}
    >
      {numberRangeType === NumberRangeTypeValues.BETWEEN && (
        <div className="form-group">
          <label>{t("FILTERS.GREATER_THAN")}</label>
          <input type="text" onChange={onChangeFrom} value={fromNumber || 0} pattern="/^[0-9]*$/" />
          <label>{t("FILTERS.LESS_THAN")}</label>
          <input type="text" onChange={onChangeTo} value={toNumber || 0} pattern="/^[0-9]*$/" />
        </div>
      )}
      {numberRangeType === NumberRangeTypeValues.GREATER_THAN && (
        <div className="form-group">
          <label>{t("FILTERS.GREATER_THAN")}</label>
          <input type="text" onChange={onChangeFrom} value={fromNumber || 0} pattern="/^[0-9]*$/" />
        </div>
      )}
      {numberRangeType === NumberRangeTypeValues.LESS_THAN && (
        <div className="form-group">
          <label>{t("FILTERS.LESS_THAN")}</label>
          <input type="text" onChange={onChangeTo} value={toNumber || 0} pattern="/^[0-9]*$/" />
        </div>
      )}
      {numberRangeType === NumberRangeTypeValues.EQUAL && (
        <div className="form-group">
          <label>{t("FILTERS.EQUAL")}</label>
          <input
            type="text"
            onChange={onChangeEqual}
            value={equalNumber || 0}
            pattern="/^[0-9]*$/"
          />
        </div>
      )}

      <ApplyButton onApplyFilter={onApplyFilter} />
    </div>
  );
};

import { ButtonGroup, DropdownButton } from "react-bootstrap";
import { FilterProps, NumberRangeTypeValues } from "../Filters.types";
import { useNumberRangeFilter } from "./useNumberRangeFilter";
import { useRef } from "react";
import { NumberValuePicker } from "./NumberValuePicker";
import { Button } from "../../button";
import { Icon } from "../../../modules/icon";
import { useTranslation } from "react-i18next";

export function NumberRangeFilter({ filter, onRemove, onFilterSet }: FilterProps) {
  const { t } = useTranslation();
  const filterTypePickerRef = useRef<HTMLDivElement>(null);
  const filterValuePickerRef = useRef<HTMLDivElement>(null);
  const {
    onChange,
    numberRangeType,
    showRangeValuePicker,
    fromNumber,
    toNumber,
    equalNumber,
    onChangeFrom,
    onChangeTo,
    onChangeEqual,
    onApplyFilter,
    title,
  } = useNumberRangeFilter(filter, onFilterSet, filterValuePickerRef);

  return (
    <div className="mr-3 my-1" ref={filterValuePickerRef}>
      <div className="btn-group" style={{ position: "relative" }}>
        <DropdownButton
          as={ButtonGroup}
          variant="outline-dark"
          autoClose={showRangeValuePicker ? false : true}
          title={title}
        >
          <div ref={filterTypePickerRef} className="px-2 pt-2">
            {Object.values(NumberRangeTypeValues).map((value) => {
              return (
                <div className="form-check" key={value}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name={filter.name}
                    value={value}
                    onChange={onChange}
                    checked={numberRangeType === value}
                  />
                  <label className="form-check-label">{t("FILTERS." + value)}</label>
                </div>
              );
            })}
          </div>
        </DropdownButton>

        {showRangeValuePicker && filterTypePickerRef.current && (
          <NumberValuePicker
            fromNumber={fromNumber}
            toNumber={toNumber}
            equalNumber={equalNumber}
            onChangeEqual={onChangeEqual}
            onChangeFrom={onChangeFrom}
            onChangeTo={onChangeTo}
            numberRangeType={numberRangeType as NumberRangeTypeValues}
            onApplyFilter={onApplyFilter}
            parentBoundingClientRect={filterTypePickerRef.current.getBoundingClientRect()}
          />
        )}
        <Button className="btn btn-outline-dark" onClick={() => onRemove(filter)}>
          <Icon title="Remove" type="fa-solid fa-xmark" size={16} />
        </Button>
      </div>
    </div>
  );
}

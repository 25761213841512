import { FiltersWithQuery } from "../../components/filters";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchWithQueryInput } from "../../components/search-input";
import useBasketTasks from "./useBasketTasks";
import { tasksTableHeaders } from "../../entities/ITask";
import TaskBulkActions from "../../components/task-bulk-actions/TaskBulkActions";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { tasksTableFilters } from "../tasks/TasksScreen.types";
import { useTranslation } from "react-i18next";
import { CreateTaskModal } from "../../components/create-task";
import { Button } from "../../components/button";
import { Icon } from "../../modules/icon";

const BasketTasksScreen = () => {
  const {
    basketTasks,
    loading,
    onRowPress,
    total,
    basketName,
    setSelectedTasks,
    selectedTasks,
    selectedAction,
    setSelectedAction,
    reassignBulkTasks,
    reassigningTasks,
    moveBulkTasks,
    movingTasks,
    createTask,
    creatingTask,
    isVisibleCreateTaskModal,
    taskStartCreate,
    createTaskForm,
    hideCreateTaskModal,
  } = useBasketTasks();
  const { t } = useTranslation();
  return (
    <PageLayout
      title={`${t("BASKET_TASKS.TITLE")}: ${basketName}`}
      titleRight={
        <div className="text-right flex-row gap-2">
          <div style={{ height: "100%", width: "2px", backgroundColor: "black" }}></div>
          <Button className="btn btn-dark" onClick={taskStartCreate}>
            <Icon type={"fa-solid fa-add"} title={t("TASKS.CREATE_LABEL")} />
          </Button>
        </div>
      }
      backBtn
      filter={<FiltersWithQuery filters={tasksTableFilters(["basketId"], t)} />}
      search={<SearchWithQueryInput placeHolder={t("BASKET_TASKS.SEARCH")} />}
    >
      <div className="flex-column gap-2">
        <TaskBulkActions
          selectedTasks={selectedTasks}
          onDismiss={() => {
            setSelectedTasks([]);
          }}
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          onReassign={reassignBulkTasks}
          reassigning={reassigningTasks}
          moving={movingTasks}
          onMove={moveBulkTasks}
        />
        <CreateTaskModal
          onDismiss={hideCreateTaskModal}
          onSubmit={createTask}
          creating={creatingTask}
          isVisible={isVisibleCreateTaskModal}
          createTaskForm={createTaskForm as any}
        />
        <Row>
          <Column>
            <TableWithQueryPaginationAndSort
              totalItems={total}
              loading={loading}
              onRowPress={onRowPress}
              headers={tasksTableHeaders.filter((item) => item.key !== "basket.name")}
              data={basketTasks}
              selection={false}
              // Bulk actions are removed from the screen
            />
          </Column>
        </Row>
      </div>
    </PageLayout>
  );
};
export default BasketTasksScreen;

import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import useBaseRequest from "../../api/BaseRequest";
import AuthGateway from "../../api/gateways/AuthGateway";
import { useToast } from "../../components/toast";
import { IUser, UserRoles } from "../../entities/IUser";
import { useAppDispatch } from "../../store";
import { authActions } from "../../store/auth/AuthReducer";
import environment from "../../config/environment";
import { filterActions } from "../../store/filters/FilterReducer";
import { useAuthCodePkce } from "../../modules/oidc";
import { useJwt } from "../../modules/jwt";
import { useTranslation } from "react-i18next";

export function useLogin() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { success, danger } = useToast();
  const { state } = useLocation();
  const { getAuthorizationUrl, getTokenUrl } = useAuthCodePkce({
    authorizationUrl: environment.oidc.authorizationEndpoint,
    clientId: environment.oidc.clientId,
    redirectUrl: environment.oidc.loginCallbackUrl,
  });
  const { verifyAndDecodeJwe } = useJwt();

  useEffect(() => {
    dispatch(authActions.setUser(null));
  }, []);

  const { execute: syncUser } = useBaseRequest(AuthGateway.syncUser, {
    onError: () => {
      danger(t("ERRORS.UNKNOWN"));
    },
    onCompleted: (data, payload) => {
      onLoginSuccess({ ...payload, user: { ...payload.user, backEndId: data.data } });
    },
  });

  const onLoginSuccess = ({
    token,
    user,
    id_token,
  }: {
    token: string;
    user: any;
    id_token: string;
  }) => {
    const roleName = Object.keys(
      user[environment.oidc.roleFieldName as keyof typeof user]
    )[0].toUpperCase();
    const role =
      roleName in UserRoles ? UserRoles[roleName as keyof typeof UserRoles] : UserRoles.AGENT;
    dispatch(
      authActions.setUser({
        token: token,
        id: user.sub,
        role: role as UserRoles,
        name: user.name as string,
        firstName: user.given_name,
        lastName: user.family_name,
        email: user.email,
        username: user.preferred_username,
        id_token,
        exp: user.exp.toString(),
        backEndId: user.backEndId,
      })
    );
    success(`${t("WELCOME")}, ${user?.name}`);
    navigate(state?.returnTo || "/", { replace: true });

    dispatch(
      filterActions.setFilterByPage({
        page: "/documents",
        filter: "",
      })
    );
  };

  const onLoginError = () => {
    navigate("/login");
    danger(t("ERRORS.UNKNOWN"));
  };

  const getAndVerifyTokens = async (code: string) => {
    const { data } = await AuthGateway.getUserToken(getTokenUrl(code));
    const keyList = await AuthGateway.getKeyList(environment.oidc.keyListEndpoint);
    const user = await verifyAndDecodeJwe(data.id_token, keyList.data.keys);

    return { token: data.access_token, user, id_token: data.id_token };
  };

  const { execute: logIn, loading: loadingLogin } = useBaseRequest(getAndVerifyTokens, {
    onCompleted: (payload) => {
      syncUser(payload);
    },
    onError: onLoginError,
  });

  const { execute: authorizeWithOidc } = useBaseRequest(getAuthorizationUrl, {
    onCompleted: (authorizeUrl) => {
      window.location.href = authorizeUrl;
    },
  });

  return { logIn, authorizeWithOidc, onLoginError };
}

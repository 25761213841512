import useBaseRequest from "../api/BaseRequest";
import ArchiveBookGateway from "../api/gateways/ArchiveBookGateway";
import OrganizationGateway from "../api/gateways/OrganizationGateway";
import { useAppDispatch } from "../store";
import { organizationActions } from "../store/organizations/OrganizationReducer";
import {
  useArchiveBooksSelector,
  useOrganizationSelector,
} from "../store/organizations/OrganizationSelector";

export function useOrganizationService() {
  const dispatch = useAppDispatch();

  function useGetOrganizations() {
    const organizations = useOrganizationSelector();
    const { execute, loading } = useBaseRequest(OrganizationGateway.getAllOrganizations, {
      autoFetch: true,
      initialPayload: {},
      onCompleted: (data) => {
        dispatch(organizationActions.setOrganizations(data));
      },
    });

    return { getOrganizations: execute, loading, organizations };
  }

  function useGetArchiveBooks() {
    const archiveBooks = useArchiveBooksSelector();

    const { execute, loading } = useBaseRequest(ArchiveBookGateway.getAllArchiveBooks, {
      autoFetch: true,
      initialPayload: {},
      onCompleted: (data) => {
        dispatch(organizationActions.setArchiveBooks(data.archiveBooks));
      },
    });

    return { getArchiveBooks: execute, loading, archiveBooks };
  }

  return { useGetOrganizations, useGetArchiveBooks };
}

import { IOrganization } from "../../entities/IOrganization";
import BaseInstance from "../BaseInstance";

const OrganizationGateway = {
  getAllOrganizations: async (): Promise<IOrganization[]> => {
    return BaseInstance.get("/organizations");
  },
};

export default OrganizationGateway;

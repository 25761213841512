import {
  CreateDocumentTypeDTO,
  IDocumentType,
  IRelatedDocumentType,
  IRelatedDocumentTypeDTO,
  IRelatedDocumentTypeList,
  IUpdateAccessConfigDTO,
} from "../../entities/IDocumentType";
import { IRolePermission } from "../../entities/IRolePermission";
import BaseInstance from "../BaseInstance";

const DocumentTypeGateway = {
  getAllDocumentTypes: async (): Promise<IDocumentType[]> => {
    return BaseInstance.get("/document-types");
  },

  getDocumentTypes: async (
    query: string
  ): Promise<{
    count: number;
    types: IDocumentType[];
  }> => {
    return BaseInstance.get(`/document-types/search${query}`);
  },

  createDocumentType: async (payload: CreateDocumentTypeDTO) => {
    const { docCategoryId, name, customFields: metaDataItemIds, providerId } = payload;
    return await BaseInstance.post("/document-types", {
      docCategoryId,
      name,
      metaDataItemIds,
      providerId,
    });
  },
  deleteDocumentType: async (id: number) => {
    return BaseInstance.delete(`/document-types/${id}`);
  },
  getDocumentTypeMetaDataById: async (id: number): Promise<any[]> => {
    return BaseInstance.get(`/document-types/${id}/meta-data`);
  },
  linkDocumentTypes: async ({
    sourceDocumentTypeId,
    targetDocumentTypeId,
    initiatorItemId,
    responderItemId,
    triggerLabelId,
  }: IRelatedDocumentTypeDTO) => {
    return BaseInstance.post(`/related-document-types`, {
      initiatorItemId,
      responderItemId,
      triggerLabelId,
      initiatorDocTypeId: sourceDocumentTypeId,
      responderDocTypeId: targetDocumentTypeId,
    });
  },
  deleteDocumentTypeLink: async ({ relationId }: { relationId: number }) => {
    return BaseInstance.delete(`/related-document-types/${relationId}`);
  },
  getDocumentTypeLinksByDocumentTypeId: async (id: number): Promise<IRelatedDocumentTypeList> => {
    const query =
      `?filters=` +
      JSON.stringify({
        name: "initiatorDocTypeId",
        operator: "eq",
        value: id.toString(),
        type: "number",
      });
    return BaseInstance.get(`/related-document-types${query}`);
  },
  getDocumentTypeAccessConfiguration: async (id: number): Promise<IRolePermission[]> => {
    return BaseInstance.get(`/document-types/${id}/access-configuration`);
  },
  editDocumentTypeAccessConfiguration: async ({
    id,
    accessConfig,
  }: IUpdateAccessConfigDTO): Promise<IRolePermission[]> => {
    return BaseInstance.put(`/document-types/${id}/access-configuration`, {
      rolePermissions: accessConfig,
    });
  },
};

export default DocumentTypeGateway;

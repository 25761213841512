import { useTranslation } from "react-i18next";
import { PageLayout } from "../../components/page-layout";
import { Column, Row } from "../../components/grid";
import { useLabels } from "./";
import { SearchWithQueryInput } from "../../components/search-input";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import CreateLabelModal from "./CreateLabelModal";
import { createLabelForm } from "./Labels.types";
import UpdateLabelModal from "./UpdateLabelModal";
import { Button } from "react-bootstrap";
import { Icon } from "../../modules/icon";
import DeleteLabelModal from "./DeleteLabelModal";

export function LabelsScreen() {
  const {
    labelsTableData,
    loadingLabels,
    labelsTableHeaders,
    count,
    selectedAction,
    onDismiss,
    onCreate,
    creating,
    updating,
    onUpdate,
    updateLabelForm,
    deleting,
    onDelete,
    setSelectedAction,
  } = useLabels();
  const { t } = useTranslation();

  return (
    <PageLayout
      title={t("LABELS.LABEL")}
      search={<SearchWithQueryInput placeHolder={t("LABELS.SEARCH_LABEL")} className="ml-auto" />}
      titleRight={
        <div className="text-right flex-row gap-2">
          <div style={{ height: "100%", width: "2px", backgroundColor: "black" }} />
          <Button
            className="btn btn-dark"
            onClick={() => {
              setSelectedAction("create");
            }}
          >
            <Icon type="fa fa-plus" title={t("ARCHIVE_BOOK.CREATE_LABEL")} />
          </Button>
        </div>
      }
    >
      <Row>
        <Column>
          <TableWithQueryPaginationAndSort
            headers={labelsTableHeaders}
            data={labelsTableData}
            totalItems={count}
            selection={false}
            loading={loadingLabels}
          />
        </Column>
      </Row>
      <CreateLabelModal
        isVisible={selectedAction === "create"}
        onDismiss={onDismiss}
        onSubmit={onCreate}
        creating={creating}
        createLabelForm={createLabelForm}
      />
      <UpdateLabelModal
        isVisible={selectedAction === "update"}
        onDismiss={onDismiss}
        onSubmit={onUpdate}
        updating={updating}
        updateLabelForm={updateLabelForm}
      />
      <DeleteLabelModal
        isVisible={selectedAction === "delete"}
        onDismiss={onDismiss}
        onDelete={onDelete}
        deleting={deleting}
      />
    </PageLayout>
  );
}

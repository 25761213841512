import { Navigate, useLocation } from "react-router";
import { screens } from "../../screens/Navigation.types";
import { useUser } from "../../store/auth/AuthSelectors";
import { AuthGuardProps } from "./Guard.types";

export function AuthGuard({ children }: AuthGuardProps) {
  const user = useUser();
  const location = useLocation();
  return user ? (
    <>{children}</>
  ) : (
    <Navigate
      replace
      to={screens.login}
      state={{ returnTo: location.pathname }}
    />
  );
}

export interface IUser {
  id: string;
  backEndId: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  username: string;
  role: UserRoles;
  token: string;
  id_token: string;
  exp?: number;
}

export enum UserRoles {
  ADMIN = "L4 - Admin",
  SUPERVISOR = "L3 - Supervisor",
  BO_EXPERT = "L2 - BO",
  SALES = "L1 - 1st line",
  AGENT = "L0 - Agent",
}

import { useNavigate } from "react-router-dom";
import { DigitalSignaturesProps } from "./DigitalSignature.types";
import CardContainer from "../../components/card/CardContainer";
import { DigitalSignatureCard } from "./DigitalSignatureCard";

export const DigitalSignatures = ({ documents, onDelete, onResend }: DigitalSignaturesProps) => {
  const navigate = useNavigate();
  return (
    <>
      {!!documents.length && (
        <section className="flex-column gap-2">
          <CardContainer
            children={[
              ...documents.map((item, i) => (
                <DigitalSignatureCard
                  key={i}
                  onResend={onResend}
                  onDelete={() => {
                    onDelete(item.digitalSignRequest?.id || -1);
                  }}
                  onClick={() => {
                    navigate(`/documents/${item.id}`);
                  }}
                  fileName={(item.files as any)[0].name || ""}
                  customerEmail={item.digitalSignRequest?.email || ""}
                  status={item.digitalSignRequest?.status || ""}
                  id={item.digitalSignRequest?.id || -1}
                />
              )),
            ]}
            cardGap={16}
            cardMinWidth={300}
            cardMaxWidth={400}
            cardMaxNumberPerRow={3}
          />
        </section>
      )}
    </>
  );
};

import { useParams } from "react-router-dom";
import { PageLayout } from "../../components/page-layout";
import { Column, Row } from "../../components/grid";
import { SearchWithQueryInput } from "../../components/search-input";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { archiveBookItemTableHeaders } from "./ArchiveBookDetail.types";
import { FiltersWithQuery } from "../../components/filters";
import { useArchiveBookDetail } from "./useArchiveBookDetail";
import { Button } from "../../components/button";
import { Icon } from "../../modules/icon";
import { useTranslation } from "react-i18next";
import { FormModal } from "../../components/modal";
import { DialogModal } from "../../components/modal/DialogModal";

export const ArchiveBookDetailScreen = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    archiveBookItemFilters,
    hideCreateItemModalHandler,
    isVisibleCreateItemModal,
    showCreateItemModalHandler,
    createItemForm,
    createItem,
    creatingItem,
    deleteItem,
    deletingItem,
    fetchingItems,
    getItems,
    itemTableData,
    itemCount,
    deleteItemId,
    hideDeleteItemModalHandler,
    exportItems,
    title,
  } = useArchiveBookDetail(id);

  return (
    <PageLayout
      title={title}
      backBtn
      titleRight={
        <div className="text-right flex-row gap-2">
          <div style={{ height: "100%", width: "2px", backgroundColor: "black" }}></div>
          <Button className="btn btn-dark" onClick={exportItems}>
            <Icon type="fa-solid fa-file-export" title={t("EXPORT_BUTTON")} />
          </Button>
          <Button className="btn btn-dark" onClick={showCreateItemModalHandler}>
            <Icon title={t("ARCHIVE_BOOK_ITEM.CREATE_LABEL")} type="fa fa-plus" />
          </Button>
        </div>
      }
      filter={<FiltersWithQuery filters={archiveBookItemFilters} />}
      search={<SearchWithQueryInput placeHolder={t("ARCHIVE_BOOK_ITEM.SEARCH")} />}
    >
      <FormModal
        form={createItemForm}
        loading={creatingItem}
        isVisible={isVisibleCreateItemModal}
        onDismiss={hideCreateItemModalHandler}
        onSubmit={createItem}
        submitButtonLabel="CREATE_BUTTON"
        title="ARCHIVE_BOOK_ITEM.CREATE_LABEL"
      />
      <DialogModal
        loading={deletingItem}
        show={deleteItemId != null}
        onAccept={deleteItem}
        onCancel={hideDeleteItemModalHandler}
        acceptLabel="DELETE_BUTTON"
        cancelLabel="CANCEL_BUTTON"
        description="ARCHIVE_BOOK_ITEM.DELETE_LABEL"
        title="ARCHIVE_BOOK_ITEM.DELETE"
      />
      <Row>
        <Column>
          <TableWithQueryPaginationAndSort
            totalItems={itemCount}
            loading={fetchingItems}
            onRowPress={() => {}}
            headers={archiveBookItemTableHeaders}
            data={itemTableData}
            selection={false}
          />
        </Column>
      </Row>
    </PageLayout>
  );
};

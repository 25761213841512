import { useTranslation } from "react-i18next";
import { SelectAction } from "../../components/select";
import { DocumentActionsProps } from "./Document.types";
import { useDocumentActions } from "./useDocumentActions";

export function DocumentActions({ onActionSelected, document, user }: DocumentActionsProps) {
  const { getActions } = useDocumentActions();
  const { t } = useTranslation();
  return (
    <SelectAction
      defaultLabel={t("DOCUMENTS.DOCUMENT_OPTIONS")}
      options={getActions(document, user)}
      onChange={onActionSelected}
    />
  );
}

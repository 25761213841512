import { Modal } from "react-bootstrap";
import { SplitPdfModalProps } from "./UploadDocument.types";
import { Button } from "../../components/button";
import { useTranslation } from "react-i18next";

export const SplitPdfModal = ({
  show,
  onDismissUpload,
  onSelectDontSplitFile,
  onSelectSplitFile,
}: SplitPdfModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} animation onHide={onDismissUpload}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {t("UPLOAD.CHOOSE_METHOD")}
        </h5>
        <div onClick={onDismissUpload}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body className="flex-column gap-2">
        <Button className="btn btn-dark" onClick={onSelectDontSplitFile}>
          {t("UPLOAD.SINGLE_METHOD")}
        </Button>
        <Button className="btn btn-secondary" onClick={onSelectSplitFile}>
          {t("UPLOAD.SPLIT_METHOD")}
        </Button>
        <Button className="btn btn-danger" onClick={onDismissUpload}>
          {t("UPLOAD.CANCEL_UPLOAD")}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

import { useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import { ConfigurationGateway } from "../../api/gateways/ConfigurationGateway";
import { TableRow } from "../../components/table";
import { MapProviderToTableRow } from "./utils/mapProviderToTableRow";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useToast } from "../../components/toast";
import { useTranslation } from "react-i18next";

export const useProvider = () => {
  const { success, danger } = useToast();
  const { t } = useTranslation();
  const [providerCount, setProviderCount] = useState<number>(0);
  const [providerTableData, setProviderTableData] = useState<TableRow[]>([]);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);
  const location = useLocation();
  const { execute: deleteProvider, loading: deleting } = useBaseRequest(
    ConfigurationGateway.deleteProvider,
    {
      onCompleted: () => {
        getProviders("");
        setDeleteId(undefined);
        success(t("CONFIGURATION.PROVIDER_DELETED_MESSAGE"));
      },
      onError: (error) => {
        setDeleteId(undefined);
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  useEffect(() => {
    getProviders(location.search || "?");
  }, [location.search]);

  const { execute: createProvider, loading: creating } = useBaseRequest(
    ConfigurationGateway.createProvider,
    {
      onCompleted: () => {
        getProviders("");
        onDismissCreateModal();
        success(t("CONFIGURATION.PROVIDER_CREATED_MESSAGE"));
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );
  const { execute: getProviders, loading } = useBaseRequest(ConfigurationGateway.getProviders, {
    autoFetch: true,
    onCompleted: (data) => {
      if (!data) return;
      setProviderCount(data.count);
      setProviderTableData(
        data.data.map((provider) =>
          MapProviderToTableRow({ provider, onDelete: (id) => setDeleteId(id) })
        )
      );
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  const [showCreateModal, setShowCreateModal] = useState(false);
  const onDismissCreateModal = () => setShowCreateModal(false);
  const onShowCreateModal = () => setShowCreateModal(true);
  return {
    providerCount,
    providerTableData,
    deleteProvider,
    createProvider,
    getProviders,
    deleteId,
    setDeleteId,
    showDeleteProviderModal: deleteId !== undefined,
    loading,
    creating,
    deleting,
    showCreateModal,
    onDismissCreateModal,
    onShowCreateModal,
    onDismissDeleteModal: () => setDeleteId(undefined),
  };
};

import { LogsResponse } from "../../components/logs/Logs.types";
import {
  AddCommentToTaskDTO,
  CreateTaskDTO,
  UpdateTaskDTO,
  GetTaskCommentDTO,
  TaskReassignDTO,
  TaskMoveToAnotherBasketDTO,
  ITask,
  GetTaskStatsResponse,
  IGetTasksApi,
  TaskChangeStatusDTO,
  TaskBulkMoveToAnotherBasketDTO,
} from "../../entities/ITask";
import {
  AddTaskAttachmentDTO,
  ExecuteTaskAttachmentActionDTO,
  ITaskAttachments,
  TaskStatusWithCommentChangeDTO,
  UpdateTaskAttachmentDTO,
} from "../../screens/task/Task.types";
import { TaskBulkReassignDTO } from "../../entities/ITask";
import BaseInstance from "../BaseInstance";
import LabelGateway from "./LabelGateway";

const TaskGateway = {
  createTask: async (payload: CreateTaskDTO): Promise<ITask> => {
    const data = BaseInstance.post("/tasks", {
      basketId: payload.basketId,
      description: payload.description?.trim() || null,
      // processDescription: payload.processDescription?.trim() || null,
      title: payload.title,
    });
    if (((await data) as any)?.id && payload.labels.length > 0) {
      LabelGateway.addLabelsToElement({
        id: ((await data) as any)?.id,
        labelIds: payload.labels,
        type: "task",
      });
    }
    return data as any;
  },
  updateTask: async (payload: UpdateTaskDTO): Promise<ITask> => {
    return BaseInstance.patch(`/tasks`, {
      taskIds: [payload.id],
      data: {
        description: payload.description?.trim() || null,
        processDescription: payload.processDescription
          ? JSON.parse(payload.processDescription)
          : null,
      },
    });
  },
  getTaskById: async (id: number): Promise<ITask> => {
    return BaseInstance.get(`/tasks/${id}`);
  },
  cancelTaskById: async (payload: TaskStatusWithCommentChangeDTO): Promise<ITask> => {
    return BaseInstance.put(`/tasks/${payload.id}/status`, {
      comment: payload.comment,
      status: "canceled",
    });
  },
  onHoldTaskById: async (payload: TaskStatusWithCommentChangeDTO): Promise<ITask> => {
    return BaseInstance.put(`/tasks/${payload.id}/status`, {
      comment: payload.comment || null,
      status: "onhold",
    });
  },
  resolveTaskById: async (payload: TaskStatusWithCommentChangeDTO): Promise<ITask> => {
    return BaseInstance.put(`/tasks/${payload.id}/status`, {
      comment: payload.comment || null,
      status: "resolved",
    });
  },
  reassignTaskById: async (payload: TaskReassignDTO): Promise<ITask> => {
    return BaseInstance.put(`/tasks/${payload.id}/status`, {
      userId: payload.userId,
      status: "assigned",
    });
  },
  unassignTaskById: async (id: number): Promise<ITask> => {
    return BaseInstance.post(`/tasks/${id}/unassign`);
  },
  changeStatus: async (payload: TaskChangeStatusDTO): Promise<ITask> => {
    return BaseInstance.put(`/tasks/${payload.id}/status`, {
      status: payload.status,
    });
  },
  addCommentToTask: async (payload: AddCommentToTaskDTO): Promise<ITask> => {
    return BaseInstance.post(`/task-comments`, payload);
  },
  getCommentsByTaskId: async (id: number): Promise<GetTaskCommentDTO> => {
    return BaseInstance.get(`/tasks/${id}/comments?limit=1000`);
  },
  moveToAnotherBasket: async (payload: TaskMoveToAnotherBasketDTO): Promise<ITask> => {
    return BaseInstance.put(`/tasks/${payload.id}/change-basket`, {
      basketId: payload.basketId,
    });
  },
  getTaskStats: async (query: string): Promise<GetTaskStatsResponse> => {
    return BaseInstance.get(`/tasks/stats${query}`);
  },
  getTasks: async (query: string): Promise<IGetTasksApi> => {
    return BaseInstance.get(`/tasks${query}`);
  },
  getTaskLogsById: async (id: string): Promise<LogsResponse> => {
    return BaseInstance.get(
      `/tasks/logs?limit=1000&filters=${JSON.stringify({
        name: "taskId",
        operator: "eq",
        value: JSON.stringify(+id),
      })}`
    );
  },
  reassignBulkTasks: async (payload: TaskBulkReassignDTO): Promise<ITask> => {
    return BaseInstance.patch(`/tasks`, payload);
  },
  moveToAnotherBasketBulkTasks: async (payload: TaskBulkMoveToAnotherBasketDTO): Promise<ITask> => {
    return BaseInstance.patch(`/tasks`, payload);
  },
  addAttachmentToTask: async ({
    payload,
  }: {
    payload: AddTaskAttachmentDTO;
    onClose?: () => void;
  }): Promise<ITaskAttachments> => {
    const data = BaseInstance.post(`/task-sections/${payload.taskId}/with-element`, {
      element: {
        content: {
          title: payload.title,
          description: payload.description?.trim() || null,
        },
        type: payload.type,
        documentId: payload.documentId,
        messageId: payload.messageId,
      },
    });
    if (payload.labels && payload.labels.length > 0 && ((await data) as any)?.id) {
      LabelGateway.addLabelsToElement({
        id: ((await data) as any)?.id,
        labelIds: payload.labels,
        type: "task-element",
      });
    }
    return data as any;
  },
  editAttachment: async (payload: UpdateTaskAttachmentDTO): Promise<ITaskAttachments> => {
    return BaseInstance.put(`/task-elements/${payload.id}`, {
      title: payload.title,
      description: payload.description,
    });
  },
  deleteAttachment: async (id: number): Promise<string> => {
    return BaseInstance.delete(`/task-elements/${id}`);
  },
  executeAttachmentAction: async ({
    id,
    action,
  }: ExecuteTaskAttachmentActionDTO): Promise<string> => {
    return BaseInstance.post(`/task-elements/${id}/action`, {
      actionName: action,
    });
  },
  addParticipantsToTask: async ({
    taskId,
    userIds,
  }: {
    taskId: number;
    userIds: number[];
  }): Promise<string> => {
    return BaseInstance.post(`/tasks/${taskId}/participants`, {
      userIds: userIds.map((item) => +item),
    });
  },
  removeParticipantsFromTask: async ({
    taskId,
    userIds,
  }: {
    taskId: number;
    userIds: number[];
  }): Promise<string> => {
    return BaseInstance.delete(`/tasks/${taskId}/participants`, {
      data: {
        userIds: userIds.map((item) => +item),
      },
    });
  },
};

export default TaskGateway;

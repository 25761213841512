import { useEffect, useState } from "react";
import { useDrawerContext } from "../../modules/drawer";

const useCard = (
  containerRef: any,
  cardGap: number,
  cardMaxNumberPerRow: number,
  cardMinWidth: number,
  cardMaxWidth: number | undefined,
  paddingX: number
) => {
  const useGetContainerWidth = (containerRef: any) => {
    const [width, setWidth] = useState(0);
    const { isOpen } = useDrawerContext();
    useEffect(() => {
      if (!containerRef?.current) return;
      const getWidth = () =>
        containerRef?.current && containerRef?.current.offsetWidth;

      const handleResize = () => {
        setWidth(getWidth());
      };

      if (containerRef?.current) {
        setWidth(getWidth());
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [containerRef]);

    useEffect(() => {
      if (!containerRef?.current) return;
      const getWidth = () =>
        containerRef?.current && containerRef?.current.offsetWidth;
      if (containerRef?.current)
        setTimeout(() => {
          setWidth(getWidth());
        }, 400);
    }, [isOpen, containerRef]);

    return width;
  };

  const calculateCardWidth = (
    width: number,
    maxCardNumber: number,
    minCardWidth: number,
    maxCardWidth: number | undefined,
    cardGap: number,
    paddingX: number
  ) => {
    let cardNumber = maxCardNumber;

    let widthInPx = width - (cardNumber - 1) * cardGap - paddingX;

    while (
      widthInPx / cardNumber < minCardWidth ||
      (maxCardWidth !== undefined && widthInPx / cardNumber > maxCardWidth)
    ) {
      if (widthInPx / cardNumber < minCardWidth) {
        cardNumber--;
      } else {
        cardNumber++;
      }

      widthInPx = width - (cardNumber - 1) * cardGap - paddingX;

      if (cardNumber === 0) {
        break;
      }
    }

    return `${widthInPx / cardNumber}px`;
  };

  const containerWidth = useGetContainerWidth(containerRef);
  const cardWidth = calculateCardWidth(
    containerWidth,
    cardMaxNumberPerRow,
    cardMinWidth,
    cardMaxWidth,
    cardGap,
    paddingX
  );

  return {
    containerWidth,
    cardWidth,
  };
};

export default useCard;

import { IArchiveBook } from "./IArchiveBook";
import { IDbEntity } from "./IDbEntity";
import { IDocumentCategory } from "./IDocumentCategory";
export interface IArchiveBookItem extends IDbEntity {
  serialNumber: number;
  entryDate: Date;
  content: string;
  periodFrom: Date;
  periodTo: Date;
  amountOfDocuments: number;
  location: string;
  remark?: string;
  recordNumber?: number;
  recordDate?: Date;
  docCategoryId: number;
  docCategory?: IDocumentCategory;
  archiveBookId: number;
  archiveBook?: IArchiveBook;
}

export interface CreateArchiveBookItemDTO {
  // Field is a numeric string, so it can binded to an input
  serialNumber: string;
  content: string;
  periodFrom: Date;
  periodTo: Date;
  docCategoryId: number;
  location: string;
  remark?: string;
}

export interface CreateArchiveBookItemBulkDTO {
  // Field is a numeric string, so it can binded to an input
  serialNumber: string;
  content: string;
  docCategoryId: number;
  location: string;
  remark?: string;
  archiveBookId: number;
}

export const ARCHIVE_BOOK_ITEM_SUCCESS_MESSAGES = {
  created: "ARCHIVE_BOOK_ITEM.CREATED_MESSAGE",
  deleted: "ARCHIVE_BOOK_ITEM.DELETED_MESSAGE",
};

export const ARCHIVE_BOOK_ITEM_INFO_MESSAGES = {
  noItemsFound: "ARCHIVE_BOOK_ITEM.NO_DOCS_FOUND",
};
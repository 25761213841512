import { useAppSelector } from "..";

export function useGetMyTasksCount() {
  return useAppSelector(({ counter }) => counter.myTasksCount);
}
export function useGetAllTasksCount() {
  return useAppSelector(({ counter }) => counter.allTasksCount);
}
export function useGetCommentsCount() {
  return useAppSelector(({ counter }) => counter.commentsCount);
}

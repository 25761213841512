import { IEnvironment } from "./environment";

export const releaseEnv: IEnvironment = {
  env: "release",
  api: {
    protocol: "https",
    host: "api.staging.aurora.codebehind.tech",
  },
  oidc: {
    clientId: "237923339606228995@braco-test",
    issuerEndpoint: "https://zitadel.puvaron.com",
    loginCallbackUrl: "http://localhost:3000/code",
    authorizationEndpoint: "https://zitadel.puvaron.com/oauth/v2/authorize",
    keyListEndpoint: "https://zitadel.puvaron.com/oauth/v2/keys",
    userInfoEndpoint: "https://zitadel.puvaron.com/oidc/v1/userinfo",
    roleFieldName: "",
    endSessionEndpoint: "https://zitadel.puvaron.com/oidc/v1/end_session",
  },
  modulesEnabled: {
    archive: false,
    digitalSignature: true,
    tasks: true,
    sefProvider: true,
    emailListenerProvider: true,
    networkShareProvider: true,
    ocr: false,
  },
};

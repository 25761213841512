import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { useEffect, useState } from "react";
import { useUser } from "../../store/auth/AuthSelectors";
import DigitalSignatureGateway from "../../api/gateways/DigitalSignatureGateway";
import { useToast } from "../../components/toast";

export const useDigitalSignature = () => {
  const user = useUser();
  const { success } = useToast();
  const payload =
    "?filters=" +
    JSON.stringify({
      name: "digitalSignRequest => status",
      type: "stringArray",
      operator: "in",
      value: JSON.stringify(["pending", "confirmed"]),
    }) +
    `&filters=${JSON.stringify({
      name: "createdById",
      operator: "eq",
      value: user?.backEndId.toString(),
      type: "string",
    })}`;
  useEffect(() => {
    if (user) {
      execute(payload);
    }
  }, [user]);
  const { data, execute, loading } = useBaseRequest(DocumentGateway.getDocuments);

  const { execute: deleteDigitalSignatureRequest, loading: deletingSignature } = useBaseRequest(
    DigitalSignatureGateway.deleteDigitalSignatureRequest,
    {
      onCompleted: (data) => {
        success("Digital Signature Request deleted successfully.");
        closeDeleteModal();
        setSelectedId(undefined);
        execute(payload);
      },
    }
  );
  const [selectedId, setSelectedId] = useState<number>();

  const closeDeleteModal = () => {
    setSelectedId(undefined);
  };
  const onStartDelete = (id: number) => {
    setSelectedId(id);
  };
  const onDelete = () => {
    selectedId && deleteDigitalSignatureRequest(selectedId);
  };
  const onResend = (id: number) => {};
  return {
    documents: data?.documents || [],
    deletingSignature,
    closeDeleteModal,
    showDeleteModal: selectedId !== undefined,
    loading,
    onDelete,
    onStartDelete,
    onResend,
  };
};

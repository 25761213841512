import { useEffect, useMemo, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import { useToast } from "../../components/toast";
import { ITask, UpdateTaskDTO } from "../../entities/ITask";
import TaskGateway from "../../api/gateways/TaskGateway";
import { useOutletContext, useParams } from "react-router-dom";
import { updateTaskForm as updateTaskDefaultForm } from "./Task.types";
import { useUser } from "../../store/auth/AuthSelectors";
import { useTranslation } from "react-i18next";
import LabelGateway from "../../api/gateways/LabelGateway";

const useTask = () => {
  const { danger, success } = useToast();
  const id = +(useParams<{ id: string }>().id || -1);
  const [task, setTask] = useState<ITask>();
  const user = useUser();
  const { t } = useTranslation();
  const { refetchTask } = useOutletContext<{
    refetchTask: () => void;
  }>();
  const { execute: getData, loading } = useBaseRequest(TaskGateway.getTaskById, {
    onCompleted: (data) => {
      setTask(data);
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });
  useEffect(() => {
    id && getData(id);
  }, [id]);

  const { execute: updateTask, loading: updating } = useBaseRequest(TaskGateway.updateTask, {
    onCompleted: (data) => {
      setUpdateInProgress(false);
      getData(id);
      success(t("TASKS.UPDATED_MESSAGE"));
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });

  const { execute: addLabelsToElement, loading: addLabelsLoading } = useBaseRequest(
    LabelGateway.addLabelsToElement,
    {
      onCompleted: () => {
        getData(id);
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );
  const { execute: removeLabelsFromElement, loading: removeLabelsLoading } = useBaseRequest(
    LabelGateway.removeLabelsFromElement,
    {
      onCompleted: () => {
        getData(id);
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    }
  );

  const [updateInProgress, setUpdateInProgress] = useState(false);
  const onCancelUpdate = () => {
    setUpdateInProgress(false);
  };

  const updateTaskForm = useMemo(
    () => ({
      ...updateTaskDefaultForm,
      id: { ...updateTaskDefaultForm.id, defaultValue: id },
      description: {
        ...updateTaskDefaultForm.description,
        defaultValue: task?.description || "",
      },
      labels: {
        ...updateTaskDefaultForm.labels,
        defaultValue: task?.labels ? task.labels.map((item) => item.id) : [],
      },
      participants: {
        ...updateTaskDefaultForm.participants,
        defaultValue: task?.participants ? task.participants.map((item) => item.id) : [],
      },
      processDescription: {
        ...updateTaskDefaultForm.processDescription,
        defaultValue: task?.processDescription || "",
      },
    }),
    [id, task?.description, task?.labels, task?.participants, task?.processDescription]
  );

  const onDocUpdate = () => {
    getData(task?.id || 0);
  };

  const { execute: addParticipantsToTask } = useBaseRequest(TaskGateway.addParticipantsToTask, {
    onCompleted: () => {
      getData(id);
      refetchTask();
    },
    onError: (error) => {
      danger(error?.message || t("ERRORS.UNKNOWN"));
    },
  });
  const { execute: removeParticipantsFromTask, loading: removeParticipantsLoading } =
    useBaseRequest(TaskGateway.removeParticipantsFromTask, {
      onCompleted: () => {
        getData(id);
        refetchTask();
      },
      onError: (error) => {
        danger(error?.message || t("ERRORS.UNKNOWN"));
      },
    });

  const updateTaskHandler = async (data: UpdateTaskDTO) => {
    const findNewLabels = data.labels.filter(
      (item) => !task?.labels?.find((label) => label.id === item)
    );
    const findDeletedLabels = task?.labels?.filter(
      (item) => !data.labels.find((label) => label === item.id)
    );

    if (findNewLabels.length) {
      addLabelsToElement({ id: data.id, labelIds: findNewLabels, type: "task" });
    }
    if (findDeletedLabels && findDeletedLabels.length) {
      removeLabelsFromElement({
        id: data.id,
        labelIds: findDeletedLabels.map((item) => item.id),
        type: "task",
      });
    }
    const findNewParticipants = data.participants.filter(
      (item) => !task?.participants?.find((participant) => participant.id === item)
    );
    const findDeletedParticipants = task?.participants?.filter(
      (item) => !data.participants.find((participant) => participant === item.id)
    );

    if (findNewParticipants && findNewParticipants.length) {
      addParticipantsToTask({
        taskId: data.id,
        userIds: findNewParticipants,
      });
    }
    if (findDeletedParticipants && findDeletedParticipants.length) {
      removeParticipantsFromTask({
        taskId: data.id,
        userIds: findDeletedParticipants.map((item) => item.id),
      });
    }
    updateTask({ ...data });
  };

  return {
    task,
    taskId: id,
    loading,
    updating,
    updateTaskForm,
    updateTask: updateTaskHandler,
    updateInProgress,
    onCancelUpdate,
    onUpdateStart: () => {
      setUpdateInProgress(true);
    },
    user,
    onDocUpdate,
  };
};

export default useTask;

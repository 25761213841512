import {
  CreateDocumentDTO,
  GetDocumentsStatsResponse,
  IDocument,
  UpdateDocumentDTO,
  ValidateDocumentResponse,
  CreateDocumentSessionResponse,
  GetDocumentsBySessionIdResponse,
  UpdateSessionDocumentDTO,
} from "../../entities/IDocument";
import { IMetaDataRule, MetaData } from "../../entities/IMetaData";
import BaseInstance from "../BaseInstance";

const DocumentGateway = {
  getMetaDataForDocType: async ({ id }: { id: number }): Promise<IMetaDataRule[]> => {
    return BaseInstance.get(`/document-types/${id}/meta-data`);
  },

  processDocument: async ({
    id,
    docTypeId,
  }: {
    id: string;
    docTypeId: string;
  }): Promise<IMetaDataRule[]> => {
    return BaseInstance.post("/documents/process", { docId: id, docTypeId }, { timeout: 45000 });
  },

  createDocument: async ({
    docTypeId,
    file,
    metaData,
    draft,
  }: CreateDocumentDTO): Promise<IDocument> => {
    const formData = new FormData();
    formData.append("file", file);
    if (docTypeId) formData.append("docTypeId", JSON.stringify(docTypeId));
    if (metaData) formData.append("metaData", JSON.stringify(metaData));
    if (draft) formData.append("draft", JSON.stringify(draft));

    return BaseInstance.post("/documents", formData, {
      timeout: 300000 /* 5min timeout for large files or slow internet */,
    });
  },

  createDocumentSession: async ({
    files,
  }: {
    files: File[];
  }): Promise<CreateDocumentSessionResponse> => {
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    return BaseInstance.post("/upload-session", formData, {
      timeout: 300000 /* 5min timeout for large files or slow internet */,
    });
  },

  getDocuments: async (
    query: string
  ): Promise<{
    count: number;
    documents: IDocument[];
  }> => {
    return BaseInstance.get(`/documents${query}`);
  },

  getDocumentsBySessionId: async ({
    id,
  }: {
    id: string;
  }): Promise<GetDocumentsBySessionIdResponse> => {
    return BaseInstance.get(`/upload-session/${id}`);
  },

  getDocumentById: async ({ id }: { id: string }): Promise<IDocument> => {
    return BaseInstance.get(`/documents/${id}`);
  },

  getDocumentByIdAndMetaData: async ({
    id,
  }: {
    id: string;
  }): Promise<{
    document: IDocument;
    metaDataRules: IMetaDataRule[] | null;
  }> => {
    const document = await DocumentGateway.getDocumentById({ id });
    let metaDataRules = null;
    if (document.docTypeId) {
      metaDataRules = await DocumentGateway.getMetaDataForDocType({
        id: document.docTypeId,
      });
    }
    return { document, metaDataRules };
  },

  validateDocument: async (payload: UpdateDocumentDTO): Promise<ValidateDocumentResponse> => {
    return BaseInstance.post("/documents/validate", {
      docTypeId: payload.documentTypeId,
      metaData: payload.metaData,
    });
  },

  updateDocument: async (payload: UpdateDocumentDTO): Promise<IDocument> => {
    const formData = new FormData();
    formData.append("metaData", JSON.stringify(payload.metaData));
    if (payload.file) {
      formData.append("file", payload.file);
    }
    if (payload.documentTypeId) {
      formData.append("documentTypeId", `${payload.documentTypeId}`);
    }
    if (payload.status) {
      formData.append("status", payload.status);
    }
    return BaseInstance.put(`/documents/${payload.id}`, formData);
  },

  updateSessionDocument: async (
    payload: UpdateSessionDocumentDTO
  ): Promise<CreateDocumentSessionResponse> => {
    const formData = new FormData();
    formData.append("metaData", JSON.stringify(payload.metaData));
    if (payload.file) {
      formData.append("file", payload.file);
    }
    if (payload.documentTypeId) {
      formData.append("documentTypeId", payload.documentTypeId.toString());
    }
    if (payload.status) {
      formData.append("status", payload.status);
    }
    return BaseInstance.patch(
      `/upload-session/${payload.sessionId}/document/${payload.id}`,
      formData
    );
  },

  dismissDocument: async ({ id }: { id: string }): Promise<{ success: boolean }> => {
    return BaseInstance.delete(`/documents/${id}`);
  },
  dismissDocumentSession: async ({ id }: { id: string }) => {
    return BaseInstance.delete(`/upload-session/${id}`);
  },

  updateDocuments: async ({
    documentIds,
    metaData,
  }: {
    documentIds: number[];
    metaData: MetaData;
  }): Promise<IDocument[]> => {
    return BaseInstance.patch("/documents", { docIds: documentIds, metaData });
  },

  getDocumentsStats: async (query: string): Promise<GetDocumentsStatsResponse> => {
    return BaseInstance.get(`/documents/stats${query}`);
  },

  addNewDocumentToSession: async ({
    file,
    id,
  }: {
    file: File;
    id: string;
  }): Promise<CreateDocumentSessionResponse> => {
    const formData = new FormData();
    formData.append("file", file);
    return BaseInstance.post("/upload-session/" + id + "/add-document", formData, {
      timeout: 300000 /* 5min timeout for large files or slow internet */,
    });
  },
  deleteDocumentFromSession: async ({
    id,
    documentId,
  }: {
    id: string;
    documentId: string;
  }): Promise<CreateDocumentSessionResponse> => {
    return BaseInstance.delete("/upload-session/" + id + "/document/" + documentId);
  },
  exportArchiveStats: async (query: string): Promise<string> => {
    return BaseInstance.get(`/documents/stats/export-report${query}`);
  },
  getDocumentFile: async (url: string): Promise<Blob> => {
    return BaseInstance.get(url, { responseType: "blob" });
  },
};

export default DocumentGateway;

import { useTranslation } from "react-i18next";
import { SelectProps } from "./Select.types";
export default function Select<T>({
  options,
  value,
  onChange,
  disabled,
  error,
  title,
  defaultLabel = "SELECT_OPTION",
  className = "",
  labelPosition = "top",
}: SelectProps<T>) {
  const { t } = useTranslation();
  const optionL = value === "" ? [...options, { label: t(defaultLabel), value: "" }] : options;
  return (
    <div
      className={` ${className}`}
      style={
        labelPosition === "left"
          ? {
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "center",
            }
          : {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }
      }
    >
      {!!title && <label style={{ whiteSpace: "nowrap" }}>{title}</label>}
      <select
        className={`form-select${error ? " is-invalid" : ""}  ${
          disabled ? "bg-light" : ""
        } cursor-text`}
        style={{ minWidth: "160px" }}
        value={value === null || typeof value === "undefined" ? "" : JSON.stringify(value)}
        onChange={(event) => {
          onChange(JSON.parse(event.target.value));
        }}
        disabled={disabled}
      >
        {options
          ? optionL.map((option, index) => (
              <option
                className={option.value === "" ? "d-none" : ""}
                key={index}
                value={JSON.stringify(option.value)}
              >
                {option.label}
              </option>
            ))
          : null}
      </select>
    </div>
  );
}

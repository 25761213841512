import { useParams } from "react-router-dom";
import useBaseRequest from "../../api/BaseRequest";
import TaskGateway from "../../api/gateways/TaskGateway";
import { useToast } from "../toast";
const useLogs = () => {
  const { id } = useParams();
  const { danger } = useToast();
  const { execute, loading, data } = useBaseRequest(TaskGateway.getTaskLogsById, {
    autoFetch: true,
    initialPayload: id,
    onCompleted: (data) => {},
  });
  return {
    logs: data?.taskLogs || [],
    loading,
  };
};
export default useLogs;

import PDF from "./utility/PDF";

export function usePDF() {

  const splitIntoPages = async (file: File) => {
    try {
      return PDF.splitIntoPages(file);
    } catch (error) {
      console.log("PDF ERROR");
    }
  };

  const splitIntoImages = async (file: File) => {
    try {
      return PDF.splitIntoImages(file);
    } catch (error) {
      console.log("PDF ERROR");
    }
  };

  const splitIntoImagePromises = async (file: File) => {
    try {
      return PDF.splitIntoImagePromises(file);
    } catch (error) {
      console.log("PDF ERROR");
    }
  };

  const groupIntoSingle = async (files: File[], filename: string) => {
    try {
      return PDF.groupIntoSingle(files, filename);
    } catch (error) {
      console.log("PDF ERROR");
    }
  };

  return { splitIntoPages, groupIntoSingle, splitIntoImages, splitIntoImagePromises };
}

import { useEffect, useMemo, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import { useToast } from "../../components/toast";
import {
  CUSTOM_FIELD_SUCCESS_MESSAGES,
  IMetaDataItem,
  MetaDataType,
} from "../../entities/IMetaData";
import { useTranslation } from "react-i18next";
import MetaDataGateway from "../../api/gateways/MetaDataGateway";
import { useLocation } from "react-router-dom";
import { TableRow } from "../../components/table";
import { mapCustomFieldToTableRow } from "./utils/mapCustomFieldToTableRow";
import { translation } from "../../components/translations";
import {
  customFieldFilters as customFieldDefaultFilters,
  createCustomFieldForm as createCustomFieldDefaultForm,
} from "./CustomFields.types";

export function useCustomFields() {
  const { t } = useTranslation();
  const location = useLocation();
  const { success } = useToast();
  const [isVisibleCreateCustomFieldModal, setIsVisibleCreateCustomFieldModal] =
    useState<boolean>(false);
  const [customFieldCount, setCustomFieldCount] = useState<number>(0);
  const [customFieldsTableData, setCustomFieldsTableData] = useState<TableRow[]>([]);

  useEffect(() => {
    getCustomFields(location.search || "?");
  }, [location.search, translation.language]);

  const showCreateCustomFieldModalHandler = () => setIsVisibleCreateCustomFieldModal(true);
  const hideCreateCustomFieldModalHandler = () => setIsVisibleCreateCustomFieldModal(false);

  const { execute: createCustomField, loading: creatingCustomField } = useBaseRequest(
    MetaDataGateway.createCustomField,
    {
      onCompleted: (data) => {
        success(t(CUSTOM_FIELD_SUCCESS_MESSAGES.saved));
        setIsVisibleCreateCustomFieldModal(false);
        getCustomFields(location.search || "?");
      },
    }
  );

  const {
    data,
    execute: getCustomFields,
    loading: fetchingCustomFields,
  } = useBaseRequest(MetaDataGateway.getCustomFields, {
    onCompleted: (data) => {
      setCustomFieldCount(data.count);
      setCustomFieldsTableData(data.metaDataItems.map((item) => mapCustomFieldToTableRow(item, t)));
    },
  });

  const customFieldFilters = useMemo(() => {
    return {
      ...customFieldDefaultFilters,
      dataType: {
        ...customFieldDefaultFilters.dataType,
        options: Object.values(MetaDataType).map((dataType) => ({
          value: dataType,
          label: t("CUSTOM_FIELDS.DATA_TYPE." + dataType),
        })),
      },
    };
  }, [translation.language]);

  const createCustomFieldForm = useMemo(() => {
    const customFieldDataTypeOptions = Object.values(MetaDataType).map((type) => ({
      value: type,
      label: t("CUSTOM_FIELDS.DATA_TYPE." + type),
    }));

    return {
      ...createCustomFieldDefaultForm,
      dataType: {
        ...createCustomFieldDefaultForm.dataType,
        options: customFieldDataTypeOptions,
        defaultValue: customFieldDataTypeOptions[0].value,
      },
    };
  }, [translation.language]);

  return {
    showCreateCustomFieldModalHandler,
    hideCreateCustomFieldModalHandler,
    isVisibleCreateCustomFieldModal,
    createCustomField,
    creatingCustomField,
    customFieldsTableData,
    customFieldCount,
    fetchingCustomFields,
    customFieldFilters,
    createCustomFieldForm
  };
}

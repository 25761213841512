import { Language } from "../components/translations/language";
import { IDbEntity } from "./IDbEntity";

export interface IMetaDataRule extends IDbEntity {
  required: boolean;
  editable: boolean;
  defaultValue: string;
  validation?: IValidationRule[];
  item: IMetaDataItem;
}

export interface createCustomFieldDTO {
  dataType: MetaDataType;
  enLabel: string;
  srbLabel: string;
  options?: string[]
}

export enum MetaDataValidators {
  REQUIRED = "required",
  MIN_CHAR = "minChar",
  MAX_CHAR = "maxChar",
  EMAIL = "email",
  PASSWORD = "password",
  NUMBER = "number",
  NUMERIC_ONLY = "numericOnly",
  REGEX = "regex",
}

export interface IValidationRule {
  type: MetaDataValidators;
  params?: { [key: string]: string | number | boolean | Date };
}

export interface MetaDataForm {
  [key: string]: string | number | Date;
}

export interface IMetaDataItem extends IDbEntity {
  name: string;
  key: string;
  dataType: MetaDataType;
  allowedValues?: Array<{ key: string; value: string }>;
  translations?: { [key in Language]: string };
}

export interface MetaData {
  [key: string]: string | number | Date;
}

export enum MetaDataType {
  TEXT = "string",
  NUMBER = "integer",
  BOOL = "boolean",
  DATE = "date",
  SELECT = "select",
}

export const CUSTOM_FIELD_SUCCESS_MESSAGES = {
  saved: "DOCUMENT_TYPE.CUSTOM_FIELD_ADDED",
};

import { Filter } from "../../components/filters";
import { buildDateQueryOperator } from "../../components/filters/DateRangeFilter/utility/buildDateQuery";
import { IOption } from "../../components/radio-button/RadioButton.types";
import { TableHeaders } from "../../components/table";

export interface CSVRow {
  [key: string]: string | number;
}

export const reportsTableHeadersBasket: TableHeaders[] = [
  { name: "BASKET_ID", key: "basketId" },
  { name: "BASKET_NAME", key: "basketName" },
  { name: "AHT", key: "aht" },
  { name: "ART", key: "art" },
  { name: "ASSIGNED_TASKS", key: "assignedTasks" },
  { name: "IN_PROGRESS_TASKS", key: "inProgressTasks" },
  { name: "DONE_TASKS", key: "doneTasks" },
  { name: "OLDEST_TASK", key: "oldestTask" },
];

export const reportsTableHeadersAssignedUser: TableHeaders[] = [
  { name: "USER_ID", key: "userId" },
  { name: "USER_NAME", key: "userName" },
  { name: "AHT", key: "aht" },
  { name: "ART", key: "art" },
  { name: "ASSIGNED_TASKS", key: "assignedTasks" },
  { name: "IN_PROGRESS_TASKS", key: "inProgressTasks" },
  { name: "DONE_TASKS", key: "doneTasks" },
  { name: "OLDEST_TASK", key: "oldestTask" },
];

export const reportsTableHeadersDocumentType: TableHeaders[] = [
  { name: "DOCUMENT_TYPE", key: "documentType" },
  { name: "ASSIGNED_TASKS", key: "assignedTasks" },
  { name: "IN_PROGRESS_TASKS", key: "inProgressTasks" },
  { name: "DONE_TASKS", key: "doneTasks" },
  { name: "OLDEST_TASK", key: "oldestTask" },
];

export const groupByOptions: IOption[] = [
  {
    label: "Basket",
    name: "group-by-types",
    value: "BASKET",
  },
  {
    label: "User",
    name: "group-by-types",
    value: "ASSIGNED_USER",
  },
  {
    label: "Document type",
    name: "group-by-types",
    value: "DOCUMENT_TYPE",
  },
];

export enum ReportsGroupByEnum {
  BASKET = "BASKET",
  ASSIGNED_USER = "ASSIGNED_USER",
  DOCUMENT_TYPE = "DOCUMENT_TYPE",
}

export type ReportFilters = {
  assignedUserIds: Filter;
  basketIds: Filter;
  documentTypeIds: Filter;
  createdAt: Filter;
};
export const reportTableFilters: Filter[] = [
  {
    type: "multiple-choice-autocomplete",
    name: "FILTERS.ASSIGNEES",
    options: [{ label: "users", value: "users" }],
    buildQuery: (filter, values) => {
      return {
        name: "assignedUserIds",
        type: "numberArray",
        operator: "in",
        value: values,
      };
    },
  },
  {
    type: "multiple-choice-autocomplete",
    name: "FILTERS.BASKETS",
    options: [{ label: "baskets", value: "baskets" }],
    buildQuery: (filter, values) => {
      return {
        name: "basketIds",
        type: "numberArray",
        operator: "in",
        value: values,
      };
    },
  },
  {
    type: "multiple-choice-autocomplete",
    name: "FILTERS.DOCUMENT_TYPE",
    options: [{ label: "document types", value: "document-types" }],
    buildQuery: (filter, values) => {
      return {
        name: "documentTypeIds",
        type: "stringArray",
        operator: "in",
        value: values,
      };
    },
  },
  {
    type: "date-range",
    name: "FILTERS.DATE_CREATED",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildDateQueryOperator(values);
      return {
        name: "createdAt",
        type,
        operator,
        value: value,
      };
    },
  },
];

import { useNavigate } from "react-router-dom";
import "./Logo.style.css";
import assests from "../../config/assets";
import {
  LogoProps,
  logoBigMargin,
  logoBigWidth,
  logoSmallMargin,
  logoSmallWidth,
} from "./Logo.types";

export const Logo = ({ isBig }: LogoProps) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate("/");
      }}
      className={"d-flex justify-content-center align-items-center width-100 logo"}
    >
      <img
        src={isBig ? assests.images.logoBigOnDark : assests.images.logoSmallOnDark}
        alt="Aurora DMS logo"
      />
    </div>
  );
};

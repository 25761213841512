import { useTranslation } from "react-i18next";
import { DocumentStatus, IDocument } from "../../entities/IDocument";
import { IUser, UserRoles } from "../../entities/IUser";
import environment from "../../config/environment";

export const useDocumentActions = () => {
  const { t } = useTranslation();
  const getActions = (document: IDocument, user: IUser | null) => {
    const task = document.task;
    const actions: any[] = [
      { value: "edit_document", label: t("DOCUMENTS.EDIT") },
      { value: "delete_document", label: t("DOCUMENTS.DELETE") },
    ];

    if (document.status === DocumentStatus.ARCHIVED) {
      return [];
    }
    if (task) {
      actions.push({
        label: t("TASKS.VIEW_LABEL"),
        value: "view_task",
      });
    }
    if (environment.modulesEnabled.digitalSignature && document?.digitalSignRequest) {
      actions.push({
        label: t("DOCUMENTS.VIEW_DIGITAL_SIGNATURE"),
        value: "view_digital_signature",
      });
    }
    if (
      environment.modulesEnabled.digitalSignature &&
      !task &&
      !document?.digitalSignRequest &&
      document.files?.[0]?.mimeType === "application/pdf"
    ) {
      actions.push({
        value: "request_digital_signature",
        label: t("DOCUMENTS.CREATE_DIGITAL_SIGNATURE_REQUEST"),
      });
    }

    return actions;
  };
  return {
    getActions,
  };
};

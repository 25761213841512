import { IMetaDataItem, createCustomFieldDTO } from "../../entities/IMetaData";
import { processCustomFieldPayload } from "../../screens/custom-fields/utils/processCustomFieldPayload";
import BaseInstance from "../BaseInstance";

const MetaDataGateway = {
  getCustomFields: async (
    query: string
  ): Promise<{
    count: number;
    metaDataItems: IMetaDataItem[];
  }> => {
    const queryParams = new URLSearchParams(query);
    const customFieldFilter = `${JSON.stringify({
      name: "default",
      operator: "eq",
      value: "false",
      type: "boolean",
    })}`;
    queryParams.append("filters", customFieldFilter);

    return BaseInstance.get(`/metadata/search`, {
      params: queryParams,
    });
  },

  createCustomField: async (payload: createCustomFieldDTO): Promise<IMetaDataItem> => {
    return BaseInstance.post("/metadata", processCustomFieldPayload(payload));
  },
};

export default MetaDataGateway;

const getFirstAppliedName = (optionsMap: { [key: string]: string }, appliedValue: any[]) => {
  for (let value of appliedValue) {
    if (optionsMap[value].length <= 30) {
      return optionsMap[value];
    }
  }
};

const renderLongAppliedNames = (optionsMap: { [key: string]: string }, appliedValue: any[]) => {
  const firstApplied = getFirstAppliedName(optionsMap, appliedValue);

  return firstApplied ? (
    <>
      {firstApplied}
      {appliedValue.length > 1 ? (
        <span className="text-secondary" style={{ fontSize: 14 }}>{` (+${
          appliedValue.length - 1
        } others)`}</span>
      ) : (
        ""
      )}
    </>
  ) : (
    `${appliedValue.length} selected`
  );
};

export const renderAppliedChoices = (
  options: {
    label: string;
    value: string | number;
  }[],
  appliedValue: any[]
): string | JSX.Element => {
  let optionsMap: { [key: string]: string } = options.reduce((map, option) => {
    return { ...map, [option.value]: option.label };
  }, {});

  const totalAppliedText = appliedValue.reduce((totalText, selectedValue, index) => {
    const option = optionsMap[selectedValue];
    return totalText + (index > 0 ? ", " : "") + option;
  }, "");

  if (totalAppliedText.length < 40) return totalAppliedText;
  return renderLongAppliedNames(optionsMap, appliedValue);
};

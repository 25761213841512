import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
import { TableHeaders } from "../../components/table";
import { ILabel } from "../../entities/ILabel";

export const labelsTableHeaders: TableHeaders[] = [
  { name: "ID", key: "id", sortable: true },
  { name: "NAME", key: "name", sortable: true },
  { name: "COLOR", key: "color" },
  { name: "ACTIONS", key: "action" },
];

export interface CreateLabelModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  onSubmit: (data: any) => void;
  creating: boolean;
  createLabelForm: GenericForm<CreateLabelDTO>;
}

export const createLabelForm: GenericForm<CreateLabelDTO> = {
  name: {
    type: "input",
    name: "name",
    label: "TABLE.NAME",
    defaultValue: "",
    validators: [
      GenericFormValidator.required(),
      GenericFormValidator.minChar({
        limit: 3,
      }),
      GenericFormValidator.maxChar({
        limit: 128,
      }),
    ],
  },
  color: {
    type: "color",
    name: "color",
    label: "TABLE.COLOR",
    defaultValue: "#000000",
    validators: [],
  },
};

export interface UpdateLabelModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  onSubmit: (data: any) => void;
  updating: boolean;
  updateLabelForm: GenericForm<ILabel>;
}

export const updateLabelForm: GenericForm<ILabel> = {
  id: {
    type: "input",
    name: "id",
    label: "TABLE.ID",
    defaultValue: -1,
    validators: [],
    visibleIf: () => false,
  },
  name: {
    type: "input",
    name: "name",
    label: "TABLE.NAME",
    defaultValue: "",
    validators: [
      GenericFormValidator.required(),
      GenericFormValidator.minChar({
        limit: 3,
      }),
      GenericFormValidator.maxChar({
        limit: 128,
      }),
    ],
  },
  color: {
    type: "color",
    name: "color",
    label: "TABLE.COLOR",
    defaultValue: "#000000",
    validators: [],
  },
};

export interface CreateLabelDTO {
  name: string;
  color: string;
}

export interface DeleteLabelModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  onDelete: () => void;
  deleting: boolean;
}

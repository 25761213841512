import { useTranslation } from "react-i18next";
import { SelectAction } from "../../components/select";
import { TaskActionsProps } from "./Task.types";
import { useTaskActions } from "./useTaskActions";

export function TaskActions({ onActionSelected, task, user }: TaskActionsProps) {
  const { getActions } = useTaskActions();
  const { t } = useTranslation();
  return (
    <SelectAction
      defaultLabel={t("TASKS.TASK_ACTIONS_LABEL")}
      options={getActions(task, user)}
      onChange={onActionSelected}
    />
  );
}

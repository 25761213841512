import { Modal } from "react-bootstrap";
import { NewDocumentScreen } from "../../new-document";
import { Button } from "../../../components/button";
import { Icon } from "../../../modules/icon";
import { useTranslation } from "react-i18next";

export const UploadDocumentModal = ({
  id,
  onCancel,
  onDismiss,
}: {
  id: string;
  onCancel: (isDocumentCreated?: boolean) => void;
  onDismiss: (id: number) => void;
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={!!id} animation onHide={onCancel} fullscreen>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {t("NEW_DOCUMENT.TITLE")}
        </h5>
        <div>
          <Button type="button" className="btn btn-danger mr-2" onClick={() => onDismiss(+id)}>
            <Icon type={`fa-solid fa-trash`} />
            &nbsp;&nbsp;{t("NEW_DOCUMENT.DISMISS")}
          </Button>{" "}
          <Button type="button" className="btn btn-light " onClick={() => onCancel()}>
            <Icon type={`fa-solid fa-xmark`} className="pt-1" size={18} title="Close document" />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <NewDocumentScreen id={id} closeDocumentModal={onCancel} />
      </Modal.Body>
    </Modal>
  );
};

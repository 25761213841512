import { FiltersWithQuery } from "../../components/filters";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchWithQueryInput } from "../../components/search-input";
import useTasks from "./useTasks";
import { tasksTableHeaders } from "../../entities/ITask";
import TaskBulkActions from "../../components/task-bulk-actions/TaskBulkActions";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { tasksTableFilters } from "./TasksScreen.types";
import { useTranslation } from "react-i18next";
const TasksScreen = () => {
  const {
    tasks,
    loading,
    onRowPress,
    total,
    setSelectedTasks,
    selectedTasks,
    selectedAction,
    setSelectedAction,
    reassignBulkTasks,
    reassigningTasks,
    moveBulkTasks,
    movingTasks,
  } = useTasks();
  const { t } = useTranslation();
  return (
    <PageLayout
      title={t("ALL_TASK.TITLE")}
      filter={<FiltersWithQuery filters={tasksTableFilters([], t)} />}
      search={<SearchWithQueryInput placeHolder={t("TASKS.SEARCH")} />}
    >
      <div className="flex-column gap-2">
        <TaskBulkActions
          selectedTasks={selectedTasks}
          onDismiss={() => {
            setSelectedTasks([]);
          }}
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          onReassign={reassignBulkTasks}
          reassigning={reassigningTasks}
          moving={movingTasks}
          onMove={moveBulkTasks}
        />
        <Row>
          <Column>
            <TableWithQueryPaginationAndSort
              totalItems={total}
              loading={loading}
              onRowPress={onRowPress}
              headers={tasksTableHeaders}
              data={tasks}
              selection={false}
            />
          </Column>
        </Row>
      </div>
    </PageLayout>
  );
};
export default TasksScreen;
